import React, {Component} from "react";
import SvgGlobe from "./icons/SvgGlobe";
import SvgMegaphone from "./icons/SvgMegaphone";
import SvgTarget from "./icons/SvgTarget";
import {formatStatistics} from "../utils/helpers";


class ChallengeDonationBar extends Component {
    render() {
        const challenge = this.props.challenge;
        const complete = challenge.awareness_progress >= challenge.awareness_goal;
        const progress = ((challenge.awareness_progress / challenge.awareness_goal) * 100).toFixed(0)
        const labelInside = (progress > 15)
        return (
            <div>
                <div className='row-ac-jb'>
                    <div className='row-ac black-100 mb-4'>
                        <SvgMegaphone style={{width:24, height:24}}/>
                        <p className='body2-bold text-slate-700 ml-4'>
                            Awareness Goal:
                        </p>
                    </div>
                    <div className='row-ac black-100 mb-4'>
                        <p className='body2-bold text-slate-700 mr-4'>
                            {challenge.awareness_goal} Follows
                        </p>
                        <SvgTarget style={{width:20, height:20}}/>
                    </div>
                </div>
                <div className='w-100p br-8 bg-black-8 relative row-ac' style={{height: 28, overflow: "hidden", backgroundColor:"#054A9110"}}>
                    <div
                        className='bg-blue-500 row-ac'
                        style={{height: 28, width: String((challenge.awareness_progress / challenge.awareness_goal)*100.0) + "%"}}
                    >
                        {!complete && labelInside &&
                            <p className='body2-bold ml-8' style={{color: 'white', marginRight: 4}}>
                                {`${formatStatistics(challenge.awareness_progress, 1)} follows`}
                            </p>
                        }
                    </div>
                    {complete &&
                        <p className='body2-bold white-100 absolute' style={{right: 8}}>
                            {`${challenge.donations_progress}`}
                        </p>
                    }
                    {!complete && !labelInside && challenge.awareness_progress !== 0 &&
                        <p
                            className='body1-bold text-blue-500'
                            style={{
                                marginLeft: 4
                            }}>
                            {`${challenge.awareness_progress}`}
                        </p>
                    }
                </div>
            </div>
        )
    }
}

export default ChallengeDonationBar
