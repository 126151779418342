import { ref, onValue, query, update, get, orderByChild, startAt, endAt } from "firebase/database";
import { firebaseDatabase } from "./firebase";
import {addRemoveGroupMember} from "./messaging"
import moment from "moment";



export const editGroup = async (user, groupId, updates) => {
    if (!user) {
        return
    }
    try {
        await update(ref(firebaseDatabase, `chats/${groupId}`), updates);
    } catch (err) {
        console.log("Error updating group:", err)
    }
};


export const attachGroupsListener = (user, groupId, callback) => {
    if (!user) {
        return
    }
    try {
        const groupRef = ref(firebaseDatabase, `chats/${groupId}`)
        return onValue(groupRef, async groupSnapshot => {
                const group = groupSnapshot.val();
                group.id = groupSnapshot.key;
                callback(group);
            })
    } catch (err) {
        console.log("Error attaching group listener:", err)
    }
}


export const searchGroups = (user, text, callback) => {
    if (!user) {
        return
    }
    try {
        const chatsRef = ref(firebaseDatabase, 'chats');

         // Search for groups with names starting with 'text'
        const startText = text;
        const endText = text + "\uf8ff";
        const searchQuery = query(chatsRef, orderByChild('name'), startAt(startText), endAt(endText));

        get(searchQuery).then((groupsSnapshot) => {
            const groups = groupsSnapshot.val() || {};
            const formattedGroups = [];

            Object.entries(groups).forEach(([groupId, group]) => {
                if (group.deprecated) return
                group.id = groupId;
                group.memberNames = {...group.members};
                group.members = Object.keys(group.members);
                formattedGroups.push(group);
            });
            formattedGroups.sort((a,b) => {
                if (a.name < b.name) {
                    return -1
                }
                if (a.name > b.name) {
                    return 1
                }
                return 0
            });
            callback(formattedGroups);
        }).catch((error) => {
            console.error("Error fetching groups:", error);
            callback([]);
        });
    } catch (err) {
        console.log("Error attaching group listener:", err)
    }
}


export const requestGroup = async (user, groupId, username, name) => {
    if (!user) {
        return;
    }
    
    const groupRef = ref(firebaseDatabase, `chats/${groupId}`);
    const group = (await get(groupRef)).val();
    const currentRequests = {...group.requests};

    currentRequests[username] = {"name": name, "created_at": moment().unix()};
    const updates = {[`chats/${groupId}/requests`]:  currentRequests}

    await update(ref(firebaseDatabase), updates);
    return groupId;
};


export const acceptRejectGroupRequest = async (user, groupId, username, name, accepted) => {
    if (!user) {
        return;
    }
    
    const groupRef = ref(firebaseDatabase, `chats/${groupId}`);
    const group = (await get(groupRef)).val();
    const currentRequests = {...group.requests};

    currentRequests[username] = null;
    const updates = {[`chats/${groupId}/requests`]:  currentRequests}

    if (accepted) {
        await addRemoveGroupMember(user, groupId, username, name);
    }

    await update(ref(firebaseDatabase), updates);
    return groupId;
};