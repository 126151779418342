import axios from "./axios";

export const getUserInvites = async (user) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.get("/invites", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const createInvite = async (user, nonProfitId, link) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {non_profit_id: nonProfitId, link}
        const res = await axios.post("/invites", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const editInvite = async (user, invite_id, params_to_edit) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            updated_params: params_to_edit,
            invite_id
        }
        const res = await axios.put("/invites", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const acceptInvite = async (user, invite_id) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {invite_id}
        const res = await axios.post("/invites/accept", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}