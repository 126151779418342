import SvgRewindClock from "../icons/SvgRewindClock";

function GlobalInvitesWidget({totalAmount}) {
    const totalSent = totalAmount
    const max = 5000
    const pctSent = (totalSent / max) * 100
    return (
        <div className='flex-1'>
            <div className={'flex flex-row flex-wrap items-center justify-between'}>
                <p className={'text-3xl font-semibold'}>
                    Invite to Donate
                </p>
                <div className={'flex flex-row items-center gap-1'}>
                    <SvgRewindClock className={'text-blue-500'} width={16} height={16}/>
                    <p className={'text-blue-500'}>
                        Resets Each Week
                    </p>
                </div>
            </div>
            <p className='text-slate-700 text-lg mt-2'>
                Share invites with friends! If they join, <p className='text-blue-500'>we donate $1</p> to
                the Nonprofit of your choice. Up to
                $5,000 every week.
            </p>
            <div className='py-4'>
                <p className='text-xl font-semibold text-blue-500 mb-2'>
                    Donated This Week: ${totalSent}
                </p>
                <div className='rounded-xl bg-blue-200 overflow-hidden'>
                    <div className='bg-blue-500' style={{height: 28, width: pctSent + '%'}}/>
                </div>
                <div className='text-slate-500 text-sm font-medium mt-2 flex flex-row items-center justify-between'>
                    <p>
                        $0
                    </p>
                    <p>
                        ${max} max
                    </p>
                </div>
            </div>
        </div>
    )
}

export default GlobalInvitesWidget
