import React, {PureComponent} from "react";
import ModalComponent from "../ModalComponent";
import {Button} from "../atoms/Button";
import SvgFire from "../icons/SvgFire";
import HorizontalDivider from "../atoms/HorizontalDivider";
import CountUp from "react-countup";

class StreakModal extends PureComponent {
    render() {
        const identifyer = this.props.sameUser ? `Your` : `${this.props.name}'s`
        return (
            <ModalComponent toggle={this.props.close}
                            isOpen={this.props.isOpen}
                            noPadding
                            size={'md'}
                            header={`${identifyer} Streak of Good`}
                            headerToggle
                            FooterComponent={
                                <div className='flex flex-row items-center gap-3'>
                                    <Button onClick={this.props.close} variant={'secondary'}>
                                        Close
                                    </Button>
                                </div>
                            }
            >
                <div className="flex flex-col px-5 pb-3 text-blue-500">
                    <div className='flex flex-row mx-auto items-end'>
                        <p className='text-6xl font-bold'>
                            <CountUp end={this.props.streak}/>d
                        </p>
                        <SvgFire className={'mb-1.5'}/>
                    </div>
                    <p className='text-xl font-semibold mx-auto'>
                        {identifyer} Streak
                    </p>
                </div>
                {!!this.props.longestStreak &&
                    <div
                        className='flex flex-row items-center justify-between rounded-lg border-slate-200 border p-3 mx-4 mb-3'>
                        <p className='text-sm text-slate-700'>
                            {identifyer} longest streak:
                        </p>
                        <p className='font-semibold'>
                            {this.props.longestStreak} day{this.props.longestStreak !== 1 && 's'}
                        </p>
                    </div>
                }
                <HorizontalDivider className={'bg-slate-200'}/>
                <div className='p-5 flex flex-col gap-2'>
                    <p className='text-lg font-semibold'>
                        What is <span className='lowercase'>{identifyer}</span> <span>Streak of Good</span>?
                    </p>
                    <p className='text-slate-700'>
                        This number tracks your activity across Starfish! Stay active to increase your streak and stand
                        out for your continued impact! Each consecutive day you interact on the platform increases your
                        streak.
                    </p>
                </div>
            </ModalComponent>
        );
    }
}

export default (StreakModal);
