import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 140.07 135.12"
    {...props}
  >
    <defs>
      <style>{".cls-2{fill:#6a93c6}"}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="OBJECTS">
        <path
          d="M12.88 81.57C4.9 86.82 4.23 99 9.15 107.24s13.75 13.13 22.46 17c11.82 5.31 24.31 9.45 37.22 10.56s26.31-1 37.56-7.38c20.84-11.9 31.16-36.77 33.25-60.67.89-10.1.54-20.45-2.48-30.13S128.22 18 119.68 12.51c-4.57-2.93-10.23-4.82-15.46-3.32-5.4 1.54-9.31 6.47-11 11.82S91.63 32.11 92 37.7s-.18 13.12-5.68 14.3c-4.64 1-8.27-3.64-11.53-7.07-5.79-6.13-14.58-9.81-22.85-8.12S36.66 46.35 37.3 54.76c.59 7.82 6.85 13.78 11.52 20.08 1.39 1.88 2.73 4.16 2.18 6.44-1.13 4.73-14.24-.39-17.74-1-6.77-1.28-14.21-2.76-20.38 1.29Z"
          style={{
            fill: "#054A91",
          }}
        />
        <path
          className="cls-2"
          d="M47.54 2.65A3.39 3.39 0 1 1 44.15 6a3.38 3.38 0 0 1 3.39-3.35ZM35.18.36a3.39 3.39 0 1 1-1.52 4.54A3.38 3.38 0 0 1 35.18.36ZM40.43 14.38a3.39 3.39 0 1 1 1.51 4.55 3.38 3.38 0 0 1-1.51-4.55ZM27.29 10.32a3.39 3.39 0 1 1 0 4.79 3.39 3.39 0 0 1 0-4.79ZM33 23.43a3.39 3.39 0 1 1 .94 4.7 3.39 3.39 0 0 1-.94-4.7Z"
        />
        <circle className="cls-2" cx={22.02} cy={21.72} r={3.39} />
        <path
          className="cls-2"
          d="M23.13 32.72a3.39 3.39 0 1 1 .53 4.77 3.38 3.38 0 0 1-.53-4.77ZM10.21 32.25a3.39 3.39 0 1 1 .53 4.75 3.39 3.39 0 0 1-.53-4.75ZM13.27 45a3.39 3.39 0 1 1 .53 4.77 3.38 3.38 0 0 1-.53-4.77ZM.74 45.35a3.39 3.39 0 1 1 .53 4.77 3.38 3.38 0 0 1-.53-4.77ZM.74 56.11a3.39 3.39 0 1 1 .68 4.74 3.38 3.38 0 0 1-.68-4.74Z"
        />
      </g>
    </g>
  </svg>
)

export default SvgComponent
