import React, {useState} from 'react';
import OnboardingStepHeader from "../Onboarding/OnboardingStepHeader";
import NonProfitSearchDropdown from "../../components/atoms/NonProfitSearchDropdown";
import {TypeAnimation} from "react-type-animation";

function CreateChallengeNonProfitSearch({goNextStep, setEin, setNpName, setSelectedNp}) {

    const onSelect = (np) => {
        setEin(np.ein);
        setNpName(np.name)
        goNextStep();
        setSelectedNp(np)
    }


    return (
        <div className='flex flex-col gap-8 w-[460px]'>
            <OnboardingStepHeader
                title={'What nonprofit do you want to support?'}
                description={"First, you'll need to link your challenge to a nonprofit. Search to find the one you want."}
            />
            <NonProfitSearchDropdown
                categories={[]}
                city={"AB"}
                state={"BA"}
                onSelect={onSelect}
            />
        </div>
    );
}

export default CreateChallengeNonProfitSearch;
