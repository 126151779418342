import React, {Component} from "react";
import navlogo from '../img/brand/LOGO-PRIMARY.png'
import BGGraphic8 from "../img/graphics/BGGraphic8";
import BGGraphic5 from "../img/graphics/BGGraphic5";
import BGGraphic10 from "../img/graphics/BGGraphic10";
import {Link} from "react-router-dom";
import {connect} from "react-redux";


function mapStateToProps(state) {
    return {
        mobile: state.userReducer.mobile,
    }
}

const DISABLED_ROUTES = ["/login", "/checkout"]

const FOOTER_LINKS_1 = [
    {
        title: 'Twitter'
    },
    {
        title: 'Discord'
    },
    {
        title: 'Instagram'
    },
    {
        title: 'LinkedIn'
    },
    {
        title: 'TikTok'
    },
    {
        title: 'Facebook'
    }
]

const FOOTER_LINKS_2 = [
    {
        title: 'FAQ'
    },
    {
        title: 'Contact Us'
    },
    {
        title: 'Suggestions'
    }
]

const FOOTER_LINKS_3 = [
    {
        title: 'Privacy Notice',
        link: 'https://app.termly.io/document/privacy-policy/3cc64057-0435-44b3-b613-35eea60b52a7'
    },
    {
        title: 'Terms / Conditions',
        link: 'https://app.termly.io/document/terms-of-use-for-website/eaf25433-f52f-4673-ae16-e16370111fea'
    },
    {
        title: 'Cookie Policy',
        link: 'https://app.termly.io/document/cookie-policy/a7511792-e72f-4331-809a-a3c08dc38786'
    },
    {
        title: 'Data Request Form',
        link: 'https://app.termly.io/notify/3cc64057-0435-44b3-b613-35eea60b52a7'
    },
]

class Footer extends Component {

    render() {
        const path = window.location.pathname;
        if (DISABLED_ROUTES.includes(path)) {
            return <div/>
        }
        if (this.props.mobile) {
            return (
                <div className='footer-mobile relative'>
                    {/*<div className='absolute cover-parent hero-wrapper' style={{zIndex: -1, opacity: .3}}/>*/}
                    {/*<div className='absolute cover-parent footer-wrapper' style={{zIndex: -1}}/>*/}
                    <div className='col-plain'>
                        <div className='row-ac'  style={{flexWrap:"wrap"}}>
                            {FOOTER_LINKS_3.map(link =>
                                <a href={link.link} target={'_blank'} key={link.link}
                                   className='body3 black-50 black-100-h pointer mr-16 pointer mb-16'
                                   style={{textDecoration: "none"}}
                                >
                                    {link.title}
                                </a>
                            )}
                            <Link style={{textDecoration: "none"}} to={'/community-guidelines'}>
                                <div className='body3 black-50 black-100-h pointer mr-16 pointer mb-16'
                                >
                                    Community Guidelines
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className='col-plain mt-12' style={{alignItems: 'flex-start', marginLeft: -12}}>
                        <div className='row-ac'>
                            <img src={require('../img/brand/logo.png')} alt={'Icon'}
                                 style={{width: 40, filter: "grayscale(100%)", opacity: .2}}/>
                            <p className='body3 black-30 ml-12'>
                                © Starfish 2024
                            </p>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='relative'>
                    <BGGraphic5 className={'absolute'} width={'15vw'}
                                style={{zIndex: 1, top: '-12vw', right: -8}}/>
                    <div className='footer relative bg-white-pure'>
                        <div className='absolute cover-parent' style={{zIndex: 0, opacity: .3}}/>
                        {/*<div className='absolute cover-parent footer-wrapper' style={{zIndex: -1}}/>*/}
                        <div className='footer-content'>
                            <div className='row-ac' style={{}}>
                                <img src={require('../img/brand/logo.png')} alt={'Icon'}
                                     style={{width: 60, filter: "grayscale(100%)", opacity: .2}}/>
                                <p className='body3 black-50 ml-12'>
                                    © Starfish 2024
                                </p>
                            </div>
                            <div className='row-ac relative' style={{zIndex: 999}}>
                                {FOOTER_LINKS_3.map(link =>
                                    <a href={link.link} target={'_blank'} key={link.link}
                                       className='body3 black-50 black-100-h pointer mr-12 pointer'
                                       style={{textDecoration: "none"}}
                                    >
                                        {link.title}
                                    </a>
                                )}
                                <Link style={{textDecoration: "none"}} to={'/community-guidelines'}>
                                    <div className='body3 black-50 black-100-h pointer mr-12 pointer'
                                    >
                                        Community Guidelines
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default connect(mapStateToProps)(Footer);