import React, {Component} from 'react';
import '../styles/AnimatedInput.css'

class AnimatedInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFocused: false,
        };
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleFocus() {
        this.setState({isFocused: true});
    }

    handleBlur() {
        this.setState({isFocused: false});
    }

    handleChange(event) {
        const {value} = event.target;
        const {onChange} = this.props;
        if (onChange) {
            onChange(value);
        }
    }

    render() {
        const {isFocused} = this.state;
        const {name, type, placeholder, value} = this.props;

        return (
            <div className={`animated-input`}>
                {this.props.multiline ?
                    <textarea
                        className="animated-input__input body1"
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}
                        id={name}
                        value={value}
                        style={{height: this.props.height || 160}}
                    />
                    :
                    <input
                        type={type}
                        className="animated-input__input body1"
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}
                        id={name}
                        value={value}
                    />
                }
                {this.props.multiline ?
                    <label
                        className={`body2 animated-multiline-input__label ${isFocused ? 'animated-multiline-input__label--focused' : value ? 'animated-multiline-input__label--filled' : ''}`}
                        htmlFor={name}
                    >
                        {placeholder}
                    </label>
                    :
                    <label
                        className={`body2 animated-input__label ${isFocused ? 'animated-input__label--focused' : value ? 'animated-input__label--filled' : ''}`}
                        htmlFor={name}
                    >
                        {placeholder}
                    </label>
                }
            </div>
        );
    }
}

export default AnimatedInput;
