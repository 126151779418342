import React, {PureComponent} from "react";
import {connect} from "react-redux";
import { editUser } from "../../api/users";
import ModalComponent from "../ModalComponent";
import {Button} from "../atoms/Button";
import TextInput from "../inputs/TextInput";
import Dropzone from "react-dropzone";
import { uploadDoc } from "../../api/firebase";
import SvgAddCircle from "../icons/SvgAddCircle";
import SearchDropdown from "../atoms/SearchDropdown";


function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
    }
}

class EditProfileHeaderModal extends PureComponent {
    state = {
        coverUrl: "",
        profileImageUrl: "",
        firstName: "",
        lastName: "",
        tagline: "",
        city: "",
        state: "",
        coverError: false,
        profileError: false,
    }

    handleInputChange = (inputName, value) => {
        this.setState({[inputName]: value})
    }

    componentDidMount = () => {
        const user = this.props.user || {};
        this.setState({
            firstName: user.first_name,
            lastName: user.last_name,
            city: user.city,
            state: user.state,
            tagline: user.tagline,
            profileImageUrl: `https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/users%2Fprofile_picture%2F${user.username}.jpeg?alt=media`,
            coverUrl: `https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/users%2Fcover_photo%2F${user.username}.jpeg?alt=media`
        });
    }

    submit = async () => {
        const {coverUrl, profileImageUrl, firstName, lastName, tagline, city, state, coverError, profileError} = this.state;
        const updatedParams = {
            first_name: firstName,
            last_name: lastName,
            city,
            state,
            tagline
        }
        const newUser = {...this.props.user, ...updatedParams};
        this.props.setUser(newUser);


        await editUser(this.props.authUser, updatedParams);
        this.props.close()
    }

    onDropCover = async (acceptedFiles) => {
        const user = this.props.user;
        const url = await uploadDoc("users/cover_photo/", user.username + ".jpeg", acceptedFiles[0]);
        this.setState({coverUrl: url});
    }

    onDropProfileImage = async (acceptedFiles) => {
        const user = this.props.user;
        const url = await uploadDoc("users/profile_picture/", user.username + ".jpeg", acceptedFiles[0]);
        this.setState({profileImageUrl: url});
    }

    render() {
        const {coverUrl, profileImageUrl, firstName, lastName, tagline, city, state, coverError, profileError} = this.state;
        return (
            <ModalComponent toggle={this.props.close}
                            isOpen={this.props.isOpen}
                            noPadding
                            size={'lg'}
                            header={'Edit your profile'}
                            headerToggle
                            FooterComponent={
                                <div className='flex flex-row items-center gap-3'>
                                    <Button variant={'secondary'} onClick={this.props.close}>
                                        Cancel
                                    </Button>
                                    <Button variant={'default'} onClick={this.submit}>
                                        Save Changes
                                    </Button>
                                </div>
                            }
            >
                <div className='flex flex-col gap-2 px-5 pb-3'>
                    <Dropzone onDrop={(files) => this.onDropCover(files)}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()} className='cursor-pointer'>
                                <input {...getInputProps()} />
                                <div style={{}} className=''>
                                    {!!coverUrl && !coverError ? (
                                        <div
                                            className='h-24 w-full bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square overflow-hidden flex flex-col items-center justify-center border border-gray-200 rounded-lg'
                                        >
                                            <img
                                                className='h-24 w-full object-cover bg-white aspect-square cursor-pointer  rounded-lg'
                                                src={coverUrl}
                                                onError={() => this.setState({coverError: true})}
                                                alt="profile-pic"
                                            />
                                            {/* <div className='absolute flex flex-col items-center justify-center'>
                                                            <SvgEdit className={'text-blue-500'}/>
                                                            <p className='text-xs mt-2'>
                                                                Edit image
                                                            </p>
                                                        </div> */}
                                        </div>
                                    ) : (
                                        <div
                                            className='h-24 w-full bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square flex flex-col items-center justify-center border border-gray-200 rounded-lg'
                                        >
                                            <SvgAddCircle className={'text-blue-500'}/>
                                            <p className='text-xs mt-2'>
                                                Add cover
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </Dropzone>
                    <Dropzone onDrop={(files) => this.onDropProfileImage(files)}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()} className='cursor-pointer mt-[-48px] ml-4 mr-auto'>
                                <input {...getInputProps()} />
                                <div style={{}} className=''>
                                    {!!profileImageUrl && !profileError ? (
                                        <div
                                            className='w-20 h-20 bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square overflow-hidden flex flex-col items-center justify-center border border-gray-200 rounded-full'
                                        >
                                            <img
                                                className='w-20 h-20 object-cover bg-white aspect-square cursor-pointer  rounded-full'
                                                src={profileImageUrl}
                                                onError={() => this.setState({profileError: true})}
                                                alt="profile-pic"
                                            />
                                            {/* <div className='absolute flex flex-col items-center justify-center'>
                                                            <SvgEdit className={'text-blue-500'}/>
                                                            <p className='text-xs mt-2'>
                                                                Edit image
                                                            </p>
                                                        </div> */}
                                        </div>
                                    ) : (
                                        <div
                                            className='w-20 h-20 bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square flex flex-col items-center justify-center border border-gray-200 rounded-full'
                                        >
                                            <SvgAddCircle className={'text-blue-500'}/>
                                            <p className='text-xs'>
                                                Add image
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </Dropzone>
                    <div className=''>
                        <TextInput
                            label={'First Name'}
                            value={firstName}
                            onChange={(e) => this.handleInputChange("firstName", e.target.value)}
                            placeholder={'e.g. John'}
                        />
                    </div>
                    <div className=''>
                        <TextInput
                            label={'Last Name'}
                            value={lastName}
                            onChange={(e) => this.handleInputChange("lastName", e.target.value)}
                            placeholder={'e.g. Smith'}
                        />
                    </div>
                    <div className=''>
                        <SearchDropdown
                            city={city}
                            state={state}
                            setCity={(city) => this.setState({city})}
                            setState={(state) => this.setState({state})}
                        />
                    </div>
                    <div className='flex flex-col'>
                        <TextInput
                            label={'Tagline'}
                            value={tagline}
                            onChange={(e) => {
                                if (e.target.value.length <= 80) this.handleInputChange("tagline", e.target.value)
                            }}
                            placeholder={'e.g. Volunteer and Donor at the ASPCA'}
                        />
                        <p className="text-slate-700 text-sm pl-16 pt-1">{!!tagline ? tagline.length : 0} / 80</p>
                    </div>
                </div>
            </ModalComponent>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileHeaderModal);
