import React, {Component} from "react";
import SvgPiggy from "./icons/SvgPiggy";
import SvgTarget from "./icons/SvgTarget";
import {formatStatistics} from "../utils/helpers";


class ChallengeDonationBar extends Component {
    render() {
        const challenge = this.props.challenge;
        const yourContributions = challenge.your_contributions || {};
        const donated = yourContributions.direct_donations + yourContributions.referred_donations || 0;
        const complete = challenge.donations_progress >= challenge.donations_goal;
        const progress = ((challenge.donations_progress / challenge.donations_goal) * 100).toFixed(0)
        const labelInside = (progress > 15)
        return (
            <div>
                <div className='row-ac-jb'>
                    <div className='row-ac black-100 mb-4'>
                        <SvgPiggy style={{width: 20, height: 20}}/>
                        <p className='text-md font-semibold text-slate-700 ml-4'>
                            Contribution Goal:
                        </p>
                    </div>
                    <div className='row-ac text-md font-semibold text-slate-700 mb-4'>
                        {!!this.props.matching &&
                            <p className='body2-bold' style={{
                                color: '#F8969C',
                                marginRight: 4
                            }}>
                                +${String((this.props.matching * challenge.donations_progress) / 2)} matched
                            </p>
                        }
                        <p className=' mr-4'>
                            ${formatStatistics(challenge.donations_goal, 1)}
                        </p>
                        <SvgTarget style={{width: 20, height: 20}}/>
                    </div>
                </div>
                <div className='w-100p br-8 bg-black-8 relative row-ac' style={{
                    height: 28,
                    overflow: "hidden",
                    backgroundColor: !!this.props.matching ? '#F8969C30' : '#054A9110',
                }}>
                    <div
                        className='bg-blue-500 row-ac'
                        style={{
                            height: 28,
                            width: String((challenge.donations_progress / challenge.donations_goal) * 100.0) + "%"
                        }}
                    >
                        {!complete && labelInside &&
                            <p className='body2-bold ml-8' style={{color: 'white', marginRight: 4}}>
                                {`$${formatStatistics(challenge.donations_progress, 1)}`}
                            </p>
                        }
                    </div>
                    {(!!this.props.matching && progress < 90) &&
                        <div style={{
                            borderRadius: 8,
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            height: 25,
                            border: '1.5px solid #F8969C',
                            overflow: "hidden",
                            position: "relative",
                            // width: 20,
                            // position: "absolute",
                            // marginLeft: -6,
                        }} className='row-ac-je'>
                            <p className={'mh-4 body2-bold'} style={{color: '#F8969C', marginLeft: 8}}>
                                {String(this.props.matching)}x
                            </p>
                        </div>
                    }
                    {complete &&
                        <p className='body2-bold white-100 absolute' style={{right: 8}}>
                            {`$${formatStatistics(challenge.donations_progress, 1)}`}
                        </p>
                    }
                    {!complete && !labelInside && challenge.donations_progress !== 0 &&
                        <p
                            className='body1-bold text-blue-500'
                            style={{
                                color: !!this.props.matching && '#F8969C',
                                marginLeft: 4
                            }}>
                            {`$${formatStatistics(challenge.donations_progress, 1)}`}
                        </p>
                    }
                </div>
            </div>
        )
    }
}

export default ChallengeDonationBar
