import React, {useEffect, useState} from 'react';
import OnboardingStepper from "../Onboarding/OnboardingStepper";
import {connect} from "react-redux";
import { createGroup } from '../../api/messaging';
import {withRouter} from "react-router-dom";
import CreateGroupName from "./CreateGroupName";
import CreateGroupImage from "./CreateGroupImage";
import SvgSearch from "../../components/icons/SvgSearch";
import SvgTarget from "../../components/icons/SvgTarget";
import SvgTypeStyles from "../../components/icons/SvgTypeStyles";
import SvgCalendar from "../../components/icons/SvgCalendar";
import SvgAddPhoto from "../../components/icons/SvgAddPhoto";
import HorizontalStepper from "../../components/atoms/HorizontalStepper";


function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
    }
}


function CreateGroupMain(props) {
    const [step, setStep] = useState(1);
    const [switchingStep, setSwitchingStep] = useState(false);
    const [renderedStep, setRenderedStep] = useState(1);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [profilePic, setProfilePic] = useState('');
    const [imageId, setImageId] = useState('');
    const [isPrivate, setIsPrivate] = useState(false)

    const NUM_STEPS = 2;

    const submit = async () => {
        await createGroup(props.authUser, props.user, name, description, imageId, isPrivate);
        props.closeSheet();
        // window.location.reload();
    };

    useEffect(() => {
        if (!switchingStep) {
            setRenderedStep(step);
        }
    }, [switchingStep, step]);

    const changeStep = (newStep) => {
        setSwitchingStep(true);
        setTimeout(() => {
            setStep(newStep);
            setSwitchingStep(false);
        }, 300);
    }

    const goNextStep = () => {
        if (step === NUM_STEPS) {
            submit();
        }
        changeStep(step + 1);


    }

    const goBackStep = () => {
        changeStep(step - 1);
    }


    const stepStyle = {
        opacity: switchingStep ? 0 : 1,
        transform: switchingStep ? 'translateY(20px)' : 'translateY(0)',
        transition: 'opacity 300ms, transform 300ms'
    };

    const closeSheet = () => {
        setStep(1)
        setName('')
        setDescription('')
        props.closeSheet()
    }

    const renderStep = () => {
        switch (renderedStep) {
            case 1:
                return <CreateGroupName
                    name={name}
                    setName={setName}
                    description={description}
                    setDescription={setDescription}
                    isPrivate={isPrivate}
                    setIsPrivate={setIsPrivate}
                    goNextStep={goNextStep}
                    closeSheet={closeSheet}
                />;
            case 2:
                return <CreateGroupImage
                    profilePic={profilePic}
                    setProfilePic={setProfilePic}
                    setImageId={setImageId}
                    goNextStep={goNextStep}
                    goBackStep={goBackStep}
                    closeSheet={closeSheet}
                    user={props.user}
                />;
            default:
                return null;
        }
    };


    const steps = [
        { label: 'Add details', Icon: SvgTypeStyles },
        { label: 'Add picture', Icon: SvgAddPhoto}
    ];


    return (
        <div className='flex bg-white flex-col items-center h-[100vh] p-5 gap-5'>
            <HorizontalStepper steps={steps} currentStep={step} />
            <div className='flex flex-col items-center justify-center flex-1' style={stepStyle}>
                {renderStep()}
            </div>
        </div>
    );
}

export default withRouter(connect(mapStateToProps)(CreateGroupMain));
