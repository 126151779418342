import React, {Component} from "react";
import moment from "moment/moment";
import {Link, withRouter} from "react-router-dom";
import SvgMessage from "../components/icons/SvgMessage";
import NPOPicture from "./atoms/NPOPicture";
import SvgLike from "../components/icons/SvgLike";
import {CARD_STYLE} from "../utils/dummy";
import {Button} from "./atoms/Button";
import SvgUserPlus from "./icons/SvgUserPlus";
import SvgAt from "./icons/SvgAt";
import SvgLink2 from "./icons/SvgLink2";
import ProfilePicture from "./atoms/ProfilePicture";

class NotificationComponent extends Component {

    switchIcon = () => {
        switch (this.props.notification.type) {
            case'liked_post':
                return <SvgLike className={'scale-[1.2] text-blue-500 w-6 h-6'}/>
            case 'followed':
                return <SvgUserPlus className={'text-blue-500 w-6 h-6'}/>
            case 'tagged_post':
                return <SvgAt className={'text-blue-500 w-6 h-6'}/>
            case 'liked_comment':
                return <SvgLike className={'scale-[1.2] text-blue-500 w-6 h-6'}/>
            case 'commented':
                return <SvgMessage className={'text-blue-500 w-6 h-6'}/>
            case 'challenge_referral':
                return <SvgLink2 className={'text-blue-500 w-6 h-6'}/>
            default:
                return <SvgLike className={'scale-[1.2] text-blue-500 w-6 h-6'}/>
        }
    }

    switchCopy = () => {
        switch (this.props.notification.type) {
            case'liked_post':
                return 'liked your post'
            case 'followed':
                return 'followed you'
            case 'tagged_post':
                return 'tagged you in a post'
            case 'liked_comment':
                return 'liked your comment'
            case 'commented':
                return 'commented on your post'
            case 'challenge_referral':
                return 'invited you to a challenge'
            default:
                return ''
        }
    }

    switchOnClick = () => {
        const notif = this.props.notification;
        switch (notif.type) {
            case'liked_post':
                this.props.history.push({pathname: `/post/${notif.content.post_id}`, state: {previous: "Notifications"}})
                return;
            case 'followed':
                this.props.history.push({pathname: `/profile/${notif.content.user_id}`, state: {previous: "Notifications"}})
                return;
            case 'tagged_post':
                this.props.history.push({pathname: `/post/${notif.content.post_id}`, state: {previous: "Notifications"}})
                return;
            case 'liked_comment':
                this.props.history.push({pathname: `/post/${notif.content.post_id}`, state: {previous: "Notifications"}})
                return;
            case 'commented':
                this.props.history.push({pathname: `/post/${notif.content.post_id}`, state: {previous: "Notifications"}})
                return;
            case 'challenge_referral':
                return;
            default:
                return ''
        }

    }

    switchBody = () => {
        const notification = this.props.notification || {};
        const content = notification.content || {};
        switch (this.props.notification.type) {
            case'liked_post':
                return null
            case 'followed':
                return (
                    <div className='rounded-xl border border-slate-200 flex-1 flex flex-col gap-2 max-w-[450px] p-5'>
                        <div className='flex flex-row items-center justify-between'>
                            <ProfilePicture
                                initial={content.name[0]}
                                userID={content.user_id}
                            />
                            <Link to={'/profile/' + content.user_id}>
                                <Button variant={'default'} radius={'full'} size={'sm'}>
                                    View profile
                                </Button>
                            </Link>
                        </div>
                        <div className='flex flex-col gap-1'>
                            <p className='text-md text-slate-800 font-semibold'>
                                {content.name}
                            </p>
                            <p className='text-slate-700 text-md'>
                                {content.tagline}
                            </p>
                        </div>
                    </div>
                )
            case 'tagged_post':
                return null
            case 'liked_comment':
                return null
            case 'commented':
                return null
            case 'challenge_referral':
                return null
            default:
                return null
        }
    }

    render() {
        const notification = this.props.notification || {};
        const content = notification.content || {};
        return (
            <div className={`${CARD_STYLE} p-5 flex gap-3 flex-col border-b border-slate-100`} onClick={this.switchOnClick}>
                <div className='flex flex-row gap-3 flex-1'>
                    <div className='mt-2'>
                        {this.switchIcon()}
                    </div>
                    <div className='flex flex-col gap-3 flex-1'>
                        <div className='flex flex-row items-center gap-2'>
                            <div className='flex flex-row items-center justify-between'>
                            {content.np_post ?
                                <NPOPicture
                                    np={content}
                                    initial={content.name[0]}
                                    />
                                :
                                <ProfilePicture
                                    initial={content.name[0]}
                                    userID={content.user_id}
                                />
                            }
                            </div>
                            <span className='text-md text-slate-800 flex flex-1 inline'>
                                <span className='font-semibold'>
                                    {notification.content.name}
                                </span>
                                &nbsp;{this.switchCopy()}&nbsp;&nbsp;
                            <span className='text-md text-slate-500'>
                                {moment(notification.created_at).fromNow('')}
                            </span>
                            </span>
                        </div>
                        {this.switchBody()}
                    </div>
                </div>
            </div>
        )
    }

}


export default withRouter(NotificationComponent);
