import React, {Component} from "react";
import Navbar from "../components/Navbar";
import {motion} from "framer-motion";
import Footer from "../components/Footer";
import SvgArrowBack from "../components/icons/ArrowBack";
import {Link} from "react-router-dom";

class CommunityGuidelines extends Component {

    render() {
        return (
            <div className='col-plain'>
                <div className='col-ac pt-100'
                     style={{minHeight: '100vh', padding: this.props.mobile ? '80px 24px' : '0 10vw 10vh 10vw'}}>
                    <div className='col-plain'>
                        <div className='body1 black-80 mt-64'>
                            <Link to={'/'} style={{textDecoration: "none"}}>
                                <div className='row-ac mb-20 body2-bold pointer black-50 black-100-h'>
                                    <SvgArrowBack width={18} height={18}/>
                                    <div className='ml-12'>
                                        Back home
                                    </div>
                                </div>
                            </Link>
                            <div className='relative'>
                                <motion.div
                                    initial={{opacity: 0, transform: 'scale(1) translateY(8px)'}}
                                    whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                                    transition={{default: {ease: "linear"}, delay: .2}}
                                    className={this.props.mobile ? 'heading2 w-100p mb-20' : 'heading2 w-60p mb-20'}
                                    style={{lineHeight: 1.4}}>
                                    Community Policies
                                </motion.div>
                            </div>
                            <motion.div
                                initial={{opacity: 0, transform: 'scale(1) translateY(8px)'}}
                                whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                                transition={{default: {ease: "linear"}, delay: .2}}
                                className={!this.props.mobile ? 'w-80p' : 'w-100p'}>
                                <div className='title1 mb-12'>
                                    Starfish illuminates the good of our users and helps them promote their humanitarian
                                    persona.
                                </div>
                                Thank you for making Starfish a place where users treat each other with respect and
                                encouragement.
                                <br/><br/>
                                The Starfish community desires to inspire individuals and institutions to expand their
                                acts of good.
                                <br/><br/>
                                Starfish is where users bring and share conversations that are thoughtful, safe, uniting
                                and inspiring. A place where messages containing profanity, bullying, racist comments or
                                violence are not welcomed.
                                <br/><br/>
                                Starfish is where users come together to share stories of good and to stay better
                                connected with other likeminded people and the causes / nonprofits they care about. It’s
                                a place to learn more about the people and the organizations that are doing good.
                                Starfish helps users promote and be known for their good acts and to stay better
                                informed of how to support the nonprofit causes they believe in. Starfish enables users
                                to build more productive and positive relationships grounded in acts of good. The
                                content that you contribute should add to the Starfish community in a positive,
                                encouraging, uplifting and constructive manner.
                                <br/><br/>
                                Together, Starfish can make our community a place where everyone has a place to share
                                stories of good that inspire others to take action in times of struggle, difficulty and
                                suffering, which, in turn, makes every community a better place. On Starfish, everyone
                                is welcome to share their good, regardless of their economic situation, educational
                                background, geographical location or political beliefs.
                                <br/><br/>
                                Starfish welcomes you to please notify us if you see something you believe may violate
                                our policies, whether in profiles, posts, messages, comments, or anywhere else. Though
                                we have technology in place to assist us, please report it to us so we can keep our
                                dynamic platform safe, trustworthy and all about you and your good.
                                <br/><br/>
                                Violating our community policies can result in action against your account or content.
                                These policies apply to all members. Depending on the severity of the violation,
                                Starfish maintains the right to potentially limit the visibility of certain content,
                                label it, or remove it entirely. Repeated or flagrant offenses will result in account
                                restriction. If you believe action taken on your content or your account was in error,
                                you are free to notify us and we’ll happily review our actions.
                                <br/><br/>
                                Please do not post harsh or harassing content. Starfish does not allow profanity,
                                bullying or harassment. This includes targeted personal attacks, intimidation, shaming,
                                disparagement, and abusive language directed at other members. Do not reveal others'
                                personal or sensitive information (aka "doxing") or incite others to do the same. You
                                are not allowed or to encourage others to engage in trolling or other repetitive
                                negative content that disrupts other members or conversations on the platform.
                                <br/><br/>
                                Threatening, promoting or inciting violence is strictly forbidden. Starfish does not
                                allow threatening or inciting violence of any kind. We do not allow individuals or
                                groups that engage in or promote violence, property damage, or organized criminal
                                activity. You may not use Starfish to encourage support for such individuals or groups
                                or to otherwise glorify or promote violence.
                                <br/><br/>
                                Starfish is a safe place for adults and children to promote their humanitarian persona
                                and thus Starfish has a zero tolerance for content that depicts the sexual exploitation
                                of children. Do not share, post, transmit, or solicit child exploitation material
                                through or using our platform. Do not use Starfish in any way to facilitate, encourage,
                                or engage in the abuse or exploitation of children. When we become aware of any apparent
                                child exploitation, we will report it to the appropriate authorities.
                                <br/><br/>
                                Starfish does not allow any content that facilitates the purchase of illegal or
                                dangerous goods and/or services, prostitution, and escort services. We do not allow
                                content that promotes fake organizations, educational and/or professional
                                certifications. You may not use Starfish to promote or solicit donations to individuals,
                                for profit organizations, fake nonprofit organizations or nonprofit organizations not in
                                good standing with the IRS.
                                <br/><br/>
                                Do not use Starfish to sensationalize or capitalize on tragic events for commercial /
                                for profit purposes.
                                <br/><br/>
                                Starfish does not allow any terrorist organizations or violent extremist groups on our
                                platform. And we do not allow any individuals who affiliate with such organizations or
                                groups to have a Starfish profile. Content that depicts terrorist activity, that is
                                intended to recruit for terrorist organizations, or that threatens, promotes, or
                                supports terrorism in any manner is not allowed or tolerated.
                                <br/><br/>
                                We require you to use your true identity on Starfish and provide accurate information
                                about yourself or your organization. Only share information that is real and authentic.
                                Do not share content in a way that you know is, or think may be, misleading or
                                inaccurate. Do not share content to interfere with or improperly influence an election
                                or other civic process. We may prevent you from posting content from sites that are
                                known to produce or contain misinformation. Do not post “deepfake” images or videos of
                                others or otherwise post content that has been manipulated to deceive. Do not share
                                content or endorse someone or something in exchange for personal benefit (including
                                personal or family relationships, monetary payment, free products or services, or other
                                value), unless you have included a clear and conspicuous notice of the personal benefit
                                you receive and have otherwise complied with our policies.
                                <br/><br/>
                                We do not allow fake profiles or entities. Do not post misleading or deceptive
                                information about yourself, your business, your qualifications, work experience,
                                affiliations, or achievements. Do not use an image of someone else, or any other image
                                that is not your likeness, for your profile photo. Do not associate yourself on Starfish
                                with a business or organization with which you are not actually associated. Do not use
                                or attempt to use another person's Starfish account or create a member profile for
                                anyone other than yourself. And do not share your Starfish account with anyone else.
                                Do not use Starfish to facilitate romance scams, promote pyramid schemes, or otherwise
                                defraud members. Do not share malicious software that puts our members, platform, or
                                services at risk. Phishing attempts are not tolerated.
                                <br/><br/>
                                The mission of Starfish is to illuminate the good in everyone and to inspire others to
                                make the world a better place through their own actions.
                                <br/><br/>
                                We allow inspiring conversations about acts and organizations related to doing good and
                                require those conversations to be appropriate, polite and encouraging. To maintain a
                                trusted and welcoming platform, members should treat each other with respect and
                                civility. Do not interact with others or share content in a way that is uncivil,
                                inappropriate, or disrespectful.
                                <br/><br/>
                                We do not allow content that attacks, denigrates, intimidates, dehumanizes, incites or
                                threatens hatred, violence, prejudicial or discriminatory action against individuals or
                                groups because of their actual or perceived race, ethnicity, national origin, caste,
                                gender, gender identity, sexual orientation, religious affiliation, age, or disability
                                status. Hate groups are not permitted on Starfish. Showing acts promoting or encouraging
                                abuse, violence or harm is not allowed. It is not allowed to use racial, religious, or
                                other slurs. Do not post or share content that denies a well-documented historical event
                                such as the Holocaust or slavery in the United States.
                                <br/><br/>
                                Starfish is a platform promoting one’s good, not a dating site. Do not use Starfish to
                                pursue romantic connections, ask for romantic dates, or provide sexual commentary on
                                someone's appearance or perceived attractiveness. Do not send unwanted advances in
                                messages, posts, or comments or send sexually explicit images to anyone on the platform.
                                Starfish does not allow content that is excessively gruesome or shocking. This includes
                                content that is sadistic or gratuitously graphic, such as the depiction of bodily
                                injury, severe physical or sexual violence, harm to animals or humans. We do not allow
                                content, activities, or events that promote, organize, depict, or facilitate criminal
                                activity. We also do not allow content depicting or promoting instructional weapon
                                making, drug abuse, and threats of theft. Do not engage in or promote escort services,
                                prostitution, exploitation of children, or human trafficking. Do not share content or
                                activities that promote or encourage suicide or any type of self-injury, including
                                self-mutilation and eating disorders. If you see signs that someone may be considering
                                self-harm, please report it using our reporting tools and consider also reporting it to
                                your local law enforcement authorities.
                                <br/><br/>
                                Starfish does not allow untargeted, irrelevant, obviously-unwanted, unauthorized,
                                inappropriately commercial or promotional, or gratuitously repetitive messages or
                                similar content. Do not use our invitation feature to send promotional messages to
                                people you do not know or to otherwise spam people. Please make the effort to create
                                original, relevant, and interesting content to show your support of an organization or
                                person. Don't do things to artificially increase engagement with your content.
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}


export default CommunityGuidelines;
