
const Typesense = require('typesense')

let client = new Typesense.Client({
    'nodes': [{
        'host': '3f9x8c1gnpbvuhokp-1.a1.typesense.net',
        'port': '443',
        'protocol': 'https'
    }],
    'apiKey': 'BnlCQeZLNTsbc5BqpkJcLoMl4PtZRJkU',
    'connectionTimeoutSeconds': 2
})

export const nonProfitIndex = client.collections('non_profits').documents();
export const userIndex = client.collections('users').documents();
export const hashtagIndex = client.collections('hashtags').documents();
export const citiesIndex = client.collections('cities').documents();
export const collegesIndex = client.collections('colleges').documents();


export const filteredNonProfits = async (categories, city, state) => {
    const formattedCategories = categories.map(cat => `${cat}`)
    const filter = `code:=[${formattedCategories}] && state:=${state} && city:${city}`;
    const searchParams = {
        q: '',
        query_by: 'name',
        filter_by: filter,
        per_page: 8,
    }
    const response = await nonProfitIndex.search(searchParams);
    const nonProfitResults = response.hits.map((hit) => {
        hit.document.id = hit.document.ein;
        return hit.document;
    })
    return nonProfitResults;
}

export const searchNonProfits = async (query) => {
    const searchParams = {
        q: query,
        query_by: 'name,city,ein',
        per_page: 10,
    }
    const response = await nonProfitIndex.search(searchParams);
    const nonProfitResults = response.hits.map((hit) => {
        hit.document.id = hit.document.ein;
        return hit.document;
    })
    return nonProfitResults;
}


export const searchUsers = async (query) => {
    const searchParams = {
        q: query,
        query_by: 'first_name,last_name,username,city',
        per_page: 10,
        filter_by: 'deactivated:=false'
    }
    const response = await userIndex.search(searchParams);
    const userResults = response.hits.map((hit) => {
        hit.document.id = hit.document.username;
        return hit.document;
    })
    return userResults.filter(user => user.first_name !== "");
}

export const searchHashtags = async (query) => {
    const searchParams = {
        q: query,
        query_by: 'hashtag',
        per_page: 10,
    }
    const response = await hashtagIndex.search(searchParams);
    const hashtagResults = response.hits.map((hit) => {
        hit.document.id = hit.document.hashtag
        return hit.document;
    })
    return hashtagResults;
}

export const searchCities = async (query) => {
    const searchParams = {
        q: query,
        query_by: 'city,state',
        per_page: 8,
    }
    const response = await citiesIndex.search(searchParams);
    const cityResults = response.hits.map((hit) => {
        return hit.document;
    })
    return cityResults;
}

export const searchColleges = async (query, page=1) => {
    const country = "United States"
    const searchParams = {
        q: query,
        query_by: 'name',
        per_page: 5,
        filter_by: `country:=${country}`,
        sort_by: 'logo_url:asc',
        page: page
    }
    const response = await collegesIndex.search(searchParams);
    const collegesResults = response.hits.map((hit) => {
        return hit.document;
    })
    return collegesResults;
}
