import SvgLock from "../icons/SvgLock";
import SvgEarth from "../icons/categories/SvgEarth";
import React from "react";

function GroupPrivacyIndicator({group, append, size}) {
    return (
        <div
            className={`bg-blue-100 text-blue-500 flex flex-row items-center gap-0.5 rounded-full ${size === 'lg' ? 'text-base' : 'text-xs'} font-semibold px-1 py-0.5`}>
            {group.private ?
                <>
                    <SvgLock className={'w-3 h-3'}/>
                    <p>
                        Private {!!append && append}
                    </p>
                </>
                :
                <>
                    <SvgEarth className={'w-4 h-4'}/>
                    <p>
                        Public {!!append && append}
                    </p>
                </>
            }
        </div>
    )
}

export default GroupPrivacyIndicator
