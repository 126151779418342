import React, {Component} from "react";
import {motion} from "framer-motion";
import BGGraphic4 from "../img/graphics/BGGraphic4";
import BGGraphic10 from "../img/graphics/BGGraphic10";

class TeamSection extends Component {

    render() {
        return (
            <div className='body1 black-80 mt-100'>
                <div className='relative'>
                    <BGGraphic4 className='absolute black-10' width={'12vw'} height={'12vw'}
                                style={{right: 0, zIndex: 0, top: -40, transform: 'rotate(12deg)'}}/>
                    <motion.div
                        initial={{opacity: 0, transform: 'scale(1) translateY(8px)'}}
                        whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                        transition={{default: {ease: "linear"}, delay: .2}}
                        className={this.props.mobile ? 'heading2 w-100p mb-20' : 'heading2 w-60p mb-20'}
                        style={{lineHeight: 1.4}}>
                        Meet our <i>founders</i> and some of our <i>team</i>
                    </motion.div>
                </div>
                <motion.div
                    initial={{opacity: 0, transform: 'scale(1) translateY(8px)'}}
                    whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                    transition={{default: {ease: "linear"}, delay: .2}}
                    className='w-80p'>
                    The founders of Starfish are entrepreneurs who have worked for the biggest
                    marketplace
                    brands including Apple, Adobe, Verifone, Tiffany & Co, Williams-Sonoma, Francis Ford
                    Coppola, and Dole Foods. All sharing a passion for bringing like-minded people
                    together to see and do good.
                </motion.div>
                <div className={this.props.mobile ? 'col-plain mt-40 flex-1' : 'row-plain mt-40 flex-1'}
                     style={{flexWrap: 'wrap'}}>
                    <motion.div
                        initial={{opacity: 0, transform: 'scale(1) translateY(8px)'}}
                        whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                        transition={{default: {ease: "linear"}, delay: .4}}
                        className={this.props.mobile ? 'mb-20' : 'mr-20 mb-20'}
                        style={{width: this.props.mobile ? '100%' : 300}}>
                        <img style={{objectFit: 'cover'}} src={require('../img/people/john.png')}
                             width={this.props.mobile ? '100%' : 300} height={300}/>
                        <div className='title1 black-80 mt-8'>
                            John Cervenka
                        </div>
                        <div className='body1 black-50' style={{fontStyle: 'italic'}}>
                            Founder | CEO
                        </div>
                    </motion.div>
                    <motion.div
                        initial={{opacity: 0, transform: 'scale(1) translateY(8px)'}}
                        whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                        transition={{default: {ease: "linear"}, delay: .5}}
                        className={this.props.mobile ? 'mb-20' : 'mr-20 mb-20'}
                        style={{width: this.props.mobile ? '100%' : 300}}>
                        <img style={{objectFit: 'cover'}} src={require('../img/people/stevemott.jpeg')}
                             width={this.props.mobile ? '100%' : 300} height={300}/>
                        <div className='title1 black-80 mt-8'>
                            Steven G. Mott
                        </div>
                        <div className='body1 black-50' style={{fontStyle: 'italic'}}>
                            Founder | COO
                        </div>
                    </motion.div>
                    <motion.div
                        initial={{opacity: 0, transform: 'scale(1) translateY(8px)'}}
                        whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                        transition={{default: {ease: "linear"}, delay: .6}}
                        className={this.props.mobile ? 'mb-20' : 'mr-20 mb-20'}
                        style={{width: this.props.mobile ? '100%' : 300}}>
                        <img style={{objectFit: 'cover'}} src={require('../img/people/careylewismott.jpeg')}
                             width={this.props.mobile ? '100%' : 300}
                             height={300}/>
                        <div className='title1 black-80 mt-8'>
                            Carey Lewis Mott
                        </div>
                        <div className='body1 black-50' style={{fontStyle: 'italic'}}>
                            Founder | Advisory Board
                        </div>
                    </motion.div>
                </div>
                {/*<div className='mt-60'>*/}
                {/*     <motion.div*/}
                {/*        initial={{opacity: 0, transform: 'scale(1) translateY(8px)'}}*/}
                {/*        whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}*/}
                {/*        transition={{default: {ease: "linear"}, delay: .2}}*/}
                {/*        className={this.props.mobile ? 'heading4 w-100p mb-20' : 'heading4 w-60p mb-20'}*/}
                {/*        style={{lineHeight: 1.4}}>*/}
                {/*        Advisory Board*/}
                {/*    </motion.div>*/}
                {/*    <motion.div*/}
                {/*        initial={{opacity: 0, transform: 'scale(1) translateY(8px)'}}*/}
                {/*        whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}*/}
                {/*        transition={{default: {ease: "linear"}, delay: .4}}*/}
                {/*        className={this.props.mobile ? 'mb-20' : 'mr-20 mb-20'}*/}
                {/*        style={{width: this.props.mobile ? '100%' : 140}}>*/}
                {/*        <img style={{objectFit: 'cover'}} src={require('../img/people/john.png')}*/}
                {/*             width={this.props.mobile ? '100%' : 140} height={140}/>*/}
                {/*        <div className='body2-bold black-80'>*/}
                {/*            John Cervenka*/}
                {/*        </div>*/}
                {/*        <div className='body3 black-50' style={{fontStyle: 'italic', marginTop:-4}}>*/}
                {/*            Founder | CEO*/}
                {/*        </div>*/}
                {/*    </motion.div>*/}
                {/*</div>*/}
            </div>
        )
    }

}


export default TeamSection;
