import React, {Component} from "react";
import SvgClose from "../icons/SvgClose";
import SvgPin from "../icons/SvgPin";

class DropzoneImage extends Component {

    deleteFile = (e) => {
        e.stopPropagation();
        this.props.deleteFile(this.props.file);
    }

    render() {
        return (
            <div
                className={`uploaded-file bg-black/5 relative rounded-xl overflow-hidden text-center gap-3 items-center justify-center flex flex-col base-black-70 ${this.props.className}`}
                onClick={this.deleteFile}
                style={{width: 150, height: 150}}>
                <img src={this.props.file} style={{width: 150, height: 150}} className='object-contain'/>
                <p className=' mt-[0px] text-xs ellipsis'>{this.props.file.name}</p>
                <div onClick={this.deleteFile} className='absolute bg-white/50 backdrop-blur-sm left-0 right-0 body4-bold cover-parent col-ac-jc'>
                    <SvgClose className={'text-red-500 w-6 h-6'}/>
                </div>
            </div>
        )
    }
}

export default DropzoneImage
