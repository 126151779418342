import React, {Component} from "react";
import {connect} from "react-redux";
import {CARD_STYLE_NO_HOVER} from "../../utils/dummy";
import ZeroStateArea from "../atoms/ZeroStateArea";
import {deleteExperience} from "../../api/users";
import ProfileSectionHeader from "./ProfileSectionHeader";
import ProfileExperienceItem from "./ProfileExperienceItem";
import AddLinkedinModal from "../modals/AddLinkedinModal";
import {Button} from "../atoms/Button";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class ProfileAboutExperience extends Component {
    state = {
        addLinkedinModalOpen: false,
        isEditingExistingItems: false,
        showAllExperiences: false,
    }

    toggleEditExistingMode = () => {
        this.setState({isEditingExistingItems: !this.state.isEditingExistingItems});
    }

    toggleShowAllExperiences = () => {
        this.setState((prevState) => ({showAllExperiences: !prevState.showAllExperiences}));
    }

    openAddExperienceModal = () => {
        this.setState({addLinkedinModalOpen: true})
    }

    deleteExperience = async (index) => {
        await deleteExperience(this.props.authUser, String(index));
        window.location.reload();
    }

    render() {
        const {experiences, user, sameUser} = this.props;
        const {showAllExperiences} = this.state;
        const displayedExperiences = showAllExperiences ? experiences : experiences.slice(0, 3);
        const prefix = sameUser ? "My" : `${user.first_name}'s`;

        return (
            <div className={`${CARD_STYLE_NO_HOVER}`}>
                <ProfileSectionHeader
                    label={`${prefix} Work`}
                    sameUser={sameUser}
                    editing={this.state.isEditingExistingItems}
                    setEditingMode={this.toggleEditExistingMode}
                    onClickAdd={this.openAddExperienceModal}
                    hideAdd={!!user.linkedin}
                />

                {displayedExperiences.map((item, index) => (
                    <ProfileExperienceItem
                        key={index}
                        item={item}
                        index={index}
                        onClickEdit={this.openAddEducationModal}
                        onClickDelete={() => this.deleteExperience(index)}
                        editing={this.state.isEditingExistingItems}
                    />
                ))}

                {experiences.length > 3 && (
                   <div className='w-full flex flex-col items-center mt-2'>
                       <Button
                           variant={'secondary'}
                           size={'sm'}
                           onClick={this.toggleShowAllExperiences}
                       >
                           {showAllExperiences ? 'See Less Work' : 'Show More Work'}
                       </Button>
                   </div>
                )}

                {experiences.length === 0 && (
                    <ZeroStateArea
                        title={'Nothing here...'}
                        sub={`${sameUser ? 'You do not' : `${user.first_name} does not`} have any work experience listed yet. ${sameUser && "If you uploaded your LinkedIn and still aren't seeing anything please reach out to hello@starfishapp.com."}`}
                    />
                )}

                <AddLinkedinModal
                    authUser={this.props.authUser}
                    isOpen={this.state.addLinkedinModalOpen}
                    close={() => this.setState({addLinkedinModalOpen: false})}
                />
            </div>
        );
    }
}

export default connect(mapStateToProps)(ProfileAboutExperience);
