import axios from "./axios";

export const getSuggestions = async (user) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {}
        };
        const res = await axios.get("/users/suggestions", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getUser = async (user, queried_user_id = null) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {queried_user_id}
        };
        const res = await axios.get("/users", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const changeInterest = async (user, non_profit_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {non_profit_id}
        const res = await axios.post("/users/interests", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const followUser = async (user, user_id_to_follow) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };

        const body = {user_id_to_follow}
        const res = await axios.post("/users/follow", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const unfollowUser = async (user, user_id_to_unfollow) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {user_id_to_unfollow}
        };
        const res = await axios.delete("/users/follow", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getDefaultSuggestions = async (user) => {
    try {
        const config = {
            // headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {}
        };
        const res = await axios.get("/users/default", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getFollowersFollowees = async (user, side, queried_user_id = null) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {queried_user_id, side}
        };
        const res = await axios.get("/users/follow", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getInterestsPreview = async (user, queried_user_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {queried_user_id}
        };
        const res = await axios.get("/users/interests", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const editUser = async (user, params_to_edit) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            updated_params: params_to_edit
        }
        const res = await axios.put("/users", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const changeConnection = async (user, non_profit_id, role, connection, date, end_date) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };

        const body = {non_profit_id, role, connection, date, end_date}
        const res = await axios.post("/users/connections", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const deleteConnection = async (user, non_profit_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            non_profit_id
        };
        const res = await axios.put("/users/connections", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const changeEducation = async (user, domain, major, connection, start_date, end_date, name) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };

        const body = {domain, major, connection, start_date, end_date, name};
        const res = await axios.post("/users/education", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const deleteEducation = async (user, domain) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            domain
        };
        const res = await axios.put("/users/education", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const deleteExperience = async (user, index) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            index
        };
        const res = await axios.put("/users/experiences", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const addLinkedin = async (user, linkedin) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };

        const body = {linkedin};
        const res = await axios.post("/users/experiences", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getPublicUser = async (queried_user_id = null) => {
    try {
        const config = {
            headers: {mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {queried_user_id}
        };
        const res = await axios.get("/users/public", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}