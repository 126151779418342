import React, {Component} from "react";
import ProfilePicture from "./atoms/ProfilePicture";
import {formatMessageTime} from "../utils/timestamp";

class ChatListItem extends Component {
    render() {
        const chat = this.props.chat || {};
        const self = this.props.username;
        const otherUser = chat?.members[0] === self ? chat?.members[1] : chat?.members[0];
        const otherName = chat?.memberNames[otherUser] || 'N/A';
        const recentMessage = chat?.recentMessage || {};
        return (
            <div className={`flex flex-row gap-2 p-3 py-2 border-b border-slate-200 cursor-pointer ${(this.props.currentChat.id === chat.id) && 'bg-blue-50'}`} onClick={() => this.props.selectChat(chat)} key={chat.timestamp}>
                <ProfilePicture userID={otherUser} size={40} initial={otherName[0]}/>
                <div className='flex-1 flex flex-col truncate'>
                    <p className='text-md font-semibold text-slate-800'>
                        {otherName}
                    </p>
                    <p className='text-md font-medium text-slate-700 flex-1 truncate'>
                        {recentMessage?.preview}
                    </p>
                </div>
                <p className='text-sm text-slate-500'>
                    {formatMessageTime(recentMessage.timestamp)}
                </p>
            </div>
        )
    }
}

export default ChatListItem
