import React, {useState} from 'react';
import OnboardingStepHeader from "./OnboardingStepHeader";
import OnboardingButtons from "./OnboardingButtons";
import NonProfitSearchDropdown from "../../components/atoms/NonProfitSearchDropdown";

function OnboardingFavoriteCauses({goNextStep, goBackStep, categories, city, state, followNp, canSubmit}) {
    const categoryMap = {
        'Arts & Museums': [`A`, `R`, `S`, `T`, `U`, `V`],
        'Education & Schools': [`B`],
        'Environment': [`C`],
        'Animals': [`D`],
        'Health': [`E`, `F`, `G`, `H`],
        'Human Services & Housing': [`I`, `J`, `K`, `L`, `M`, `N`, `O`, `P`],
        'Religion & Faith': [`X`],
        'Fraternal Societies & Clubs': [`Y`],
        'Military & Veterans': [`W`],
        'International Relief & Human Rights': [`Q`, `Z`]
    }

    const handleSubmit = () => {
        goNextStep();
    };


    const filterCategories = categories.flatMap(category => categoryMap[category]);
    return (
        <div className='flex flex-col gap-8 md:w-[460px]'>
            <OnboardingStepHeader
                title={'Add a nonprofit you are connected to'}
                description={'Get a head start by adding an experience you already have. You must add one to enter the platform.'}
            />
            <NonProfitSearchDropdown
                categories={filterCategories}
                city={city}
                state={state}
                onSelect={followNp}
            />
            <OnboardingButtons
                goNextStep={handleSubmit}
                goBackStep={goBackStep}
                nextDisabled={!canSubmit}
                finish
            />
        </div>
    );
}

export default OnboardingFavoriteCauses;
