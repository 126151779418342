import React, {Component} from "react";
import ModalComponent from "../components/ModalComponent";
import {Button} from "./atoms/Button";

class SelectPosterModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            amount: '',
        }
    }

    selectOption = () => {
        this.props.closeModal()
    }

    render() {
        const creators = this.props.creators || [];
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                size={'md'}
                toggle={this.props.closeModal}
                headerToggle
                header={'Who do you want to post as?'}
                FooterComponent={
                    <div className={'flex flex-row items-center gap-5'}>
                        <Button onClick={this.props.closeModal} variant={'secondary'}>
                            Cancel
                        </Button>

                    </div>
                }
            >
                <div
                    className='bg-slate-100 rounded-xl p-5 px-4 flex flex-col items-center justify-items-start max-h-[400px] overflow-y-scroll'>
                    {creators.map((creator, index) =>
                        <div key={creator+index}>
                            <button
                                onClick={() => this.props.selectOption(creator, index)}
                                className='w-full py-3 px-2 rounded-xl hover:bg-slate-200 cursor-pointer'>
                                <p className='text-md font-medium'>
                                    {creator.name}
                                </p>
                            </button>
                        </div>
                    )}
                </div>
            </ModalComponent>
        )
    }

}


export default SelectPosterModal;
