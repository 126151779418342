import React, {Component} from "react";
import LoadingSection from "../components/LoadingSection";
import MiddleViewHeader from "../components/layout/MiddleViewHeader";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import ProfilePicture from "../components/atoms/ProfilePicture";
import moment from "moment";
import PostAttachments from "../components/posts/PostAttachments";
import SvgMoney from "../components/icons/SvgMoney";
import SvgEditNote from "../components/icons/SvgEditNote";
import PostContent from "../components/posts/PostContent";
import ReplyComponent from "../components/posts/ReplyComponent";
import DonateModal from "../components/DonateModal";
import PostEditor from "../components/posts/PostEditor";
import { convertToRaw } from "draft-js";
import {Button} from "../components/atoms/Button";
import {getPost, likeUnlikePost, getComments} from "../api/posts";
import {createDonationCheckout} from "../api/stripe_connect";
import {postComment, likeUnlikeComment} from "../api/comments";
import NPOPicture from "../components/atoms/NPOPicture";
import PostInteractionsRow from "../components/posts/PostInteractionsRow";
import SvgMoreHorizontal from '../components/icons/SvgMoreHorizontal';
import DeletePostModal from '../components/modals/DeletePostModal';
import SuggestedPostBanner from "../components/atoms/SuggestedPostBanner";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        posts: state.postReducer.posts,
        mobile: state.userReducer.mobile,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updatePosts: (posts) => {
            dispatch({
                type: "UPDATE_POSTS",
                posts,
            })
        },
        toggleCreatePostModal: (currentPostId) => {
            dispatch({
                type: "TOGGLE_CREATE_POST_MODAL",
                currentPostId
            })
        },
    }
}

class PostPage extends Component {
    state = {
        liked: false,
        loading: true,
        post: {},
        content: '',
        comments: [],
        postId: "",
        deleteModalOpen: false,
        donateModalOpen: false,
        amount: 0,
        memo: ''
    }

    componentDidMount = async () => {
        this.getPostInfo()
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.user !== prevProps.user || this.props.location.pathname !== prevProps.location.pathname) {
            this.getPostInfo();
        }
    }

    getPostInfo = async () => {
        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const postId = this.props.postId || lastArg?.split("?")[0];

        if (!!this.props.posts) {
            const res = await getPost(this.props.authUser, postId);
            this.props.updatePosts([res.post]);
        }

        const comments = await getComments(this.props.authUser, postId);
        this.setState({loading: false, comments: comments.comments, postId});
    }


    postComment = async () => {
        this.setState({loading: true});
        this.setState({commentsLoading: true});

        const content = convertToRaw(this.state.content);
        const plaintext = content.blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

        const users = []
        const nonProfits = []
        const hashtags = [];
        let url = null

        for (const entity of Object.values(content.entityMap)) {
            if (entity.data.mention?.type === "userTag") users.push(entity.data.mention.id);
            else if (entity.data.mention?.type === "nonProfitTag") nonProfits.push(entity.data.mention.id);
            else if (entity.data.mention?.type === "hashtag") hashtags.push(entity.data.mention.hashtag);
            else if (entity.type === "LINK") url = entity.data.url;
        }

        const imageUrls = [];
        const videos = [];
        await postComment(this.props.authUser, this.state.postId, content, imageUrls, users, nonProfits, hashtags, plaintext, videos);

        this.setState({content: ""});
        this.getPostInfo();
    }

    likeComment = async (commentId) => {
        this.setState({loading: true})
        await likeUnlikeComment(this.props.authUser, commentId);
        this.getPostInfo();
    }

    clickProfile = () => {
        const post = this.props.posts[this.state.postId] || {};
        this.props.closeSheet();
        if (post.np_post) {
            this.props.history.push({pathname: `/non-profit/${post.creator}`, state: {previous: "Back"}})
        } else {
            this.props.history.push({pathname: `/profile/${post.creator}`, state: {previous: "Back"}})
        }
    }

    openDeleteModal = () => {
        this.setState({deleteModalOpen: true});
    }

    closeDeleteModal = () => {
        this.setState({deleteModalOpen: false});
    }

    openEditPost = () => {
        this.props.closeSheet();
        this.props.toggleCreatePostModal(this.state.postId);
    }

    openDonateModal = () => {
        this.setState({donateModalOpen: true})
    }

    closeDonateModal = () => {
        this.setState({donateModalOpen: false})
    }

    createCheckout = async () => {
        console.log("CREATING DONATION CHECKOUT")
        const post = this.props.posts[this.state.postId] || {};
        const nonProfit = post.non_profit;
        createDonationCheckout(this.props.authUser, this.state.amount, nonProfit.ein, false, null, this.state.memo, this.state.postId);
    }

    updateAmount = (amount) => {
        this.setState({amount: amount})
    }

    onChangeMemo = (memo) => {
        this.setState({memo});
    }


    render() {
        const user = this.props.user || {};
        const post = this.props.posts[this.state.postId] || {};
        const comments = this.state.comments || [];
        const np = post.non_profit || {};
        const deleted = post.post_content === "This post has been deleted."
        return (
            <div className='flex flex-col flex-1 overflow-hidden'>
                {!this.props.isSheet &&
                    <MiddleViewHeader
                        title={'Post'}
                        previous
                        back
                    />
                }
                {this.state.loading ?
                    <LoadingSection/>
                    :
                    <div className='flex flex-col flex-1 overflow-y-scroll'>
                        <div className='bg-white  p-5 flex flex-col gap-3 border-b border-slate-100'>
                            <SuggestedPostBanner post={post}/>
                            <div className='flex flex-row gap-3 justify-between'>
                                <div className='flex flex-row items-center gap-1 cursor-pointer'>
                                    <div onClick={this.clickProfile}>
                                        {post.np_post ?
                                            <NPOPicture
                                                np={np}
                                                size={56}
                                                logoUrl={np.logo_url}
                                                initial={np.name[0]}
                                                className={'border border-slate-200'}
                                            />
                                            :
                                            <ProfilePicture
                                                size={56}
                                                initial={post?.creator_name[0]}
                                                userID={post.creator}
                                            />
                                        }
                                    </div>
                                    <div className='flex flex-col'>
                                        <div className='flex flex-row items-center'>
                                            <p className='text-lg font-semibold text-slate-800'>
                                                {post.creator_name}
                                            </p>
                                            <p className='text-md text-slate-500 ml-2'>
                                                {moment(post.created_at).fromNow('')}
                                            </p>
                                        </div>
                                        {(post.scope !== "public" && !this.props.isInsideGroup) &&
                                            <div className={'flex flex-row items-center -mt-1'}>
                                                <p className=' text-slate-500'>
                                                    Posted In:
                                                </p>
                                                <Link to={'/groups'}>
                                                    <p className='text-blue-500 font-semibold cursor-pointer hover:text-blue-600'>&nbsp;{post?.group?.name}</p>
                                                </Link>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="flex flex-row gap-2">
                                    {(post.np_post) &&
                                        <Button onClick={this.openDonateModal} variant={'default'} radius={'full'}
                                                className='flex-1'
                                        >
                                            <SvgMoney style={{width: 20, height: 20, marginBottom: 1}}
                                                      className={'mr-4'}/>

                                            Donate
                                        </Button>
                                    }
                                    {/*Show delete post option IFF user created post*/}
                                    {(post.creator === user.username) ?
                                        <div className="flex flex-row gap-2">
                                            <div className='flex flex-row items-end cursor-pointer'>
                                                <div onClick={this.openEditPost}>
                                                    <SvgEditNote/>
                                                </div>
                                            </div>
                                            <div className='flex flex-row items-end cursor-pointer'
                                                 onClick={this.openDeleteModal}>
                                                <SvgMoreHorizontal/>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            {deleted ?
                                <p>This post has been deleted.</p>
                                :
                                <div className='flex flex-col gap-5'>
                                    <PostContent post={post}/>
                                    <PostAttachments
                                        post={post}
                                    />
                                    <div className='bg-slate-200 w-full h-[1px]'/>
                                    <PostInteractionsRow
                                        user={user}
                                        post={post}
                                        inFullPage
                                    />
                                    <div className='bg-slate-200 w-full h-[1px] '/>
                                    <p className='text-lg font-semibold text-slate-800 m-4'>
                                        {comments.length === 1 ? "Reply" : "Replies"} ({comments.length})
                                    </p>
                                    <div className='flex flex-row items-center gap-3'>
                                        <div className='flex flex-row items-center gap-1 flex-1'>
                                            <ProfilePicture
                                                initial={user.first_name[0] + user.last_name[0]}
                                                userID={user.username}
                                                size={48}
                                            />
                                            <div className='flex-1'>
                                                <PostEditor
                                                    onChange={(content) => this.setState({content})}
                                                    // placeholder={`Reply to ${post.creator_name}`}
                                                />
                                            </div>
                                        </div>
                                        <div className='ml-auto mt-[2px]'>
                                            <Button variant={'default'} onClick={this.postComment}>
                                                Reply
                                            </Button>
                                        </div>
                                    </div>
                                    <div>
                                        {comments.map((comment) =>
                                            <ReplyComponent reply={comment} userId={user.username}
                                                            likeComment={this.likeComment}/>
                                        )}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
                <DeletePostModal
                    isOpen={this.state.deleteModalOpen}
                    closeModal={this.closeDeleteModal}
                    postId={this.state.postId}
                    history={this.props.history}
                />
                <DonateModal
                    isOpen={this.state.donateModalOpen}
                    closeModal={this.closeDonateModal}
                    openAuthModal={this.createCheckout}
                    mobile={this.props.mobile}
                    nonProfit={post?.non_profit}
                    updateAmount={this.updateAmount}
                    memo={this.state.memo}
                    onChangeMemo={this.onChangeMemo}
                />
            </div>
        )
    }

}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostPage));
