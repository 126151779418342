import React, {Component} from "react";
import HeroSection from "./Main/HeroSection";
import NpoSection1 from "./NPO/NpoSection1";
import NpoSection2 from "./NPO/NpoSection2";
import NpoSection3 from "./NPO/NpoSection3";
import NpoSection4 from "./NPO/NpoSection4";
import {connect} from "react-redux";


function mapStateToProps(state) {
    return {
        mobile: state.userReducer.mobile,
    }
}

class Main extends Component {

    render() {
        return (
            <div className='col-plain page-wrapper'>
                <HeroSection mobile={this.props.mobile} nonProfitSide/>
                <NpoSection1 mobile={this.props.mobile}/>
                <NpoSection2 mobile={this.props.mobile}/>
                <NpoSection3 mobile={this.props.mobile}/>
                <NpoSection4 mobile={this.props.mobile}/>
            </div>
        )
    }

}


export default connect(mapStateToProps)(Main);
