import React, {useState} from 'react';
import TextInput from "../../components/inputs/TextInput";
import OnboardingStepHeader from "./OnboardingStepHeader";
import OnboardingButtons from "./OnboardingButtons";
import SuccessfullyJoinedModal from "../../components/modals/SuccessfullyJoinedModal";

function OnboardingName({goNextStep, firstName, setFirstName, lastName, setLastName, tagline, setTagline, np}) {
    const [errorMessage, setErrorMessage] = useState('');

    const handleFirstNameChange = (event) => {
        if (errorMessage) setErrorMessage('');
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event) => {
        if (errorMessage) setErrorMessage('');
        setLastName(event.target.value);
    };

    const handleTaglineChange = (event) => {
        const text = event.target.value;
        if (errorMessage) setErrorMessage('');
        else if (text.length <= 80) setTagline(text);
    };


    const validateForm = () => {
        if (!firstName) {
            setErrorMessage('Please enter your first name.');
            return false;
        }
        if (!lastName) {
            setErrorMessage('Please enter your last name.');
            return false;
        }
        return true;
    };

    const handleSubmit = () => {
        if (validateForm()) {
            goNextStep();
        }
    };

    return (
        <div className='flex flex-col gap-8 md:w-[460px]'>
            <OnboardingStepHeader
                title={'Let’s set up your Good Profile'}
                description={'Enter your name and a brief tagline to get started'}
            />
            <div className='flex flex-col gap-5'>
                <TextInput
                    label={'First Name'}
                    value={firstName}
                    placeholder={'John'}
                    onChange={handleFirstNameChange}
                />
                <TextInput
                    label={'Last Name'}
                    value={lastName}
                    placeholder={'Doe'}
                    onChange={handleLastNameChange}
                />
                <TextInput
                    label={'Purpose'}
                    value={tagline}
                    placeholder={'e.g. Volunteer at the ASPCA'}
                    onChange={handleTaglineChange}
                />
                <p className={`text-sm h-4 text-slate-500 ml-2`} style={{marginTop: -15}}>
                    {tagline.length} / 80
                </p>
                <p className={`text-xs h-4 ${errorMessage ? 'text-red-400' : 'text-transparent'}`}>
                    {errorMessage}
                </p>
            </div>
            <OnboardingButtons
                goNextStep={handleSubmit}
                backHidden
                nextDisabled={!firstName || !lastName}
            />
            <SuccessfullyJoinedModal
                isOpen={true}
                np={np}
            />
        </div>
    );
}

export default OnboardingName;
