import React from 'react';
import OnboardingStepHeader from "../Onboarding/OnboardingStepHeader";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import OnboardingButtons from "../Onboarding/OnboardingButtons";
import '../../styles/Calendar.css'

function CreateChallengeEndDate({ goNextStep, goBackStep, endDate, setEndDate }) {
    const handleSubmit = () => {
        goNextStep();
    };

    const today = new Date();
    const isFuture = endDate ? endDate > today : true;

    return (
        <div className='flex flex-col gap-8 w-[460px]'>
            <OnboardingStepHeader
                title={'Set your end date!'}
                description={'Set the date when this challenge ends.'}
            />
            <div className='flex flex-col items-center'>
                <Calendar
                    onChange={setEndDate}
                    value={endDate}
                    minDate={new Date()}
                    className="border-[1px] rounded-lg py-1 px-2 outline-0 border-slate-200 focus:border-blue-500 text-slate-900"
                />
            </div>
            <OnboardingButtons
                goNextStep={handleSubmit}
                goBackStep={goBackStep}
                nextDisabled={!isFuture}
            />
        </div>
    );
}

export default CreateChallengeEndDate;
