import './App.css';
import './styles/Formatting.css';
import './styles/ColorStyles.css'
import './styles/Inputs.css'
import './styles/Navbar.css'
import './styles/TextStyles.css'
import './styles/ButtonStyles.css'
import './styles/HeroStyles.css'

import React, {PureComponent} from "react";
import {BrowserRouter as Router} from 'react-router-dom';
import UserProvider, {UserContext} from "./providers/UserProvider";
import {Provider} from "react-redux";
import Store from "./Store";
import Routes from './Routes';
import {Helmet} from 'react-helmet';
import { datadogLogs } from '@datadog/browser-logs';

const DD_CLIENT_TOKEN = "pub0761b063cc0a4bcdc3dd7d87dbd254c0";
datadogLogs.init({
    // applicationId: DD_APP_ID,
    clientToken: DD_CLIENT_TOKEN,
    site: 'us5.datadoghq.com',
    service: 'starfish_web',
    forwardErrorsToLogs: true,
    forwardConsoleLogs: "all",
    // env: 'dev',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    // Connect to backend traces
    allowedTracingUrls: ["https://starfish-seethegood.uc.r.appspot.com", "https://dev-dot-starfish-seethegood.uc.r.appspot.com"],
});
datadogLogs.logger.setLevel('info');

console.log("HEAP KEY", process.env.REACT_APP_HEAP_KEY);

class App extends PureComponent {
    render() {
        return (
            <>
                <Helmet>
                    <script type="text/javascript">
                    {`
                        window.heapReadyCb=window.heapReadyCb||[],window.heap=window.heap||[],heap.load=function(e,t){window.heap.envId=e,window.heap.clientConfig=t=t||{},window.heap.clientConfig.shouldFetchServerConfig=!1;var a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src="https://cdn.us.heap-api.com/config/"+e+"/heap_config.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(a,r);var n=["init","startTracking","stopTracking","track","resetIdentity","identify","getSessionId","getUserId","getIdentity","addUserProperties","addEventProperties","removeEventProperty","clearEventProperties","addAccountProperties","addAdapter","addTransformer","addTransformerFn","onReady","addPageviewProperties","removePageviewProperty","clearPageviewProperties","trackPageview"],i=function(e){return function(){var t=Array.prototype.slice.call(arguments,0);window.heapReadyCb.push({name:e,fn:function(){heap[e]&&heap[e].apply(heap,t)}})}};for(var p=0;p<n.length;p++)heap[n[p]]=i(n[p])};
                        heap.load("${process.env.REACT_APP_HEAP_KEY}");
                    `}
                    </script>
                </Helmet>
                <UserProvider>
                    <Router>
                        <Provider store={Store}>
                            <UserContext.Consumer>
                                {(user) => (
                                    <Routes user={user}/>
                                )}
                            </UserContext.Consumer>
                        </Provider>
                    </Router>
                </UserProvider>
            </>
            
        )
    }
}

export default App;
