import React, {PureComponent} from "react";
import ModalComponent from "../ModalComponent";
import {Button} from "../atoms/Button";
import SvgFire from "../icons/SvgFire";
import HorizontalDivider from "../atoms/HorizontalDivider";
import CountUp from "react-countup";
import { connect } from "react-redux";

const encouragingMessages = [
    "Well done! Keep shining!",
    "Awesome job! You're making a difference!",
    "Keep it up! Your efforts are inspiring!",
    "Fantastic! You're a true humanitarian!",
    "Great work! The world needs more people like you!",
    "You're doing an incredible job! Keep it going!",
    "Wonderful! Your contributions matter!",
    "Excellent! Keep spreading positivity!",
    "Bravo! You're on a roll!"
];


function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
    }
}

function getRandomMessage() {
    return encouragingMessages[Math.floor(Math.random() * encouragingMessages.length)];
}

class StreakIncreasedModal extends PureComponent {
    state = {
        dismissed: true
    }

    dismiss = () => {
        this.setState({dismissed: true})
    }

    componentDidMount = () => {
        this.getUserInfo();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.user?.new_streak !== this.props.user?.new_streak) {
            this.getUserInfo();
        }
    }

    getUserInfo = () => {
        this.setState({dismissed: !this.props.user?.new_streak})
    }

    render() {
        return (
            <ModalComponent toggle={this.props.close}
                            isOpen={!this.state.dismissed}
                            noPadding
                            confetti
                            size={'sm'}
                            FooterComponent={
                                <div className='flex flex-row items-center gap-3'>
                                    <Button onClick={this.dismiss} variant={'secondary'}>
                                        Close
                                    </Button>
                                </div>
                            }
            >
                <div className="flex flex-col px-5 pb-3 text-blue-500">
                    <p className='text-slate-900 text-2xl text-center font-bold mx-3 pt-6 pb-5'>
                        You just increased your streak of good!
                    </p>
                    <div className='flex flex-row mx-auto items-end'>
                        <p className='text-6xl font-bold'>
                            <CountUp end={this.props.user?.current_streak}/>d
                        </p>
                        <SvgFire className={'mb-1.5'}/>
                    </div>
                    <p className='text-xl font-semibold mx-auto mb-3'>
                        My New Streak
                    </p>
                    <div className='ph-6 py-4 bg-blue-500/10 text-center rounded-xl'>
                        <p className='text-blue-500 font-bold px-3'>
                            {getRandomMessage()}
                        </p>
                    </div>
                </div>
            </ModalComponent>
        );
    }
}

export default connect(mapStateToProps)(StreakIncreasedModal);
