import branch from 'branch-sdk';

branch.init('key_live_iFmp5Nzr2n54PNVlEelA9bcesueRvumA');

export const shareProfile = async (username, name) => {
    const controlParams = {
        page: 'profile',
        object_id: username,
        $desktop_url: `https://www.starfishapp.com/profile/${username}`,
        $og_image_url: `https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/users%2Fprofile_picture%2F${username}.jpeg?alt=media`,
        $og_title: `${name}'s Starfish Profile`
    };

    try {
        const link = await new Promise((resolve, reject) => {
            branch.link({
                feature: 'sharing',
                data: controlParams
            }, function (err, link) {
                if (err) {
                    reject(err);
                } else {
                    resolve(link);
                }
            });
        });
        return link;
    } catch (error) {
        console.error('Error generating link:', error);
        throw error;
    }
};


export const shareGroup = async (groupId, name, imageId) => {
    const controlParams = {
        page: 'group',
        object_id: groupId,
        $desktop_url: `https://www.starfishapp.com/group-preview/${groupId}`,
        $og_image_url: `https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/groups%2F${imageId}?alt=media`,
        $og_title: `${name}'s Starfish Group`
    };

    try {
        const link = await new Promise((resolve, reject) => {
            branch.link({
                feature: 'sharing',
                data: controlParams
            }, function (err, link) {
                if (err) {
                    reject(err);
                } else {
                    resolve(link);
                }
            });
        });
        return link;
    } catch (error) {
        console.error('Error generating link:', error);
        throw error;
    }
};


export const shareInvite = async (user_id, non_profit_id) => {
    const invite_id = `${user_id}-${non_profit_id}`
    const webLink = `https://starfishapp.com/referral/${window.btoa(invite_id)}`
    let controlParams = {
        page: 'invite',
        object_id: invite_id,
        $desktop_url: webLink,
        $og_image_url: "https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/branding%2Fshare-og-1.png?alt=media",
        $og_title: "Join Starfish and we Donate $1"
    }

    try {
        const link = await new Promise((resolve, reject) => {
            branch.link({
                feature: 'sharing',
                data: controlParams
            }, function (err, link) {
                if (err) {
                    reject(err);
                } else {
                    resolve(link);
                }
            });
        });
        return link;
    } catch (error) {
        console.error('Error generating link:', error);
        throw error;
    }
};