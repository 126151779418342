import {changeInterest, followUser, unfollowUser} from "../api/users";

const initialState = {
    user: null,
    authUser: {},
    mobile: false,
    followees: [],
    interests: [],
    createPostModalOpen: false,
    createPostGroupName: "",
    createPostGroupId: "",
    imageViewerModalOpen: false,
    imageViewerImages: [],
    imageViewerIndex: 0,
    currentPostId: null,
    createGroupModalOpen: false,
    path: "",
    referralInfo: {},
    scrollPosition: null,
    fullPostModalID:null,
    groupPreviewID:null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_USER":
            return {
                ...state,
                user: action.user
            }
        case "SET_AUTH_USER":
            return {
                ...state,
                authUser: action.user
            }
        case "CLEAR_USER":
            return {...initialState}
        case "SET_PATH":
            return {
                ...state,
                path: action.path
            }
        case 'UPDATE_FOLLOWEES':
            return {
                ...state,
                followees: action.followees,
            };
        case 'ADD_FOLLOWEE':
            const followeesAdd = [...state.followees];
            followeesAdd.push(action.followee);
            followUser(state.authUser, action.followee)
            return {
                ...state,
                followees: followeesAdd,
            };
        case 'REMOVE_FOLLOWEE':
            const followeesRemove = [...state.followees];
            const index = followeesRemove.indexOf(action.followee);
            followeesRemove.splice(index, 1);
            unfollowUser(state.authUser, action.followee)
            return {
                ...state,
                followees: followeesRemove,
            };
        case 'UPDATE_INTERESTS':
            return {
                ...state,
                interests: action.interests,
            };
        case 'UPDATE_SCROLL_POSITION':
            return {
                ...state,
                scrollPosition: action.scrollPosition,
            };
        case 'ADD_INTEREST':
            const interestsAdd = [...state.interests];
            const currentIds = interestsAdd.map(({id}) => id);

            const newUser = {...state.user};
            newUser.nux.follow_user_np = true;
            if (action.interest.fromChallenge) {
                newUser.nux.participate_challenge = true;
            }

            if (currentIds.includes(action.interest.id)) {
                return {
                    ...state,
                    interests: interestsAdd,
                    user: newUser,
                }
            }

            interestsAdd.push(action.interest);
            changeInterest(state.authUser, action.interest.id);
            return {
                ...state,
                interests: interestsAdd,
            };
        case 'REMOVE_INTEREST':
            const interestsRemove = [...state.interests];
            const newInterests = interestsRemove.filter(interest => interest.id !== action.interest.id)
            changeInterest(state.authUser, action.interest.id);
            return {
                ...state,
                interests: newInterests,
            };
        case "SET_MOBILE":
            return {
                ...state,
                mobile: action.mobile
            }
        case "TOGGLE_CREATE_POST_MODAL":
            return {
                ...state,
                createPostModalOpen: !state.createPostModalOpen,
                currentPostId: action.currentPostId,
                createPostGroupName: action.groupName,
                createPostGroupId: action.groupId,
            }
        case "TOGGLE_CREATE_GROUP_MODAL":
            return {
                ...state,
                createGroupModalOpen: !state.createGroupModalOpen,
            }
        case "SET_IMAGE_VIEWER_MODAL_OPEN":
            return {
                ...state,
                imageViewerModalOpen: true,
                imageViewerImages: action.images,
                imageViewerIndex: action.index || 0
            }
        case "SET_IMAGE_VIEWER_MODAL_CLOSED":
            return {
                ...state,
                imageViewerModalOpen: false,
                imageViewerIndex: 0
            }
        case "SET_IMAGE_VIEWER_MODAL_INDEX":
            console.log("made it here", action.index)
            return {
                ...state,
                imageViewerIndex: action.index
            }
        case "SET_REFERRAL_INFO":
            return {
                ...state,
                referralInfo: action.referralInfo
            }
        case 'TOGGLE_FULL_POST_MODAL':
            return {
                ...state,
                fullPostModalID: action.fullPostModalID,
            };
        case 'TOGGLE_GROUP_PREVIEW_MODAL':
            return {
                ...state,
                groupPreviewID: action.groupPreviewID,
            };
        default:
            return state;
    }
};
