import React from 'react';

function CollapseComponent({ isOpen, style, className, children }) {
    const containerStyle = {
        height: isOpen ? 'fit-content' : 0,
        overflow: 'hidden',
        transition: 'height 0.3s ease-out', // Add a smooth transition for height
        ...style
    };

    return (
        <div style={containerStyle} className={className}>
            {children}
        </div>
    );
}

export default CollapseComponent;
