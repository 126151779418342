import React, {useState, useEffect} from 'react';
import TextInput from "../../components/inputs/TextInput";
import NonProfitSearchDropdownResult from "./NonProfitSearchDropdownResult";
import {searchColleges} from "../../utils/typesense";


function CollegeSearchDropdown(props) {
    const [search, setSearch] = useState('');
    const [colleges, setColleges] = useState([]);

    const handleSearchChange = (event) => {
        const search = event.target.value;

        searchColleges(search).then((collegeResults) => {
            setColleges(collegeResults);
        })
        setSearch(search);
    };

    useEffect(() => {
        handleSearchChange({target: {value: ""}});
    }, []);

    return (
        <div className='flex flex-col relative'>
            <TextInput
                label={'Find your college'}
                value={search}
                placeholder={'Search colleges...'}
                onChange={handleSearchChange}
            />
            <div className='pt-3 h-[300px] overflow-y-scroll'>
                {(colleges).map((res) =>
                    <NonProfitSearchDropdownResult
                        item={res}
                        key={res.name}
                        selectNp={props.onSelect}
                    />
                )}
                {!!search ? 
                    <NonProfitSearchDropdownResult
                        item={{name: search, domain: "custom"}}
                        key={search}
                        selectNp={props.onSelect}
                    />
                    : null}
            </div>
        </div>
    );
}

export default (CollegeSearchDropdown);
