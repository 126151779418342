import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import DownloadAppCTA from "./Main/DownloadAppCTA";
import {getPublicUser} from "../api/users";
import LoadingSection from "../components/LoadingSection";
import ProfileHeaderCard from "../components/profile/ProfileHeaderCard";
import SectionTabs from "../components/SectionTabs";
import ProfileAboutSection from "../components/profile/ProfileAboutSection";
import {connect} from "react-redux";


function mapStateToProps(state) {
    return {
        mobile: state.userReducer.mobile,
    }
}

class PublicProfilePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mobileMenu: false,
            mobile: false,
            user: {},
            loading: true,
            activeTab: 'About'
        }
    }

    getUserInfo = async () => {
        this.setState({loading: true});
        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const user_id = lastArg.split("?")[0];

        const res = await getPublicUser(user_id);
        this.setState({user: res.user, loading: false})
    }

    componentDidMount = () => {
        this.getUserInfo();
    }

    toAuth = () => {
        this.props.history.push({pathname: `/authentication`, state: {previous: "Back"}})
    }

    changeTab = (tab) => {
        this.setState({activeTab: tab});
    };

    render() {
        const user = this.state.user || {};
        const connections = user.connections || [];
        const TABS = ['About', 'Donations', 'Posts']
        return (
            <div className='col-plain page-wrapper col-ac'
            style={{marginTop: 120 }}>
                <div className='absolute cover-parent hero-wrapper' style={{zIndex: -1, opacity: .3}}/>
                {this.state.loading ?
                    <LoadingSection/>
                    :
                    <div className='flex flex-col flex-1 overflow-hidden ' style={{maxWidth: this.props.mobile ? '100vw' : '60vw', minWidth: this.props.mobile ? '100vw' : '60vw'}}>
                        <div className='p-5 flex flex-col flex-1 overflow-y-scroll bg-white' >
                            <ProfileHeaderCard
                                user={user}
                                sameUser={false}
                                followees={[]}
                                addFollowee={this.toAuth}
                                removeFollowee={this.toAuth}
                                navigateToList={this.toAuth}
                                mobile={this.props.mobile}
                            />
                             <div className='mb-3'>
                        <SectionTabs
                            activeTab={this.state.activeTab}
                            changeTab={this.changeTab}
                            tabs={TABS}
                            labelOverrides={[`About ${user.first_name}`, null, null]}
                        />
                        </div>
                        {this.state.activeTab === 'About' &&
                            <ProfileAboutSection
                                connections={connections}
                                user={user}
                                sameUser={this.state.sameUser}
                            />
                        }
                        {this.state.activeTab === 'Posts' || this.state.activeTab === "Donations" ?
                            <div className='rounded-xl flex flex-col gap-2'>
                                <DownloadAppCTA mobile={this.props.mobile} ctaText={"View full profile"}/>
                            </div>
                            : null
                        }
                        </div>
                    </div>
                }
            </div>
        )
    }

}


export default connect(mapStateToProps)(withRouter(PublicProfilePage));
