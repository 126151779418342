import React, {Component} from "react";
import {Button} from "../../components/atoms/Button";
import {connect} from "react-redux";
import SvgClose from "../../components/icons/SvgClose";
import CreateGroupMain from "../CreateGroup/CreateGroupMain";

function mapDispatchToProps(dispatch) {
    return {
        toggleCreateGroupModal: () => {
            dispatch({
                type: "TOGGLE_CREATE_GROUP_MODAL",
            })
        },
    }
}

function mapStateToProps(state) {
    return {
        createGroupModalOpen: state.userReducer.createGroupModalOpen,
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
    }
}


class CreateGroupSheet extends Component {
    closeSheet = () => {
        this.props.toggleCreateGroupModal()
    }

    render() {
        return (
            <div
                className={`absolute ${this.props.createGroupModalOpen ? 'h-[100vh]' : 'h-0 overflow-hidden'} transition-all left-0 right-0 bottom-0 bg-white z-[15]`}>
                <div className={`flex flex-col flex-1 h-full p-5`}>
                    <div className='flex flex-row items-center justify-between'>
                        <p className='text-2xl font-semibold text-slate-800'>
                            Create a group
                        </p>
                        <Button onClick={this.closeSheet} variant={'secondary'} size={'icon'} radius={'full'}>
                            <SvgClose/>
                        </Button>
                    </div>
                    <CreateGroupMain closeSheet={this.props.toggleCreateGroupModal}/>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroupSheet)
