import React, {Component} from "react";
import BGGraphic4 from "../img/graphics/BGGraphic4";
import {motion} from "framer-motion";
import Blueprint from "./icons/Blueprint";
import BGGraphic9 from "../img/graphics/BGGraphic9";
import BGGraphic6 from "../img/graphics/BGGraphic6";

class TimelineBlock extends Component {

    render() {
        return (
            <div className='relative row-plain'
                 style={{flexDirection: this.props.mobile ? 'row-reverse' : !this.props.isEvenIndex ? 'row-reverse' : 'row'}}>
                <div className={`flex-1 col-plain ${this.props.mobile ? 'pv-100' : 'pv-60'}`}
                     style={{textAlign: (!this.props.mobile && this.props.isEvenIndex) && 'right'}}>
                    {this.props.i === 1 &&
                    <BGGraphic4 className='absolute black-10' width={this.props.mobile ? '32vw' : '12vw'}
                                height={this.props.mobile ? '32vw' : '12vw'}
                                style={{right: 0, zIndex: 0, top: -40, transform: 'rotate(12deg)'}}/>
                    }
                    {this.props.i === 2 &&
                    <BGGraphic6 className='absolute black-10' width={this.props.mobile ? '32vw' : '12vw'}
                                height={this.props.mobile ? '32vw' : '12vw'}
                                style={{left: 0, zIndex: 0, top: -40, transform: 'rotate(12deg)'}}/>
                    }
                    <motion.div
                        initial={{
                            opacity: 0,
                            transform: `scale(1) translateX(${(!this.props.mobile && this.props.isEvenIndex) && '-'}30px)`
                        }}
                        whileInView={{opacity: 1, transform: `scale(1) translateX(0px)`}}
                        transition={{default: {ease: "linear"}, delay: .2}}
                        className={this.props.mobile ? 'heading2 w-100p mb-20' : 'heading2 mb-20'}
                        style={{lineHeight: 1.4}}>
                        <div className='body3-bold black-50' style={{textTransform: 'uppercase'}}>
                              {this.props.block.heading}
                        </div>
                        <div>
                          {this.props.block.label}
                        </div>
                    </motion.div>
                    <motion.div
                        initial={{
                            opacity: 0,
                            transform: `scale(1) translateX(${(!this.props.mobile && this.props.isEvenIndex) && '-'}40px)`
                        }}
                        whileInView={{opacity: 1, transform: 'scale(1) translateX(0px)'}}
                        transition={{default: {ease: "linear"}, delay: .4}}
                        className=''>
                        {this.props.block.description}
                    </motion.div>
                </div>
                <motion.div
                    initial={{backgroundColor: '#00000010'}}
                    whileInView={{backgroundColor: '#6A93C6'}}
                    transition={{delay: .4, duration: .9}}
                    className={`col-plain ${this.props.mobile ? 'mr-60 ml-20' : 'mh-80'} relative`}
                    style={{width: this.props.mobile ? 1 : 3}}>
                    {this.props.i === 0 &&
                    <motion.div
                        initial={{backgroundColor: '#00000010'}}
                        whileInView={{backgroundColor: '#6A93C6'}}
                        transition={{delay: .4, duration: .9}}
                        className={this.props.mobile ? 'absolute timeline-start-dot-mobile' : 'absolute timeline-start-dot'}
                        style={{backgroundColor: '#6A93C6',}}/>
                    }
                    {(this.props.i === (this.props.length - 1)) &&
                    <motion.div
                        initial={{backgroundColor: '#00000010'}}
                        whileInView={{backgroundColor: '#6A93C6'}}
                        transition={{delay: .4, duration: .9}}
                        className={this.props.mobile ? 'absolute timeline-end-dot-mobile' : 'absolute timeline-end-dot'}
                        style={{backgroundColor: '#6A93C6'}}/>
                    }
                    <motion.div className={`absolute ${this.props.mobile ? 'timeline-label-mobile' : 'timeline-label'}`}
                                initial={{opacity: 0, transform: 'scale(1) translateY(30px)'}}
                                whileInView={{opacity: 1, transform: 'scale(1) translateY(0px)'}}
                                transition={{default: {ease: "linear"}, delay: 0}}
                    >
                        {this.props.mobile ? this.props.block.mobileIcon : this.props.block.icon}
                    </motion.div>
                </motion.div>
                {!this.props.mobile &&
                <div className='flex-1 col-plain'/>
                }
            </div>
        )
    }

}


export default TimelineBlock;
