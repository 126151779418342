import React, {Component} from "react";
import {Button} from "../../components/atoms/Button";
import ProfilePicture from "../../components/atoms/ProfilePicture";
import {connect} from "react-redux";
import SvgChevronDown from "../../components/icons/SvgChevronDown";
import SvgClose from "../../components/icons/SvgClose";
import Dropzone from "../../components/atoms/Dropzone";
import SelectPosterModal from "../../components/SelectPosterModal";
import {uploadDoc} from "../../api/firebase";
import {v4 as uuid} from 'uuid';
import {truncateString} from "../../utils/strings";
import PostEditor from "../../components/posts/PostEditor";
import {convertFromRaw, convertToRaw} from "draft-js";
import {createPost, editPost} from "../../api/posts";
import {uploadVideo} from "../../api/video";
import Spinner from "../../components/Spinner";
import {TypeAnimation} from "react-type-animation";

function mapDispatchToProps(dispatch) {
    return {
        toggleCreatePostModal: () => {
            dispatch({
                type: "TOGGLE_CREATE_POST_MODAL",
            })
        },
    }
}

function mapStateToProps(state) {
    return {
        createPostModalOpen: state.userReducer.createPostModalOpen,
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
        currentPostId: state.userReducer.currentPostId,
        posts: state.postReducer.posts,
        groupName: state.userReducer.createPostGroupName,
        groupId: state.userReducer.createPostGroupId,
    }
}


class CreatePostSheet extends Component {
    state = {
        content: '',
        images: [],
        videos: [],
        files: [],
        creator: {},
        npPost: false,
        selectPosterModalOpen: false,
        imageError: false,
        mode: 'create',
        originalContent: null,
        loading: false,
    }

    contentChanged = (content) => {
        this.setState({content});
    }

    addFile = async (files) => {
        const file = files[0];
        const id = uuid();

        if (file.type.includes("image")) {
            const url = await uploadDoc("uploads", id, file);
            file["url"] = url;
            this.setState({images: [...this.state.images, url]})
        } else {
            const uploadedVideo = await uploadVideo(file, id);
            file["url"] = uploadedVideo;
            this.setState({videos: [...this.state.videos, uploadedVideo]})
        }

        this.setState({files: [...this.state.files, file]})

    }

    deleteFile = (file) => {
        const updatedFiles = this.state.files.filter(f => f.url !== file)
        const updatedImages = this.state.images.filter(url => url !== file)
        const updatedVideos = this.state.videos.filter(url => url !== file)

        this.setState({files: updatedFiles, images: updatedImages, videos: updatedVideos});
    }

    post = () => {
        this.setState({content: ''})
        this.props.toggleCreatePostModal()
    }

    openSelectPosterModal = () => {
        this.setState({selectPosterModalOpen: true})
    }


    closeSelectPosterModal = () => {
        this.setState({selectPosterModalOpen: false})
    }

    closeSheet = () => {
        this.props.toggleCreatePostModal()
    }

    selectOption = (creator, index) => {
        this.setState({creator, npPost: index !== 0});
        this.closeSelectPosterModal();
    }

    componentDidMount = () => {
        const user = this.props.user || {};
        this.setState({creator: {name: user.first_name + " " + user.last_name, value: user.username}});
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.currentPostId !== this.props.currentPostId && !!this.props.currentPostId) {
            const post = this.props.posts[this.props.currentPostId];
            const contentState = convertFromRaw(post.content);

            const images = !!post.image_urls && post.image_urls.length ? post.image_urls.map(url => ({
                name: "image",
                url
            })) : [];
            const videos = !!post.videos && post.videos.length ? post.videos.map(url => ({name: "video", url})) : [];
            this.setState({
                mode: "edit",
                originalContent: contentState,
                images: post.image_urls || [],
                videos: post.videos || [],
                files: images.concat(videos)
            })
        }
    }

    createPost = async () => {
        this.setState({loading: true})
        const content = convertToRaw(this.state.content);
        const plaintext = content.blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

        const users = []
        const nonProfits = []
        const hashtags = [];
        let url = null

        for (const entity of Object.values(content.entityMap)) {
            if (entity.data.mention?.type === "userTag") users.push(entity.data.mention.id);
            else if (entity.data.mention?.type === "nonProfitTag") nonProfits.push(entity.data.mention.id);
            else if (entity.data.mention?.type === "hashtag") hashtags.push(entity.data.mention.hashtag);
            else if (entity.type === "LINK") url = entity.data.url;
        }
        try {
            if (this.state.mode === 'create') {
                const groupId = !!this.props.groupId ? this.props.groupId : "public"
                await createPost(this.props.authUser, this.props.authUser.uid, content, this.state.images, url, users, nonProfits, hashtags, plaintext, this.state.videos, null, null, null, false, groupId);
            } else {
                await editPost(this.props.authUser, this.props.currentPostId, {
                    content,
                    image_urls: this.state.images,
                    videos: this.state.videos,
                    plaintext: plaintext,
                    users,
                    hashtags,
                    non_profits: nonProfits,
                    url
                });
                window.location.reload();
            }
            this.setState({loading: false})
            this.closeSheet();
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const user = this.props.user || {};
        const nps = user.admin_np_info || [];
        let creators = nps.map(np => {
            return ({name: np.name, value: np.ein, logo_url: np.logo_url})
        });
        creators.unshift({name: user.first_name + " " + user.last_name, value: user.username});
        const groupName = this.props.groupName || "";
        return (
            <div
                className={`absolute ${this.props.createPostModalOpen ? 'h-[100vh]' : 'h-0 overflow-hidden'} transition-all left-0 right-0 bottom-0 bg-white z-[3]`}>
                <div className={`flex flex-col flex-1 h-full p-5`}>
                    <div className='flex flex-row items-center justify-between'>
                        <p className='text-2xl font-semibold text-slate-800'>
                            {this.state.mode === 'create' ? 'Create a post' : 'Edit your post'}
                        </p>
                        <Button onClick={this.closeSheet} variant={'secondary'} size={'icon'} radius={'full'}>
                            <SvgClose/>
                        </Button>
                    </div>
                    <div className='mt-5 border border-slate-300 p-5 rounded-xl flex flex-col gap-4'>
                        <div className='flex flex-row items-center gap-2'>
                            {this.state.npPost ?
                                <img src={this.state.creator.logo_url}
                                     style={{
                                         width: 40,
                                         height: 40,
                                         objectFit: "contain",
                                         backgroundColor: "white",
                                         border: '2px solid #f6f6f6'
                                     }}
                                     onError={(e) => {
                                         e.target.src =
                                             "https://handshake-production-cdn.joinhandshake.com/static_assets/default_institution_1.png";
                                         this.setState({imageError: true});
                                     }}
                                     className='br-8 mr-8'/>
                                :
                                <ProfilePicture
                                    initial={this.state.creator.name}
                                    userID={this.state.creator.value}
                                />
                            }
                            <div
                                onClick={() => nps.length > 0 && this.state.mode === "create" ? this.openSelectPosterModal() : null}
                                className='flex flex-row items-center gap-2 text-slate-800h hover:text-blue-500 cursor-pointer'>
                                <p className='text-lg font-semibold'>
                                    {this.state.creator.name}
                                </p>
                                {nps.length > 0 && this.state.mode === "create" &&
                                    <SvgChevronDown className={'w-4 h-4'}/>
                                }
                            </div>
                            <p className='bg-blue-100 text-blue-500 text-sm font-semibold p-0.5 px-3 rounded-full ml-auto'>
                                Posting to: {!!groupName ? (groupName.length > 20 ? groupName.slice(0,20) + '...' : groupName) : 'Home feed'}
                            </p>
                        </div>
                        <PostEditor
                            originalContent={this.state.originalContent}
                            onChange={this.contentChanged}
                            placeholder={"Tell the world what you're thinking..."}
                        />
                        <Dropzone
                            addedFiles={this.state.files}
                            images={this.state.images}
                            videos={this.state.videos}
                            onDrop={this.addFile}
                            deleteFile={this.deleteFile}
                        />
                        <div className='flex flex-row items-center'>
                            <div className='flex flex-row text-blue-500 items-center gap-2 text-sm'>
                                <p className='font-bold'>
                                    Tips:
                                </p>
                                <TypeAnimation
                                    sequence={[
                                        // Same substring at the start will only be typed out once, initially
                                        'Type @ to tag a user',
                                        800, // wait 1s before replacing "Mice" with "Hamsters"
                                        'Type / to tag a nonprofit',
                                        800,
                                        'Upload images or videos using the area below',
                                        500,
                                    ]}
                                    wrapper="span"
                                    speed={50}
                                    style={{display: 'inline-block'}}
                                    repeat={Infinity}
                                />
                            </div>
                            <div className='ml-auto'>
                                {this.state.loading ?
                                    <Spinner/>
                                    :
                                    <Button variant={'default'} onClick={this.createPost}
                                            disabled={!this.state.content || !this.state.content.hasText()}>
                                        {this.state.mode === 'create' ? "Post" : "Edit"} {nps.length > 0 &&
                                        `as ${truncateString(this.state.creator.name, 20)}`
                                    }
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <SelectPosterModal
                    creators={creators}
                    isOpen={this.state.selectPosterModalOpen}
                    closeModal={this.closeSelectPosterModal}
                    selectOption={this.selectOption}
                />
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePostSheet)
