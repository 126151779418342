import React, {PureComponent} from "react";
import ModalComponent from "../components/ModalComponent";
import {sendPasswordResetEmail} from "firebase/auth";
import {firebaseAuth} from "../api/firebase";
import AnimatedInput from "../components/AnimatedInput";
import Spinner from "../components/Spinner";



class ForgotPassword extends PureComponent {
    state = {
        email: ''
    }

    onChangeEmail = (e) => {
        this.setState({email: e})
    }

    sendPasswordReset = () => {
        sendPasswordResetEmail(firebaseAuth, this.state.email);
        this.setState({email: ""})
        this.props.closeModal();
    };

    render() {
        const buttonDisabled = !this.state.email;
        return (
            <ModalComponent
            isOpen={this.props.isOpen}
            size={this.props.mobile ? 'full' : 'sm'}
            toggle={this.props.closeModal}
            headerToggle
        >
            <div className={`col-jc flex-1 ${this.props.mobile ? 'p-5' : 'p-5'} pt-0`}>
                <h1 className={`${this.props.mobile ? 'title1' : 'heading2'} black-80 mb-0 mt-0 text-center`}>
                    Forgot your password?
                </h1>

                <div className='mt-40 row-ac body1' style={{marginTop: this.props.mobile && 24}}>
                    <div className='flex-1'>
                        <AnimatedInput
                            name="email"
                            onChange={this.onChangeEmail}
                            value={this.state.email}
                            type="email"
                            placeholder="Your Email"
                        />
                    </div>
                </div>
                <div className='row-ac-jc mt-40 mb-12'>
                    <button disabled={buttonDisabled} onClick={this.sendPasswordReset} className='primary-button body1-bold'
                            style={{width: 200, opacity: buttonDisabled && .3}}>
                        {this.state.processing ?
                            <Spinner/> : 'Submit'}
                    </button>
                </div>
            </div>
        </ModalComponent>
        )
    }
}

export default ForgotPassword
