
import * as React from "react"

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={24} width={24}  viewBox={'0 0 26 26'}  {...props}>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
         fill={props.fill || 'currentColor'}
        d="M7 7h10v3l4-4-4-4v3H5v6h2V7zm10 10H7v-3l-4 4 4 4v-3h12v-6h-2v4z" />
  </svg>
)

export default SvgComponent
