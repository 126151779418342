import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button} from '../../components/atoms/Button';
import SvgClose from '../../components/icons/SvgClose';
import SvgSearch from "../../components/icons/SvgSearch";
import { searchGroups } from '../../api/groups';
import GroupSearchResultItem from "../../components/groups/GroupSearchResultItem";
import ZeroStateArea from "../../components/atoms/ZeroStateArea";

const SearchGroupsSheet = ({isOpen, close}) => {
    const user = useSelector(state => state.userReducer.user);
    const authUser = useSelector(state => state.userReducer.authUser);
    const dispatch = useDispatch();
    const [text, setText] = useState('')
    const [groupsList, setGroupsList] = useState([]);

    const searchChanged = (e) => {
        const text = e.target.value
        setText(text);
        search(text)
    }

    useEffect(() => {
        search("");
    }, [])

    const closeSheet = () => {
        dispatch(close());
    };

    const search = async (text) => {
        await searchGroups(authUser, text, (groups) => setGroupsList(groups));
    }


    return (
        <div
            className={`absolute ${isOpen ? 'h-full overflow-y-scroll' : 'h-0 overflow-hidden'} transition-all left-0 right-0 bottom-0 bg-white z-[10]`}>
            <div className={`flex flex-col flex-1 h-full p-5 mb-`}>
                <div className='flex flex-row items-center justify-between'>
                    <p className='text-2xl font-semibold text-slate-800'>
                        Search for Groups
                    </p>
                    <Button onClick={closeSheet} variant={'secondary'} size={'icon'} radius={'full'}>
                        <SvgClose/>
                    </Button>
                </div>
                <div className='flex flex-col gap-5 mt-20 pb-20'>
                    <div className='row-ac relative mb-3'>
                        <input
                            onChange={searchChanged}
                            value={text}
                            type="text"
                            className={'w-100p search-input rounded-lg'}
                            placeholder="Search Groups by name..."
                        />
                        <SvgSearch className={'absolute black-50'}
                                   style={{top: 10, left: 24, width: 24, height: 24}}/>
                    </div>
                </div>
                <div>
                    {groupsList.map(group =>
                        <GroupSearchResultItem group={group} key={group.id} user={user}/>
                    )}
                    <div className='hidden only:block mt-32'>
                        <ZeroStateArea
                            title={'Nothing found...'}
                            sub={`Try another search or create your own group`}
                        />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default SearchGroupsSheet;
