import React, {Component} from "react";
import LoadingSection from "../components/LoadingSection";
import MiddleViewHeader from "../components/layout/MiddleViewHeader";
import {getHashTagPosts} from "../api/feeds";
import PostComponent from "../components/posts/PostComponent";
import {connect} from "react-redux";
import {Button} from "../components/atoms/Button";
import SvgUserPlus from "../components/icons/SvgUserPlus";

function mapDispatchToProps(dispatch) {
    return {
        toggleCreatePostModal: () => {
            dispatch({
                type: "TOGGLE_CREATE_POST_MODAL",
            })
        },
        updatePosts: (posts) => {
            dispatch({
                type: "UPDATE_POSTS",
                posts,
            })
        },
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        posts: state.postReducer.posts,
    }
}

class HashtagPage extends Component {
    state = {
        liked: false,
        loading: true,
        posts: [],
        hashtag: "",
    }

    componentDidMount = async () => {
        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const hashtag = "#" + lastArg.split("?")[0];

        const res = await getHashTagPosts(this.props.authUser, hashtag);
        this.props.updatePosts(res.posts);
        this.setState({posts: res.posts, loading: false, hashtag});
    }


    render() {
        return (
            <div className='flex flex-col flex-1 overflow-hidden'>
                <MiddleViewHeader
                    back
                    title={this.state.hashtag}
                />
                {this.state.loading ?
                    <LoadingSection/>
                    :
                    <div className='flex flex-col flex-1 overflow-y-scroll'>
                        {this.state.posts.map(post =>
                            <PostComponent
                                post={this.props.posts[post.post_ID]}
                            />
                        )}
                    </div>
                }
            </div>
        )
    }

}


export default connect(mapStateToProps, mapDispatchToProps)(HashtagPage);
