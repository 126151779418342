import SvgChevronDown from "../icons/SvgChevronDown";
import React from "react";
import GroupMembersListItem from "./GroupMembersListItem";

function GroupMembersList({group, goBack, user, removeMember, challengeParticipants}) {
    const isAdmin = "admin" in group && Object.keys(group.admin).includes(user.username);
    return (
        <>
            <div onClick={goBack}
                 className='flex flex-row items-center gap-2 cursor-pointer text-slate-500 hover:text-slate-700'>
                <SvgChevronDown className={'rotate-90'}/>
                <p>
                    Back
                </p>
            </div>
            {group?.members.map(username =>
                <GroupMembersListItem
                    username={username}
                    key={username}
                    name={group?.memberNames[username]}
                    user={user}
                    removeMember={removeMember}
                    isAdmin={isAdmin}
                    isChallengeParticipant={challengeParticipants.includes(username)}
                />
            )}
        </>
    )
}

export default GroupMembersList
