import React, { useEffect } from 'react';
import ChecklistButton from "./atoms/ChecklistButton";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";


function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        toggleCreatePostModal: () => {
            dispatch({
                type: "TOGGLE_CREATE_POST_MODAL",
            })
        },
    }
}

const NuxWidget = ({ user, toggleCreatePostModal, history }) => {
    const nux = user.nux || {};
    const goToRoute = (pathname) => {
        history.push({pathname})
    };

    const openPost = () => {
        setTimeout(() => {
            toggleCreatePostModal();
        }, 500);
    };

    const hideNux = nux.add_np && nux.create_post && nux.follow_user_np && nux.participate_challenge;
    if (hideNux) {
        return null
    }
    return (
        <div>
            <p className='text-xl mb-3 font-semibold '>
                Get Started
            </p>
            <div className="p-4 bg-white shadow-lg rounded-lg mt-2 border border-slate-200">
                <h3 className="text-xl font-bold text-gray-700 mt-4">✨ Welcome to Starfish!</h3>
                <p className="text-base font-medium text-gray-700 mt-4 mb-6">
                    Here are some steps to help you get started.
                </p>
                <ChecklistButton
                    completed={nux.follow_user_np}
                    title="Follow a user or a Nonprofit"
                    onClick={() => goToRoute('/network')}
                />
                <ChecklistButton
                    completed={nux.add_np}
                    title="Add a Nonprofit to your profile"
                    onClick={() => goToRoute('/network')}
                />
                <ChecklistButton
                    completed={nux.participate_challenge}
                    title="Participate in a challenge"
                    onClick={() => goToRoute('?active_tab=Challenges')}
                />
                <ChecklistButton
                    completed={nux.create_post}
                    title="Create your first post"
                    onClick={() => openPost()}
                />
            </div>
        </div>
    );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NuxWidget));
