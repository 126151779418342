import SvgChevronDown from "../icons/SvgChevronDown";
import GroupPicture from "../atoms/GroupPicture";
import TextInput from "../inputs/TextInput";
import {Button} from "../atoms/Button";
import {v4 as uuid} from 'uuid';
import React, {useEffect, useState} from "react";
import Dropzone from "react-dropzone";
import SvgLibraryAdd from "../icons/SvgLibraryAdd";
import { editGroup } from "../../api/groups";
import { uploadDoc } from "../../api/firebase";

function EditGroupDetails({group, goBack, authUser}) {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [imageId, setImageId] = useState('')

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    useEffect(() => {
        setName(group?.name);
        setDescription(group?.description)
        setImageId(group?.image_id)
    }, [group])

    const onDropProfileImage = async (acceptedFiles) => {
        const imageId = uuid();
        await uploadDoc("groups/", imageId, acceptedFiles[0]);
        setImageId(imageId);
    }

    const saveChanges = async () => {
        await editGroup(authUser, group.id, {
            name: name,
            description: description,
            image_id: imageId
        });
        goBack();
    };

    return (
        <>
            <div onClick={goBack}
                 className='flex flex-row items-center gap-2 cursor-pointer text-slate-500 hover:text-slate-700'>
                <SvgChevronDown className={'rotate-90'}/>
                <p>
                    Back
                </p>
            </div>
            <div className='relative mr-a'>
                <GroupPicture size={'3xl'} imageId={imageId}/>
                <div className='absolute bottom-0 -right-2'>
                    <Dropzone onDrop={(files) => onDropProfileImage(files)}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()} className='cursor-pointer mt-[-48px] ml-4'>
                                <input {...getInputProps()} />
                                <Button size={'icon'} variant={'secondary'} >
                                    <SvgLibraryAdd className={'w-5 h-5'}/>
                                </Button>
                            </div>
                        )}
                    </Dropzone>
                </div>
            </div>
            <TextInput
                label={'Name'}
                value={name}
                placeholder={'e.g. Neighborhood Pals'}
                onChange={handleNameChange}
            />
            <TextInput
                label={'Description'}
                value={description}
                textarea
                placeholder={'e.g. Bringing together the people of the neighborhood to do good!'}
                onChange={handleDescriptionChange}
                className={'min-h-[140px]'}
            />
            <Button onClick={saveChanges}>
                Save Changes
            </Button>
        </>
    )
}

export default EditGroupDetails
