import React, {PureComponent} from "react";
import BGGraphic7 from "../../img/graphics/BGGraphic7";
import {motion} from "framer-motion";
import SvgPin from "../../components/icons/SvgPin";
import SvgCalendar from "../../components/icons/SvgCalendar";
import ProfileCard from "../../components/ProfileCard";
import ConnectionCard from "../../components/ConnectionCard";
import SvgCrowd from "../../components/icons/SvgCrowd";
import SvgProfile from "../../components/icons/SvgProfile";
import {PROFILE_CAROUSEL_CONTENT} from "../../utils/dummy";
import {wait} from "@testing-library/user-event/dist/utils";
import ArrowBack from "../../components/icons/ArrowBack";
import ArrowForward from "../../components/icons/ArrowForward";


const STEP_ORDER = {
    first: 0,
    second: 1,
    third: 2,
}

class Section1 extends PureComponent {
    state = {
        step: 'first',
        firstTranslation: 20,
        secondTranslation: 20,
        thirdTranslation: 20,
        autoPlay: true,
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.next()
        }, 6000);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.autoPlay && !this.state.autoPlay) {
            clearInterval(this.interval)
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    switchGraphic = () => {
        switch (this.state.step) {
            case 'first':
                return (
                    <div
                        style={{
                            transition: 'opacity 300ms ease, transform 600ms ease',
                            opacity: this.state.opacity,
                            transform: `translateY(${Math.abs(this.state.firstTranslation) / 2}px)`
                        }}
                    >
                        <ProfileCard profile={PROFILE_CAROUSEL_CONTENT[0].profile} mobile={this.props.mobile}/>
                        <motion.div
                            initial={{opacity: 0, transform: 'scale(1) translateY(8px)'}}
                            whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            transition={{default: {ease: "linear"}, delay: .2}}
                            style={{
                                marginTop: -16,
                                marginLeft: this.props.mobile ? '5%' : '20%',
                                marginRight: this.props.mobile ? '2%' : '-4%'
                            }}>
                            <ConnectionCard connection={PROFILE_CAROUSEL_CONTENT[0].connections[0]}
                                            mobile={this.props.mobile}/>
                        </motion.div>
                    </div>
                )
            case 'second':
                return (
                    <div
                        style={{
                            transition: 'opacity 300ms ease, transform 600ms ease',
                            opacity: this.state.opacity,
                            transform: `translateY(${Math.abs(this.state.secondTranslation) / 2}px)`
                        }}
                    >
                        <ProfileCard profile={PROFILE_CAROUSEL_CONTENT[1].profile} mobile={this.props.mobile}/>
                        <motion.div
                            initial={{opacity: 0, transform: 'scale(1) translateY(8px)'}}
                            whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            transition={{default: {ease: "linear"}, delay: .2}}
                            style={{
                                marginTop: -16,
                                marginLeft: this.props.mobile ? '5%' : '20%',
                                marginRight: this.props.mobile ? '2%' : '-4%'
                            }}>
                            <ConnectionCard connection={PROFILE_CAROUSEL_CONTENT[1].connections[0]}
                                            mobile={this.props.mobile}/>
                        </motion.div>
                    </div>
                )
            case 'third':
                return (
                    <div
                        style={{
                            transition: 'opacity 300ms ease, transform 600ms ease',
                            opacity: this.state.opacity,
                            transform: `translateY(${Math.abs(this.state.thirdTranslation) / 2}px)`
                        }}
                    >
                        <ProfileCard profile={PROFILE_CAROUSEL_CONTENT[2].profile} mobile={this.props.mobile}/>
                        <motion.div
                            initial={{opacity: 0, transform: 'scale(1) translateY(8px)'}}
                            whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            transition={{default: {ease: "linear"}, delay: .2}}
                            style={{
                                marginTop: -16,
                                marginLeft: this.props.mobile ? '5%' : '20%',
                                marginRight: this.props.mobile ? '2%' : '-4%'
                            }}>
                            <ConnectionCard connection={PROFILE_CAROUSEL_CONTENT[2].connections[0]}
                                            mobile={this.props.mobile}/>
                        </motion.div>
                    </div>
                )
            default:
                return null
        }
    }

    navigateToStep = step => {
        const currentStep = this.state.step
        const movingForwards = STEP_ORDER[step] > STEP_ORDER[currentStep]
        this.setState(
            {opacity: 0, [`${currentStep}Translation`]: movingForwards ? -20 : 20},
            () => {
                wait(600)
                    .then(() => {
                        this.setState(
                            {step},
                            () => wait(300)
                                .then(() => this.setState({opacity: 1, [`${step}Translation`]: 0}))
                        )
                    })
            }
        )
    }

    next = () => {
        if (this.state.step === 'first') {
            this.navigateToStep('second')
        }
        if (this.state.step === 'second') {
            this.navigateToStep('third')
        }
        if (this.state.step === 'third') {
            this.navigateToStep('first')
        }
    }

    back = () => {
        if (this.state.step === 'second') {
            this.navigateToStep('first')
        }
        if (this.state.step === 'third') {
            this.navigateToStep('second')
        }
    }


    render() {
        return (
            <section className={this.props.mobile ? 'col-ac relative' : 'row-ac-jb relative'}
                     style={{
                         height: !this.props.mobile && '90vh',
                         flexDirection: !this.props.mobile && 'row-reverse',
                         padding: this.props.mobile ? '80px 24px' : '0 10vw'
                     }}>
                <BGGraphic7 className='absolute' fill={'#054A91'}
                            style={{bottom: -100, right: 60, width: '15vw', zIndex: 2}}/>
                <motion.div style={{flex: 1, maxWidth: !this.props.mobile && '30vw'}}
                            initial={{opacity: 0, transform: 'scale(1) translateY(12px)'}}
                            whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            transition={{default: {ease: "linear"}, delay: .2}}
                            className={this.props.mobile ? 'mb-40 col-plain' : 'col-plain'}>
                    {/*<div className='ph-24 pv-12 br-64 body1-bold mb-12 white-pure'*/}
                    {/*     style={{backgroundColor: "#F0A042A0", marginRight: "auto"}}>*/}
                    {/*    <SvgProfile width={36} height={36} fill={'#FFFFFF'}/>*/}
                    {/*</div>*/}
                    <div className='body3-bold black-50 mb-8' style={{textTransform: 'uppercase'}}>
                       PROFILES
                    </div>
                    <h1 className='heading2 black-100 mb-32 mt-0'>
                        Create your<br/> profile of good
                    </h1>
                    <p className='mt-0 body1 black-70'>
                        Highlight who you are, the causes you're involved with, tell the community about your passions,
                        and share
                        stories of the connections you are excited about.
                        Your profile is your place to tell others about the good you do and the things you value.
                    </p>
                </motion.div>
                <div style={{flex: 1, maxWidth: this.props.mobile ? '100%' : '40vw'}} className='col-plain relative'>
                    <motion.div
                        initial={{opacity: 0, transform: 'scale(1) translateY(12px)'}}
                        whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                        transition={{default: {ease: "linear"}}}
                    >
                        {this.switchGraphic()}
                        {/*<div className='row-ac mt-32'>*/}
                        {/*    <div onClick={() => {*/}
                        {/*        this.back()*/}
                        {/*        this.setState({autoPlay: false})*/}
                        {/*    }}*/}
                        {/*         style={{marginRight: 8}}*/}
                        {/*         className={`${this.state.step === 'first' ? 'black-30 next-button-disabled' : 'black-100 pointer next-button'}`}>*/}
                        {/*        <ArrowBack width={30} height={30}/>*/}
                        {/*    </div>*/}
                        {/*    <div onClick={() => {*/}
                        {/*        this.next()*/}
                        {/*        this.setState({autoPlay: false})*/}
                        {/*    }}*/}
                        {/*         style={{marginLeft: 8}}*/}
                        {/*         className={`${'black-100 pointer next-button'}`}>*/}

                        {/*        <ArrowForward width={30} height={30}/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </motion.div>
                </div>
            </section>
        )
    }
}

export default Section1
