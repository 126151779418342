import React, {useEffect, useRef, useState} from "react";
import LoadingSection from "../components/LoadingSection";
import MiddleViewHeader from "../components/layout/MiddleViewHeader";
import {getFollowerPosts, getForYouPosts} from "../api/feeds";
import PostComponent from "../components/posts/PostComponent";
import {connect} from "react-redux";
import {Button} from "../components/atoms/Button";
import SvgUserPlus from "../components/icons/SvgUserPlus";
import SectionTabs from "../components/SectionTabs";
import SvgAddCircle from "../components/icons/SvgAddCircle";
import ChallengeFeedPage from "./ChallengeFeedPage";
import {Link, withRouter} from "react-router-dom";
import SvgEditNote from "../components/icons/SvgEditNote";
import SvgTarget from "../components/icons/SvgTarget";
import EventInFeedWidget from "../components/suggestions/EventInFeedWidget";
import { getActivity } from "../api/activity";


function mapDispatchToProps(dispatch) {
    return {
        toggleCreatePostModal: () => {
            dispatch({
                type: "TOGGLE_CREATE_POST_MODAL",
            })
        },
        updatePosts: (posts) => {
            dispatch({
                type: "UPDATE_POSTS",
                posts,
            })
        },
        updateScrollPosition: (scrollPosition) => {
            dispatch({
                type: "UPDATE_SCROLL_POSITION",
                scrollPosition
            });
        }
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        posts: state.postReducer.posts,
        path: state.userReducer.path,
        scrollPosition: state.userReducer.scrollPosition
    }
}

const FeedPage = (props) => {
    const [loading, setLoading] = useState(true);
    const [index, setIndex] = useState(null);
    const [posts, setPosts] = useState([]);
    const [activeTab, setActiveTab] = useState("For you");
    const [fetchingPosts, setFetchingPosts] = useState(false);
    const [activity, setActivity] = useState([]);

    const list = useRef(null);

    const onScroll = () => {
        if (list.current) {
            const {scrollTop, scrollHeight, clientHeight} = list.current;
            props.updateScrollPosition(scrollTop);


            if (scrollTop + clientHeight >= scrollHeight - 4000 && !fetchingPosts) {
                getPosts(activeTab, index, posts);
            }
        }
    };

    const scrollToTop = () => {
        if (list.current) {
            list.current.scrollTo({
                top: 0,
                behavior: 'smooth' // Optional: for smooth scrolling
            });
        }
    };

    useEffect(() => {
        if (!!props.path && props.path !== null && props.path !== "null" && !props.path.includes("referral")) {
            props.history.push({pathname: `/` + props.path});
            return;
        }

        getPosts(activeTab, null, []);
        getAllActivity();

        if (!posts.length) {
            getPosts(activeTab, index, posts);
        }
    }, []);

    const getAllActivity = async () => {
        const res = await getActivity(props.authUser);
        setActivity(res.activity);
    }

    const getPosts = async (tab, index, posts) => {
        setFetchingPosts(true);
        let res = {};
        if (tab === "For you") {
            res = await getForYouPosts(props.authUser, index)
        } else {
            res = await getFollowerPosts(props.authUser, index)

        }
        props.updatePosts([...Object.values(props.posts), ...res.posts]);
        setPosts([...posts, ...res.posts]);
        setIndex(res.index);
        setLoading(false);
        setFetchingPosts(false);
    }

    const changeTab = (tab) => {
        setLoading(true);
        setActiveTab(tab);
        scrollToTop();
        getPosts(tab, null, []);
    }


    return (
        <div className='flex flex-col flex-1 overflow-hidden'>
            <MiddleViewHeader
                title={'My Feed'}
                RightComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Link to={'/create-challenge'}>
                            <Button
                                size={'lg'}
                                icon={SvgAddCircle}
                            >
                                <SvgTarget className={'w-5 h-5'}/>
                                Start a Challenge
                            </Button>
                        </Link>
                        <Button
                            size={'lg'}
                            icon={SvgUserPlus}
                            onClick={props.toggleCreatePostModal}
                        >
                            <SvgEditNote/>
                            Post
                        </Button>
                    </div>
                }
            >
                <div className='-mb-4'>
                    <SectionTabs
                        activeTab={activeTab}
                        changeTab={changeTab}
                        tabs={["For you", "Following", 'Challenges']}
                    />
                </div>
            </MiddleViewHeader>
            {loading ?
                <LoadingSection/>
                :
                activeTab === 'Challenges' ?
                    <>
                        <ChallengeFeedPage/>
                    </>
                    :
                    <div className='flex flex-col flex-1 overflow-y-scroll' onScroll={() => onScroll()} ref={list}>
                        {posts.map((post,i) => {
                            const activityIndex = Math.floor(i / 10) - 1
                            if (i > 0 && i % 10 === 0 && activityIndex < activity.length) {
                                return (
                                    <EventInFeedWidget
                                        activity={activity[activityIndex]}
                                    />
                                )
                            }
                            return (
                                <PostComponent
                                    post={props.posts[post.post_ID]}
                                />
                            )
                        }

                        )}
                        <div>
                            <LoadingSection/>
                        </div>
                    </div>
            }
        </div>
    )


}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FeedPage));
