import React, {Component} from "react";
import MiddleViewHeader from "../components/layout/MiddleViewHeader";
import ChatPage from "./ChatPage";
import {connect} from "react-redux";
import {Button} from "../components/atoms/Button";
import LoadingSection from "../components/LoadingSection";
import SvgUserPlus from "../components/icons/SvgUserPlus";
import {attachUserChatsListener} from "../api/messaging";
import ChatListItem from "../components/ChatListItem";
import CreateDmSheet from "./Sheets/CreateDmSheet";
import SvgGroupAdd from "../components/icons/SvgGroupAdd";
import SvgChatBubbles from "../components/icons/SvgChatBubbles";


function mapDispatchToProps(dispatch) {
    return {
        toggleCreatePostModal: () => {
            dispatch({
                type: "TOGGLE_CREATE_POST_MODAL",
            })
        },
        updatePosts: (posts) => {
            dispatch({
                type: "UPDATE_POSTS",
                posts,
            })
        },
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        posts: state.postReducer.posts,
    }
}

class MessagesPage extends Component {
    state = {
        chats: [],
        loading: true,
        currentChat: {},
        createDmOpen: false,
    }

    componentDidMount() {
        this.chatsListener = attachUserChatsListener(this.props.authUser, async chats => {
            const filteredChats = chats.filter(chat => !chat?.is_group);
            this.setState(prevState => {
                    const currentChat = !!prevState.currentChat && Object.values(prevState.currentChat).length ? prevState.currentChat : filteredChats[0];
                    return {
                        chats: filteredChats,
                        currentChat,
                        loading: false
                    }
                }
            );
        })
    }

    selectChat = (chat) => {
        this.setState({currentChat: chat});
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.location.state?.userId !== this.props.location.state?.userId) {
            this.setState({createDmOpen: true})
        }
    }


    render() {
        if (this.state.loading) {
            return (
                <LoadingSection/>
            )
        }
        return (
            <div className='flex flex-col flex-1 overflow-hidden'>
                <MiddleViewHeader
                    title={'Direct Messages'}
                    RightComponent={
                        <Button
                            size={'lg'}
                            onClick={() => this.setState({createDmOpen: true})}
                        >
                            <SvgChatBubbles className={'w-5 h-5 mr-1'}/>
                            New DM
                        </Button>
                    }
                />
                <div className='flex flex-row flex-1 overflow-hidden bg-white'>
                    <div className='w-[300px] overflow-y-scroll flex flex-col pt-0 border-r border-slate-200'>
                        {this.state.chats.map(chat =>
                            <ChatListItem
                                username={this.props.authUser.uid}
                                chat={chat}
                                currentChat={this.state.currentChat}
                                selectChat={this.selectChat}
                            />
                        )}
                        <div className='p-5 flex flex-col items-center gap-3'>
                            {this.state.chats.length === 0 &&
                                <div className='flex flex-col items-center gap-3 mt-12'>
                                    <SvgGroupAdd className={'text-slate-300'}/>
                                    <p className='text-slate-500 text-sm text-center'>
                                        You aren't in any chats yet.
                                    </p>
                                </div>
                            }
                            <Button size={'sm'} onClick={() => this.setState({createDmOpen: true})}>
                                Start a DM
                            </Button>
                        </div>
                    </div>
                    <ChatPage chat={this.state.currentChat}/>
                </div>
                <CreateDmSheet isOpen={this.state.createDmOpen} close={() => this.setState({createDmOpen: false})}/>
            </div>
        )
    }

}


export default connect(mapStateToProps, mapDispatchToProps)(MessagesPage);
