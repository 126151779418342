import React, {Component} from "react";
import ModalComponent from "../components/ModalComponent";
import {Link} from "react-router-dom";

class CampaignFeatureModal extends Component {

    render() {

        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                size={this.props.mobile ? 'full' : 'md'}
                toggle={this.props.closeModal}
                headerToggle
                FooterComponent={
                    <div className='row-ac flex-1'>
                        <button
                            onClick={this.props.closeModal}
                            className='secondary-button body1-bold mr-12'
                            style={{flex: 1}}>
                            Close
                        </button>
                        <Link to='/campaign'>
                            <button
                                className='primary-button body1-bold'
                                style={{flex: 1}}>
                                Go To Campaign
                            </button>
                        </Link>
                    </div>
                }
            >
                <div className={`col-ac-jc ${this.props.mobile ? 'p-5' : 'p-5'} pt-0`} style={{flex: 1}}>
                    <div className='col-ac text-center'>
                        <img src={require('../img/graphics/banner.png')} style={{
                            backgroundColor: "white",
                            width: '100%',
                            border: '1px solid #00000030',
                            borderRadius: 12
                        }}/>
                        <h1 className={`${this.props.mobile ? 'title1' : 'heading2'} black-80 mb-12 mt-8`}>
                            <span className='primary heading4'>NEW: </span><br/>Starfish Million Dollar Campaign
                        </h1>
                        <p className='body2 black-50'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam.
                        </p>
                    </div>
                </div>
            </ModalComponent>
        )
    }

}


export default CampaignFeatureModal;
