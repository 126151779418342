import React, {useEffect, useState} from 'react';
import NPOPicture from "../atoms/NPOPicture";
import SvgUserPlus from "../icons/SvgUserPlus";
import {Button} from "../atoms/Button";
import SvgCheck from "../icons/SvgCheck";
import SvgLink2 from "../icons/SvgLink2";
import {wait} from "../../utils/timestamp";
import { shareInvite } from '../../utils/share';


function InviteListItem({invite, deactivate}) {
    const [copiedLink, setCopiedLink] = useState(false);
    const [link, setLink] = useState("https://starfishapp.app.link/...");

    const generateLink = async () => {
        if (!invite.link) {
            const link = await shareInvite(invite.user_id, invite.non_profit_id);
            return link;
        }
        return invite.link;
        
    }

    const copyLinkToClipboard = async (e) => {
        e.stopPropagation()
        setCopiedLink(true)

        navigator.clipboard.writeText(await generateLink())
            .then(() => {
                console.log('Link copied to clipboard:', link);
                // You can show a success message or perform other actions here
            })
            .catch((error) => {
                console.error('Failed to copy link to clipboard:', error);
                // You can show an error message or handle the error here
            });
        wait(3000).then(() => {
            setCopiedLink(false)
        })
    }
    const nonProfit = invite.non_profit || {};
    return (
        <div className="border bg-white border-gray-200 rounded-lg p-3 mb-1">
            <div className="flex items-center gap-4 justify-between">
                <div className="flex items-center flex-1">
                    <NPOPicture
                        initial={nonProfit.name[0]}
                        np={nonProfit}
                        size={40}
                        className=" bg-white border border-slate-200"
                    />
                    <span className="font-bold flex flex-1 text-xl ml-1 break-all">
                        {nonProfit.name}
                    </span>
                </div>
                <div className="flex items-center">
                    <span className="text-xl text-blue-500">
                        {invite.accepted_users.length}
                    </span>
                    <SvgUserPlus className="w-5 h-5 text-blue-500 ml-1" />
                    <span className="text-xl text-gray-500 ml-2">
                        ${invite.accepted_users.length}
                    </span>
                </div>
            </div>
            <div className="flex items-center gap-3 justify-end border-t border-gray-200 pt-2 mt-2">
                {!invite.deactivated &&
                    <div className='mr-auto w-min mr-auto'>
                        <div onClick={copyLinkToClipboard}
                            className={`flex flex-row items-center gap-3 p-2 rounded-xl  cursor-pointer ${copiedLink ? 'text-green-500 border border-green-200' : 'text-blue-500 hover:bg-slate-100 border border-slate-200 border-dashed'}`}>
                            {copiedLink ? <SvgCheck className={'w-5 h-5 scale-[1.2]'}/> :
                                <SvgLink2 className={'w-5 h-5 scale-[1.2]'}/>}
                            <p className='whitespace-nowrap'>
                                {copiedLink ? 'Link copied!' : link.slice(0,36) + '...'}
                            </p>
                        </div>
                    </div>
                }
                <Button variant={'secondary'} onClick={() => deactivate(invite.id, !invite.deactivated)}>
                    {!invite.deactivated ? 'Deactivate' : 'Activate'} Link
                </Button>
            </div>
        </div>
    );
}

export default InviteListItem;
