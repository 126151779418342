import React, {Component} from "react";
import {Button} from "../../components/atoms/Button";
import ProfilePicture from "../../components/atoms/ProfilePicture";
import {connect} from "react-redux";
import { searchUsers } from "../../utils/typesense";
import SvgClose from "../../components/icons/SvgClose";
import SvgSearch from "../../components/icons/SvgSearch";
import SvgPin from "../../components/icons/SvgPin";
import { CARD_STYLE } from "../../utils/dummy";
import { createChat } from "../../api/messaging";



function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
    }
}


class CreatePostSheet extends Component {
    state = {
        search: '',
        usersList: [],
    }

    searchChanged = (e) => {
        this.setState({search: e.target.value})
        searchUsers(e.target.value).then(userResults => {
            this.setState({usersList: userResults})
        });
    }

    closeSheet = () => {
        this.props.close();
    }

    create = async (username) => {
        await createChat(this.props.authUser, username);
        this.setState({search: '', usersList: []})
        this.closeSheet();
    }


    render() {
        const user = this.props.user || {};
        const {search, usersList} = this.state;
        return (
            <div
                className={`absolute ${this.props.isOpen ? 'h-[100vh]' : 'h-0 overflow-hidden'} transition-all left-0 right-0 bottom-0 bg-white`}>
                <div className={`flex flex-col flex-1 h-full p-5`}>
                    <div className='flex flex-row items-center justify-between'>
                        <p className='text-2xl font-semibold text-slate-800'>
                            Create a Direct Message
                        </p>
                        <Button onClick={this.closeSheet} variant={'secondary'} size={'icon'} radius={'full'}>
                            <SvgClose/>
                        </Button>
                    </div>
                    <div className='mt-5 border border-slate-300 p-5 rounded-xl flex flex-col gap-4'>
                        <div className='row-ac relative mb-3'>
                            <input
                                onChange={this.searchChanged}
                                value={search}
                                type="text"
                                className={'w-100p search-input rounded-lg'}
                                placeholder="Search users..."
                            />
                            <SvgSearch className={'absolute black-50'}
                                    style={{top: 10, left: 24, width: 24, height: 24}}/>
                        </div>
                        {search.length > 0 && usersList.length === 0 &&
                            <p className='text-slate-500 text-sm text-center mx-auto w-full my-5'>
                                No results match this search...
                            </p>
                        }
                    <div className='max-h-[300px] overflow-y-scroll'>
                        {usersList.map((user) =>
                            <div
                                className={`${CARD_STYLE} p-3 flex flex-row items-center`}
                                key={user.username}
                                >
                                <ProfilePicture
                                    initial={user.first_name[0] + user.last_name[0]}
                                    userID={user.username}
                                    size={60}
                                />
                                <div className='flex flex-col flex-1 ml-12' style={{textAlign: "left"}}>
                                    <p className='text-lg text-slate-800 font-semibold'>
                                        {user.first_name} {user.last_name}
                                    </p>
                                    {!!user.tagline ?
                                        <p className='text-slate-500 font-semibold -mt-1.5'>
                                            {user.tagline}
                                        </p>
                                    : null}
                                    {!!user.city &&
                                        <div className='row-ac'>
                                            <SvgPin className={'text-blue-500 w-3 h-3'}/>
                                            <p className='text-md text-blue-500 ml-4'>
                                                {user.city}, {user.state}
                                            </p>
                                        </div>
                                    }
                                </div>
                                    <Button
                                        onClick={() => {
                                            this.create(user.username)
                                        }}
                                        variant={'default'}
                                        radius={'full'}
                                        size={'sm'}
                                    >
                                        Create
                                    </Button>
                            </div>
                        )
                        }
                    </div>
                       
                      
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(CreatePostSheet)
