import React, {Component} from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import MissionSection from "./MissionSection";
import TeamSection from "./TeamSection";
import VideoSeciton from "./VideoSection";
import TimelineSection from "./TimelineSection";
import AnimatedInput from "../components/AnimatedInput";
import RadioButtonSet from "../components/RadioButtonSet";
import ModalComponent from "../components/ModalComponent";
import {wait} from "../utils/timestamp";
import Spinner from "../components/Spinner";

class ContactUs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            selectedOption: '',
            feedback: '',
            hasSubmit: false,
            processing: false,

        }
    }

    onChangeFirstName = (firstName) => {
        this.setState({firstName})
    }

    onChangeLastName = (lastName) => {
        this.setState({lastName})
    }

    onChangeEmail = (email) => {
        this.setState({email})
    }

    onChangeFeedback = (feedback) => {
        this.setState({feedback})
    }

    handleOptionSelect = (selectedOption) => {
        this.setState({selectedOption})
    }

    onSubmit = () => {
        this.setState({processing: true})
        wait(1000).then(() => {
            this.setState({processing: false, hasSubmit: true})
        })
        wait(1300).then(() => {
            this.closeModal()
        })
    }


    closeModal = () => {
        this.props.closeContactUs()
        wait(500).then(() =>
            this.setState({
                processing: false,
                hasSubmit: false,
                firstName: '',
                lastName: '',
                email: '',
                selectedOption: '',
                feedback: '',
            })
        )
    }

    render() {
        const options = ['General Feedback', 'Technical Question', 'Suggested Improvement', 'Other']
        const buttonDisabled = this.state.feedback.length === 0 || this.state.email.length === 0
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                size={this.props.mobile ? 'full-mobile' : 'lg'}
                toggle={this.props.closeContactUs}
                headerToggle
            >
                <div className={`col-jb ${this.props.mobile ? '' : 'p-5'} pt-0`}>
                    <div>
                        <h1 className={`${this.props.mobile ? 'title1' : 'heading2'} black-80 mb-12 mt-0`}>
                            Reach out to the team
                        </h1>
                        <p className='body2 black-50'>
                            We’d love to hear your thoughts and feedback on how we can improve your experience on
                            Starfish.
                            Feel
                            free to use this form to make suggestions, request more information, or to ask a technical
                            question.
                            We're here to help!
                        </p>
                        <div className='mt-40 row-ac body1' style={{marginTop: this.props.mobile && 24}}>
                            <div className='flex-1 mr-16'>
                                <AnimatedInput
                                    name="firstName"
                                    onChange={this.onChangeFirstName}
                                    value={this.state.firstName}
                                    type="text"
                                    placeholder="First Name"
                                />
                            </div>
                            <div className='flex-1 ml-16'>
                                <AnimatedInput
                                    name="lastName"
                                    onChange={this.onChangeLastName}
                                    value={this.state.lastName}
                                    type="text"
                                    placeholder="Last Name"
                                />
                            </div>
                        </div>
                        <div className='mt-40 row-ac body1' style={{marginTop: this.props.mobile && 24}}>
                            <div className='flex-1'>
                                <AnimatedInput
                                    name="email"
                                    onChange={this.onChangeEmail}
                                    value={this.state.email}
                                    type="email"
                                    placeholder="Your Email"
                                />
                            </div>
                        </div>
                        <div className={this.props.mobile ? 'mt-24 body3' : 'mt-40 body2'}>
                            <RadioButtonSet
                                options={options}
                                selectedOption={this.state.selectedOption}
                                onSelect={this.handleOptionSelect}
                            />
                        </div>
                        <div className={`mt-36 row-ac ${this.props.mobile ? 'body2' : 'body1'}`}>
                            <div className='flex-1'>
                                <AnimatedInput
                                    name="email"
                                    onChange={this.onChangeFeedback}
                                    value={this.state.feedback}
                                    multiline
                                    height={this.props.mobile && 100}
                                    placeholder="Your Feedback"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row-ac-jc mt-40'>
                        <button disabled={buttonDisabled} onClick={this.onSubmit} className='primary-button body1-bold'
                                style={{width: 200, opacity: buttonDisabled && .3}}>
                            {this.state.processing ? <Spinner/> : (this.state.hasSubmit ? 'Sent!' : 'Submit')}
                        </button>
                    </div>
                </div>
            </ModalComponent>
        )
    }

}


export default ContactUs;
