import React, {Component} from "react";
import {connect} from "react-redux";
import SvgConfetti from "../components/icons/SvgConfetti";
import Confetti from 'react-confetti'
import {Link} from "react-router-dom";



function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        mobile: state.userReducer.mobile,
    }
}

class DonationSuccessPage extends Component {
    state = {
        nonProfitName: "",
        link: "",
    }

    componentDidMount = () => {
        const queryParameters = new URLSearchParams(window.location.search);
        const ein = queryParameters.get("ein", null);
        const challengeId = queryParameters.get("challenge_id", null);
        const nonProfitName = queryParameters.get("non_profit_name", null);
        
        let link = '/non-profit/' + ein;
        if (!!challengeId) {
            link = '/challenge/' + challengeId;
        }
        this.setState({nonProfitName, link});
    }

    render() {
        return (
            <div className='col-ac-jc page-wrapper' style={{minHeight: '100vh'}}>
                <Confetti
                    number={this.props.mobile ? 120 : 200}
                    colors={['#edf7f5', '#054a91', '#81a4cd', '#f17300', '#e3d265']}
                    initialVelocityY={5}
                    recycle={false}
                />
                <SvgConfetti width={160} height={160}/>
                <p className='heading2 mv-16'>
                    Thanks for pitching in!
                </p>
                <p className='title1 black-70 w-50p text-center mb-16'>
                    Your donation to {this.state.nonProfitName} is confirmed.
                </p>
                <p className='body1 black-70 w-50p text-center'>
                    You will be notified with a confirmation and any status updates via
                    email. Thank you for making Starfish the place to illuminate the good! Download our app to track
                    your donation history and find new causes!
                </p>
                <Link style={{textDecoration: "none"}} to={this.state.link}>
                    <button className='primary-button body1-bold mt-32'>
                        Head Back
                    </button>
                </Link>
            </div>
        )
    }

}


export default connect(mapStateToProps)(DonationSuccessPage);
