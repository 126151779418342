import React, {Component} from "react";
import ProfilePicture from "../atoms/ProfilePicture";
import SvgShare2 from "../icons/SvgShare2";
import GroupPicture from "../atoms/GroupPicture";
import moment from "moment";
import {formatMessageTime} from "../../utils/timestamp";
import GroupPrivacyIndicator from "../atoms/GroupPrivacyIndicator";

class GroupListItem extends Component {
    render() {
        const group = this.props.group || {};
        const recentMessage = group?.recentMessage || {};
        const creatorName = group?.memberNames[group?.creator];
        return (
            <div
                className={`gap-4 p-3 py-2 border-b border-slate-200 cursor-pointer ${(this.props.currentGroup.id === group.id) && 'bg-blue-50'}`}
                onClick={() => this.props.selectGroup(group)} key={group.id}>
                <div className='flex flex-row items-center gap-3'>
                    <GroupPicture
                        size={'2xl'}
                        imageId={group.image_id}
                    />
                    <div className='flex-1 flex flex-col'>
                        <p style={{lineHeight: 1.1}}
                           className={`text-lg font-semibold line-clamp-2 text-slate-800 ${(this.props.currentGroup.id === group.id) && 'text-blue-500'}`}>
                            {group.name}
                        </p>
                        <div className='flex flex-row items-center gap-1 mt-1'>
                            <p className='text-xs font-medium text-slate-700 line-clamp-1'>
                                Created by
                            </p>
                            <ProfilePicture userID={group.creator} size={14}/>
                            <p className='text-xs font-medium -ml-2 text-slate-700 line-clamp-1'>
                                {creatorName}
                            </p>
                        </div>
                        <div className='flex flex-row  items-center gap-2 text-sm mt-2 font-semibold text-slate-500'>
                            <GroupPrivacyIndicator group={group}/>
                            <p>
                                {/*Active {formatMessageTime(recentMessage.timestamp)}*/}
                                {/*&nbsp; */}
                                • &nbsp;
                                {group?.members.length} member{group.members.length !== 1 ? "s" : ""}
                            </p>
                        </div>
                    </div>
                </div>
                {/*<p className='text-md font-medium text-slate-700 mt-3 line-clamp-3'>*/}
                {/*    <span*/}
                {/*        className='text-slate-700 font-semibold'>{group?.memberNames[recentMessage?.username]}:</span> {recentMessage?.preview}*/}
                {/*</p>*/}
            </div>
        )
    }
}

export default GroupListItem
