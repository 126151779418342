import React, {PureComponent} from "react";
import SvgPin from "./icons/SvgPin";
import SvgCalendar from "./icons/SvgCalendar";


class ProfileCard extends PureComponent {
    render() {
        return (
            <div className='bg-white-pure br-12 box profile-card' style={{maxWidth: 500}}>
                <img src={this.props.profile.cover}
                     style={{
                         width: '100%',
                         height: this.props.mobile ? 100 : 140,
                         objectFit: "cover",
                         borderRadius: '12px 12px 0 0',
                         marginBottom: this.props.mobile ? -80 : -100
                     }}/>
                <div className='p-4'>
                    <img src={this.props.profile.img}
                         style={{
                             width: this.props.mobile ? 80 : 100,
                             height: this.props.mobile ? 80 : 100,
                             objectFit: "cover",
                             borderRadius: 120,
                             border: '4px solid #ffffff'
                         }}/>
                    <div className='title1 mb-4'>
                        {this.props.profile.name}
                    </div>
                    <div className='body2'>
                        {this.props.profile.bio}
                    </div>
                    <div className='row-ac primary body2-bold mt-16'>
                        <div className='row-ac mr-20 body2-bold'>
                            <SvgPin width={18} height={18} className={'mr-4'}/>
                            {this.props.profile.location}
                        </div>
                        <div className='row-ac'>
                            <SvgCalendar width={20} height={20} className={'mr-4'}/>
                            Joined {this.props.profile.joined}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProfileCard
