import React, {Component} from "react";

class LoadingSection extends Component {
    render() {
        return (
            <div className='flex flex-col items-center flex-1 justify-center'>
                <img src={require('../img/brand/logo.png')} className='logo-spin'/>
            </div>
        )
    }
}

export default LoadingSection
