import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 116.02 125.98"
    {...props}
  >
    <defs>
      <style>
        {
          ".cls-1{fill:#956ce6;stroke:#fff;stroke-miterlimit:10}.cls-2{fill:#efa143}"
        }
      </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="OBJECTS">
        <path
          className="cls-1"
          d="M99.91 2c2.22-.86 4.53-1.74 6.9-1.5s4.77 2 4.91 4.34c.18 2.93-2.86 4.92-5.49 6.24-30.7 15.43-61.66 31-94.86 39.81-7.32 2-14.9-2.69-8.4-10 6.12-6.89 22.12-9.75 30.55-13ZM109.3 23.51c1.92-.65 4.42-1.14 5.63.48a4 4 0 0 1 .57 2.65c-.29 4.15-3.13 7.76-6.59 10.06s-7.52 3.48-11.49 4.68a616.07 616.07 0 0 0-82.87 31.67c-11.63 5.43-20.93-3.34-7.24-9.9 11-5.28 22.53-9.7 33.83-14.27q33.72-13.66 68.16-25.37ZM93.08 55.85c6.38-2.32 12.94-4.67 19.73-4.54 2.61 2.72 1.86 7.42-.59 10.28s-6.11 4.33-9.61 5.69L43.24 90.4C32 94.79 20.49 99.23 8.43 100.14c-3.32.25-7.78-.75-7.93-4.08-.38-8.7 36.08-19 42.92-21.7 16.44-6.46 33.05-12.49 49.66-18.51ZM105.6 81.08c2.33-.81 5-1.58 7.15-.35 2.74 1.59 2.86 5.78 1.06 8.39s-4.83 4-7.72 5.3c-30.79 13.7-62.22 27.57-95.74 31-3.63.37-10.49-.15-9.8-5.59.44-3.53 5-4.2 7.78-5.2 10.89-3.94 21.92-7.55 32.86-11.32Z"
        />
        <path
          className="cls-2"
          d="M48.55 100.1a3.39 3.39 0 1 1 4.11-2.46 3.38 3.38 0 0 1-4.11 2.46Z"
        />
        <circle className="cls-2" cx={49.93} cy={108.53} r={3.39} />
        <path
          className="cls-2"
          d="M59.16 96.23a3.39 3.39 0 1 1 3.39-3.39 3.39 3.39 0 0 1-3.39 3.39Z"
        />
        <circle className="cls-2" cx={60.11} cy={104.63} r={3.39} />
        <path
          className="cls-2"
          d="M69.33 94.19a3.39 3.39 0 1 1 3.38-3.38 3.38 3.38 0 0 1-3.38 3.38Z"
        />
        <circle className="cls-2" cx={61.66} cy={115.89} r={3.39} />
        <path
          className="cls-2"
          d="M73.59 103.3A3.39 3.39 0 1 1 77 99.92a3.38 3.38 0 0 1-3.41 3.38Z"
        />
        <circle className="cls-2" cx={73.16} cy={109.82} r={3.39} />
        <path
          className="cls-2"
          d="M71.67 123.84a3.39 3.39 0 1 1 3.39-3.39 3.38 3.38 0 0 1-3.39 3.39ZM83.34 98.57a3.39 3.39 0 1 1 3.39-3.39 3.38 3.38 0 0 1-3.39 3.39Z"
        />
        <circle className="cls-2" cx={81.19} cy={120.24} r={3.39} />
        <path
          className="cls-2"
          d="M85.35 112.1a3.39 3.39 0 1 1 3.39-3.39 3.39 3.39 0 0 1-3.39 3.39ZM92.67 104.05a3.39 3.39 0 1 1 3.39-3.38 3.38 3.38 0 0 1-3.39 3.38Z"
        />
        <circle className="cls-2" cx={91.34} cy={119.76} r={3.39} />
        <path
          className="cls-2"
          d="M97.3 114.28a3.39 3.39 0 1 1 3.39-3.39 3.39 3.39 0 0 1-3.39 3.39Z"
        />
      </g>
    </g>
  </svg>
)

export default SvgComponent
