import React, {useState, useCallback, useEffect, useRef} from 'react';
import {EditorState, Modifier, SelectionState, convertToRaw} from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, {MentionPluginTheme} from '@draft-js-plugins/mention';
import createLinkDetectionPlugin from 'draft-js-link-detection-plugin';
import {searchNonProfits, searchUsers, searchHashtags} from "../../utils/typesense";
import '@draft-js-plugins/mention/lib/plugin.css';
import '@draft-js-plugins/hashtag/lib/plugin.css';
import '@draft-js-plugins/linkify/lib/plugin.css';
import '../../styles/EditorStyles.css';
import ProfilePicture from "../atoms/ProfilePicture";
import { is } from 'immutable';


const hashtagPlugin = createMentionPlugin({
    mentionTrigger: "#",
    supportWhitespace: true,
    entityMutability: 'IMMUTABLE',
});
const mentionPluginUser = createMentionPlugin({
    mentionTrigger: "@",
    // theme: mentionStyles,
    supportWhitespace: true,
    entityMutability: 'IMMUTABLE',
});
const mentionPluginNonProfit = createMentionPlugin({
    mentionTrigger: "/",
    supportWhitespace: true,
    entityMutability: 'IMMUTABLE',
});
const linkDetectionPlugin = createLinkDetectionPlugin({
  component(props) {
    return <a {...props} onClick={() => window.open(props.href, '_blank', 'noreferrer')} />;
  },
});

const MentionSuggestionsUser = mentionPluginUser.MentionSuggestions;
const MentionSuggestionsNonProfits = mentionPluginNonProfit.MentionSuggestions;
const MentionSuggestionsHashtag = hashtagPlugin.MentionSuggestions;
const plugins = [mentionPluginUser, mentionPluginNonProfit, hashtagPlugin, linkDetectionPlugin];

const PostEditor = ({placeholder, onChange, originalContent}) => {
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [suggestions, setSuggestions] = useState([]);
    const [npSuggestions, setNpSuggestions] = useState([]);
    const [open, setOpen] = useState(false);
    const [npOpen, setNpOpen] = useState(false);
    const [hashtagOpen, setHashtagOpen] = useState(false);
    const [hashtagSuggestions, setHashtagSuggestions] = useState([]);

    useEffect(() => {
        if (!!originalContent) {
            const es = EditorState.createWithContent(originalContent);
            setEditorState(EditorState.moveFocusToEnd(es))
        } else {
            setEditorState(() => EditorState.createEmpty())
        }
    }, [originalContent]);
      

    const onSearchChange = useCallback(({value}) => {
        if (value) {
            searchUsers(value).then((userResults) => {
                const userSuggestions = userResults.map((hit) => ({
                    id: hit.username,
                    name: hit.first_name + " " + hit.last_name,
                    userID: hit.username,
                    type: "userTag"
                }));
                setSuggestions(userSuggestions);
            });
        } else {
            setSuggestions([]);
        }
    }, []);

    const onSearchChangeNp = useCallback(({value}) => {
        if (value) {
            searchNonProfits(value).then((npResults) => {
                const nonProfitSuggestions = npResults.map((hit) => ({
                    id: hit.ein,
                    name: hit.name,
                    username: hit.ein,
                    type: "nonProfitTag",
                }));
                setNpSuggestions(nonProfitSuggestions);
            });
        } else {
            setNpSuggestions([]);
        }
    }, []);

    const onSearchChangeHashtag = useCallback(({value}) => {
        if (value) {
            searchHashtags(value).then((hashtagResults) => {
                const hashtagSuggestions = hashtagResults.map((hit) => ({
                    id: hit.hashtag,
                    hashtag: "#" + hit.hashtag,
                    name: "#" + hit.hashtag,
                    type: "hashtag",
                }));
                if (!hashtagSuggestions.map(hashtag => hashtag.name).includes("#" + value)) {
                    hashtagSuggestions.push({
                        id: value,
                        hashtag: "#" + value,
                        name: "#" + value,
                        type: "hashtag",
                    })
                }
                setHashtagSuggestions(hashtagSuggestions);
            });
        } else {
            setHashtagSuggestions([]);
        }
    }, []);

    const onOpenChange = useCallback((_open) => {
        setOpen(_open);
    }, []);

    const onNpOpenChange = useCallback((_open) => {
        setNpOpen(_open);
    }, []);

    const onHashtagOpenChange = useCallback((_open) => {
        setHashtagOpen(_open);
    }, []);

    const onAddMention = useCallback((mention) => {
        const currentContent = editorState.getCurrentContent();
        const currentSelection = editorState.getSelection();

        // Find the start of the mention in the text
        const blockText = currentContent.getBlockForKey(currentSelection.getStartKey()).getText();
        const mentionTrigger = mention.username ? '/' : '@'; // Adjust the trigger based on the type of mention
        const start = blockText.lastIndexOf(mentionTrigger, currentSelection.getStartOffset());

        // Create a selection that spans the mention trigger text
        const mentionTextSelection = new SelectionState({
            anchorKey: currentSelection.getStartKey(),
            anchorOffset: start,
            focusKey: currentSelection.getStartKey(),
            focusOffset: currentSelection.getEndOffset(),
        });
        // Create a new entity with the 'mention' data
        let entityType = mention.username ? 'USER_MENTION' : 'NONPROFIT_MENTION';
        const contentStateWithEntity = currentContent.createEntity(entityType, 'IMMUTABLE', {mention});
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();


        // Replace the mention trigger text with the actual mention text
        const mentionText = `${mention.name} `;
        const newContentState = Modifier.replaceText(
            contentStateWithEntity,
            mentionTextSelection,
            mentionText,
            null,
            entityKey
        );

        // Push the changes into a new EditorState
        let newEditorState = EditorState.push(editorState, newContentState, 'insert-mention');
        newEditorState = EditorState.forceSelection(
            newEditorState,
            newContentState.getSelectionAfter()
        );

        setEditorState(newEditorState);

        // Close the suggestions list
        setOpen(false);
        setNpOpen(false);
    }, [editorState, setOpen, setNpOpen]);

    const UserSuggestionEntry = (props) => {
        const {mention} = props;

        const handleMouseDown = (event) => {
            event.preventDefault(); // Prevent the editor from losing focus
            onAddMention(mention); // Add the mention
        };

        return (
            <div
                onMouseDown={handleMouseDown}
                className="flex flex-row items-center z-[100] gap-1 hover:bg-slate-100 rounded-xl p-2 mx-2"
            >
                <ProfilePicture userID={mention.id}/>
                <span>{mention.name}</span>
            </div>
        );
    };

    const NonProfitSuggestionEntry = (props) => {
        const {mention} = props;

        const handleMouseDown = (event) => {
            event.preventDefault(); // Prevent the editor from losing focus
            onAddMention(mention); // Add the mention
        };

        return (
            <div
                onMouseDown={handleMouseDown}
                className="flex flex-row items-center z-[100] gap-1 hover:bg-slate-100 rounded-xl p-2 mx-2"
            >
                {/*<NPOPicture*/}
                {/*    logoUrl={mention.logo_url}*/}
                {/*    initial={mention.name}*/}
                {/*/>*/}
                <span>{mention.name}</span>
            </div>
        );
    };

    const onEditorChange = (editorState) => {
        setEditorState(editorState);
        onChange(editorState.getCurrentContent());
    }

    const styleMap = {
        'USER_MENTION': {
            color: '#3b82f6',
        },
        'NONPROFIT_MENTION': {
            color: '#f97316',
        },
    };

    return (
        <div className='p-5 bg-slate-100 rounded-xl'>
            <Editor
                editorState={editorState}
                onChange={onEditorChange}
                plugins={plugins}
                placeholder={placeholder}
                customStyleMap={styleMap}
            />
            <MentionSuggestionsUser
                onSearchChange={onSearchChange}
                suggestions={suggestions}
                onAddMention={onAddMention}
                onOpenChange={onOpenChange}
                open={open}
                // entryComponent={UserSuggestionEntry}
            />
            <MentionSuggestionsNonProfits
                onSearchChange={onSearchChangeNp}
                suggestions={npSuggestions}
                onAddMention={onAddMention}
                onOpenChange={onNpOpenChange}
                open={npOpen}
                // entryComponent={NonProfitSuggestionEntry}
            />
            <MentionSuggestionsHashtag suggestions={hashtagSuggestions}
                                       open={hashtagOpen}
                                       onOpenChange={onHashtagOpenChange}
                                       onSearchChange={onSearchChangeHashtag}
            />

        </div>
    );
};

export default PostEditor;
