import React, {Component} from "react";
import AnimatedInput from "../components/AnimatedInput";
import ModalComponent from "../components/ModalComponent";
import Spinner from "../components/Spinner";
import ChallengeDonationBar from "./ChallengeDonationBar";
import SvgCheck from "./icons/SvgCheck";
import SvgAlert from "./icons/SvgAlert";

class DonateModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            amount: '',
        }
    }

    onChangeAmount = (event) => {
        const numericValue = event.target.value.replace(/[^0-9.]/g, ""); // Allow only digits and decimal point
        this.props.updateAmount(numericValue);
        this.setState({amount: numericValue});
    }

    render() {
        const buttonEnabled = !!this.state.amount;
        const nonProfit = this.props.nonProfit || {};
        const challenge = this.props.challenge;
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                size={this.props.mobile ? 'full' : 'lg'}
                toggle={this.props.closeModal}
                headerToggle
                FooterComponent={
                    <div className='col-ac'>
                        <button disabled={!buttonEnabled} onClick={() => {this.setState({processing: true}); this.props.openAuthModal()}}
                                className='primary-button body1-bold'
                                style={{width: 300, opacity: !buttonEnabled && .3}}>
                            {this.state.processing ?
                                <Spinner/> : 'Confirm Donation'}
                        </button>
                        <p className='body4 black-50 mv-12'>
                            By continuing, you agree with Starfish <a
                            href={'https://app.termly.io/document/terms-of-use-for-website/eaf25433-f52f-4673-ae16-e16370111fea'}
                            target={'_blank'} rel={'noreferrer'} className='body4-bold primary'>terms</a> and <a
                            href={'https://app.termly.io/document/privacy-policy/3cc64057-0435-44b3-b613-35eea60b52a7'}
                            target={'_blank'} rel={'noreferrer'} className='body4-bold primary'>privacy policy</a>.
                        </p>
                    </div>
                }
            >
                <div className={`col-ac-jc ${this.props.mobile ? 'p-5' : 'p-5'} pt-0`} style={{flex: 1}}>
                    <div className='col-ac text-center'>
                        <h1 className={`${this.props.mobile ? 'title1' : 'title1'} black-80 mb-12 mt-0`}>
                            Enter your donation for {nonProfit.name}
                        </h1>
                        {!!challenge &&
                            <p className='body2 black-50'>
                                You are about to participate in {challenge.challenge_name}!
                            </p>
                        }
                        <div className='mt-40 row-ac body1' style={{marginTop: this.props.mobile && 24}}>
                            <div className='row-plain'>
                                <p className='black-50 body1-bold mt-8'>
                                    $
                                </p>
                                <input
                                    name="amount"
                                    onChange={this.onChangeAmount}
                                    value={this.state.amount}
                                    // type="number"
                                    className='donate-input'
                                    placeholder="0"
                                    size={this.state.amount.length + 1}
                                    style={{
                                        width: 'auto',
                                        maxWidth: '100%',
                                        padding: 0,  // Remove any padding
                                        border: 'none',  // Remove border,
                                    }}
                                />
                                <p className='black-50 body1-bold mt-8'>
                                    USD
                                </p>
                            </div>
                        </div>
                        <div className='col-ac mt-16' style={{height: 32}}>
                            {this.state.amount === '0' &&
                                <div className='body3 row-ac br-32 p-3'
                                     style={{backgroundColor: '#cf364a10', color: '#cf364a'}}>
                                    <SvgAlert style={{width: 16, height: 16}} className={'mr-8'}/>
                                    Please enter a donation amount
                                </div>
                            }
                        </div>
                        <div className='br-8 p-4 mt-8 col-plain w-100p pt-28' style={{border: '1px solid #00000020'}}>
                            <AnimatedInput
                                name="memo"
                                onChange={this.props.onChangeMemo}
                                value={this.props.memo}
                                type="text"
                                className={'w-100p flex-1'}
                                placeholder="Add a memo (optional)"
                                multiline
                                height={100}
                                style={{width: '100%', display: 'flex'}}
                            />
                        </div>
                    </div>
                </div>
            </ModalComponent>
        )
    }

}


export default DonateModal;
