import {combineReducers, createStore} from "redux";
import UserReducer from "./reducers/UserReducer";
import PostReducer from "./reducers/PostReducer";


export default createStore(
    combineReducers({
        userReducer: UserReducer,
        postReducer: PostReducer
    })
)
