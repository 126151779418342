import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {TwitterTweetEmbed} from "react-twitter-embed";

const TWEET_WIDTH = 350;
const TWEET_MAX_HEIGHT = 480;

class TwitterXma extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            tweetOverflowed: false,
            tweetModalOpen: false,
        }
    }

    render() {
        return (
            <div style={{maxHeight: TWEET_MAX_HEIGHT, width: TWEET_WIDTH, overflow: 'hidden'}}
                 className="relative br-12"
            >
                <TwitterTweetEmbed tweetId={this.props.tweetID}
                                   options={{theme: 'dark', width: TWEET_WIDTH, conversation: 'none'}}
                                   onLoad={element => this.setState({tweetOverflowed: element.offsetHeight > TWEET_MAX_HEIGHT})}
                />
                <div className="absolute pointer"
                     style={{top: 0, bottom: 0, left: 0, right: 0}}
                     onClick={e => {
                         e.stopPropagation();
                         this.setState({tweetModalOpen: true})
                     }}
                >
                    {this.state.tweetOverflowed &&
                        <div className="row-ae-je absolute pr-12 pb-8 tweet-XMA-gradient">
                            <p className="bright-blue body1-emphasized pointer">See More</p>
                        </div>
                    }
                </div>
                {/*<TweetModal isOpen={this.state.tweetModalOpen}*/}
                {/*            toggle={() => this.setState({tweetModalOpen: false})}*/}
                {/*            tweetID={this.props.tweetID}*/}
                {/*/>*/}
            </div>
        );
    }
}

TwitterXma.propTypes = {
    tweetID: PropTypes.string.isRequired
};

export default TwitterXma;
