import React, {useState} from 'react';
import TextInput from "../../components/inputs/TextInput";
import OnboardingStepHeader from "../Onboarding/OnboardingStepHeader";
import OnboardingButtons from "../Onboarding/OnboardingButtons";
import SvgMegaphone from "../../components/icons/SvgMegaphone";
import SvgPiggy from "../../components/icons/SvgPiggy";
import SvgTarget from "../../components/icons/SvgTarget";
import SvgArrowBack from "../../components/icons/ArrowBack";
import {Button} from "../../components/atoms/Button";
import NPOPicture from "../../components/atoms/NPOPicture";
import SvgVerified from "../../components/icons/SvgVerified";

const GOAL_TYPE_TEXTS = {
    awareness: {
        title: 'Set your awareness goal',
        description: 'Encourage new friends to follow and support the cause. Set a target for new followers!'
    },
    donation: {
        title: 'Set your donation goal',
        description: 'Help raise funds for the cause. Set a monetary goal and drive direct impact!'
    },
    pledge: {
        title: 'Set your pledge goal',
        description: 'Encourage others to take action. Set a target for the number of pledges and describe the actions!'
    },
    default: {
        title: 'How do you want to help?',
        description: 'Pick the type of challenge you want to run!'
    }
};

function CreateChallengeGoals({
                                  goNextStep,
                                  awarenessGoal,
                                  setAwarenessGoal,
                                  donationsGoal,
                                  setDonationsGoal,
                                  pledgeGoal,
                                  setPledgeGoal,
                                  pledgeText,
                                  setPledgeText,
                                  goBackStep,
                                  selectedGoalType,
                                  handleSwitchGoalType,
                                  npName,
                                  selectedNp,
                              }) {
    const handleAwarenessChange = (event) => {
        const value = event.target.value;
        if (!!Number(value) || !value) setAwarenessGoal(Number(value));
    };

    const handleDonationsChange = (event) => {
        const value = event.target.value;
        if (!!Number(value) || !value) setDonationsGoal(Number(event.target.value));
    };

    const handlePledgeChange = (event) => {
        const value = event.target.value;
        if (!!Number(value) || !value) setPledgeGoal(Number(event.target.value));
    };

    const handlePledgeTextChange = (event) => {
        setPledgeText(event.target.value);
    };

    const handleSubmit = () => {
        goNextStep();
    };

    const renderGoalInput = () => {
        if (selectedGoalType === "awareness") {
            return (
                <div>
                    <p className={`${awarenessGoal ? 'text-green-500 font-semibold' : 'text-blue-500'} text-sm w-full text-center mb-2`}>
                        {!!awarenessGoal ?
                            `Your goal is to drive ${awarenessGoal} new followers to ${npName}.`
                            :
                            `Enter a new followers goal for your challenge supporting ${npName} below.`
                        }
                    </p>
                    <div className='row-ac body1 items-center'>
                        <div className='row-plain w-full justify-center'>
                            <input
                                name="amount"
                                onChange={handleAwarenessChange}
                                value={awarenessGoal}
                                className='large-input'
                                placeholder="10"
                                size={awarenessGoal + 1}
                            />
                        </div>
                    </div>
                    <p className='text-slate-700 body1-bold mb-8 w-full text-center mt-2'>
                        new followers
                    </p>
                </div>
            );
        }

        if (selectedGoalType === "donation") {
            return (
                <div>
                    <p className={`${donationsGoal ? 'text-green-500 font-semibold' : 'text-blue-500'} text-sm w-full text-center mb-2`}>
                        {!!donationsGoal ?
                            `Your goal is to drive $${donationsGoal} in donations to ${npName}.`
                            :
                            `Enter a contribution goal for your challenge supporting ${npName} below.`
                        }
                    </p>
                    <div className='row-ac body1 items-center'>
                        <div className='row-plain w-full justify-center gap-2'>
                            <p className='black-50 body1-bold mt-32'>
                                $
                            </p>
                            <input
                                name="amount"
                                onChange={handleDonationsChange}
                                value={donationsGoal}
                                className='large-input'
                                placeholder="50"
                                size={donationsGoal + 1}
                            />
                            <p className='opacity-0 body1-bold mt-32'>
                                $
                            </p>
                        </div>
                    </div>
                    <p className='text-slate-700 body1-bold mb-8 w-full text-center mt-2'>
                        in total donations
                    </p>
                </div>
            );
        }

        if (selectedGoalType === "pledge") {
            return (
                <div>
                    <p className={`${(pledgeGoal && pledgeText.length > 0) ? 'text-green-500 font-semibold' : 'text-blue-500'} text-sm w-full text-center mb-2`}>
                        {!!(pledgeGoal && pledgeText.length > 0)  ?
                            `Your goal is to drive ${pledgeGoal} people to commit to your entered pledge.`
                            :
                            `Enter total pledges you want to reach for your challenge supporting ${npName} below.`
                        }
                    </p>
                    <div className='row-ac body1 items-center'>
                        <div className='row-plain w-full justify-center'>
                            <input
                                name="amount"
                                onChange={handlePledgeChange}
                                value={pledgeGoal}
                                className='large-input'
                                placeholder="10"
                                size={pledgeGoal + 1}
                            />
                        </div>
                    </div>
                    <p className='text-slate-700 body1-bold mb-8 w-full text-center mt-2'>
                        people pledge to...
                    </p>
                    <TextInput
                        value={pledgeText}
                        placeholder={'e.g.) Volunteer at an upcoming event, tell 3 friends about a cause...'}
                        onChange={handlePledgeTextChange}
                        textarea
                    />
                </div>
            );
        }

        return null;
    };

    const SelectionCard = ({onClick, Icon, title, Description, isNew}) => {
        return (
            <div onClick={onClick}
                 className="flex flex-row gap-3 w-full items-center justify-center p-4 border border-gray-300 rounded-2xl hover:bg-gray-50 cursor-pointer">
                <Icon className={'w-10 h-10'}/>
                <div className='flex flex-col flex-1'>
                    <div className='flex flex-row gap-2'>
                        {!!isNew &&
                            <div className='bg-blue-500 h-6 mt-0.5 rounded-full px-2'>
                                <p className='text-xs font-semibold mt-1 text-white'>
                                    NEW
                                </p>
                            </div>
                        }
                        <p className='text-lg font-semibold'>
                            {title}
                        </p>
                    </div>
                    <p className='text-sm'>
                        {Description}
                    </p>
                </div>
            </div>
        )
    }

    const {title, description} = GOAL_TYPE_TEXTS[selectedGoalType] || GOAL_TYPE_TEXTS.default;

    return (
        <div className='flex flex-col gap-8 w-[460px]'>
            <OnboardingStepHeader
                title={title}
                description={description}
            />
            <div className='flex flex-row items-center gap-3 bg-slate-100 rounded-xl mx-auto p-3 px-5'>
                <NPOPicture
                    np={selectedNp}
                    size={40}
                    className={'bg-white border border-slate-200'}
                />
                <div className='flex flex-col flex-1 mr-2'>
                    <p className='text-lg font-semibold text-slate-800'>
                        {selectedNp.name}
                    </p>
                    {selectedNp.location &&
                        <p className='text-md text-slate-800'>
                            {selectedNp.location}
                        </p>
                    }
                </div>
            </div>
            {/*<TransitionGroup className='flex flex-col gap-5'>*/}
            {/*    <CSSTransition*/}
            {/*        key={selectedGoalType || 'default'}*/}
            {/*        timeout={500}*/}
            {/*        classNames="fade"*/}
            {/*    >*/}
            <div className='relative min-h-[320px]'>
                {!selectedGoalType ? (
                    <div
                        className='flex flex-col items-center gap-3 justify-center absolute top-0 left-0 right-0 bottom-0'>
                        <SelectionCard
                            onClick={() => handleSwitchGoalType("awareness")}
                            title={'Drive followers'}
                            Description={
                                <>
                                    Encourage new friends to follow <span
                                    className='text-blue-500 font-semibold'>{npName}</span>.
                                </>
                            }
                            Icon={SvgMegaphone}
                        />
                        <SelectionCard
                            onClick={() => handleSwitchGoalType("donation")}
                            title={'Drive donations'}
                            Description={
                                <>
                                    Set a donation goal & drive direct impact to <span
                                    className='text-blue-500 font-semibold'>{npName}</span>.
                                </>
                            }
                            Icon={SvgPiggy}
                        />
                        <SelectionCard
                            onClick={() => handleSwitchGoalType("pledge")}
                            title={'Drive action'}
                            Description={
                                <>
                                    Fill out a pledge and encourage others to join in!
                                </>
                            }
                            Icon={SvgVerified}
                            isNew
                        />
                    </div>
                ) : (
                    <div>
                        <div className="flex justify-center items-center mb-8">
                            <Button size={'sm'} variant={'ghost'} onClick={() => handleSwitchGoalType('')}>
                                <SvgArrowBack className={'w-5 h-5'}/>
                                <p>
                                    Switch goal type
                                </p>
                            </Button>
                        </div>
                        {renderGoalInput()}
                    </div>
                )}
            </div>
            {/*    </CSSTransition>*/}
            {/*</TransitionGroup>*/}
            <OnboardingButtons
                goBackStep={goBackStep}
                goNextStep={handleSubmit}
                nextDisabled={!awarenessGoal && !donationsGoal && (!pledgeGoal || !pledgeText)}
            />
        </div>
    );
}

export default CreateChallengeGoals;
