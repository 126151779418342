import { likeUnlikePost } from "../api/posts";

const initialState = {
    posts: {},
    feedRef: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_POSTS':
            const newPosts = {...state.posts};
            for (const post of action.posts) {
                if (!(post.post_ID in newPosts)) {
                    newPosts[post.post_ID] = post;
                }
            }
            return {
                ...state,
                posts: newPosts,
            };
        case 'UPDATE_REF':
            return {
                ...state,
                feedRef: action.feedRef,
            };
        case 'LIKE_POST':
            const likedPosts = {...state.posts};
            const newPost = {...likedPosts[action.postId]};
            const likes = {...newPost.likes};
            const numLikes = newPost.num_likes;
            if (!!likes[action.userId]) {
                likes[action.userId] = likes[action.userId] + 1;
            } else {
                likes[action.userId] = 1;
            }
            newPost.likes = likes;
            newPost.num_likes = numLikes + 1;
            likedPosts[action.postId] = newPost;
            return {
                ...state,
                posts: likedPosts
            };
        default:
            return state;
    }
}

