import React, {useEffect, useState} from "react";
import {motion} from "framer-motion";
import SvgLike from "./icons/SvgLike";
import SvgLikeFilled from "./icons/SvgLikeFilled";

const PostLikedCount = ({active, toggle, numLikes, labelText}) => {
    const [rotation, setRotation] = useState(0);

    useEffect(() => {
        setRotation(rotation + 45)
    }, [numLikes]);

    const handleClick = (e) => {
        toggle(e);
        e.stopPropagation()
    };
    return (
        <button onClick={handleClick} className="flex"
                style={{touchAction: "manipulation"}}>
            <motion.div
                animate={{rotate: rotation}}
                transition={{duration: 0.25, type: "spring", stiffness: 100}}
                className="relative"
            >
                {active ? (
                    <SvgLikeFilled className={'w-5 h-5 scale-[1.2] text-blue-500'} strokeWidth={1.7}/>
                ) : (
                    <SvgLike className={'w-5 h-5 scale-[1.2]'} strokeWidth={1.7}/>
                )}
            </motion.div>
            <motion.span
                // transition={{duration: 0.5}}
                className={`ml-1 text-md font-medium ${active ? 'text-blue-500' : 'text-slate-500'}`}
            >
                {numLikes} {labelText ? (numLikes !== 1 ? 'likes' : 'like') : ''}
            </motion.span>
        </button>
    );
};

export default PostLikedCount;
