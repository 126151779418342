import React, {PureComponent} from "react";
import BGGraphic7 from "../../img/graphics/BGGraphic7";
import {motion} from "framer-motion";
import BGGraphic9 from "../../img/graphics/BGGraphic9";
import SvgCommunication from "../../components/icons/SvgCommunication";
import SvgCrowd from "../../components/icons/SvgCrowd";


class NpoSection4 extends PureComponent {
    render() {
        return (
            <section className={this.props.mobile ? 'col-ac relative' : 'row-ac-jb relative'}
                     style={{
                         height: !this.props.mobile && '90vh',
                         backgroundColor: "rgb(245,248,255)",
                         padding: this.props.mobile ? '80px 24px' : '60px 10vw'
                     }}>
                <BGGraphic9 className='absolute' style={{
                    bottom: this.props.mobile ? -50 : -100,
                    left: this.props.mobile ? -12 : 60,
                    width: this.props.mobile ? '25vw' : '14vw',
                    opacity: 1
                }}/>
                <motion.div style={{flex: 1, maxWidth: !this.props.mobile && '30vw'}}
                            initial={{opacity: 0, transform: 'scale(1) translateY(12px)'}}
                            whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            transition={{default: {ease: "linear"}, delay: .2}}
                            className={this.props.mobile ? 'mb-40 col-plain' : 'col-plain'}>
                    {/*<div className='ph-24 pv-12 br-64 body1-bold mb-12 white-pure'*/}
                    {/*     style={{backgroundColor: "#6A93C6A0", marginRight: "auto"}}>*/}
                    {/*    <SvgCrowd width={36} height={36} fill={'#FFFFFF'}/>*/}
                    {/*</div>*/}
                    <div className='body3-bold black-50 mb-8' style={{textTransform: 'uppercase'}}>
                        ANALYTICS
                    </div>
                    <h1 className='heading2 black-100 mb-24 mt-0'>
                        Novel data and analytics<br/> at your fingertips
                    </h1>
                    <p className='mt-0 body1 black-70'>
                        Gain powerful insights into your nonprofit's performance and impact with Starfish Analytics.
                        Track your progress, access detailed reports on donor info, behavior, and engagement, and make
                        data-driven decisions to optimize your fundraising and outreach efforts.
                    </p>
                </motion.div>
                <div style={{flex: 1, maxWidth: this.props.mobile ? '100%' : '40vw'}} className='col-plain relative'>
                    <motion.div
                        initial={{opacity: 0, transform: 'scale(1) translateY(12px)'}}
                        whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                        transition={{default: {ease: "linear"}}}
                    >
                        <img src={require('../../img/npo/npo5.png')} className='floating'
                             style={{
                                 width: this.props.mobile ? '100%' : '40vw',
                                 objectFit: 'contain',
                             }}/>
                    </motion.div>
                </div>
            </section>
        )
    }
}

export default NpoSection4
