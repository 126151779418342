import React, {PureComponent} from "react";

const ROTATING_WORDS = ["See", `Be`, "Illuminate", "Connect", `Share`];

class HeroText extends PureComponent {


    componentDidMount() {
        var words = document.getElementsByClassName('word');
        var wordArray = [];
        var currentWord = 0;

        words[currentWord].style.opacity = 1;
        for (var i = 0; i < words.length; i++) {
            wordArray = this.splitLetters(words[i], wordArray, i);
        }
        this.changeWord(currentWord, wordArray, words);
        currentWord++;
        setInterval(() => {
            this.changeWord(currentWord, wordArray, words)
            currentWord++;
        }, 2500);
    }

    changeWord(currentWord, wordArray, words) {
        var cw = wordArray[currentWord % wordArray.length];
        var nw = wordArray[(currentWord + 1) % wordArray.length];
        for (var i = 0; i < cw.length; i++) {
            this.animateLetterOut(cw, i);
        }

        for (var i = 0; i < nw.length; i++) {
            nw[i].className = 'letter behind';
            if (!!nw[0].parentElement) {
                nw[0].parentElement.style.opacity = 1;
            }
            this.animateLetterIn(nw, i);
        }

    }

    animateLetterOut(cw, i) {
        setTimeout(function () {
            cw[i].className = 'letter out';
        }, i * 80);
    }

    animateLetterIn(nw, i) {
        setTimeout(function () {
            nw[i].className = 'letter in';
        }, 340 + (i * 80));
    }

    splitLetters(word, wordArray, index) {
        const wordArrayCopy = [...wordArray];
        var content = word.innerHTML;
        word.innerHTML = '';
        var letters = [];
        const words = ROTATING_WORDS[index];
        for (var i = 0; i < words.length; i++) {
            var letter = document.createElement('div');
            letter.className = 'letter';
            letter.innerHTML = words.charAt(i);
            if (letter.innerHTML === ' ') {
                letter.className = 'letter';
                word.appendChild(letter);
            } else {
                word.appendChild(letter);
            }
            letters.push(letter);
        }
        wordArrayCopy.push(letters);
        return wordArrayCopy;
    }

    render() {
        return (
            <div className=' primary' style={{fontSize: this.props.mobile ? '14vw' : '6.5vw', fontWeight: 800}}>
                <div className="text primary" style={{marginTop: this.props.mobile ? '-14vw' : '-6.5vw'}}>
                    <p></p>
                    <p>
                        <div className="word wisteria"></div>
                        <div className="word belize"></div>
                        <div className="word pomegranate"></div>
                        <div className="word green"></div>
                        <div className="word midnight"></div>
                    </p>
                </div>
                <div style={{marginBottom: this.props.mobile ? '9vw' : '0vw'}}>
                    {this.props.nonProfitSide ? 'your' : 'the'} good
                </div>
            </div>
        )
    }
}

export default HeroText
