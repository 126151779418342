import React, {Component} from "react";
import {getAllChallenges} from "../api/challenges";
import {withRouter} from "react-router-dom";
import ChallengeCard from "../components/ChallengeCard";
import {connect} from "react-redux";
import LoadingSection from "../components/LoadingSection";
import MiddleViewHeader from "../components/layout/MiddleViewHeader";
import {Button} from "../components/atoms/Button";
import ZeroStateArea from "../components/atoms/ZeroStateArea";


function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        mobile: state.userReducer.mobile,
    }
}

class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mobileMenu: false,
            mobile: false,
            challenges: [],
            authModalOpen: false,
            loading: true,
        }
    }

    componentDidMount = () => {
        this.getChallenges()
    }

    getChallenges = async () => {
        const challenges = await getAllChallenges();
        this.setState({
            challenges: challenges.challenges,
            loading: false,
        })
    }

    navigateToChallenge = (id) => {
        this.props.history.push({pathname: `/challenge/${id}`, state: {previous: "All Challenges"}})
    }

    render() {
        return (
            <div className='flex flex-col flex-1 overflow-hidden'>
                {/*<MiddleViewHeader*/}
                {/*    title={'Trending Challenges'}*/}
                {/*    RightComponent={*/}
                {/*        <Button variant={'default'}*/}
                {/*                onClick={() => this.props.history.push("/create-challenge")}>*/}
                {/*            Create Challenge*/}
                {/*        </Button>*/}
                {/*    }*/}
                {/*/>*/}
                {this.state.loading ?
                    <LoadingSection/>
                    :
                    this.state.challenges.length === 0 ?
                        <ZeroStateArea
                            title={'No active challenges'}
                            sub={'No active challenges right now!'}
                            buttonTitle={'Create a challenge'}
                            onClick={() => this.props.history.push("/create-challenge")}
                        />
                        :
                        <div className='flex flex-col flex-1 overflow-y-scroll p-5'>
                            {this.state.challenges.map(challenge => {
                                    return (
                                        <ChallengeCard inFeed challenge={challenge} mobile={this.props.mobile}/>
                                    )
                                }
                            )}
                        </div>
                }
                {/* {this.state.challenges.map(challenge => {
                    return (
                        <ChallengeCard challenge={challenge} mobile={this.props.mobile} />
                    )}
                )} */}
                {/* {this.state.challenges.length === 0 ?
                    <div className='row-ac-jb mb-20' style={{
                        width: this.props.mobile ? '85vw' : 820,
                    }}>
                        <p>
                            There are currently no trending challenges. Please check back later!
                        </p>
                    </div>
                    :
                    null
                } */}
            </div>
        )
    }

}


export default withRouter(connect(mapStateToProps)(Main));
