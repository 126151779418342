import React, {PureComponent} from "react";
import ModalComponent from "../ModalComponent";
import {Button} from "../atoms/Button";
import TextInput from "../inputs/TextInput";
import SvgVolunteer from "../icons/SvgVolunteer";
import SvgAward from "../icons/SvgAward";
import SvgSupporter from "../icons/SvgSupporter";
import NonProfitSearchDropdown from "../atoms/NonProfitSearchDropdown";
import NonProfitSearchDropdownResult from "../atoms/NonProfitSearchDropdownResult";
import { changeConnection } from "../../api/users";


class AddWorkModal extends PureComponent {
    state = {
        connection: "",
        role: "",
        ein: "",
        date: "",
        endDate: "",
        npName: "",
        npLogo: "",
        mode: "Add"
    }

    setInfo = () => {
        const item = this.props.item || {};
        this.setState({
            ein: item.ein,
            npLogo: item.logo_url,
            npName: item.name,
            connection: item.connection,
            date: item.date,
            endDate: item.end_date,
            role: item.role,
            mode: !!item.ein && !this.props.inOnboarding ? "Edit" : "Add" 
        })
    }

    componentDidMount = () => {
        this.setInfo();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.item !== this.props.item) {
            this.setInfo();
        }
    }

    renderIcon = (role) => {
        switch (role) {
            case 'Supporter':
                return <SvgSupporter className={'w-5 h-5'}/>
            case 'Volunteer':
                return <SvgVolunteer className={'w-5 h-5'}/>
            case 'Employee':
                return <SvgAward className={'w-5 h-5'}/>
        }
    }

    selectColor = (role) => {
        switch (role) {
            case 'Supporter':
                return '#3b81f6'
            case 'Volunteer':
                return "#ea66c3"
            case 'Employee':
                return "#F17300"
        }
    }

    handleInputChange = (e) => {
        this.setState({connection: e.target.value})
    }

    selectNonProfit = (np) => {
        this.setState({ein: np.ein, npName: np.name, npLogo: np.logo_url})
    }

    selectType = (role) => {
        this.setState({role})
    }

    handleDateChange = (type, e) => {
        const date = e.target.value;
        if (date.length < 5) this.setState({[type]: date})
    }

    submit = async () => {
        await changeConnection(this.props.authUser, this.state.ein, this.state.role, this.state.connection, this.state.date, this.state.endDate);
        if (this.props.inOnboarding) {
            this.props.setCanSubmit(true);
            this.props.close();
        } else {
            window.location.reload();
        }
    }

    render() {
        const disabled = !this.state.ein || !this.state.role || !this.state.date || this.state.date.length !== 4 || !this.state.connection || (!!this.state.endDate && this.state.endDate.length !== 4);
        return (
            <ModalComponent toggle={this.props.close}
                            isOpen={this.props.isOpen}
                            noPadding
                            size={'lg'}
                            header={`${this.state.mode} your Involvement`}
                            headerToggle
                            FooterComponent={
                                <div className='flex flex-row items-center gap-3'>
                                    <Button variant={'secondary'}>
                                        Cancel
                                    </Button>
                                    <Button variant={'default'} disabled={disabled} onClick={this.submit}>
                                        {this.state.mode === "Add" ? "Add To" : "Edit"} Involvement
                                    </Button>
                                </div>
                            }
            >
                <div className="flex flex-col px-5 pb-3">
                    <div className="mb-16">
                        {!!this.state.ein ? 
                            <NonProfitSearchDropdownResult
                                item={{ein: this.state.ein, name: this.state.npName, logo_url: this.state.npLogo}}
                                key={this.state.ein}
                                selectNp={() => this.setState({ein: "", npName: "", npLogo: ""})}
                                removeNp={true}
                            />
                            :
                            <NonProfitSearchDropdown
                                categories={[]}
                                city={"AB"}
                                state={"BA"}
                                onSelect={this.selectNonProfit}
                            />
                        }
                    </div>
                    <div className='flex flex-row mt-16 gap-2'>
                        <div className="flex flex-col gap-1 mb-16">
                            <p className='text-md text-slate-500'>
                                Level of involvement
                            </p>
                            <div className="flex flex-row">
                                {["Volunteer", "Employee", "Supporter"].map(role => 
                                    <Button className={`flex flex-row items-center text-slate-500 mr-8 ${role === this.state.role ? "opacity-1": "opacity-50"}`}
                                            style={{backgroundColor: this.selectColor(role)}} onClick={() => this.selectType(role)} key={role}>
                                        {this.renderIcon(role)}
                                        <p className={` font-semibold ml-4 text-lf`}>
                                            {role}
                                        </p>
                                    </Button>
                                )}
                            </div>
                        </div>
                       
                    </div>
                    <div className='flex flex-row mb-4 gap-2'>
                        <TextInput
                            label={'Start year'}
                            value={this.state.date}
                            type="numeric"
                            onChange={(e) => this.handleDateChange("date", e)}
                            placeholder={'e.g. 2015'}
                        />
                        <TextInput
                            label={'End year'}
                            value={this.state.endDate}
                            type="numeric"
                            onChange={(e) => this.handleDateChange("endDate", e)}
                            placeholder={'Leave empty if present'}
                        />
                    </div>
                    <TextInput
                        label={'Describe your connection'}
                        value={this.state.connection}
                        onChange={this.handleInputChange}
                        placeholder={'How do you support this organization?'}
                        textarea
                    />
                </div>
            </ModalComponent>
        );
    }
}

export default (AddWorkModal);
