import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            width="48px"
            height="48px"
            {...props}
        >
            <path fill={'currentColor'} d="M32 7c-.646 0-1.253.312-1.629.838l-9 12.601A1.999 1.999 0 0021 21.602v14.142c0 .183.034.359.08.527l-9.13 5.64a2 2 0 00-.95 1.7V54a2 2 0 002 2h38a2 2 0 002-2V43.611a2 2 0 00-.95-1.7l-9.13-5.64c.046-.168.08-.343.08-.527V21.602c0-.417-.13-.824-.371-1.163l-9-12.601A2.003 2.003 0 0032 7zm0 5.441L37.398 20H26.602L32 12.441zM25 24h14v9.85l-4-2.471v-1.9c0-1.922-1.343-3.481-3-3.481s-3 1.559-3 3.48v1.9l-4 2.472V24zm7 10.229l17 10.5V52H39v-2.176C39 45.51 35.859 42 32 42s-7 3.51-7 7.824V52H15v-7.271l17-10.5zM32 46c1.654 0 3 1.715 3 3.824V52h-6v-2.176C29 47.715 30.346 46 32 46z" />
        </svg>
    )
}

export default SvgComponent
