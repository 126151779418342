import React, {useState, useEffect} from 'react';
import TextInput from "../../components/inputs/TextInput";
import { searchCities } from '../../utils/typesense';
import CollapseComponent from "../CollapseComponent";

function SearchDropdown({setCity, setState, city, state}) {
    const [search, setSearch] = useState('');
    const [collapseOpen, setCollapseOpen] = useState(false);
    const [results, setSearchResults] = useState([]);


    const handleSearchChange = async (event) => {
        const search = event.target.value;
        if (!search) setCollapseOpen(false)
        else setCollapseOpen(true);

        setSearch(search);
        const results = await searchCities(search);
        setSearchResults(results);
    };

    useEffect(() => {
        if (!!city) {
            setSearch(city + ', ' + state)
        }
    }, [city]);

    return (
        <div className='flex flex-col relative'>
            <TextInput
                label={'Your city'}
                value={search}
                placeholder={'Search cities...'}
                onChange={handleSearchChange}
            />
            <div className='absolute top-20 left-0 right-0 z-[99]'>
                <CollapseComponent isOpen={search.length !== 0 && collapseOpen}>
                    <div className='bg-white shadow-lg border border-neutral-200 rounded-lg max-h-[180px] overflow-y-scroll'>
                        {results.map((res) =>
                            <div
                                key={res.id}
                                onClick={() => {
                                    setCity(res.city)
                                    setState(res.state)
                                    // setSearch(res.city + ', ' + res.state)
                                    setCollapseOpen(false)
                                }}
                                className='flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 rounded-xl px-2'>
                                <div className='flex flex-col'>
                                    <p className='text-md text-slate-800'>
                                        {res.city}, {res.state}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </CollapseComponent>
            </div>
        </div>
    );
}

export default SearchDropdown;
