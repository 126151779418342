import React, {Component} from "react";
import moment from "moment";

class ChallengeEndDate extends Component {
    state = {
        calendar: true,
        timeDiff: "",
        disabled: false,
    }

    componentDidMount() {
        this.updateCal()
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.updateCal()
        }
    }

    updateCal = () => {
        const rn = moment();
        const endDate = moment(this.props.challenge.end_date);

        const duration = moment.duration(endDate.diff(rn));
        const hours = parseInt(duration.asHours());
        const minutes = parseInt(duration.asMinutes()) % 60;
        if (minutes <= 0 && hours <= 0) {
            this.setState({disabled: true})
        } else if (hours < 24) {
            const hrs = String(hours).length === 1 ? "0" + String(hours) : String(hours);
            const mins = String(minutes).length === 1 ? "0" + String(minutes) : String(minutes);
            this.setState({timeDiff: hrs + "h:" + mins + 'm', calendar: false})
        }
    }

    render() {
        const challenge = this.props.challenge;
        const challengeEndDate = moment(challenge.end_date);
        const currentDate = moment();
        const inPast = challengeEndDate.isBefore(currentDate);
        return (
            <div className='flex flex-col items-center gap-0.5' style={{marginTop: !this.state.calendar && 4}}>
                <p className='text-xs font-medium text-slate-400'>
                    {inPast ? "ENDED" : "ENDS"}
                </p>
                {this.state.calendar ?
                    <div className='col-plain text-center overflow-hidden rounded-lg border border-slate-200'>
                        <div className='flex flex-col items-center bg-slate-300 w-[48px]'>
                            <p className='text-sm font-bold text-slate-800 uppercase'>
                                {moment(challenge.end_date).format('MMM')}
                            </p>
                        </div>
                        <div className='py-1'>
                            <p className='body1-bold'>
                                {moment(challenge.end_date).date()}
                            </p>
                        </div>
                    </div>
                    :
                    <div>
                        <p className='body1-bold mv-4' style={{color: '#de4949'}}>
                            {this.state.timeDiff}
                        </p>
                    </div>
                }
            </div>
        )
    }
}

export default ChallengeEndDate
