export const hasLowercaseCharacter = string => {
    return /[a-z]/.test(string);
};

export const hasUppercaseCharacter = string => {
    return /[A-Z]/.test(string);
};

export const hasDigit = string => {
    return /[0-9]/.test(string);
};

export const hasSymbol = string => {
    return /[^0-9a-zA-Z]/.test(string);
};

export function validateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return (true)
    }
    return (false)
}

export function truncateString(str, num) {
    if (str?.length <= num) {
        return str;
    }

    const truncated = str?.slice(0, num);
    return truncated?.trimEnd() + '...';
}
