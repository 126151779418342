import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 126.15 129.26"
    {...props}
  >
    <defs>
      <style>{".cls-3{fill:#efa143}"}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="OBJECTS">
        <path
          d="M51.31 27.41c-26.06-10.2-45.58-16.8-49.65 7.47s-.81 73.75 11.4 85.89S74.92 118 98.52 107.7s57.79-39.21-47.21-80.29Z"
          style={{
            fill: "#054A91",
          }}
        />
        <path
          d="M62.71.34C44 4.07 26.08 17.14 38.29 46.08s41.51 30.81 57.79 4.67S95.27-3.4 62.71.34Z"
          style={{
            fill: "#6a93c6",
          }}
        />
        <circle className="cls-3" cx={79.25} cy={125.63} r={3.08} />
        <path
          className="cls-3"
          d="M91.65 128.36a3.08 3.08 0 1 1 0-4.36 3.08 3.08 0 0 1 0 4.36ZM83.13 115.55a3.08 3.08 0 1 1-1.95-3.9 3.08 3.08 0 0 1 1.95 3.9ZM94.75 117.91a3.08 3.08 0 1 1-.85-4.27 3.08 3.08 0 0 1 .85 4.27ZM90.17 107.6a3.08 3.08 0 1 1-.62-4.32 3.08 3.08 0 0 1 .62 4.32ZM103.65 111.11a3.08 3.08 0 1 1-.86-4.27 3.08 3.08 0 0 1 .86 4.27ZM92.87 97.37A3.08 3.08 0 1 1 92 93.1a3.08 3.08 0 0 1 .87 4.27ZM103.31 100.68a3.08 3.08 0 1 1 0-4.36 3.09 3.09 0 0 1 0 4.36ZM99.5 89.1a3.08 3.08 0 1 1-.86-4.27 3.09 3.09 0 0 1 .86 4.27ZM113.16 101.64a3.08 3.08 0 1 1 1.38-4.14 3.08 3.08 0 0 1-1.38 4.14ZM107.39 84.89a3.08 3.08 0 1 1 0-4.35 3.07 3.07 0 0 1 0 4.35Z"
        />
        <circle className="cls-3" cx={113.92} cy={89.33} r={3.08} />
        <circle className="cls-3" cx={114.98} cy={79.92} r={3.08} />
        <path
          className="cls-3"
          d="M123.07 92.28a3.08 3.08 0 1 1 3.08-3.08 3.08 3.08 0 0 1-3.08 3.08Z"
        />
      </g>
    </g>
  </svg>
)

export default SvgComponent
