import React from "react";
import {useDropzone} from "react-dropzone";
import DropzoneImage from "./DropzoneImage";
import SvgClose from "../icons/SvgClose";

function Dropzone({onDrop, accept, open, addedFiles, deleteFile, images, videos}) {
    const {getRootProps, getInputProps, isDragActive, acceptedFiles} =
        useDropzone({
            accept,
            onDrop,
        });
    const zoneHeight = 44
    return (
        <div>
            <div {...getRootProps({className: "dropzone"})}
                 className={`
                 ${addedFiles.length > 0 ? 'flex flex-row items-center' : 'cursor-pointer file-drop-area flex flex-col items-center justify-center'}
                 bg-slate-100 rounded-xl hover:bg-slate-200 min-h-[100px]
                 `}
                // style={{height: zoneHeight}}
            >
                <div onClick={open} className='w-full'>
                    <input className="input-zone" {...getInputProps()} />
                    {images.length === 0 && videos.length === 0 ?
                        <div className="text-center">
                            {isDragActive ? (
                                <p className="body3 base-black-50">
                                    Release to drop the files here
                                </p>
                            ) : (
                                <div className='flex flex-row items-center justify-center gap-2'>
                                    <p className="text-sm base-black-50">
                                        Drop media here or click
                                    </p>
                                    <div className='text-sm base-black-50 h-base-white-100'>
                                        <SvgClose style={{transform: "rotate(-45deg)"}} className={'w-4 h-4'}/>
                                    </div>
                                </div>
                            )}
                        </div>
                        :
                        <div className='flex flex-row p-8 items-center gap-5 w-full relative'>
                            {images.map(file =>
                                <DropzoneImage file={file} key={file.name} deleteFile={deleteFile} className={'mr-8'}/>
                            )}
                            {videos.length > 0 &&
                                <>
                                    {videos.map(file =>
                                        <DropzoneImage file={file} key={file.name} deleteFile={deleteFile}
                                                       className={'mr-8'}/>
                                    )}
                                </>
                            }
                            <div className='body4 base-black-50 h-base-white-100' style={{right: 0}}>
                                <SvgClose style={{transform: "rotate(-45deg)"}} width={24} height={24}/>
                            </div>
                            <p className='text-xs absolute base-black-50' style={{bottom: 12, right: 12}}>
                                {isDragActive ? 'Drop files here' : 'Drag more in if you want'}
                            </p>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Dropzone;
