import React, {Component} from "react";
import BGItem1 from "../../img/graphics/BGItem1";
import BGGraphic8 from "../../img/graphics/BGGraphic8";
import BGGraphic3 from "../../img/graphics/BGGraphic3";
import {motion} from "framer-motion";
import HeroText from "../../components/HeroText";
import SvgApple from "../../components/icons/SvgApple";
import SvgArrowForward from "../../components/icons/ArrowForward";
import {Link} from "react-router-dom";
import SvgScribbleCircle from "../../components/icons/SvgScribbleCircle";
import UserNpoToggle from "../../components/UserNpoToggle";
import SvgGooglePlay from "../../components/icons/SvgGooglePlay";

class HeroSection extends Component {
    render() {
        const subCopy = this.props.nonProfitSide ?
            'Connect with current supporters, amplify your marketing efforts, and attract new supporters and new donors to your cause.'
            :
            'Starfish is the global place where you share your humanitarian profile, find meaningful connections, and make a difference!'
        if (this.props.mobile) {
            return (
                <section className='relative col-plain w-100p pt-64' style={{overflowX: "hidden"}}>
                    <div className='absolute cover-parent hero-wrapper' style={{zIndex: -1}}/>
                    <BGGraphic3 strokeWidth={20} className={'absolute w-25p'} style={{
                        left: '-10vw',
                        top: '20vh',
                        zIndex: -1,
                        opacity: 1,
                        transform: 'rotate(-20deg)'
                    }}/>
                    <BGItem1 className={'absolute w-55p'} style={{
                        right: '-30vw',
                        bottom: '28vh',
                        zIndex: -1,
                        opacity: 1,
                        transform: 'rotate(-20deg)'
                    }}/>
                    <motion.div
                        initial={{opacity: 0, transform: 'scale(1) translateY(3px)'}}
                        whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                        transition={{default: {ease: "linear"}, delay: .2}}
                        style={{marginTop:this.props.mobile ? 32 : 100}}
                    >
                        <img
                            src={require(this.props.nonProfitSide ? '../../img/npo/npo1.png' : '../../img/graphics/newsplash (1).png')}
                            className='floating hero-img'
                            style={{width: '126vw', objectFit: "contain", marginLeft: 24}}/>
                    </motion.div>
                    <UserNpoToggle mobile/>
                    {this.props.nonProfitSide ?
                        <div className='relative ph-24 col-je' style={{ marginTop: -120}}>
                            <div className='primary mb-60'
                                 style={{fontSize: '14vw', fontWeight: 800}}>
                                Empower your cause
                            </div>
                        </div>
                        :
                        <div className='relative ph-24 col-je' style={{marginTop:-64}}>
                            <HeroText mobile={this.props.mobile} nonProfitSide={this.props.nonProfitSide}/>
                        </div>
                    }
                    <motion.div
                        initial={{opacity: 0, transform: 'scale(1) translateY(12px)'}}
                        whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                        transition={{default: {ease: "linear"}}}
                        className='col-ac mb-60 ph-24'
                        style={{marginTop: -24}}
                    >
                        <p className='mt-0 body1 black-70'>
                            {subCopy}
                        </p>
                        <div className='row-ac mt-40'>
                            {this.props.nonProfitSide ?
                                <div className='col-ac' style={{gap:12}}>
                                    <a style={{textDecoration: 'none'}}
                                       href='https://nonprofit.starfishapp.com/' target={'_blank'}
                                       className='primary-button row-ac body1-bold'
                                        // style={{backgroundColor: '#EFA042', textDecoration:"none"}}
                                    >
                                        <div className='mr-8'>
                                            Claim Your Page
                                        </div>
                                        <SvgArrowForward style={{transform: 'rotate(-45deg)'}}/>
                                    </a>
                                    <a style={{textDecoration: 'none'}}
                                       href='https://nonprofit.starfishapp.com/' target={'_blank'}
                                       className='secondary-button row-ac body1-bold'
                                        // style={{backgroundColor: '#EFA042', textDecoration:"none"}}
                                    >
                                        <div className='mr-8'>
                                            Admin Login
                                        </div>
                                        <SvgArrowForward style={{transform: 'rotate(-45deg)'}}/>
                                    </a>
                                </div>
                                :
                                <div className='row-ac' style={{gap:12}}>
                                    <a style={{textDecoration: 'none'}}
                                       href='https://apps.apple.com/us/app/starfish-social/id1642351040'
                                       target={'_blank'}
                                       className='row-ac primary-button body1-bold'>
                                        <SvgApple className={'mr-8'} width={20} height={20}/>
                                        iOS
                                    </a>
                                    <a style={{textDecoration: 'none'}}
                                       href='https://play.google.com/store/apps/details?id=com.starfishsocialmedia.app&hl=en_US&gl=US'
                                       target={'_blank'}
                                       className='row-ac primary-button body1-bold'>
                                        <SvgGooglePlay className={'mr-8'} width={20} height={20}/>
                                        Android
                                    </a>
                                </div>
                            }
                            {/*<button className="learn-more ml-20">*/}
                            {/*    <span className="circle" aria-hidden="true">*/}
                            {/*      <span className="icon arrow"></span>*/}
                            {/*    </span>*/}
                            {/*    <span className="button-text">Download</span>*/}
                            {/*</button>*/}
                        </div>
                    </motion.div>
                </section>
            )
        }
        return (
            <section className='relative col-ac-jc' style={{minHeight: '100vh'}}>
                <div className='absolute cover-parent hero-wrapper' style={{zIndex: -1}}/>
                <div className='row-jb relative'
                     style={{padding: '10vh 0 0 10vw'}}>
                    <BGGraphic3 strokeWidth={20} className={'absolute w-25p'} style={{
                        left: this.props.nonProfitSide ? '-18vw' : '-10vw',
                        bottom: this.props.nonProfitSide ? '1vw' : '-20vh',
                        zIndex: -1,
                        opacity: 1,
                        transform: 'rotate(-20deg)'
                    }}/>
                    <BGItem1 className={'absolute w-25p'} style={{
                        right: '-10vw',
                        bottom: '2vh',
                        zIndex: -1,
                        opacity: 1,
                        transform: 'rotate(-20deg)'
                    }}/>
                    <div style={{flex: 1, maxWidth: '60vw', paddingTop:'15vh'}} className='col-plain'>
                        <div className='relative col-je'>
                            <BGGraphic8 className='absolute' style={{top: 200, right: -60, width: '3vw', zIndex: 0}}/>
                            <motion.div
                                initial={{opacity: 0, transform: 'scale(1) translateY(12px)'}}
                                whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                                transition={{default: {ease: "linear"}}}
                            >
                                <UserNpoToggle/>
                                {this.props.nonProfitSide ?
                                    <div className='relative'>
                                        <div className='primary'
                                             style={{fontSize: '6.5vw', fontWeight: 800,marginTop: this.props.mobile ? '-14vw' : '-6.5vw'}}>
                                            Empower your cause
                                        </div>
                                    </div>
                                    :
                                    <HeroText nonProfitSide={this.props.nonProfitSide}/>
                                }
                            </motion.div>
                        </div>
                        <motion.div
                            initial={{opacity: 0, transform: 'scale(1) translateY(12px)'}}
                            whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            transition={{default: {ease: "linear"}}}
                            className='w-100p col-plain'
                        >
                            <p className='mt-0 body1 black-70 mt-20 w-80p' style={{fontSize: '1.3rem'}}>
                                {subCopy}
                            </p>
                            <div className='row-ac mt-40'>
                                {this.props.nonProfitSide ?
                                    <div className='row-ac' style={{gap:12}}>
                                        <a style={{textDecoration: 'none'}}
                                           href='https://nonprofit.starfishapp.com/' target={'_blank'}
                                           className='primary-button row-ac body1-bold'
                                            // style={{backgroundColor: '#EFA042', textDecoration:"none"}}
                                        >
                                            <div className='mr-8'>
                                                Claim Your Page
                                            </div>
                                            <SvgArrowForward style={{transform: 'rotate(-45deg)'}}/>
                                        </a>
                                        <a style={{textDecoration: 'none'}}
                                           href='https://nonprofit.starfishapp.com/' target={'_blank'}
                                           className='secondary-button row-ac body1-bold'
                                            // style={{backgroundColor: '#EFA042', textDecoration:"none"}}
                                        >
                                            <div className='mr-8'>
                                                Admin Login
                                            </div>
                                            <SvgArrowForward style={{transform: 'rotate(-45deg)'}}/>
                                        </a>
                                    </div>
                                    :
                                    <div className='row-ac' style={{gap:12}}>
                                        <a style={{textDecoration: 'none'}}
                                           href='https://apps.apple.com/us/app/starfish-social/id1642351040'
                                           target={'_blank'}
                                           className='row-ac primary-button body1-bold'>
                                            <SvgApple className={'mr-8'} width={20} height={20}/>
                                            iOS
                                        </a>
                                        <a style={{textDecoration: 'none'}}
                                           href='https://play.google.com/store/apps/details?id=com.starfishsocialmedia.app&hl=en_US&gl=US'
                                           target={'_blank'}
                                           className='row-ac primary-button body1-bold'>
                                            <SvgGooglePlay className={'mr-8'} width={20} height={20}/>
                                            Android
                                        </a>
                                    </div>
                                }
                            </div>
                        </motion.div>
                    </div>
                    <div style={{flex: 1, maxWidth: '50vw'}} className='col-ac'>
                        <motion.div
                            initial={{opacity: 0, transform: 'scale(1) translateY(3px)'}}
                            whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            transition={{default: {ease: "linear"}, delay: .2}}
                        >
                            <img
                                src={require(this.props.nonProfitSide ? '../../img/npo/npo1.png' : '../../img/graphics/newsplash (1).png')}
                                className='floating hero-img'
                                style={{width: '60vw', height: '60vw', objectFit: "contain"}}/>
                        </motion.div>
                    </div>
                </div>
            </section>
        )
    }

}


export default HeroSection;
