import React, {Component} from "react";
import SvgScribbleCircle from "./icons/SvgScribbleCircle";
import {Link, withRouter} from "react-router-dom";

class UserNpoToggle extends Component {
    state = {
        hoveringUsers: false,
        hoveringNpos: false,
        currentRoute: ''
    }

    componentDidMount() {
        this.setState({currentRoute: this.props.location.pathname});
    }

    hoverUsers = () => {
        this.setState({hoveringUsers: true})
    }

    blurUsers = () => {
        this.setState({hoveringUsers: false})
    }

    hoverNpos = () => {
        this.setState({hoveringNpos: true})
    }

    blurNpos = () => {
        this.setState({hoveringNpos: false})
    }


    render() {
        const {currentRoute} = this.state;
        return (
            <div className=' row-ac' style={{marginLeft: -40, marginBottom: 60}}>
                <div className='row-jc' style={{marginRight: 40}}>
                    <Link
                        to={'/'}
                        onMouseEnter={this.hoverUsers}
                        onMouseLeave={this.blurUsers}
                        className='col-ac'
                        style={{textDecoration: 'none'}}
                    >
                        <p className='body1-bold'
                           style={{
                               color: currentRoute === '/' ? "#064A91" : (this.state.hoveringUsers ? "#EFA042" : '#1d1d1d80')
                           }}
                        >
                            For Users
                        </p>
                        <SvgScribbleCircle
                            fill={((currentRoute === '/') || this.state.hoveringUsers) ? '#EFA042' : 'transparent'}
                            style={{
                                width: 200,
                                height: 140,
                                marginTop: -78,
                                opacity: this.state.hoveringNpos ? .3 : 1
                            }}
                        />
                    </Link>
                    <div className='relative'>
                        {(currentRoute !== '/non-profits') &&
                            <div className='absolute'
                                 style={{transform: "rotate(-209deg) scaleY(-1)", marginRight: -20}}>


                            </div>
                        }
                        <Link
                            onMouseEnter={this.hoverNpos}
                            onMouseLeave={this.blurNpos}
                            to={'/non-profits'} className='col-ac' style={{marginLeft:this.props.mobile ? -80 : -60, textDecoration: 'none'}}>
                            <p className='body1-bold'
                               style={{
                                   color: currentRoute === '/non-profits' ? "#064A91" : (this.state.hoveringNpos ? "#EFA042" : '#1d1d1d80')
                               }}
                            >
                                For Nonprofits
                            </p>
                            <SvgScribbleCircle
                                fill={((currentRoute === '/non-profits') || this.state.hoveringNpos) ? '#EFA042' : 'transparent'}
                                style={{
                                    width:this.props.mobile ? 180 : 240,
                                    height: 190,
                                    marginTop: -108,
                                    marginRight: -6,
                                    transform: 'rotate(-2deg) scaleX(-1) scaleY(-1)',
                                    opacity: this.state.hoveringUsers ? .3 : 1
                                }}
                            />
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(UserNpoToggle)
