import React, {Component} from "react";
import PostImages from "../PostImages";
import LinkPreviewWrapper from "./LinkPreviewWrapper";
import ChallengeCard from "../ChallengeCard";
import {connect} from "react-redux";
import PostComponent from "./PostComponent";
import SvgRepost from "../icons/SvgRepost";


function mapDispatchToProps(dispatch) {
    return {
        setImageViewerModalOpen: (images, index) => {
            dispatch({
                type: "SET_IMAGE_VIEWER_MODAL_OPEN",
                images,
                index
            })
        },
    }
}

class PostAttachments extends Component {


    openImageLightbox = (imageURLs, index, creator, creator_name) => {
        this.props.setImageViewerModalOpen(imageURLs, index);
    }

    render() {
        const post = this.props.post || {};
        const challenge = post.challenge || {};
        const show = (post.link_preview) || (Object.keys(challenge).length > 0) || (post.image_urls.length > 0 || post.original_post || post.videos?.length > 0)
        if (!show) {
            return null
        }
        return (
            <div className='flex flex-col gap-3'>
                {post.link_preview && (
                    <LinkPreviewWrapper link_preview={post.link_preview}/>
                )}
                {Object.keys(challenge).length > 0 &&
                    <ChallengeCard
                        key={challenge.challenge_id}
                        challenge={challenge}
                        border
                        inPost
                    />
                }
                {(post.image_urls.length > 0 || post.videos?.length > 0) &&
                    <div>
                        <PostImages
                            id={post.post_ID}
                            openImageLightbox={this.openImageLightbox}
                            imageURLs={post.image_urls}
                            videos={post.videos}
                            creator={post.creator}
                            creator_name={post.creator_name}
                            paused={true}
                            inFeed={this.props.inFeed}
                            navigateToPost={this.navigateToPost}
                        />
                    </div>
                }
                {post.original_post &&
                    <div className='border border-slate-200 rounded-xl p-3 flex flex-col gap-2'>
                        <div className={'flex flex-row items-center text-slate-600 gap-1 text-sm'}>
                            <SvgRepost className={'w-4 h-4'}/>
                            <p>
                                {post.creator_name} reposted
                            </p>
                        </div>
                        <PostComponent
                            post={post.original_post}
                            hidePostInteractions
                            noBorder
                            noPadding
                            isRepost
                        />
                    </div>
                }
            </div>
        )
    }

}


export default connect(null, mapDispatchToProps)(PostAttachments);
