import React from 'react';
import ModalComponent from "../ModalComponent";
import {Button} from "../atoms/Button";
import LottieWrapper from "../atoms/LottieWrapper";

function ConfirmationModal(props) {
    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'sm'}
            header={props.title}
            description={props.description}
            toggle={props.close}
            FooterComponent={
                <div className='flex flex-col gap-3 w-full'>
                    {!!props.lottie &&
                        <div className='flex flex-col items-center justify-center'>
                         <div className='w-1/ hue-rotate-[120deg]'>
                             <LottieWrapper
                                 json={props.lottie}
                                 loop={false}
                             />
                         </div>
                        </div>
                    }
                    {!props.hideConfirm &&
                        <>
                            {props.onConfirm ?
                                <Button onClick={() => {
                                    props.onConfirm()
                                    props.close()
                                }} variant={props.confirmVariant || 'destructive'}>
                                    {props.confirmText || 'Confirm'}
                                </Button>
                                :
                                <Button onClick={() => {
                                    props.close()
                                }} variant={'destructive'}>
                                    Exit
                                </Button>
                            }
                        </>
                    }
                    {props.onConfirm2 &&
                        <Button onClick={() => {
                            props.onConfirm2()
                            props.close()
                        }} variant={props.confirmVariant || 'destructive'}>
                            {props.confirm2Text || 'Confirm'}
                        </Button>
                    }
                    <Button onClick={props.close} variant={'secondary'}>
                        {props.cancelText || 'Cancel'}
                    </Button>
                </div>
            }
        >
        </ModalComponent>
    );
}

export default ConfirmationModal;
