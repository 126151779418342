import React , {useState, useEffect}from "react";
import {CARD_STYLE, sample_nps} from "../utils/dummy";
import SvgPin from "./icons/SvgPin";
import NPOPicture from "./atoms/NPOPicture";
import SvgHotelClass from "./icons/SvgHotelClass";
import { getSuggestions } from "../api/users";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom"


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}


function NpSuggestions(props) {
    const [nonProfitSuggestions, setNonProfitSuggestions] = useState([]);

    useEffect(() => {
        suggestions()
    }, [])

    const suggestions = async () => {
        const suggestions = await getSuggestions(props.authUser);
        setNonProfitSuggestions(suggestions.non_profits)

    }


    return (
        <div>
            <div className='flex flex-row items-center gap-1 mb-2'>
                <SvgHotelClass/>
                <p className='text-xl font-semibold '>
                    Suggested Nonprofits
                </p>
            </div>
            <div>
                {nonProfitSuggestions.slice(0, 4).map(np => {
                    return (
                        <div
                            className={`${CARD_STYLE} p-[12px] flex flex-row items-center`}
                            onClick={() => {props.history.push(`/non-profit/${np.ein}`)}}
                            key={np.id}
                        >
                            <NPOPicture
                                size={44}
                                np={np}
                                logoUrl={np.logo_url}
                                className={'border border-slate-200'}
                            />
                            <div className='flex flex-col flex-1 ml-4' style={{textAlign: "left"}}>
                                <p className='text-base text-slate-800 font-semibold'>
                                    {np.name}
                                </p>
                                <p className='text-sm text-slate-500 line-clamp-1'>
                                    {np.category}
                                </p>
                                <div className='row-ac'>
                                    <SvgPin className={'text-blue-500 w-3 h-3'}/>
                                    <p className='text-sm text-blue-500 ml-4'>
                                        {np.city}, {np.state}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default withRouter(connect(mapStateToProps)(NpSuggestions));

