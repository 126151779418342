import React, {Component} from "react";
import {Route, Switch, withRouter, Redirect} from "react-router-dom";
import ChallengeFeedPage from "../../pages/ChallengeFeedPage";
import SearchPage from "../../pages/SearchPage";
import ChallengePage from "../../pages/ChallengePage";
import NonProfitPage from "../../pages/NonProfitPage";
import AppLeftSidebar from "./AppLeftSidebar";
import ProfilePage from "../../pages/ProfilePage";
import FeedPage from "../../pages/FeedPage";
import NotificationsPage from "../../pages/NotificationsPage";
import GivingHistory from "../../pages/GivingHistory";
import CreatePostSheet from "../../pages/Sheets/CreatePostSheet";
import ImageViewerModal from "../modals/ImageViewerModal";
import PostPage from "../../pages/PostPage";
import CampaignPage from "../../pages/CampaignPage";
import HashtagPage from "../../pages/HashtagPage";
import SettingsPage from "../../pages/SettingsPage";
import UserListPage from "../../pages/UserListPage";
import InvitePage from "../../pages/InvitePage";
import ToastComponent from '../atoms/ToastComponent';
import 'react-toastify/dist/ReactToastify.css';
import MessagesPage from "../../pages/MessagesPage";
import CreateChallengeMain from "../../pages/CreateChallenge/CreateChallengeMain";
import GroupsPage from "../../pages/GroupsPage";
import CreateGroupSheet from "../../pages/Sheets/CreateGroupSheet";
import FullPostSheet from "../../pages/Sheets/FullPostSheet";
import GroupPreviewSheet from "../../pages/Sheets/GroupPreviewSheet";
import GroupPreviewPage from "../../pages/GroupPreviewPage";
import StreakIncreasedModal from "../modals/StreakIncreasedModal";
import DonationSuccessPage from "../../pages/DonationSuccessPage";
import Marquee from "react-fast-marquee";
import NuxWidget from "../NuxWidget";
import ActivityBanner from "../ActivityBanner";
import UserSuggestions from "../UserSuggestions";
import NpSuggestions from "../NpSuggestions";
import FeaturedChallenges from "../FeaturedChallenges";
import MobilePlaceholder from "../MobilePlaceholder";

class AppMain extends Component {
    state = {
        placeholder: false,
        page: "",
        pathname: ""
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        this.updatePathSegment();
        window.addEventListener('popstate', this.updatePathSegment);
    }

    resize() {
        this.setState({
            placeholder: window.innerWidth <= 760,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        // Update the path segment if the location changes
        if (window.location.pathname !== this.state.pathname) {
            this.updatePathSegment();
        }
    }

    componentWillUnmount() {
        // Cleanup listener on component unmount
        window.removeEventListener('popstate', this.updatePathSegment);
    }

    updatePathSegment = () => {
        const pathname = window.location.pathname
        const pathArray = pathname.split('/').filter(Boolean);
        this.setState({ page: pathArray[0] || '' , pathname});
    };

    render() {
        const {page} = this.state;
        if (this.state.placeholder) {
            return (
                <MobilePlaceholder/>
            )
        }
        return (
            <div className='flex flex-col flex-1 h-[100vh] overflow-hidden'>
                <div className='flex flex-row justify-center flex-1 overflow-hidden'>
                    <AppLeftSidebar/>
                    <div
                        className='flex flex-col bg-slate-50 flex-1 overflow-hidden border-x border-slate-200 relative'>
                        <Switch>
                            <Route exact path="/" component={FeedPage}/>
                            <Route path="/post" component={PostPage}/>
                            <Route exact path="/network" component={SearchPage}/>
                            <Route path="/challenges" component={ChallengeFeedPage}/>
                            <Route path="/challenge" component={ChallengePage}/>
                            <Route path="/non-profit" component={NonProfitPage}/>
                            <Route path="/profile" component={ProfilePage}/>
                            <Route path="/notifications" component={NotificationsPage}/>
                            <Route path="/messages" component={MessagesPage}/>
                            <Route path="/groups" component={GroupsPage}/>
                            <Route path="/thanks" component={DonationSuccessPage}/>
                            <Route path="/history" component={GivingHistory}/>
                            <Route path="/hashtag" component={HashtagPage}/>
                            <Route exact path="/settings" component={SettingsPage}/>
                            <Route path="/following" component={UserListPage}/>
                            <Route path="/followers" component={UserListPage}/>
                            <Route path="/create-challenge" component={CreateChallengeMain}/>
                            <Route exact path="/invite/:groupId" component={InvitePage}/>
                            <Route path="/group-preview/:groupId" component={GroupPreviewPage}/>
                            <Route path="/campaign" component={CampaignPage}/>
                            <Redirect to="/"/>

                        </Switch>

                        <CreatePostSheet/>
                        <CreateGroupSheet/>
                        <FullPostSheet/>
                        <GroupPreviewSheet/>
                        <StreakIncreasedModal/>
                    </div>
                    <div className='w-[200px] md:w-[120px] lg:w-[30vw] px-4 pt-5 overflow-y-scroll'>
                        <div className='lg:pr-12 flex flex-col gap-8 pb-5'>
                            {page === "" || page === "profile" ? <NuxWidget/> : null}
                            {page === "" ? <ActivityBanner/> : null}
                            {/* <FeaturedChallenges/> */}
                            {page === "non-profit" || page === "profile" || page === "groups" || page === "campaign" ? <UserSuggestions/> : null}
                            {page === "non-profit" || page === "profile" || page === "groups" ? <NpSuggestions/> : null}
                        </div>
                    </div>
                </div>
                <ImageViewerModal/>
                <ToastComponent/>
            </div>
        )
    }
}

export default withRouter(AppMain);
