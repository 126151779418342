import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            width="48px"
            height="48px"
            {...props}
        >
            <path fill={'currentColor'} d="M21 10c-4.567 0-14 3.667-14 15 0 .681.044 1.344.11 2h4.027A16.042 16.042 0 0111 25c0-9.133 7.888-11 10-11 4.438 0 7.652 3.004 9.418 5.215a2.015 2.015 0 003.146.01C35.348 17.013 38.587 14 43 14c2.112 0 10 1.867 10 11 0 6.982-4.58 12.641-8.568 16.791-5.127 5.334-10.707 9.22-12.432 10.096-1.725-.874-7.306-4.762-12.434-10.096A56.957 56.957 0 0117.046 39H11.98c1.487 2.072 3.134 3.928 4.705 5.563C21.632 49.71 29.672 56 32 56c2.328 0 10.367-6.29 15.314-11.438C51.824 39.873 57 33.41 57 25c0-11.333-9.433-15-14-15-5.521 0-9.068 3.023-11 5.389C30.068 13.023 26.521 10 21 10zm0 13c-1.886 0-2.296 1.272-4.947 8H9a2 2 0 000 4h8c1.779 0 2.508-1.673 3.66-4.73C22.592 38.563 22.91 40 25 40c1.571 0 2.218-1.001 5.645-6h3.91c.693 1.19 1.968 2 3.445 2a4 4 0 000-8c-1.477 0-2.752.81-3.445 2H30c-1.57 0-2.184 1.084-4.322 4.127C23.536 24.594 23.09 23 21 23z" />
        </svg>
    )
}

export default SvgComponent
