import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button} from '../../components/atoms/Button';
import SvgClose from '../../components/icons/SvgClose';
import {off, ref} from 'firebase/database';
import GroupPicture from "../../components/atoms/GroupPicture";
import SvgSettings from "../../components/icons/SvgSettings";
import SvgContacts from "../../components/icons/SvgContacts";
import EditGroupDetails from "../../components/groups/EditGroupDetails";
import GroupMembersList from "../../components/groups/GroupMembersList";
import GroupInviteWidget from "../../components/groups/GroupInviteWidget";
import GroupNonProfitListItem from "../../components/groups/GroupNonProfitListItem";
import ZeroStateArea from "../../components/atoms/ZeroStateArea";
import ChallengeCard from '../../components/ChallengeCard';
import SearchNonProfitsSheet from './SearchNonProfitsSheet';
import {addRemoveGroupMember} from '../../api/messaging';
import {attachGroupsListener, editGroup} from '../../api/groups';
import {getBatchNonProfits} from '../../api/nonProfits';
import {firebaseDatabase} from '../../api/firebase';
import {getChallenge, getChallengeActions} from '../../api/challenges';
import SearchChallengesSheet from './SearchChallengesSheet';
import GroupPrivacyIndicator from "../../components/atoms/GroupPrivacyIndicator";
import GroupManageApprovalsWidget from "../../components/groups/GroupManageApprovalsWidget";

const GroupDetailsSheet = ({isOpen, currentGroup, toggleGroupDetailsSheet}) => {
    const user = useSelector(state => state.userReducer.user);
    const authUser = useSelector(state => state.userReducer.authUser);
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState('Home')
    const [nonProfitsList, setNonProfitsList] = useState([]);
    const [group, setGroup] = useState({});
    const [challenge, setChallenge] = useState({});
    const [challengeParticipants, setChallengeParticipants] = useState([])
    const isAdmin = "admin" in group && Object.keys(group.admin).includes(user.username);


    const closeSheet = () => {
        dispatch(toggleGroupDetailsSheet());
    };

    const goBack = () => {
        setActiveTab('Home')
    }

    const removeMember = async (username, name) => {
        await addRemoveGroupMember(authUser, group.id, username, name);
    }

    const leaveGroup = async () => {
        await addRemoveGroupMember(authUser, group.id, user.username, `${user.first_name} ${user.last_name}`);
        window.location.reload();
    };

    const updateChallenge = async (challenge_id) => {
        await editGroup(authUser, group.id, {challenge_id});
    };

    useEffect(() => {
        if (!currentGroup?.id) return;

        const groupsListener = attachGroupsListener(authUser, currentGroup?.id, async group => {
            console.log("Refreshing in groups listener", group)
            getNps(group);
            getChallengeInfo(group);

            group.id = currentGroup.id;
            group.memberNames = {...group.members};
            group.members = Object.keys(group.members);
            setGroup(group);
        })
        return () => {
            off(ref(firebaseDatabase, `chats/${currentGroup.id}`), 'value', groupsListener);
        }
    }, [currentGroup?.id])

    const getNps = async (group) => {
        if ("non_profits" in group && Object.keys(group.non_profits).length) {
            const res = await getBatchNonProfits(Object.keys(group?.non_profits))
            setNonProfitsList(res.non_profits);
        } else {
            setNonProfitsList([])
        }

    }

    const getChallengeInfo = async (group) => {
        if (!!group.challenge_id) {
            const res = await getChallenge(group.challenge_id);
            const actions = await getChallengeActions(group.challenge_id)
            setChallenge(res.challenge);
            setChallengeParticipants(actions.actions.map(action => action.user_id))
        } else {
            setChallenge({});
            setChallengeParticipants([])
        }
    }

    const NAV_ITEMS = [
        {
            title: 'View all members',
            icon: SvgContacts,
            onClick: () => setActiveTab('Members')
        },
    ]
    if (isAdmin) {
        NAV_ITEMS.push({
            title: 'Edit Group',
            icon: SvgSettings,
            onClick: () => setActiveTab('Edit')
        });
    }

    return (
        <div
            className={`absolute ${isOpen ? 'h-full overflow-y-scroll' : 'h-0 overflow-hidden'} transition-all left-0 right-0 bottom-0 bg-white z-[10]`}>
            <div className={`flex flex-col flex-1 h-full p-5 mb-`}>
                <div className='flex flex-row items-center justify-between'>
                    <p className='text-2xl font-semibold text-slate-800'>
                        Group Details
                    </p>
                    <Button onClick={closeSheet} variant={'secondary'} size={'icon'} radius={'full'}>
                        <SvgClose/>
                    </Button>
                </div>
                <div className='flex flex-col gap-5 mt-20 pb-20'>
                    {activeTab === 'Home' &&
                        <>
                            <div className='flex flex-col items-center gap-2'>
                                <GroupPicture imageId={group?.image_id} size={'2xl'}/>
                                <p className='mt-2 -mb-1 text-center text-2xl font-semibold'>
                                    {group?.name}
                                </p>
                                <p className='text-slate-700 text-center'>
                                    {group?.description}
                                </p>
                                <GroupPrivacyIndicator group={group} append={'Group'}/>
                            </div>
                            <div className='flex flex-col gap-2'>
                                {NAV_ITEMS.map(item =>
                                    <div onClick={item.onClick} className='flex flex-row items-center gap-3 p-3 border border-slate-200 rounded-xl text-slate-700
                                        hover:bg-slate-50 hover:text-slate-900 cursor-pointer
                                        '>
                                        <item.icon className={'w-5 h-5'}/>
                                        <p>
                                            {item.title}
                                        </p>
                                    </div>
                                )}
                            </div>
                            {(isAdmin && group.private) &&
                                <GroupManageApprovalsWidget group={group} user={user}/>
                            }
                            <div className='border border-slate-200  p-3 rounded-xl'>
                                <div className='flex flex-row items-center justify-between'>
                                    <p className='text-lg font-semibold mb-2'>
                                        Group Challenge
                                    </p>
                                    {isAdmin &&
                                        <div>
                                            <Button onClick={() => setActiveTab('SearchChallenges')} size={'sm'}>
                                                {!!group.challenge_id ? "Update challenge" : "Add a challenge"}
                                            </Button>
                                            {!!group.challenge_id &&
                                                <Button onClick={() => updateChallenge(null)} size={'sm'}
                                                        className="ml-2" variant={'destructive'}>
                                                    Remove challenge
                                                </Button>
                                            }
                                        </div>
                                    }
                                </div>
                                <div className='mt-2'>
                                    {Object.keys(challenge).length > 0 &&
                                        <ChallengeCard
                                            key={challenge.challenge_id}
                                            challenge={challenge}
                                            border
                                            inPost
                                        />
                                    }
                                    <div className='hidden only:flex'>
                                        <p className='text-sm text-slate-500'>This group does not currently have a linked challenge</p>
                                    </div>
                                </div>
                            </div>
                            <div className='border border-slate-200  p-3 rounded-xl'>
                                <div className='flex flex-row items-center justify-between'>
                                    <p className='text-lg font-semibold mb-2'>
                                        Supported Nonprofits
                                    </p>
                                    {isAdmin &&
                                        <Button onClick={() => setActiveTab("SearchNonProfits")} size={'sm'}>
                                            Add a cause
                                        </Button>
                                    }
                                </div>
                                <div className='mt-2'>
                                    {nonProfitsList.map(nonProfit =>
                                        <GroupNonProfitListItem
                                            nonProfit={nonProfit}
                                            key={nonProfit.id}
                                            group={group}
                                            removable
                                            isAdmin={isAdmin}
                                        />
                                    )}
                                    <div className='hidden only:flex'>
                                        <p className='text-sm text-slate-500'>
                                            No supported causes added yet
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <GroupInviteWidget group={group}/>
                            <div>
                            <Button onClick={leaveGroup} size={'sm'}
                                    className="ml-2" variant={'destructive'}>
                                Leave group
                            </Button>
                            </div>
                        </>
                    }
                    {activeTab === 'Edit' &&
                        <EditGroupDetails
                            goBack={goBack}
                            group={group}
                            authUser={authUser}
                        />
                    }
                    {activeTab === 'Members' &&
                        <GroupMembersList
                            goBack={goBack}
                            group={group}
                            user={user}
                            removeMember={removeMember}
                            challengeParticipants={challengeParticipants}
                        />
                    }
                    {activeTab === 'SearchNonProfits' &&
                        <SearchNonProfitsSheet
                            isOpen={true}
                            close={() => setActiveTab('Home')}
                            group={group}
                        />
                    }
                    {activeTab === 'SearchChallenges' &&
                        <SearchChallengesSheet
                            goBack={goBack}
                            updateChallenge={updateChallenge}
                        />
                    }
                </div>
            </div>

        </div>
    );
};

export default GroupDetailsSheet;
