import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 -960 960 960"
            width={48}
            {...props}
        >
            <path fill={'currentColor'}
                  d="M480-444q50 0 84-34t34-84q0-50-34-84t-84-34q-50 0-84 34t-34 84q0 50 34 84t84 34zM132-160q-24 0-42-18t-18-42v-520q0-26 18-43t42-17h696q24 0 42 18t18 42v520q0 24-18 42t-42 18H132zm88-60h520q-49-63-120.5-94.5T480-346q-69 0-139 31.5T220-220zM174-40q-13 0-21.5-8.5T144-70q0-13 8.5-21.5T174-100h612q13 0 21.5 8.5T816-70q0 13-8.5 21.5T786-40H174zm0-820q-13 0-21.5-8.5T144-890q0-13 8.5-21.5T174-920h612q13 0 21.5 8.5T816-890q0 13-8.5 21.5T786-860H174z"/>
        </svg>
    )
}

export default SvgComponent
