import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            width="48px"
            height="48px"
            {...props}
        >
            <path fill={'currentColor'} d="M27.479 7A1.5 1.5 0 0026 8.5v7.785c-2.658.297-7.027 1.058-11.426 3.906-4.994 3.234-9.434 9.18-9.537 18.995-.001.007-.006.012-.008.02h.004c0 .107-.033.185-.033.294a1.5 1.5 0 002.97.295c1.188-5.936 4.89-8.567 8.99-9.916 3.463-1.139 6.852-1.14 9.04-1.024V36.5a1.5 1.5 0 002.56 1.06l14-14a1.5 1.5 0 000-2.12l-14-14A1.5 1.5 0 0027.48 7zM29 12.121L39.379 22.5 29 32.879v-5.494a1.5 1.5 0 00-1.36-1.494c-2.083-.195-6.85-.43-11.617 1.138a17.279 17.279 0 00-6.335 3.727c1.519-3.714 3.858-6.323 6.517-8.045 4.374-2.832 9.4-3.52 11.404-3.668A1.5 1.5 0 0029 17.547V12.12z" />
        </svg>
    )
}

export default SvgComponent
