import {Component} from 'react';
import Marquee from "react-fast-marquee";
import SvgMegaphone from "./icons/SvgMegaphone";

class CampaignBanner extends Component {
    render() {
        return (
            <Marquee className='bg-primary'>
                <div onClick={() => this.props.navigateTo({pathname: `/campaign`})}
                     className={'row-ac pointer pv-8 bg-white-10-h'}>
                    <div className='row-ac' style={{marginRight: '20vw'}}>
                        <SvgMegaphone style={{color: 'white'}} width={24} height={24}/>
                        <p className='title2 ml-8' style={{color: 'white'}}>
                            Join the Land of the Free Military Support Campaign!
                        </p>
                    </div>
                    <div className='row-ac' style={{marginRight: '20vw'}}>
                        <SvgMegaphone style={{color: 'white'}} width={24} height={24}/>
                        <p className='title2 ml-8' style={{color: 'white'}}>
                            Welcome to Starfish Social Network where you can share your good and support the causes you care about
                        </p>
                    </div>
                    <div className='row-ac' style={{marginRight: '20vw'}}>
                        <SvgMegaphone style={{color: 'white'}} width={24} height={24}/>
                        <p className='title2 ml-8' style={{color: 'white'}}>
                            Starfish is the global social network designed to promote and share your good with the world
                        </p>
                    </div>
                    {/* <div className='row-ac' style={{marginRight: '20vw'}}>
                        <SvgMegaphone style={{color: 'white'}} width={24} height={24}/>
                        <p className='title2 ml-8' style={{color: 'white'}}>
                            Join Starfish today and we'll make a donation to BinghamtonU in your name
                        </p>
                    </div> */}
                    <div className='row-ac' style={{marginRight: '20vw'}}>
                        <SvgMegaphone style={{color: 'white'}} width={24} height={24}/>
                        <p className='title2 ml-8' style={{color: 'white'}}>
                            Join the Land of the Free Military Support Campaign!
                        </p>
                    </div>
                </div>
            </Marquee>
        )
    }
}

export default CampaignBanner
