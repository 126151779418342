import React, {Component} from "react";
import {contentToJSX} from "../../utils/posts";


class PostContent extends Component {

    render() {
        const post = this.props.post || {};
        if (post.block_style) {
            const content = contentToJSX(
                post.content,
                post.created_at,
            );

            return (
                <div className='text-lg' style={{marginTop:2}} key={post.post_ID}>
                    {content}
                </div>
            );
        } else {
            return (
                <p className='text-lg' key={post.post_ID}>
                    {post.post_content || post.content}
                </p>
            )
        }
    }
}

export default PostContent;
