import React, {Component} from "react";
import {Button} from "../../components/atoms/Button";


class ZeroStateArea extends Component {
    render() {
        return (
            <div className='flex flex-col items-center gap-2 justify-center flex-1 px-14 text-center'>
                <p className='text-lg text-slate-500 font-medium'>
                    {this.props.title || 'No results'}
                </p>
                {this.props.sub &&
                    <p className='text-md text-slate-400'>
                        {this.props.sub}
                    </p>
                }
                {this.props.buttonTitle &&
                    <Button variant={'default'}
                            onClick={this.props.onClick}>
                        {this.props.buttonTitle}
                    </Button>
                }
            </div>
        )
    }
}

export default ZeroStateArea
