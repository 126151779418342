import React from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {Button} from '../../components/atoms/Button';
import SvgClose from '../../components/icons/SvgClose';
import PostPage from "../PostPage";

function mapDispatchToProps(dispatch) {
    return {
        toggleFullPostModal: (fullPostModalID) => {
            dispatch({
                type: "TOGGLE_FULL_POST_MODAL",
                fullPostModalID,
            })
        },
    }
}

function mapStateToProps(state) {
    return {
        fullPostModalID: state.userReducer.fullPostModalID,
    }
}

const FullPostSheet = (props) => {


    const closeSheet = () => {
        props.toggleFullPostModal(null);
    };

    return (
        <div
            className={`absolute ${props.fullPostModalID !== null ? 'h-full overflow-y-scroll' : 'h-0 overflow-hidden'} transition-all left-0 right-0 bottom-0 bg-white z-[10]`}>
            <div className={`flex flex-col flex-1 h-full`}>
                <div className='flex flex-row items-center justify-between border-b border-slate-200 pb-3 p-5'>
                    <p className='text-2xl font-semibold text-slate-800'>
                        Post
                    </p>
                    <Button onClick={closeSheet} variant={'secondary'} size={'icon'} radius={'full'}>
                        <SvgClose/>
                    </Button>
                </div>
                {!!props.fullPostModalID &&
                    <PostPage postId={props.fullPostModalID} isSheet closeSheet={closeSheet}/>
                }
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(FullPostSheet);
