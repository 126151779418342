import React, {Component} from "react";
import {connect} from "react-redux";
import {Button} from "../components/atoms/Button";
import PostEditor from "../components/posts/PostEditor";
import {firebaseDatabase} from "../api/firebase";
import Message from "../components/Message";
import moment from "moment";
import {convertToRaw} from "draft-js";
import {get, off, onChildAdded, onValue, query, ref} from "firebase/database";
import {sendChatMessage} from "../api/messaging";
import { getChallenge, getChallengeActions } from "../api/challenges";
import ChallengeDonationBar from "../components/ChallengeDonationBar";
import ChallengeAwarenessBar from "../components/ChallengeAwarenessBar"
import GroupPicture from "../components/atoms/GroupPicture";
import GroupChatInitialContent from "../components/groups/GroupChatInitialContent";
import SvgChevronDown from "../components/icons/SvgChevronDown";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        posts: state.postReducer.posts,
    }
}

class ChatPage extends Component {
    state = {
        content: "",
        messages: [],
        challenge: {},
        actions: [],
    }

    componentDidMount = () => {
        this.loadMessages();
        this.getChallengeInfo();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.chat === null || prevProps.chat?.id !== this.props.chat?.id) {
            this.setState({messages: []});
            this.loadMessages();
            this.getChallengeInfo();
        }
    }

    componentWillUnmount() {
        if (!!this.props.chat) {
            const chatID = this.props.chat.id;
            const messagesRef = ref(firebaseDatabase, `chats/${chatID}/messages`);

            // Remove the listeners
            off(messagesRef, 'child_added', this.newMessageCallback);
            off(messagesRef, 'child_removed', this.handleDeletedMessage);
        }
    }

    getChallengeInfo = async () => {
        const group = this.props.chat;

        if (!!group.challenge_id) {
            const res = await getChallenge(group.challenge_id);
            const actions = await getChallengeActions(group.challenge_id)
            this.setState({challenge: res.challenge, actions: actions.actions.map(action => action.user_id)});
        } else {
            this.setState({challenge: {}, actions: []})
        }
    }

    sendChat = async () => {
        const content = convertToRaw(this.state.content);
        const plaintext = content.blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

        const users = []
        const nonProfits = []
        const hashtags = [];
        const links = [];

        for (const entity of Object.values(content.entityMap)) {
            if (entity.data.mention?.type === "userTag") users.push(entity.data.mention.id);
            else if (entity.data.mention?.type === "nonProfitTag") nonProfits.push(entity.data.mention.id);
            else if (entity.data.mention?.type === "hashtag") hashtags.push(entity.data.mention.hashtag);
            else if (entity.type === "LINK") links.push(entity.data.url);
        }
        const timestamp = moment().unix();
        this.setState({content: ""})
        await sendChatMessage(this.props.authUser, this.props.chat.id, content, timestamp, plaintext, null, links);
    }

    loadMessages = async () => {
        const chat = this.props.chat;
        const user = this.props.authUser;
        this.messageIDsLoaded = new Set();

        this.newMessageCallback = onChildAdded(ref(firebaseDatabase, `chats/${chat.id}/messages`), newMessageIDSnapshot => {
            this.handleNewMessage(newMessageIDSnapshot);
        });

        // ref(firebaseDatabase, `users/${user.uid}/unread/chats/${chat.id}`)
        //     .once('value')
        //     .then(async notificationsSnapshot => {
        //         const notifications = notificationsSnapshot.val();
        //         this.setState({unread: notifications});
        //         const updates = {};
        //         updates[`users/${user.uid}/unread/chats/${chat.id}`] = 0;
        //         firebaseDatabase().ref().update(updates);
        const chatRef = query(ref(firebaseDatabase, `chats/${chat.id}/messages`))
        const messagesSnapshot = await (get(chatRef))
        this.handleFirstMessageBatch(messagesSnapshot); // notifications

        // .limitToLast(Math.max(this.messageBatchSize, notifications + 5))
    }

    handleNewMessage = async (newMessageIDSnapshot) => {
        const newMessageID = newMessageIDSnapshot.key;
        this.messageIDsLoaded.add(newMessageID);

        const messageDataSnapshot = await get(ref(firebaseDatabase, `messages/${newMessageID}`));
        const messageData = messageDataSnapshot.val();
        const newMessages = [{...messageData, id: newMessageID}, ...this.state.messages];
        this.setState({messages: newMessages});
        // this.scrollToBottom();
    };

    handleFirstMessageBatch = (messagesSnapshot) => { //notifications
        const messagePromises = [];
        if (!messagesSnapshot.val()) {
            this.setState({loading: false});
            return
        }
        for (const messageID of Object.keys(messagesSnapshot.val())) {
            this.messageIDsLoaded.add(messageID);
            const messageRef = ref(firebaseDatabase, `messages/${messageID}`)
            onValue(messageRef, messageSnapshot => {
                messagePromises.push(messageSnapshot.val())
            })
        }
        Promise.all(messagePromises).then(resolvedObjects => {
            const newMessages = [];
            for (const snapshot of resolvedObjects) {
                newMessages.push({
                    ...snapshot,
                    id: snapshot.chatID
                })
            }
            // this.scrollToBottom();
            // if (notifications > 0) {
            //     const unreadDivider = "UNREAD-DIVIDER";
            //     newMessages.splice(newMessages.length - notifications, 0, unreadDivider);
            // }
            newMessages.sort((a, b) => b.timestamp - a.timestamp);
            this.setState({messages: newMessages, loading: false});
        })
    };

    render() {
        const chat = this.props.chat || {};
        const self = this.props.authUser.uid;
        let otherName = ""
        if (chat?.members?.length) {
            const otherUser = chat?.members[0] === self ? chat?.members[1] : chat?.members[0];
            otherName = chat?.memberNames[otherUser]
        }
        const numPendingApprovals = "requests" in chat ? Object.keys(chat.requests).length : 0;
        const isAdmin = "admin" in chat && Object.keys(chat.admin).includes(self);

        return (
            <div className='flex flex-col flex-1 relative overflow-hidden'>
                {this.props.isGroup ?
                    <div>
                        <div
                            onClick={this.props.toggleGroupDetailsSheet}
                            className='flex absolute top-2 left-2 right-2  p-3 border border-slate-200 rounded-lg flex-col
                            bg-white hover:bg-slate-50 cursor-pointer group z-[2]
                            '>
                            <div className='flex flex-row items-center justify-between'>
                                <div className='flex flex-row items-center gap-3'>
                                    <GroupPicture imageId={chat.image_id}/>
                                    <p className='font-semibold'>
                                        {chat.name}
                                    </p>
                                </div>
                                <div className="flex flex-row gap-2">
                                    {isAdmin && numPendingApprovals !== 0 &&
                                        <div className='bg-red-400 flex flex-col items-center justify-center text-white rounded-full text-sm font-semibold px-3'>
                                            {numPendingApprovals}
                                        </div>
                                    }
                                    <SvgChevronDown className={'-rotate-90 group-hover:text-blue-500'}/>
                                </div>
                            </div>
                            {!!chat.challenge_id &&
                                <div className='mt-2 flex flex-col gap-2'>
                                    {this.state.challenge.donations_goal > 0 ?
                                        <div>
                                            <ChallengeDonationBar challenge={this.state.challenge}/>
                                        </div>
                                        : null}
                                    {this.state.challenge.awareness_goal > 0 ?
                                        <div>
                                            <ChallengeAwarenessBar challenge={this.state.challenge}/>
                                        </div>
                                        : null}
                                </div>
                            }
                        </div>
                    </div>
                    : null
                }
                <div className='flex flex-col-reverse flex-1 p-5 gap-4 overflow-y-scroll'>
                    {this.state.messages.map(message =>
                        <Message
                            message={message}
                            chat={chat}
                            actions={this.state.actions}
                        />
                    )}
                    {this.props.isGroup &&
                        <GroupChatInitialContent
                            hasNoMessages={this.state.messages.length === 0}
                            group={chat}
                            toggleInviteToGroupModal={this.props.toggleInviteToGroupModal}
                            toggleNonprofitSearchModal={this.props.toggleNonprofitSearchModal}
                        />
                    }
                </div>
                {chat.members?.length > 1 &&
                    <div className='flex flex-row items-center p-3 gap-3 border-t border-slate-200'>
                        <div className='flex-1'>
                            <PostEditor
                                originalContent={this.state.content}
                                onChange={(content) => this.setState({content})}
                                placeholder={`Reply${chat?.is_group ? '' : ` to ${otherName}`}...`}
                            />
                        </div>
                        <Button variant={'default'} onClick={this.sendChat}>
                            Send
                        </Button>
                    </div>
                }
            </div>
        )
    }

}


export default connect(mapStateToProps, null)(ChatPage);



