import React from 'react';
import PropTypes from 'prop-types';
import SvgChevronDown from "../icons/SvgChevronDown";
import SvgCheck from "../icons/SvgCheck";

const ChecklistButton = ({ completed, title,disabled, onClick }) => {
    return (
        <button
            className={`flex items-center justify-between w-full py-5 px-4 mb-2 rounded-lg relative transition-opacity ${
                completed ? 'bg-gradient-to-r from-blue-400/20 to-blue-600/20 text-white' : 'bg-slate-100 hover:bg-slate-200'
            }`}
            disabled={disabled || completed}
            onClick={onClick}
        >
            <div className="flex items-center text-left">
                <span className="font-semibold">{title}</span>
            </div>
            {completed ? (
                <SvgCheck className={''}/>
            ) : (
            <SvgChevronDown className={' -rotate-90'}/>
            )}
        </button>
    );
};

ChecklistButton.propTypes = {
    completed: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};

export default ChecklistButton;
