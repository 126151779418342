import React, {PureComponent} from "react";
import ModalComponent from "../ModalComponent";
import {Button} from "../atoms/Button";
import GlobalInvitesWidget from "../invites/GlobalInvitesWidget";
import SvgAddCircle from "../icons/SvgAddCircle";
import InviteListItem from "../invites/InviteListItem";
import SearchNonprofitsModal from "./SearchNonprofitsModal";
import {createInvite, editInvite, getUserInvites} from "../../api/invites";
import { shareInvite } from "../../utils/share";

class DonateByInvitingModal extends PureComponent {
    state = {
        isSearchModalOpen: false,
        totalAmount: 0,
        invites: []
    }

    openSearchModal = () => {
        this.setState({isSearchModalOpen: true})
    }

    closeSearchModal = () => {
        this.setState({isSearchModalOpen: false})
    }

    onSelectNonProfit = async (nonProfit) => {
        const link = await shareInvite(this.props.authUser.uid, nonProfit.ein); 
        await createInvite(this.props.authUser, nonProfit.ein, link);
        this.getInfo();
    }

    deactivate = async (inviteId, deactivated) => {
        await editInvite(this.props.authUser, inviteId, {deactivated});
        this.getInfo();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.isOpen && prevProps.isOpen !== this.props.isOpen) {
            this.getInfo();
        }
    }

    componentDidMount = () => {
        this.getInfo();
    }

    getInfo = async () => {
        const res = await getUserInvites(this.props.authUser);
        this.setState({totalAmount: res.total_amount, invites: res.invites});
    }

    render() {
        return (
            <ModalComponent toggle={this.props.close}
                            isOpen={this.props.isOpen}
                            size={'xl'}
            >
                <div className='flex flex-col lg:flex-row items-stretch gap-10'>
                    <div className='flex flex-1 flex-col justify-between'>
                        <GlobalInvitesWidget totalAmount={this.state.totalAmount}/>
                        <div className='flex flex-row items-center gap-3'>
                            <Button onClick={this.openSearchModal} variant={'default'}>
                                <SvgAddCircle/> Create New Link
                            </Button>
                            <Button onClick={this.props.close} variant={'secondary'}>
                                Close
                            </Button>
                        </div>
                    </div>
                    <div className='w-[1.5px] bg-slate-200'/>
                    <div className='flex-[1.5]'>
                        <div className='flex flex-row mb-3 items-center justify-between'>
                            <p className='text-lg font-semibold'>
                                Your Links ({this.state.invites.length})
                            </p>
                            <Button onClick={this.openSearchModal} size={'sm'}>
                                <SvgAddCircle/> Create
                            </Button>
                        </div>
                        <div className='max-h-[350px] rounded-xl bg-slate-100 p-3 overflow-y-scroll'>
                            <p className='hidden only:flex text-center text-sm text-slate-700 flex flex-col items-center justify-center my-12'>
                                No invite links created yet. Create your first now!
                            </p>
                            {this.state.invites.map(item =>
                                <InviteListItem key={item.id} invite={item} deactivate={this.deactivate}/>
                            )}
                        </div>
                    </div>
                </div>
                <SearchNonprofitsModal
                    title={'Select the cause your referral donations will support'}
                    onSelect={this.onSelectNonProfit}
                    isOpen={this.state.isSearchModalOpen}
                    close={this.closeSearchModal}
                />
            </ModalComponent>
        );
    }
}

export default (DonateByInvitingModal);
