import React, {Component} from "react";
import {Button} from "../atoms/Button";
import SvgEditNote from "../icons/SvgEditNote";
import SvgAddCircle from "../icons/SvgAddCircle";
import SvgMoreHorizontal from "../icons/SvgMoreHorizontal";
import SvgClose from "../icons/SvgClose";

class ProfileSectionHeader extends Component {

    render() {
        return (
            <div className={`flex flex-row items-center gap-5`}>
                <p className='text-2xl font-semibold mr-auto'>
                    {this.props.label}
                </p>
                {(this.props.sameUser && this.props.onClickAdd && !this.props.editing && !this.props.hideAdd) &&
                    <Button variant={'default'} onClick={this.props.onClickAdd} size={'icon'}>
                        <SvgAddCircle/>
                    </Button>
                }
                {(this.props.setEditingMode && this.props.sameUser) &&
                    <Button variant={'secondary'} onClick={this.props.setEditingMode} size={'icon'}>
                        {this.props.editing ? <SvgClose/> : <SvgMoreHorizontal/>}
                    </Button>
                }
                {(this.props.onClickEdit && this.props.sameUser) &&
                    <Button variant={'secondary'} onClick={this.props.onClickEdit} size={'icon'}>
                        <SvgEditNote/>
                    </Button>
                }
            </div>
        )
    }
}

export default ProfileSectionHeader