import axios from "./axios";


export const getPost = async (user, post_id) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {post_id: post_id}
        };
        const res = await axios.get("/posts", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getPostLikes = async (user, post_id) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {post_id: post_id}
        };
        const res = await axios.get("/posts/like", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}



export const createPost = async (user, creator, post_content, image_urls, url, users, nonProfits, hashtags, plaintext, videos, original_post_id=null, challenge_id=null, event_id=null, np_post=false, scope="public") => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            post_content,
            image_urls,
            block_style: true,
            url,
            users,
            non_profits: nonProfits,
            hashtags,
            plaintext,
            original_post_id,
            videos,
            challenge_id,
            event_id,
            creator,
            np_post,
            scope
        }
        const res = await axios.post("/posts", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const deletePost = async (user, post_id) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {post_id}
        };
        const res = await axios.delete("/posts", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const getComments = async (user, post_id) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {post_id: post_id}
        };
        const res = await axios.get("/posts/comments", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const editPost = async (user, post_id, params_to_edit) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            updated_params: params_to_edit,
            post_id
        }
        const res = await axios.put("/posts", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getLinkPreview = async (user, url) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {url}
        };
        const res = await axios.get("/posts/link_preview", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const likeUnlikePost = async (user, post_id) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {post_id}
        const res = await axios.post("/posts/like", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}



export const getPublicPost = async (post_id) => {
    try {
        const config = {
            headers: {mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {post_id}
        };
        const res = await axios.get("/posts/public", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}
