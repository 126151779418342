import React, {useEffect, useRef} from 'react';

function HorizontalStepper({ steps, currentStep }) {
    const containerRef = useRef(null);

    useEffect(() => {
        const activeStepElement = document.getElementById(`step-${currentStep}`);
        if (activeStepElement && containerRef.current) {
            containerRef.current.scrollLeft = activeStepElement.offsetLeft;
        }
    }, [currentStep]);

    return (
        <div className="flex flex-col items-center gap-4">
            <div ref={containerRef} className="flex items-center w-screen max-w-screen md:w-auto hide-scrollbar px-2 md:max-w-[80vw] overflow-auto whitespace-nowrap gap-2 md:gap-0">
                {steps.map((step, index) => (
                    <React.Fragment key={index} id={`step-${index + 1}`}>
                        <div className="flex flex-col items-center ml-4 mr-4">
                            <div
                                className={`flex items-center justify-center w-8 md:w-10 h-8 md:h-10 rounded-full ${
                                    currentStep >= index + 2 ? 'bg-blue-500' : (currentStep >= index + 1 ? 'bg-blue-500 animate-pulse' : 'bg-slate-300')
                                }`}
                            >
                                <step.Icon className="w-4 md:w-6 h-4 md:h-6 text-white" />
                            </div>
                            <p className={`text-center text-xs md:text-sm mt-2 font-medium ${currentStep >= index + 2 ? 'text-blue-500' : 'text-slate-500'}`}>
                                {step.label}
                            </p>
                        </div>
                        {index < steps.length - 1 && (
                            <div
                                className={`flex-1 h-1.5 w-12 rounded-full overflow-hidden  -mt-6 relative ${currentStep !== index + 2 && 'bg-slate-200'}`}>
                                <div
                                    className={`absolute top-0 left-0 h-1.5 bg-blue-500 transition-width duration-300 ${
                                        currentStep === index + 2 ? 'w-full bg-blue-500 animate-pulse' : ''
                                    }`}
                                    style={{
                                        width: currentStep !== index + 2 && (currentStep > index + 1 ? '100%' : '0%')
                                    }}
                                />
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}

export default HorizontalStepper;
