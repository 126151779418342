import {firebaseDatabase} from "./firebase";
import { ref, onValue, push, update, get, orderByChild, query, orderByValue } from "firebase/database";
import moment from "moment";
import { getLinkPreview } from "./posts";
import { getUser } from "./users";
import { createActivity } from "./activity";


// Send a message to a chat
export const sendChatMessage = async (user, chatID, messageContent, timestamp, text, messageAttachment, links, imageURLs) => {
    let finalMessageAttachment = messageAttachment;
    if (!!links && !!links.length) {
        const linkInfo = await getLinkPreview(user, links[0]);
        finalMessageAttachment = {...linkInfo};
        finalMessageAttachment.type = "link"
    }

    const newMessageData = {
        content: messageContent,
        creator: user.uid,
        imageURLs: [],
        chatID: chatID,
        previewData: {},
        reactions: {},
        messageAttachment: finalMessageAttachment,
        timestamp
    };
    const newMessageRef = push(ref(firebaseDatabase, 'messages'));
    const newMessageID = newMessageRef.key;
    const updates = {};
    updates[`messages/${newMessageID}`] = {...newMessageData};
    updates[`chats/${chatID}/messages/${newMessageID}`] = true;
    updates[`chats/${chatID}/recentMessage/timestamp`] = timestamp;
    updates[`chats/${chatID}/recentMessage/preview`] = text;

    const membersSnapshot = await get(ref(firebaseDatabase, `chats/${chatID}/members`));
    membersSnapshot.forEach(memberSnapshot => {
        const userID = memberSnapshot.key;
        updates[`users/${userID}/chats/${chatID}`] = timestamp;
        if (userID !== user.uid) {
            updates[`users/${userID}/unread/chats/${chatID}`] = 1 // TODO(cory) increment current number of unreads
        }
    });
    await update(ref(firebaseDatabase), updates);

    updateChatLatestMessage(user, chatID, timestamp, text);
    return newMessageID;
};


// // Update the given chat's latest message to the given timestamp
const updateChatLatestMessage = async (user, chatID, timestamp, text) => {
    try {
        const updates = {
            recentMessage: {
                timestamp,
                username: user.uid,
                preview: text
            }
        }
        await update(ref(firebaseDatabase, `chats/${chatID}`), updates);
    } catch (err) {
        console.log("Error updating chat latest message:", err)
    }
};


// Create a new chat
export const createChat = async (user, recipient) => {
    if (!user || !recipient) {
        return
    }
    try {
        const dmsRef = ref(firebaseDatabase, `users/${user.uid}/dms`)
        const chatIDs = (await get(dmsRef)).val()
        if (chatIDs && chatIDs[recipient]) {
            return chatIDs[recipient];
        }
        
        const timestamp = moment().unix();
        const userInfo = await getUser(user);
        const userName = userInfo.user ? userInfo.user.first_name + " " + userInfo.user.last_name : user.uid;
        const memberObject = {[user.uid]: userName};
        
        const recipientInfo = await getUser(user, recipient);
        const recipientName = recipientInfo.user ? recipientInfo.user.first_name + " " + recipientInfo.user.last_name : recipient;
        memberObject[recipient] = recipientName;
        
        const chatData = {
            recentMessage: {
                preview: `${userName} created the chat`,
                timestamp
            },
            members: memberObject,
            messages: {},
            timestamp
        }
        const newChatID = push(ref(firebaseDatabase, 'chats')).key;
        const updates = {
            [`chats/${newChatID}`]: chatData,
            [`users/${user.uid}/chats/${newChatID}`]: timestamp,
            [`users/${user.uid}/unread/chats/${newChatID}`]: 0,
            [`users/${user.uid}/dms/${recipient}`]: newChatID,
            [`users/${recipient}/dms/${user.uid}`]: newChatID,
            [`users/${recipient}/chats/${newChatID}`]: timestamp,
            [`users/${recipient}/unread/chats/${newChatID}`]: 0
        };     
        await update(ref(firebaseDatabase), updates);
        return newChatID;
    } catch (err) {
        console.log("Error creating chat:", err);
    }
};


export const createGroup = async (authUser, user, name, description, image_id, isPrivate) => {
    if (!user) {
        return;
    }
    try {
        const timestamp = moment().unix();
        const memberObject = {[user.username]: `${user.first_name} ${user.last_name}`};
        
        const chatData = {
            recentMessage: {
                preview: `created the group`,
                username: user.username,
                timestamp
            },
            members: memberObject,
            messages: {},
            name,
            private: isPrivate,
            description,
            image_id,
            creator: user.username,
            admin: {[user.username]: true},
            timestamp,
            is_group: true,
        }
        const newChatID = push(ref(firebaseDatabase, 'chats')).key;
        const updates = {
            [`chats/${newChatID}`]: chatData,
            [`users/${user.username}/chats/${newChatID}`]: timestamp,
            [`users/${user.username}/unread/chats/${newChatID}`]: 0,
        };
            
        await update(ref(firebaseDatabase), updates);
        createActivity(authUser, {
            username: user.username,
            name: `${user.first_name} ${user.last_name}`,
            type: "created_group",
            group_id: newChatID,
        })
        return newChatID;
    } catch (err) {
        console.log("Error creating group:", err);
    }
};

// export const getChat = (chatId) => {
//     return firebaseDatabase().ref(`chats/${chatId}`).once('value')
//         .then(snapshot => snapshot.val())
//         .then(chatObject => {
//             chatObject.memberNames = {...chatObject.members}
//             const members = Object.keys(chatObject.members);
//             chatObject.members = members.sort((a, b) => a.toLocaleLowerCase() - b.toLocaleLowerCase());
//             chatObject.id = chatId;
//             // delete chatObject.name;
//             delete chatObject.recentMessage;
//             return chatObject;
//         })
// }

export const attachUserChatsListener = (user, callback) => {
    try {
        const chatsRef = ref(firebaseDatabase, `users/${user.uid}/chats`)
        return onValue(chatsRef,      
           async chatsListSnapshot => {
                const chatPromises = []
                const chatsList = chatsListSnapshot.val();
                if (!chatsList) {
                    callback([])
                    return;
                }
                for (const chatID of Object.keys(chatsList)) {
                    const chatSnapshot = await get(ref(firebaseDatabase, `chats/${chatID}`))
                    const chatObject = chatSnapshot.val();
                    if (chatObject.deprecated) continue
                    chatObject.memberNames = {...chatObject.members};
                    const members = Object.keys(chatObject.members);
                    chatObject.members = members.sort((a, b) => a.toLocaleLowerCase() - b.toLocaleLowerCase());
                    chatObject.id = chatID;
                    chatPromises.push(chatObject)
                }
              
                Promise.all(chatPromises).then(chats => {
                    const unreadRef = ref(firebaseDatabase, `users/${user.uid}/unread/chats`)
                    onValue(unreadRef, unreadSnapshot => {
                        const unread = unreadSnapshot.val()
                        for (const chat of chats) {
                            if (unread && unread[chat.id]) {
                                chat.unread = unread[chat.id]
                            }
                        }
                    })
                    
                    chats.sort((a,b) => a.recentMessage.timestamp - b.recentMessage.timestamp)
                    chats.reverse()
                    callback(chats)
                }
            )
        })
    } catch (err) {
        console.log("Error attaching user chats listener:", err)
    }
}



export const addRemoveGroupMember = async (user, groupId, username, name, dontRemove=false) => {
    if (!user) {
        return;
    }
    
    const groupRef = ref(firebaseDatabase, `chats/${groupId}`);
    const group = (await get(groupRef)).val();
    const currentMembers = {...group.members};

    const updates = {};
    if (!(username in currentMembers)) {
        currentMembers[username] = name;
        updates[`users/${username}/chats/${groupId}`] = moment().unix();
        updates[`users/${username}/unread/chats/${groupId}`] = 0
    } else if (dontRemove) {
        return groupId;
    } else {
        delete currentMembers[username];
        if (Object.keys(currentMembers).length === 0) {
            throw new Error("EmptyGroup")
        }
        updates[`users/${username}/chats/${groupId}`] = null
        updates[`users/${username}/unread/chats/${groupId}`] = null
    }
    updates[`chats/${groupId}/members`] = currentMembers

    await update(ref(firebaseDatabase), updates);
    return groupId;
};


