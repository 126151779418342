import React, {PureComponent} from "react";
import ModalComponent from "../ModalComponent";
import {Button} from "../atoms/Button";
import TextInput from "../inputs/TextInput";
import CollegeSearchDropdown from "../atoms/CollegeSearchDropdown";
import NonProfitSearchDropdownResult from "../atoms/NonProfitSearchDropdownResult";
import { changeEducation } from "../../api/users";


class AddEducationModal extends PureComponent {
    state = {
        connection: "",
        domain: "",
        startDate: "",
        endDate: "",
        collegeName: "",
        collegeLogo: "",
        major: "",
        mode: "Add"
    }

    setInfo = () => {
        const item = this.props.item || {};
        this.setState({
            domain: item.domain,
            collegeLogo: item.logo_url,
            collegeName: item.name,
            connection: item.connection,
            startDate: item.start_date,
            endDate: item.end_date,
            major: item.major,
            mode: !!item.domain ? "Edit" : "Add" 
        })
    }

    componentDidMount = () => {
        this.setInfo();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.item !== this.props.item) {
            this.setInfo();
        }
    }


    handleInputChange = (inputName, e) => {
        this.setState({[inputName]: e.target.value})
    }

    selectCollege = (college) => {
        this.setState({collegeName: college.name, collegeLogo: college.logo_url, domain: college.domain})
    }


    handleDateChange = (type, e) => {
        const date = e.target.value;
        if (date.length < 5) this.setState({[type]: date})
    }

    submit = async () => {
        await changeEducation(this.props.authUser, this.state.domain, this.state.major, this.state.connection, this.state.startDate, this.state.endDate, this.state.collegeName);
        window.location.reload();
    }

    render() {
        const disabled = !this.state.domain || !this.state.startDate || this.state.startDate.length !== 4 || (!!this.state.endDate && this.state.endDate.length !== 4);
        return (
            <ModalComponent toggle={this.props.close}
                            isOpen={this.props.isOpen}
                            noPadding
                            size={'lg'}
                            header={`${this.state.mode} your Education`}
                            headerToggle
                            FooterComponent={
                                <div className='flex flex-row items-center gap-3'>
                                    <Button variant={'secondary'} onClick={this.props.close}>
                                        Cancel
                                    </Button>
                                    <Button variant={'default'}  onClick={this.submit} disabled={disabled}>
                                        {this.state.mode === "Add" ? "Add To" : "Edit"} Education
                                    </Button>
                                </div>
                            }
            >
                <div className="flex flex-col px-5 pb-3">
                    <div className="mb-16">
                        {!!this.state.domain ? 
                            <NonProfitSearchDropdownResult
                                item={{name: this.state.collegeName, logo_url: this.state.collegeLogo}}
                                key={this.state.domain}
                                selectNp={() => this.setState({domain: "", collegeName: "", collegeLogo: ""})}
                                removeNp={true}
                            />
                            :
                            <CollegeSearchDropdown
                                onSelect={this.selectCollege}
                            />
                        }
                    </div>
                    <div className='flex flex-row mt-16 mb-16 gap-3'>
                        <TextInput
                            label={'Degree / Major'}
                            value={this.state.major}
                            onChange={(e) => this.handleInputChange("major", e)}
                            placeholder={'e.g. Economics'}
                        />
                        <TextInput
                            label={'Start year'}
                            value={this.state.startDate}
                            type="numeric"
                            onChange={(e) => this.handleDateChange("startDate", e)}
                            placeholder={'e.g. 2015'}
                        />
                        <TextInput
                            label={'Graduation year'}
                            value={this.state.endDate}
                            type="numeric"
                            onChange={(e) => this.handleDateChange("endDate", e)}
                            placeholder={'Leave empty if present'}
                        />
                    </div>
                    <TextInput
                        label={'Description'}
                        value={this.state.connection}
                        onChange={(e) => this.handleInputChange("connection", e)}
                        placeholder={'...'}
                        textarea
                    />
                </div>
            </ModalComponent>
        );
    }
}

export default (AddEducationModal);
