import UserTag from "../components/tags/UserTag";
import NonProfitTag from "../components/tags/NonProfitTag";
import Hashtag from "../components/tags/Hashtag";
import { convertToRaw} from 'draft-js';

export const LINKS_REGEX = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
export const HASHTAG_REGEX = /(^|\s)(#[a-z\d-]+)/gi;


export const contentToJSX = (
    content,
    timestamp,
    openProfileModal,
    openStockModal,
    openPositionsModal,
    navigateToChannel,
    toggleImageModal,
    onMessageSelected,
    setLockScreen,
    isScrolling,
    numberOfLines,
    isGreyed,
    isSmall,
    isOwnMessage
    ) => {
        const contentArray = [];
        if (!content) {
            return [];
        }
        let previousBlockWasOrderedList = false;
        let currentListNumber = 1;
        for (const block of content.blocks) {
            const blockText = block.text;
            const blockArray = [];
            let style;
            switch (block.type) {
                // case 'header-one':
                //     style = {...styles.longFormHeading};
                //     break;
                // case 'header-two':
                //     style = {...styles.longFormSubheading};
                //     break;
                // case 'blockquote':
                //     style = {
                //         fontStyle: 'italic',
                //         borderTopWidth: 1,
                //         borderTopColor: baseWhite20,
                //         borderBottomWidth: 1,
                //         borderBottomColor: baseWhite20,
                //         ...styles.pv4, ...styles.baseWhite80,

                //     };
                //     break;
                case 'unstyled':
                case 'unordered-list-item':
                case 'ordered-list-item':
                default:
                    // style = {
                    //     ...(isGreyed ? baseWhite100 : baseWhite80),
                    //     ...(isSmall ? styles.body3Medium : styles.body1Medium),
                    //     ...(isGreyed ? {opacity: 0.7} : {}),
                    //     ...(isOwnMessage ? styles.baseWhite100 : styles.baseBlack70),
                    //     // backgroundColor:'red'
                    // };
            }
            const inlineStyles = {
                0: {styles: {}},
                [blockText.length]: {styles: {}},
            };
            if (block.inlineStyleRanges) {
                for (const inlineStyleRange of block.inlineStyleRanges) {
                    const start = inlineStyleRange.offset,
                        end = inlineStyleRange.offset + inlineStyleRange.length,
                        type = inlineStyleRange.style;
                    if (!inlineStyles[start]) {
                        inlineStyles[start] = {
                            styles: {},
                        };
                    }
                    if (!inlineStyles[end]) {
                        inlineStyles[end] = {
                            styles: {},
                        };
                    }
                    inlineStyles[start].styles[type] = true;
                    inlineStyles[end].styles[type] = false;
                }
            }
            if (block.entityRanges) {
                for (const entityRange of block.entityRanges) {
                    const start = entityRange.offset,
                        end = entityRange.offset + entityRange.length;
                    if (!inlineStyles[start]) {
                        inlineStyles[start] = {};
                    }
                    if (!inlineStyles[end]) {
                        inlineStyles[end] = {
                            styles: {},
                        };
                    }
                    inlineStyles[entityRange.offset].entity = {
                        ...content.entityMap[entityRange.key],
                        offset: entityRange.offset,
                        length: entityRange.length,
                    };

                }
            }
            const styleChangeIndexes = Object.keys(inlineStyles).map(i => parseInt(i)).sort((a, b) => a - b);
            const currentStyle = {};
            for (let i = 0; i < styleChangeIndexes.length; i++) {
                let index = styleChangeIndexes[i];
                const {entity, styles} = inlineStyles[index];
                if (entity) {
                    if (entity.type === 'LINK') {
                        blockArray.push(
                            <a key={block.key + i} href={entity.data.url} target={'_blank'}>
                                <span key={block.key + i}
                                      className='bg-transparent hover:bg-blue-50 rounded-md text-blue-500'
                                >
                                    {blockText.slice(entity.offset, entity.offset + entity.length)}
                                </span>
                            </a>
                        );
                    } else if (entity.type === 'hashtag') {
                        blockArray.push(
                            <Hashtag key={block.key + i} hashtag={entity.data.hashtag}/>
                        )
                    } else if (entity.type.includes('mention')) {
                        if (entity.data.mention.type === 'userTag') {
                            blockArray.push(
                                <UserTag key={block.key + i}
                                         userId={entity.data.mention.userID}
                                         name={entity.data.mention.name}
                                         isSmall={isSmall}
                                />
                            );
                        } else if (entity.data.mention.type === 'nonProfitTag') {
                            blockArray.push(
                                <NonProfitTag key={block.key + i}
                                              name={entity.data.mention.name}
                                              ein={entity.data.mention.id}
                                />
                            );
                        } else if (entity.data.mention.type === 'hashtag') {
                            const hashtagData = !!entity.data.hashtag ? entity.data.hashtag : entity.data.mention.hashtag;
                            blockArray.push(
                                <Hashtag key={block.key + i} hashtag={hashtagData}/>
                            );
                        }
                    }
                }
                if (styles) {
                    for (const [style, isOn] of Object.entries(styles)) {
                        if (isOn) {
                            switch (style) {
                                case 'BOLD':
                                    currentStyle.fontWeight = 'bold';
                                    break;
                                case 'ITALIC':
                                    currentStyle.fontStyle = 'italic';
                                    break;
                                case 'UNDERLINE':
                                    currentStyle.textDecorationLine = 'underline';
                                    break;
                                case 'EDITED-MESSAGE':
                                    currentStyle.color = 'white'
                                    blockArray.push(
                                        <p style={{...currentStyle}} key={block.key + i + 'edited'}>
                                            &nbsp;(edited)
                                        </p>
                                    )
                                    break;
                                default:
                                    console.log('Unexpected style:', style, content);
                            }
                        } else {
                            switch (style) {
                                case 'BOLD':
                                    delete currentStyle.fontWeight;
                                    break;
                                case 'ITALIC':
                                    delete currentStyle.fontStyle;
                                    break;
                                case 'UNDERLINE':
                                    delete currentStyle.textDecorationLine;
                                    break;
                                case 'EDITED-MESSAGE':
                                    delete currentStyle.color
                                    break;
                                default:
                                    console.log('Unexpected style:', style);
                            }
                        }
                    }
                    if (!entity) {
                        blockArray.push(
                            <span style={{...currentStyle}} key={block.key + i}>
                                {blockText.slice(index, styleChangeIndexes[i + 1])}
                            </span>,
                        );
                    }
                }
            }
            contentArray.push(
                <div key={timestamp+getRandomInt(10000)}>
                    {block.type === 'unordered-list-item' &&
                    <p style={style} numberOfLines={numberOfLines}>• </p>}
                    {block.type === 'ordered-list-item' &&
                    <p style={style} numberOfLines={numberOfLines}>{currentListNumber}. </p>}
                    {block.type === 'atomic' ?
                        blockArray
                        :
                        <p style={style} numberOfLines={numberOfLines}>{blockArray}</p>
                    }
                </div>
            );
            if (block.type === 'ordered-list-item') {
                currentListNumber += 1;
                previousBlockWasOrderedList = true;
            } else {
                previousBlockWasOrderedList = false;
                currentListNumber = 1;
            }
        }

        return contentArray;
    }
;


export const extractEntitiesFromEntityMap = entityMap => {
    const userTags = {};
    const stockTags = {};
    const channelTags = {};
    const links = [];
    if (entityMap) {
        Object.values(entityMap).forEach(entity => {
            console.log("entity", entity)
            // if (entity.get("type") === 'LINK') {
            //     links.push(entity.data.url)
            // } else if (entity.type.includes('mention')) {
            //     const type = entity.data.mention.type;
            //     switch (type) {
            //         case "stockTag":
            //             break;
            //         default:
            //             break;
            //     }
            // }
        })
    }
    const previewURL = links[0];
    return {userTags, stockTags, channelTags, previewURL, links}
}

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }