import React, {PureComponent} from "react";
import ModalComponent from "../ModalComponent";
import {Button} from "../atoms/Button";
import {connect} from "react-redux";
import LottieWrapper from "../atoms/LottieWrapper";
import lottie from '../../img/lottie/Check.json'

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
    }
}

class SuccessfullyJoinedModal extends PureComponent {
    state = {
        dismissed: false,
        shareClicked: false,
    }

    dismiss = () => {
        this.setState({dismissed: true})
    }

    handleShareClick = () => {
        navigator.clipboard.writeText(`https://starfishapp.com/authentication?activeTab=signup&ein=${this.props.np.ein}`); // Replace with actual link if needed
        this.setState({ shareClicked: true });
        setTimeout(() => {
            this.setState({ shareClicked: false });
        }, 4000);
    };

    render() {
        const { shareClicked } = this.state;
        const np = this.props.np;
        return (
            <ModalComponent toggle={this.props.close}
                            isOpen={this.props.isOpen && !this.state.dismissed}
                            noPadding
                            confetti
                            size={'md'}
                            FooterComponent={
                                <div className='flex flex-row items-center gap-3'>
                                    <Button onClick={this.dismiss} variant={'secondary'}>
                                        Close
                                    </Button>
                                </div>
                            }
            >
                <div className="flex flex-col px-5 pb-3 text-blue-500">
                    <p className='text-slate-900 text-2xl text-center font-bold mx-3 pt-6 pb-5'>
                        Thanks for joining Starfish!
                    </p>
                    <div className='hue-rotate-[220deg]'>
                        <LottieWrapper
                            json={lottie}
                            loop={false}
                            width={120}
                            height={120}
                        />
                    </div>
                    <div className='flex flex-row mx-auto mb-5'>
                        <p className='text-4xl text-center font-bold'>
                            $1 donated
                        </p>
                    </div>
                    <p className='text-xl text-slate-700 font-semibold mx-auto mb-3 text-center'>
                        We've donated on <span className='font-black'>your behalf</span> to the <span className='text-orange-400'>{!!np.name ? np.name : "ASPCA"}</span>, just because you joined!
                    </p>
                    <div className='ph-6 py-4 bg-blue-500/10 text-center rounded-xl mt-3'>
                        <p className='text-blue-500 font-bold px-3'>
                            Want to spread the word? Share Starfish with friends and we'll donate $1 for every single
                            person that joins!
                        </p>
                        <Button
                            size="sm"
                            className="mt-3"
                            variant={shareClicked ? 'green'  :'default'}
                            onClick={this.handleShareClick}
                        >
                            {shareClicked ? 'Link copied. Paste anywhere to share.' : 'Share to donate more'}
                        </Button>
                    </div>
                </div>
            </ModalComponent>
        );
    }
}

export default connect(mapStateToProps)(SuccessfullyJoinedModal);
