import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            width="48px"
            height="48px"
            {...props}
        >
            <path fill={'currentColor'} d="M31.996 12.037a7.973 7.973 0 00-3.166.652l-7.75 3.34 5.14 2.14 4.2-1.81c.5-.22 1.04-.33 1.58-.33s1.08.11 1.58.33l21.305 9.182a.5.5 0 010 .918L33.58 35.641c-1 .43-2.16.43-3.16 0L9.115 26.459a.5.5 0 010-.918l8.153-3.512 13.962 5.817a1.999 1.999 0 101.54-3.691l-10.391-4.33.012-.005-5.141-2.14-10.78 4.65A3.988 3.988 0 004.05 26c0 1.6.95 3.04 2.42 3.67L13 32.484v17.028c-.604.456-1 1.172-1 1.988v4a2.5 2.5 0 002.5 2.5h1a2.5 2.5 0 002.5-2.5v-4c0-.816-.396-1.532-1-1.988v-3.774C19.811 47.755 24.58 50 32 50c9.44 0 14.64-3.642 16.898-5.813C50.234 42.906 51 41.083 51 39.187v-6.702l6.53-2.814A3.988 3.988 0 0059.95 26c0-1.6-.95-3.04-2.42-3.67l-22.36-9.64a8.028 8.028 0 00-3.174-.653zM17 34.21l11.83 5.102c1.01.44 2.09.65 3.17.65s2.16-.21 3.17-.65L47 34.209v4.977c0 .813-.318 1.584-.873 2.117C44.302 43.057 40.039 46 32 46c-8.039 0-12.302-2.943-14.127-4.697-.555-.533-.873-1.304-.873-2.117v-4.977z" />
        </svg>
    )
}

export default SvgComponent
