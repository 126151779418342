import React, {useEffect, useState} from "react";
import {addRemoveGroupMember} from "../../api/messaging";
import { requestGroup } from "../../api/groups";
import {CARD_STYLE} from "../../utils/dummy";
import ProfilePicture from "../atoms/ProfilePicture";
import moment from "moment";
import {Button} from "../atoms/Button";
import GroupPicture from "../atoms/GroupPicture";
import GroupPrivacyIndicator from "../atoms/GroupPrivacyIndicator";
import ConfirmationModal from "../modals/ConfirmationModal";
import lottie from '../../img/lottie/Check.json'
import {connect} from "react-redux";

function mapDispatchToProps(dispatch) {
    return {
        toggleGroupPreviewModal: (groupPreviewID) => {
            dispatch({
                type: "TOGGLE_GROUP_PREVIEW_MODAL",
                groupPreviewID,
            })
        },
    }
}

function GroupSearchResultItem({group, user, ...props}) {
    const [showModal, setShowModal] = useState(false);
    const [stateJoined, setJoined] = useState(false);
    
    const toggleInvited = () => {
        setJoined(true);
        joinGroup();
    }

    const joinGroup = async () => {
        await addRemoveGroupMember(user, group.id, user.username, `${user.first_name} ${user.last_name}`);
    }

    const requestToJoin = async () => {
        setShowModal(true);
        await requestGroup(user, group.id, user.username, `${user.first_name} ${user.last_name}`);
    }

    const handleCloseModal = () => {
        setShowModal(false);
        window.location.reload();
    };

    const creatorName = group?.memberNames[group?.creator];
    const joined = group?.members.includes(user.username);
    const requested = user.username in (group.requests || {});
    return (
        <div
            className={`${CARD_STYLE} p-2 flex flex-col`}
            key={group.image_id}
        >
            <div className='flex flex-row items-center justify-between'>
                <div className='flex flex-row items-center gap-3'>
                    <GroupPicture
                        size={'2xl'}
                        imageId={group.image_id}
                    />
                    <div className='flex-1 flex flex-col'>
                        <p className={`text-lg font-semibold text-slate-800}`}>
                            {group.name}
                        </p>
                        <div className='flex flex-row items-center'>
                            <p className='text-sm font-medium text-slate-700 mr-1'>
                                Created by
                            </p>
                            <ProfilePicture userID={group.creator} size={14}/>
                            <p className='text-sm font-medium -ml-1 text-slate-700'>
                                {creatorName}
                            </p>
                        </div>
                    </div>
                </div>

                <div className='flex flex-row items-center gap-3'>
                    <Button
                        onClick={() => props.toggleGroupPreviewModal(group.id)}
                        size={'sm'} variant={'secondary'}>
                        Preview
                    </Button>
                    {!group.private ?
                        <Button size={'sm'} variant={joined || stateJoined ? 'secondary' : 'default'} disabled={joined || stateJoined}
                                onClick={toggleInvited}>
                            {joined || stateJoined? 'Joined' : 'Join'}
                        </Button>
                        :
                        <Button size={'sm'} variant={joined || requested ? 'secondary' : 'default'}
                                disabled={joined || requested}
                                onClick={requestToJoin}>
                            {joined ? 'Joined' : (requested ? 'Requested' : 'Request')}
                        </Button>
                    }
                </div>
            </div>
            <p className='text-md font-medium text-slate-700 mt-3'>
                {group.description}
            </p>
            <div className='flex flex-row  items-center gap-2 text-sm mt-2 font-semibold text-slate-500'>
                <GroupPrivacyIndicator group={group}/>
                <div className='flex flex-row  items-center gap-2 hover:text-blue-500'>
                    {/* <SvgShare2 className={'w-5 h-5'}/> */}
                    <p>
                        Active {moment(group.recentMessage?.timestamp * 1000).fromNow()}
                    </p>
                </div>
                <p>
                    • &nbsp;{Object.keys(group?.members).length} member{group.members.length !== 1 ? "s" : ""}
                </p>
            </div>
            <ConfirmationModal
                isOpen={showModal}
                lottie={lottie}
                title={`You've requested to join ${group.name}!`}
                description={"The owner of the group needs to accept you in order for you to join. You'll be notified if you are!"}
                hideConfirm
                cancelText={'Got it!'}
                close={handleCloseModal}
            />
        </div>
    )
}

export default connect(null, mapDispatchToProps)(GroupSearchResultItem)
