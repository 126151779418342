import React, {Component} from "react";
import NPOPicture from "../atoms/NPOPicture";
import {Button} from "../atoms/Button";
import SvgArrowForward from "../icons/ArrowForward";
import SvgCalendar from "../icons/SvgCalendar";
import SvgPin from "../icons/SvgPin";
import SvgSchool from "../icons/categories/SvgSchool";
import SvgStudy from "../icons/SvgStudy";
import SvgEditNote from "../icons/SvgEditNote";
import SvgTrash from "../icons/SvgTrash";

class ProfileExperienceItem extends Component {
    formatDate = (input) => {
        if (!input) return "Present";

        const { day, month, year } = input;
        const months = [
            "Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.", 
            "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."
        ];
        
        return `${months[month - 1]} ${year}`;
    }

    render() {
        const item = this.props.item
        const index = this.props.index
        return (
            <div
                className={`flex flex-col gap-3 border-b border-slate-200 last:border-transparent last:pb-0 py-5`}
                key={item.company}
                style={{}}
                onError={(e) => {
                    e.target.src =
                        "https://handshake-production-cdn.joinhandshake.com/static_assets/default_institution_1.png";
                    this.setState({imageError: true});
                }}
                >
                <div className='flex flex-row flex-1 items-center justify-between gap-2'>
                    <NPOPicture
                        logoUrl={item.logo_url}
                        np={item}
                        size={64}
                        className={'border min-w-[64px] border-slate-200'}
                    />
                    <div className='col-plain flex flex-1 truncate' style={{textAlign: "left"}}>
                        <p className='text-xl font-semibold'>
                            {item.company}
                        </p>
                        {!!item.title &&
                            <div className='flex flex-row items-center text-slate-500'>
                                <SvgStudy className={'w-5 h-5'}/>
                                <p className='font-semibold ml-4 text-lg'>
                                    {item.title}
                                </p>
                            </div>
                        }
                        {!!item.location && 
                            <div className='flex flex-row items-center gap-2 text-slate-500'>
                            <SvgPin className={'w-4 h-4'}/>
                            <p className='font-semibold text-md truncate'>
                                {item.location}
                            </p>
                        </div>
                        }
                    </div>
                    <div className='flex flex-row items-center gap-3'>
                        {this.props.editing &&
                            <Button size={'icon'} variant={'destructive'} onClick={() => this.props.onClickDelete(index)}>
                                <SvgTrash/>
                            </Button>
                        }
                    </div>
                </div>
                <div className='flex flex-col flex-1 gap-1'>
                    {!!item.starts_at &&
                        <div className="flex flex-row gap-5">
                            <div className='flex flex-row items-center text-slate-500'>
                                <SvgCalendar className={'w-5 h-5'}/>
                                <p className='font-semibold ml-4'>
                                    {this.formatDate(item.starts_at)} - {this.formatDate(item.ends_at)}
                                </p>
                            </div>
                        </div>
                    }
                    <p className='text-slate-700 w-full mt-1 text-lg'>
                        {item.description}
                    </p>
                </div>
            </div>
        )
    }
}

export default ProfileExperienceItem;
