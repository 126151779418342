import React, {Component} from "react";
import Section1 from "./Main/Section1";
import Section2 from "./Main/Section2";
import Section3 from "./Main/Section3";
import HeroSection from "./Main/HeroSection";
import Section4 from "./Main/Section4";
import {connect} from "react-redux";
import ChallengeCTASection from "./Main/ChallengeCTASection";


function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        mobile: state.userReducer.mobile,
    }
}

class Main extends Component {

    render() {
        return (
            <div className='col-plain page-wrapper'>
                <HeroSection mobile={this.props.mobile}/>
                <ChallengeCTASection mobile={this.props.mobile}/>
                <Section1 mobile={this.props.mobile}/>
                <Section4 mobile={this.props.mobile}/>
                <Section3 mobile={this.props.mobile}/>
                <Section2 mobile={this.props.mobile}/>
            </div>
        )
    }

}


export default connect(mapStateToProps)(Main);
