import React, {Component} from "react";
import {withRouter} from "react-router-dom";


class UserTag extends Component {

    onClick = (e) => {
        this.props.history.push({pathname: `/profile/${this.props.userId}`, state: {previous: "Back"}})
        e.stopPropagation()
    };

    render() {

        return (
            <div onClick={this.onClick}
                 className='inline-flex bg-blue-50 hover:bg-blue-100 px-1 rounded-md text-blue-500'
            >
                <p className='font-semibold'>
                    {this.props.name}
                </p>
            </div>
        );
    }
}


export default withRouter(UserTag);
