import React, {useState} from 'react';
import TextInput from "../../components/inputs/TextInput";
import OnboardingStepHeader from "./OnboardingStepHeader";
import OnboardingButtons from "./OnboardingButtons";

const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
        const part1 = match[1];
        const part2 = match[2] ? `-${match[2]}` : "";
        const part3 = match[3] ? `-${match[3]}` : "";
        return `${part1}${part2}${part3}`;
    }
    return phoneNumber;
};


function OnboardingPhone({goNextStep, goBackStep, phoneNumber, setPhoneNumber}) {
    const [errorMessage, setErrorMessage] = useState('');

    const handlePhoneNumberChange = (event) => {
        if (errorMessage) setErrorMessage('');
        const formattedPhoneNumber = formatPhoneNumber(event.target.value);
        if (formattedPhoneNumber.length < 13) {
            setPhoneNumber(formattedPhoneNumber);
        }
    };

    const validateForm = () => {
        if (!phoneNumber) {
            setErrorMessage('Please enter a phone number.');
            return false;
        }
        return true;
    };

    const handleSubmit = () => {
        if (validateForm()) {
            goNextStep();
        }
    };

    return (
        <div className='flex flex-col gap-8  md:w-[460px]'>
            <OnboardingStepHeader
                title={"What's your phone number?"}
                description={'Enter your number to make it easier to find friends'}
            />
            <div className='flex flex-col gap-5'>
                <TextInput
                    label={'Your phone'}
                    value={phoneNumber}
                    placeholder={'123-456-7890'}
                    onChange={handlePhoneNumberChange}
                />
                <p className={`text-xs h-4 ${errorMessage ? 'text-red-400' : 'text-transparent'}`}>
                    {errorMessage}
                </p>
            </div>
            <OnboardingButtons
                goNextStep={handleSubmit}
                goBackStep={goBackStep}
                nextDisabled={phoneNumber.length < 12}
            />
        </div>
    );
}

export default OnboardingPhone;
