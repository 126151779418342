import React, {PureComponent} from "react";
import {connect} from "react-redux";
import { editUser } from "../../api/users";
import ModalComponent from "../ModalComponent";
import {Button} from "../atoms/Button";
import TextInput from "../inputs/TextInput";


function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
    }
}

class EditPurposeModal extends PureComponent {
    state = {
        purpose: ""
    }

    handleInputChange = (e) => {
        this.setState({purpose: e.target.value})
    }

    componentDidMount = () => {
        this.setState({purpose: this.props.user?.bio});
    }

    submit = async () => {
        const newUser = {...this.props.user};
        newUser.bio = this.state.purpose
        this.props.setUser(newUser);

        await editUser(this.props.authUser, {bio: this.state.purpose});
        this.props.close()
    }

    render() {
        return (
            <ModalComponent toggle={this.props.close}
                            isOpen={this.props.isOpen}
                            noPadding
                            size={'md'}
                            header={'Edit your purpose'}
                            headerToggle
                            FooterComponent={
                                <div className='flex flex-row items-center gap-3'>
                                    <Button variant={'secondary'} onClick={this.props.close}>
                                        Cancel
                                    </Button>
                                    <Button variant={'default'} onClick={this.submit}>
                                        Save Changes
                                    </Button>
                                </div>
                            }
            >
                <div className="flex flex-col px-5 pb-3">
                    <TextInput
                        label={'Purpose'}
                        value={this.state.purpose}
                        onChange={this.handleInputChange}
                        placeholder={'Student - Univ. of Michigan. I care about my school, helping animals get adopted, my faith, keeping the environment clean, and healthcare for children.'}
                        textarea
                    />
                </div>
            </ModalComponent>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPurposeModal);
