import axios from 'axios';

export const bunnyCDNKey = "036a7815-ec99-4063-8c5ad8204e1c-d097-4ffa";

export const uploadVideo = async (file, video_id) => {
    const config = {
        headers: {
        AccessKey: bunnyCDNKey,
        'Content-Type': 'application/octet-stream'
        }
    };

    const response = await axios.put(
        `https://la.storage.bunnycdn.com/starfish-app-video/${video_id}.mp4`,
        file,
        config
    );

    console.log("VIDEO", `https://starfish-app.b-cdn.net/${video_id}.mp4)`, response.status);
    return `https://starfish-app.b-cdn.net/${video_id}.mp4`;

};