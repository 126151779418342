import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            width="48px"
            height="48px"
            {...props}
        >
            <path fill={'currentColor'} d="M25 11c-4.881 0-8.866 3.906-8.996 8.756A9.465 9.465 0 0012 27.5c0 1.079.187 2.15.543 3.162A10.485 10.485 0 0010 37.5C10 43.29 14.71 48 20.5 48h2.152l-.152 4.4a2.502 2.502 0 105 0l-.646-18.707 3.503-6.68a1.001 1.001 0 00-1.714-1.029l-1.957 2.877L26.5 23.5a1.501 1.501 0 00-3 0l-.158 4.563-2.069-2.196a1.001 1.001 0 00-1.546 1.266l3.478 4.918-.166 4.756-2.69-1.543a1.505 1.505 0 00-1.7 2.473l4.24 3.44L22.79 44H20.5a6.508 6.508 0 01-6.5-6.5c0-1.872.813-3.656 2.234-4.895.708-.617.89-1.644.44-2.468A5.483 5.483 0 0116 27.5c0-2.059 1.14-3.93 2.977-4.883a1.998 1.998 0 001.064-2.013A5.212 5.212 0 0120 20c0-2.757 2.243-5 5-5 2.922 0 5.141 2.501 4.988 5.191a2 2 0 001.502 2.004 5.982 5.982 0 014.489 5.547l-5.875 17.625A2 2 0 0032 48h8.762l-.262 4.4c0 .09-.01.2 0 .3a2.506 2.506 0 005-.299L45.238 48H54a2.003 2.003 0 001.896-2.633l-11-33a1.998 1.998 0 00-3.792 0l-3.18 9.54a9.976 9.976 0 00-4.002-3.098C33.337 14.407 29.559 11 25 11zm18 8.324L51.225 44H34.773L43 19.324z" />
        </svg>
    )
}

export default SvgComponent
