import React, {PureComponent} from 'react';


class LinkPreview extends PureComponent {
    state = {
        imageError: false,
    }
    render() {
        let short_url = this.props.url;
        if (!!short_url) {
            short_url = short_url.replace('https://', '');
            const n = short_url.indexOf('/');
            short_url = short_url.slice(0, n);
        }

        return (
            <a href={this.props.url} target={'_blank'}>
                <div className='flex flex-row items-center gap-3 p-3 border-slate-200 border rounded-lg hover:bg-slate-50 hover:border-slate-400 cursor-pointer'>
                    <img
                        onError={() => this.setState({imageError: true})}
                        src={!!this.props.image && !this.state.imageError ? this.props.image : "https://handshake-production-cdn.joinhandshake.com/static_assets/default_institution_1.png"} className='w-20 h-20 aspect-square bg-slate-50 border border-slate-200 object-contain rounded-lg'/>
                    <div className='flex flex-col'>
                        <p className='text-md font-sm text-slate-500'>
                            {short_url}
                        </p>
                        <p className='text-md font-medium text-slate-800'>
                            {this.props.title}
                        </p>
                    </div>
                </div>
            </a>
        );
    }
}

export default LinkPreview;
