import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-award"
      {...props}
    >
      <circle cx={12} cy={8} r={7} />
      <path d="M8.21 13.89L7 23 12 20 17 23 15.79 13.88" />
    </svg>
  )
}

export default SvgComponent