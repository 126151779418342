import React from "react";
import GroupPicture from "../atoms/GroupPicture";
import {Button} from "../atoms/Button";

const GroupChatInitialContent = (props) => {
    return (
        <div className={`flex flex-col text-center items-center gap-3 px-5 pb-5 mb-5 mt-[80px] mb-[120px] ${!props.hasNoMessages && 'border-t border-slate-200'}`}>
            <GroupPicture
                size={'3xl'}
                className="mt-20"
                imageId={props.group.image_id}
            />
            {props.hasNoMessages ?
                <p className='text-2xl font-medium font-semibold'>
                    Welcome to the start of<br/> {props.group.name}
                </p>
                :
                <p className='text-2xl font-medium font-semibold'>
                    You've reached the end of<br/> {props.group.name}
                </p>
            }
            <p className='text-slate-500 text-lg'>
                Don’t be shy! Invite some friends to the group and spark the conversation.
            </p>
            <div className='flex flex-row gap-3'>
                <Button variant={'secondary'} onClick={props.toggleInviteToGroupModal}>
                    Invite Members
                </Button>
                <Button variant={'secondary'} onClick={props.toggleNonprofitSearchModal}>
                   Link a Nonprofit to support
                </Button>
            </div>
        </div>
    );
}

export default GroupChatInitialContent;
