import React from 'react';
import SvgCheck from "../icons/SvgCheck";

const AccessibleToggle = ({label, value, onToggle, hideCheck, subLabel}) => {
    return (
        <div className="flex items-center  gap-3">
            <button
                role="switch"
                aria-checked={value}
                onClick={() => onToggle(!value)}
                className={`relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 ${
                    value ? 'bg-green-500' : 'bg-slate-200'
                }`}
            >
        <span
            aria-hidden="true"
            className={`inline-block h-5 w-5 rounded-full mt-0.5 bg-white shadow transform ring-0 transition ease-in-out duration-200 ${
                value ? 'translate-x-5' : 'translate-x-1'
            }`}
        />
            </button>
            <div>
                {!!label &&
                    <p className="font-medium text-slate-700">{label}</p>
                }
                {!!subLabel &&
                    <p className="font-medium text-xs text-slate-500">{subLabel}</p>
                }
            </div>
            {(value && !hideCheck) && <SvgCheck className={'w-5 h-5 -ml-2 text-green-500'}/>}
        </div>
    );
};

export default AccessibleToggle;
