import React, {Component} from "react";
import Spinner from "../Spinner";
import ModalComponent from "../ModalComponent";
import PostComponent from "../posts/PostComponent";
import {Button} from "../atoms/Button";
import PostEditor from "../posts/PostEditor";
import { createPost } from '../../api/posts';
import { connect } from 'react-redux';
import { convertToRaw } from "draft-js";

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser
    }
}

class RepostModal extends Component {
    state = {
        comment:'',
        content: '',
    }

    commentChanged = e => this.setState({comment: e.target.value});

    handleRepost = async () => {
        const user = this.props.authUser;
        const creator = this.props.user?.username;

        const content = convertToRaw(this.state.content);
        const plaintext = content.blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

        const users = []
        const nonProfits = []
        const hashtags = [];
        let url = null

        for (const entity of Object.values(content.entityMap)) {
            if (entity.data.mention?.type === "userTag") users.push(entity.data.mention.id);
            else if (entity.data.mention?.type === "nonProfitTag") nonProfits.push(entity.data.mention.id);
            else if (entity.data.mention?.type === "hashtag") hashtags.push(entity.data.mention.hashtag);
            else if (entity.type === "LINK") url = entity.data.url;
        }

        const imageUrls = [];
        const videos = [];
        const originalPostId = this.props.post.post_ID;
        await createPost(user, creator, content, imageUrls, url, users, nonProfits, hashtags, plaintext, videos, originalPostId);
        this.setState({comment: ""});
        this.props.closeModal();
    }

    contentChanged = (content) => {
        this.setState({content});
    }

    render() {
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                size={'lg'}
                toggle={this.props.closeModal}
                header={'Repost this post'}
                headerToggle
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                      <Button onClick={this.props.closeModal} variant={'secondary'}>
                          Cancel
                      </Button>
                        <Button 
                            onClick={this.handleRepost}
                            disabled={this.state.content.length === 0} variant={'default'}
                        >
                            Repost
                        </Button>
                    </div>
                }
            >
               <div className='pointer-events-none p-3 bg-slate-50 border border-slate-200 rounded-xl mb-3'>
                   <PostComponent
                       post={this.props.post}
                       noBorder
                       hidePostInteractions
                   />
               </div>
                {/* <TextInput
                    label={'Your comments'}
                    value={this.state.comment}
                    onChange={this.commentChanged}
                    placeholder={'Add your thoughts to repost...'}
                    textarea
                /> */}
                <PostEditor
                    onChange={this.contentChanged}
                    placeholder={"Add your thoughts to repost..."}
                />
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps)(RepostModal);
