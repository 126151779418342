import React, {PureComponent} from "react";

class TextInput extends PureComponent {
    render() {
        if (this.props.textarea) {
            return (
                <div className='flex flex-col gap-1'>
                    <p className='text-md text-slate-500'>
                        {this.props.label}
                    </p>
                    <textarea
                        placeholder={this.props.placeholder}
                        value={this.props.value}
                        onChange={this.props.onChange}
                        className={`min-h-16
                        placeholder:text-slate-400
                        border-[1px] text-3-400 rounded-2xl p-4 outline-0 border-slate-300 focus:border-blue-500 text-2-400 text-gray-900 ${this.props.className}`}/>
                </div>
            )
        }
        return (
            <div className={`flex flex-col gap-1 ${this.props.className}`}>
                <p className='text-md text-slate-500'>
                    {this.props.label}
                </p>
                <input
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    type={this.props.type}
                    className='border-[1px] text-3-400 rounded-full py-3 px-5 outline-0 border-slate-300 focus:border-blue-500 text-2-400 text-gray-900'/>
            </div>
        )
    }
}

export default TextInput
