import React, {Component} from "react";
import {withRouter} from "react-router-dom";


class NonProfitTag extends Component {

    onClick = (e) => {
        this.props.history.push({pathname: `/non-profit/${this.props.ein}`, state: {previous: "Back"}})
        e.stopPropagation()
    };

    render() {

        return (
            <span onClick={this.onClick}
                  className='inline-flex bg-orange-50 hover:bg-orange-100 px-1 rounded-md text-orange-400'
            >
                    <p className='font-semibold'>
                        {this.props.name}
                    </p>
                </span>
        );
    }
}


export default withRouter(NonProfitTag);
