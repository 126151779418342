import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            width="48px"
            height="48px"
            {...props}
        >
            <path fill={'currentColor'} d="M19.5 9a4.5 4.5 0 100 9 4.5 4.5 0 000-9zm27.008 0c-2.021 0-3.706 1.42-4.18 3.559-.25 1.192-.646 2.616-1.193 3.916-.248.59.13 1.258.76 1.367 1.22.21 7.987.21 9.21 0 .63-.11 1.01-.777.762-1.367-.516-1.224-.891-2.553-1.144-3.7-.346-2.144-2.1-3.774-4.215-3.775zm.414 11A5.923 5.923 0 0041 25.922v27.164a1.847 1.847 0 003.693.092l.34-7.178h2.934l.34 7.178A1.848 1.848 0 0052 53.086V46h1.67a2 2 0 001.976-2.305L52.773 25.02A5.92 5.92 0 0046.922 20zm-27.422.1c-4.706 0-7.5 3.334-7.5 5.834v10.398c0 .921.747 1.668 1.668 1.668h.436v15.086a1.847 1.847 0 003.693.092L18.516 38h1.968l.72 15.178a1.848 1.848 0 003.692-.092V38h.436c.921 0 1.668-.747 1.668-1.668V25.934c0-2.5-2.794-5.834-7.5-5.834zm14 6.9a3 3 0 100 6 3 3 0 000-6zm0 7.305c-2.819 0-4.492 1.997-4.492 3.494v7.203c0 .551.447.996.998.996h.094v7.772a1.164 1.164 0 002.328.056l.37-7.828h1.403l.371 7.828a1.165 1.165 0 002.326-.056v-7.772h.096a.997.997 0 00.998-.996v-7.203c0-1.497-1.673-3.494-4.492-3.494z" />
        </svg>
    )
}

export default SvgComponent
