import React from 'react';
import {connect} from 'react-redux';
import {Button} from '../../components/atoms/Button';
import SvgClose from '../../components/icons/SvgClose';
import GroupPreviewPage from "../GroupPreviewPage";

function mapDispatchToProps(dispatch) {
    return {
        toggleGroupPreviewModal: (groupPreviewID) => {
            dispatch({
                type: "TOGGLE_GROUP_PREVIEW_MODAL",
                groupPreviewID,
            })
        },
    }
}

function mapStateToProps(state) {
    return {
        groupPreviewID: state.userReducer.groupPreviewID,
    }
}

const GroupPreviewSheet = (props) => {


    const closeSheet = () => {
        props.toggleGroupPreviewModal(null);
    };

    return (
        <div
            className={`absolute ${props.groupPreviewID !== null ? 'h-full overflow-y-scroll' : 'h-0 overflow-hidden'} transition-all left-0 right-0 bottom-0 bg-white z-[10]`}>
            <div className={`flex flex-col flex-1 h-full`}>
                <div className='flex flex-row items-center justify-between border-b border-slate-200 pb-3 p-5'>
                    <p className='text-2xl font-semibold text-slate-800'>
                        Group Preview
                    </p>
                    <Button onClick={closeSheet} variant={'secondary'} size={'icon'} radius={'full'}>
                        <SvgClose/>
                    </Button>
                </div>
                {!!props.groupPreviewID &&
                    <GroupPreviewPage groupId={props.groupPreviewID} isSheet/>
                }
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupPreviewSheet);
