import pp1 from '../img/profilepic.png'
import cover1 from '../img/profilecover.jpg'
import feedingamerica from '../img/feedingamerica.png'
import coral from '../img/coral.png'
import cover2 from '../img/cover2.png'
import pp2 from '../img/pp2.png'
import wwf from '../img/wwf.png'
import jungle from '../img/jungle.jpg'
import SvgLock from '../components/icons/SvgLock'
import usccover from '../img/usccover.png'
import feedingtb from '../img/feedingtb.png'
import SvgHelp from '../components/icons/SvgHelp'
import SvgInbox from '../components/icons/SvgInbox'
import SvgExpand from "../components/icons/SvgExpand";
import aspca from '../img/brand/aspca-logo.jpeg'
import katie from '../img/katief.png'
import katiecover from '../img/people/cover-min.png'
import umich from '../img/umich.jpeg'
import Ceremony from "../components/icons/Ceremony";
import React from "react";
import Blueprint from "../components/icons/Blueprint";
import Todo from "../components/icons/Todo";
import SvgArts from "../components/icons/categories/SvgArts";
import SvgSchool from "../components/icons/categories/SvgSchool";
import SvgTrees from "../components/icons/categories/SvgTrees";
import SvgAnimals from "../components/icons/categories/SvgAnimals";
import SvgFamily from "../components/icons/categories/SvgFamily";
import SvgHealth from "../components/icons/categories/SvgHealth";
import SvgChurch from "../components/icons/categories/SvgChurch";
import SvgUserCards from "../components/icons/categories/SvgUserCards";
import SvgMedal from "../components/icons/categories/SvgMedal";
import SvgEarth from "../components/icons/categories/SvgEarth";


export const CARD_STYLE = 'bg-white cursor-pointer border border-slate-200 hover:border-slate-400 br-12 p-4 mb-12 shadow-xl shadow-slate-300/40'

export const CARD_STYLE_NO_HOVER = 'bg-white cursor-pointer border border-transparent br-12 p-4 mb-12 shadow-xl shadow-slate-300/40'

export const PROFILE_CAROUSEL_CONTENT = [
    {
        profile: {
            name: 'Katie Fenderson',
            bio: 'Student - Univ. of Michigan. I care about my school, helping animals get adopted, my faith, keeping the environment clean, and healthcare for children.',
            img: katie,
            cover: katiecover,
            location: "Chicago, IL",
            joined: 'Nov 22'
        },
        connections: [
            {
                name: 'ASPCA',
                category: 'Philanthropy',
                img: aspca,
                type: 'Volunteer',
                desc: "I love the work of the ASPCA as they come to the aid of all types of animals needing care.  I’m grateful to support their efforts by volunteering at animal shelters helping the ASPCA raise much-needed funds.",
                since: 2019
            }
        ]
    },
    {
        profile: {
            name: 'Nancy Stevenson',
            bio: 'Student at Columbia University. Passionate about combating hunger in America and around the world.',
            img: pp1,
            cover: cover1,
            location: "New York, NY",
            joined: 'Jan 23'
        },
        connections: [
            {
                name: 'Feeding America',
                category: 'Philanthropy',
                img: feedingamerica,
                type: 'Volunteer',
                desc: 'Proud volunteer since 2018. Met some of my closest friends through Feeding America and have participated in events across 4 different states so far.',
                since: 2018
            }
        ]
    },
    {
        profile: {
            name: 'Teddy Duncan',
            bio: 'Surfer kid, science geek, somewhat fast swimmer, animal rights advocate. | Brisbane High 2024',
            img: pp2,
            cover: cover2,
            location: "Brisbane, AUS",
            joined: 'Dec 22'
        },
        connections: [
            {
                name: 'Coral Reef Alliance',
                category: 'Philanthropy',
                img: coral,
                type: 'Supporter',
                desc: "Growing up in an ocean town, I've always had interest in our amazing reefs and tried my best to contribute to doing my part in making sure they stay for generations to come.",
                since: 2015
            }
        ]
    },
]

export const NPO_DUMMY_SLIDES = [
    {
        npo: {
            name: 'World Wildlife Fund',
            category: 'Philanthropy',
            img: wwf,
            cover: jungle,
            bio: 'The world\'s leading conservation organization. Operating in over 100 countries, the WWF aims to protect communities, wildlife, and the places they live.',
            location: 'International',
            interested: '3k',
        },
        posts: [
            {
                npo: true,
                img: wwf,
                name: 'World Wildlife Fund',
                id: 'npo1',
                likes: '800',
                comments: 368,
                reposts: 457,
                time: '32 mins ago'
            },
            {
                npo: false,
                img: pp2,
                name: 'Greg Tweedy',
                id: 'user1',
                likes: '668',
                comments: 87,
                reposts: 74,
                time: '5d ago'
            }
        ]
    },
    {
        npo: {
            name: 'University of Michigan',
            category: 'Philanthropy',
            img: umich,
            cover: usccover,
            bio: 'World-renowned public institution, fostering excellence for all. Go Wolverines!',
            location: 'Ann Arbor, MI',
            interested: '2k',
        },
        posts: [
            {
                npo: false,
                img: katie,
                name: 'Katie Fenderson',
                id: 'um',
                likes: '92',
                comments: 33,
                reposts: 14,
                time: '19 mins ago'
            },
        ]
    },
    {
        npo: {
            name: 'ASPCA',
            category: 'Philanthropy',
            img: aspca,
            cover: cover1,
            bio: 'The ASPCA works to rescue animals, pass humane laws, and share resources with shelters nationwide.',
            location: 'New York, NY',
            interested: '33k',
        },
        posts: [
            {
                npo: true,
                img: feedingtb,
                name: 'Feeding Tampa Bay',
                id: 'npo1',
                likes: '1.2k',
                comments: 368,
                reposts: 457,
                time: '32 mins ago'
            },
            {
                npo: false,
                img: katie,
                name: 'Katie Fenderson',
                id: 'aspca',
                likes: '12',
                comments: 5,
                reposts: 2,
                time: '3d ago'
            }
        ]
    },
]

export const TIMELINE = [
    {
        label: 'Where It Started',
        heading: 'Concept',
        description: 'When Starfish was conceived, the stated vision was to build a dynamic social media platform where all users could share their good persona. The platform would be safe, trusted and secure. An amazing team was assembled and the hard work began.',
        icon: <Blueprint fill={'#6A93C6'} width={44} height={44}/>,
        mobileIcon: <Blueprint fill={'#6A93C6'} width={34} height={34}/>,
    },
    {
        label: 'Where We Are',
        heading: 'Now',
        description: 'The Starfish platform has launched and users across America -- and in other countries -- are creating their personal profiles and sharing their stories of good. On Starfish, our members are able to search over 1.8 million US based nonprofit causes. Soon, Starfish Challenges for Good will connect individuals, institutions and influencers to expand their network of humanitarian interests. This powerful, proprietary feature enables users to safely donate funds directly from Starfish to their favorite nonprofit causes.',
        icon: <Ceremony fill={'#6A93C6'} width={48} height={48}/>,
        mobileIcon: <Ceremony fill={'#6A93C6'} width={38} height={38}/>
    },
    {
        label: "Where We're Going",
        heading: 'Future Goals',
        description: 'Our followers now have a place where their good can be seen, illuminated and promoted. Going forward, the growing Starfish community will actively generate content centered on good, enabling everyone to be a philanthropist by sharing their stories and actions. Giving back to the community is at the core of every act of good, whether it be contributing time, resources or talents to favorite causes. The massive Starfish footprint for good continues to grow.',
        icon: <Todo fill={'#6A93C6'} width={48} height={48}/>,
        mobileIcon: <Todo fill={'#6A93C6'} width={38} height={38}/>
    },
]

export const LAB_DETAILS = [
    {
        label: 'Why was this started?',
        heading: 'Creation',
        description: 'Starfish assembled the Lab to help guide the product development of Starfish Social Media. We want to work directly with non-profits and thought leaders who are excited about Starfish and wish to help guide its growth and direction.',
        icon: <Blueprint fill={'#6A93C6'} width={44} height={44}/>,
        mobileIcon: <Blueprint fill={'#6A93C6'} width={34} height={34}/>,
    },
    {
        label: 'What does joining Starfish Lab entail?',
        heading: 'Ideation',
        description: 'Joining the Lab is completely free. All we ask is that you be willing to periodically provide us feedback, feature requests, and to answer a few surveys. You’ll get to hear about some features before the marketplace does so getting your thoughts prior to us officially launching something will be extremely valuable. Of course, we always welcome members of the Lab that wish to proactively share their suggestions if they wish to!',
        icon: <Ceremony fill={'#6A93C6'} width={48} height={48}/>,
        mobileIcon: <Ceremony fill={'#6A93C6'} width={38} height={38}/>
    },
    {
        label: "How can I become a part of it?",
        heading: 'Join Now',
        description: 'You can apply to the Lab just by using the link below. After applying, you can expect to hear back from us within 24-48 hours. The only prerequisite is that you value illuminating good and making a difference.',
        icon: <Todo fill={'#6A93C6'} width={48} height={48}/>,
        mobileIcon: <Todo fill={'#6A93C6'} width={38} height={38}/>
    },
]

export const DOCUMENTS = [
    {
        title: 'About',
        icon: <SvgHelp size={20}/>,
        url: "https://www.starfishapp.com/"
    },
    {
        title: 'Terms',
        icon: <SvgInbox size={20}/>,
        url: "https://app.termly.io/document/terms-of-use-for-website/eaf25433-f52f-4673-ae16-e16370111fea"
    },
    {
        title: 'Privacy Policy',
        icon: <SvgLock size={20}/>,
        url: "https://app.termly.io/document/privacy-policy/3cc64057-0435-44b3-b613-35eea60b52a7"
    },
    {
        title: 'Cookie Policy',
        icon: <SvgExpand size={20}/>,
        url: "https://app.termly.io/document/cookie-policy/a7511792-e72f-4331-809a-a3c08dc38786"
    },
]


export const CHAT_LIST_DUMMY = [
    {
        "timestamp": 1690412143,
        "recentMessage": {
            "preview": "JJ Strachan I wonder what this does",
            "timestamp": 1702745573
        },
        "members": [
            "jack",
            "cory"
        ],
        "memberNames": {
            "jack": "Jack Phifer",
            "cory": "Cory O’Shea"
        },
        "id": "-NaJP_J417tHbA64Pr7B"
    },
    {
        "timestamp": 1687441377,
        "recentMessage": {
            "preview": "Hi. This is not auto correcting. ",
            "timestamp": 1701298046
        },
        "members": [
            "jason10",
            "cory"
        ],
        "memberNames": {
            "jason10": true,
            "cory": true
        },
        "id": "-NYYL-biBl4xojAMhfUs"
    },
    {
        "timestamp": 1690421965,
        "recentMessage": {
            "preview": "This thing still work?\n",
            "timestamp": 1701049441
        },
        "members": [
            "cory",
            "jackstra"
        ],
        "memberNames": {
            "cory": "Cory O’Shea",
            "jackstra": "Jack Strachan"
        },
        "id": "-NaK-2Hx0y9Q52Zd-0dS"
    },
    {
        "timestamp": 1698773654,
        "recentMessage": {
            "preview": "cory created the chat",
            "timestamp": 1698773654
        },
        "members": [
            "janines",
            "cory"
        ],
        "memberNames": {
            "janines": "Janine Sabin",
            "cory": "Cory O’Shea"
        },
        "id": "-Ni5nCndE3zkANcIEtu2"
    },
    {
        "timestamp": 1689882366,
        "recentMessage": {
            "preview": "k",
            "timestamp": 1692549292
        },
        "members": [
            "cory",
            "tommyhannan"
        ],
        "memberNames": {
            "cory": "Cory O’Shea",
            "tommyhannan": "Tommy Hannan"
        },
        "id": "-N_opdd-BmHDsbDTqIwC"
    },
    {
        "timestamp": 1691372106,
        "recentMessage": {
            "preview": "Hello 👋 Cory ",
            "timestamp": 1691372123
        },
        "members": [
            "morgan98",
            "cory"
        ],
        "memberNames": {
            "morgan98": "Alex Morgan",
            "cory": "Cory O’Shea"
        },
        "id": "-NbCcYqVS4Bm4odyrIe3"
    },
    {
        "timestamp": 1690933396,
        "recentMessage": {
            "preview": "Hello ",
            "timestamp": 1690933402
        },
        "members": [
            "charita86",
            "cory"
        ],
        "memberNames": {
            "charita86": "Alexander Charita",
            "cory": "Cory O’Shea"
        },
        "id": "-NanU-pKCQv8DUJPv1__"
    },
    {
        "timestamp": 1689813960,
        "recentMessage": {
            "preview": "I don’t think we have them set up",
            "timestamp": 1690209269
        },
        "members": [
            "jack",
            "cory"
        ],
        "memberNames": {
            "jack": "Jack Phifer",
            "cory": "Cory O’Shea"
        },
        "id": "-N_kkgWWSfI5x_yJSOJg"
    },
    {
        "timestamp": 1689882366,
        "recentMessage": {
            "preview": "cory created the chat",
            "timestamp": 1689882366
        },
        "members": [
            "cory",
            "tommyhannan"
        ],
        "memberNames": {
            "cory": "Cory O’Shea",
            "tommyhannan": "Tommy Hannan"
        },
        "id": "-N_opdVVnMpMT0L4LO0O"
    },
    {
        "timestamp": 1689882365,
        "recentMessage": {
            "preview": "cory created the chat",
            "timestamp": 1689882365
        },
        "members": [
            "cory",
            "tommyhannan"
        ],
        "memberNames": {
            "cory": "Cory O’Shea",
            "tommyhannan": "Tommy Hannan"
        },
        "id": "-N_opdSrdrTRNM7naxjs"
    },
    {
        "timestamp": 1689873753,
        "recentMessage": {
            "preview": "stevemott created the chat",
            "timestamp": 1689873753
        },
        "members": [
            "stevemott",
            "cory"
        ],
        "memberNames": {
            "stevemott": "Steve Mott",
            "cory": "Cory O’Shea"
        },
        "id": "-N_oJmOkSF7ob8ABRkeH"
    }
]


export const MESSAGES_DUMMY = [
    {
        "chatID": "-NaJP_J417tHbA64Pr7B",
        "timestamp": 1702745573,
        "content": {
            "blocks": [
                {
                    "key": "0pjs8",
                    "entityRanges": [
                        {
                            "key": 0,
                            "offset": 0,
                            "length": 11
                        }
                    ],
                    "type": "unstyled",
                    "depth": 0,
                    "text": "JJ Strachan I wonder what this does"
                }
            ],
            "entityMap": [
                {
                    "type": "mention",
                    "mutability": "IMMUTABLE",
                    "data": {
                        "mention": {
                            "userID": "abcdefghjdi",
                            "type": "userTag",
                            "name": "JJ Strachan"
                        }
                    }
                }
            ]
        },
        "creator": "cory",
        "id": "-NlnXsSOK9zIppto-TuV"
    },
    {
        "chatID": "-NaJP_J417tHbA64Pr7B",
        "timestamp": 1701790739,
        "content": {
            "blocks": [
                {
                    "key": "0c4kr",
                    "type": "unstyled",
                    "depth": 0,
                    "text": "We need notifications "
                }
            ]
        },
        "creator": "cory",
        "id": "-NkucTqJESIqQ5xzB7u1"
    },
    {
        "chatID": "-NaJP_J417tHbA64Pr7B",
        "timestamp": 1701787802,
        "content": {
            "blocks": [
                {
                    "key": "0zy6i",
                    "type": "unstyled",
                    "depth": 0,
                    "text": "This is botched"
                }
            ]
        },
        "creator": "jack",
        "id": "-NkuSGlFDoesBi6RM0yI"
    },
    {
        "chatID": "-NaJP_J417tHbA64Pr7B",
        "timestamp": 1701049431,
        "content": {
            "blocks": [
                {
                    "key": "0ji2e",
                    "type": "unstyled",
                    "depth": 0,
                    "text": "Tester\n"
                }
            ]
        },
        "creator": "cory",
        "id": "-NkDRbFz_V-ZBWEG7bda"
    },
    {
        "chatID": "-NaJP_J417tHbA64Pr7B",
        "timestamp": 1700574702,
        "content": {
            "blocks": [
                {
                    "key": "0l4n0",
                    "type": "unstyled",
                    "depth": 0,
                    "text": "Well that looks money"
                }
            ]
        },
        "creator": "cory",
        "id": "-Njm8epuNQHnS7_jQb48"
    },
    {
        "chatID": "-NaJP_J417tHbA64Pr7B",
        "timestamp": 1700574693,
        "content": {
            "blocks": [
                {
                    "key": "0eer8",
                    "entityRanges": [
                        {
                            "key": 0,
                            "offset": 11,
                            "length": 51
                        }
                    ],
                    "type": "unstyled",
                    "depth": 0,
                    "text": "Asdadsdasm Mckinley Elementary Parents as Educational Partners sodas test test"
                }
            ],
            "entityMap": [
                {
                    "type": "mention",
                    "mutability": "IMMUTABLE",
                    "data": {
                        "mention": {
                            "name": "Mckinley Elementary Parents as Educational Partners",
                            "type": "nonProfitTag",
                            "id": "87-2050769"
                        }
                    }
                }
            ]
        },
        "creator": "cory",
        "id": "-Njm8ccVRcDjMuPx1z8h"
    },
    {
        "chatID": "-NaJP_J417tHbA64Pr7B",
        "timestamp": 1700160166,
        "content": {
            "blocks": [
                {
                    "key": "0v6ag",
                    "type": "unstyled",
                    "depth": 0,
                    "text": "Testing this"
                }
            ]
        },
        "creator": "cory",
        "messageAttachment": {
            "id": "jbkF5jYcOMoL8cB48NeD",
            "type": "post"
        },
        "id": "-NjORKlLfMjAtCGxmyg4"
    },
    {
        "chatID": "-NaJP_J417tHbA64Pr7B",
        "timestamp": 1693313716,
        "content": {
            "blocks": [
                {
                    "key": "012d8",
                    "type": "unstyled",
                    "depth": 0,
                    "text": "Working"
                }
            ]
        },
        "creator": "cory",
        "id": "-Nd0MC-tq3moGtdSpCD6"
    },
    {
        "chatID": "-NaJP_J417tHbA64Pr7B",
        "timestamp": 1692549352,
        "content": {
            "blocks": [
                {
                    "key": "0mngw",
                    "type": "unstyled",
                    "depth": 0,
                    "text": "Hey buddy "
                }
            ]
        },
        "creator": "cory",
        "id": "-NcInNno7MLUIR0kDGnM"
    },
    {
        "chatID": "-NaJP_J417tHbA64Pr7B",
        "timestamp": 1691718153,
        "content": {
            "blocks": [
                {
                    "key": "00zz1",
                    "type": "unstyled",
                    "depth": 0,
                    "text": "Here we go"
                }
            ]
        },
        "creator": "jack",
        "messageAttachment": {
            "id": "4W9FKEDE33DQrSTvu7Fc",
            "type": "challenge"
        },
        "id": "-NbXFbGEqxU__D_RVXY6"
    },
    {
        "chatID": "-NaJP_J417tHbA64Pr7B",
        "timestamp": 1691717644,
        "content": {
            "blocks": [
                {
                    "key": "0ogwy",
                    "type": "unstyled",
                    "depth": 0,
                    "text": "Hey buddy"
                }
            ]
        },
        "creator": "jack",
        "messageAttachment": {
            "type": "challenge"
        },
        "id": "-NbXDetTmlV3qlaiLKNA"
    },
    {
        "chatID": "-NaJP_J417tHbA64Pr7B",
        "timestamp": 1691557686,
        "content": {
            "blocks": [
                {
                    "key": "0a3je",
                    "type": "unstyled",
                    "depth": 0,
                    "text": "This is a challenge!"
                }
            ]
        },
        "creator": "jack",
        "messageAttachment": {
            "type": "challenge"
        },
        "id": "-NbNgTpSNAHmqnlfbN2K"
    },
    {
        "chatID": "-NaJP_J417tHbA64Pr7B",
        "timestamp": 1691556443,
        "content": {
            "blocks": [
                {
                    "key": "0syhx",
                    "type": "unstyled",
                    "depth": 0,
                    "text": "Garbage post"
                }
            ]
        },
        "creator": "jack",
        "messageAttachment": {
            "id": "yege9jFocqL4flhSl9yO",
            "type": "post"
        },
        "id": "-NbNbjM-Ktigrpvfugxu"
    },
    {
        "chatID": "-NaJP_J417tHbA64Pr7B",
        "timestamp": 1691554852,
        "content": {
            "blocks": [
                {
                    "key": "0s016",
                    "type": "unstyled",
                    "depth": 0,
                    "text": "Sending you a DM"
                }
            ]
        },
        "creator": "jack",
        "messageAttachment": {
            "id": "APZ4DVkxMa2PmNPdqRbs",
            "type": "post"
        },
        "id": "-NbNWezDC5cVNZJIW9xM"
    },
    {
        "chatID": "-NaJP_J417tHbA64Pr7B",
        "timestamp": 1690860103,
        "content": {
            "blocks": [
                {
                    "key": "0u46h",
                    "type": "unstyled",
                    "depth": 0,
                    "text": "What the"
                }
            ]
        },
        "creator": "cory",
        "id": "-Naj6PSgJgpc0fqUvTG1"
    },
    {
        "chatID": "-NaJP_J417tHbA64Pr7B",
        "timestamp": 1690859929,
        "content": {
            "blocks": [
                {
                    "key": "03rl6",
                    "type": "unstyled",
                    "depth": 0,
                    "text": "That did not work"
                }
            ]
        },
        "creator": "jack",
        "id": "-Naj5k2mcwalxDBn4-tS"
    },
    {
        "chatID": "-NaJP_J417tHbA64Pr7B",
        "timestamp": 1690859919,
        "content": {
            "blocks": [
                {
                    "key": "05bzu",
                    "type": "unstyled",
                    "depth": 0,
                    "text": ""
                }
            ]
        },
        "creator": "jack",
        "messageAttachment": {
            "id": "5KJeyodhUhwedwW0LRgr",
            "type": "post"
        },
        "id": "-Naj5hY_KObkPOVm8P8y"
    },
    {
        "chatID": "-NaJP_J417tHbA64Pr7B",
        "timestamp": 1690847868,
        "content": {
            "blocks": [
                {
                    "key": "0rz0d",
                    "type": "unstyled",
                    "depth": 0,
                    "text": "Does this work #seethegood"
                }
            ]
        },
        "creator": "cory",
        "messageAttachment": {
            "id": "Og3nZgbCL5f9r6xGY47i",
            "type": "post"
        },
        "id": "-NaiNjTZUj8XCAVwukTj"
    },
    {
        "chatID": "-NaJP_J417tHbA64Pr7B",
        "timestamp": 1690412144,
        "content": {
            "blocks": [
                {
                    "key": "0b0vv",
                    "type": "unstyled",
                    "depth": 0,
                    "text": "Testing dming this challenge "
                }
            ]
        },
        "creator": "cory",
        "messageAttachment": {
            "id": "4W9FKEDE33DQrSTvu7Fc",
            "type": "challenge"
        },
        "id": "-NaJP_LNYXKdKP8ll4ae"
    },
    {
        "chatID": "-NaJP_J417tHbA64Pr7B",
        "timestamp": 1690412143,
        "content": {
            "blocks": [
                {
                    "key": "0eqh6",
                    "type": "unstyled",
                    "depth": 0,
                    "text": "cory created the chat"
                }
            ]
        },
        "creator": "cory",
        "id": "-NaJP_KF7oKZRO4CEbyA"
    }
]

export const DUMMY_CITIES = [
    {
        city: 'New York',
        state: 'NY'
    },
    {
        city: 'Boston',
        state: 'MA'
    },
    {
        city: 'Los Angeles',
        state: 'CA'
    },
    {
        city: 'Chicago',
        state: 'IL'
    },
    {
        city: 'Houston',
        state: 'TX'
    },
    {
        city: 'Phoenix',
        state: 'AZ'
    },
    {
        city: 'Philadelphia',
        state: 'PA'
    },
    {
        city: 'San Antonio',
        state: 'TX'
    },
    {
        city: 'San Diego',
        state: 'CA'
    },
    {
        city: 'Dallas',
        state: 'TX'
    },
    {
        city: 'San Jose',
        state: 'CA'
    },
    {
        city: 'Austin',
        state: 'TX'
    },
    {
        city: 'Jacksonville',
        state: 'FL'
    },
    {
        city: 'Fort Worth',
        state: 'TX'
    },
    {
        city: 'Columbus',
        state: 'OH'
    },
    {
        city: 'Charlotte',
        state: 'NC'
    },
    {
        city: 'Indianapolis',
        state: 'IN'
    },
    {
        city: 'Seattle',
        state: 'WA'
    },
    {
        city: 'Denver',
        state: 'CO'
    },
    {
        city: 'Washington',
        state: 'DC'
    }
];


export const NPO_CATEGORIES_DICT = [
    {
        name: 'Arts & Museums',
        emoji: '🎨',
        icon: SvgArts
    },
    {
        name: 'Education & Schools',
        emoji: '🎓',
        icon: SvgSchool
    },
    {
        name: 'Environment', emoji: '🌳',
        icon: SvgTrees
    },
    {
        name: 'Animals', emoji: '🌳',
        icon: SvgAnimals
    },
    {
        name: 'Health', emoji: '🏥',
        icon: SvgHealth
    },
    {
        name: 'Human Services & Housing', emoji: '🏥',
        icon: SvgFamily
    },
    {
        name: 'Religion & Faith', emoji: '⛪',
        icon: SvgChurch
    },
    {
        name: 'Fraternal Societies & Clubs', emoji: '🤝',
        icon: SvgUserCards
    },
    {
        name: 'Military & Veterans', emoji: '🤝',
        icon: SvgMedal
    },
    {
        name: 'International Relief & Human Rights', emoji: '🌍',
        icon: SvgEarth
    }
]

export const DUMMY_CAUSES = [
    {
        name: 'World Wildlife Fund',
        img: require('../img/coral.png'),
        location: 'Tampa, FL'
    },
    {
        name: 'World Wildlife Fund',
        img: require('../img/coral.png'),
        location: 'Tampa, FL',
        following: true
    },
]

export const DUMMY_CAUSES_SEARCH = [
    {
        name: 'World Wildlife Fund',
        img: require('../img/pp.png'),
        location: 'Tampa, FL'
    },
    {
        name: 'World Wildlife Fund',
        img: require('../img/feedingtb.png'),
        location: 'Tampa, FL',
        following: true
    },
]

export const NPO_CATEGORY_ICON_DICT = {
    'Arts & Museums': SvgArts,
    'Education & Schools': SvgSchool,
    'Environment': SvgTrees,
    'Animals': SvgAnimals,
    'Health': SvgHealth,
    'Human Services & Housing': SvgFamily,
    'Religion & Faith': SvgChurch,
    'Fraternal Societies & Clubs': SvgUserCards,
    'Military & Veterans': SvgMedal,
    'International Relief & Human Rights': SvgEarth
};

export const NPO_CATEGORY_COLOR_DICT = {
    'Arts & Museums': '#4A90E2', // Blue
    'Education & Schools': '#c565b8', // Light Blue
    'Environment': '#75cf6f', // Green
    'Animals': '#56CCF2', // Sky Blue
    'Health': '#EB5757', // Red
    'Human Services & Housing': '#BB6BD9', // Purple
    'Religion & Faith': '#F2994A', // Orange
    'Fraternal Societies & Clubs': '#F2C94C', // Yellow
    'Military & Veterans': '#15303d', // Dark Blue
    'International Relief & Human Rights': '#51E0AE' // Violet
};

export const reverseCategoryMap = {
    "A": "Arts & Museums",
    "R": "Arts & Museums",
    "S": "Arts & Museums",
    "T": "Arts & Museums",
    "U": "Arts & Museums",
    "V": "Arts & Museums",
    "B": "Education & Schools",
    "C": "Environment",
    "D": "Animals",
    "E": "Health",
    "F": "Health",
    "G": "Health",
    "H": "Health",
    "I": "Human Services & Housing",
    "J": "Human Services & Housing",
    "K": "Human Services & Housing",
    "L": "Human Services & Housing",
    "M": "Human Services & Housing",
    "N": "Human Services & Housing",
    "O": "Human Services & Housing",
    "P": "Human Services & Housing",
    "X": "Religion & Faith",
    "Y": "Fraternal Societies & Clubs",
    "W": "Military & Veterans",
    "Q": "International Relief & Human Rights",
    "Z": "International Relief & Human Rights",
};



export const DUMMY_POSTS = [
    {
        "created_at": "2024-03-27 23:28:34.576303+00:00",
        "content": {
            "blocks": [
                {
                    "text": "University of California Santa Barbara just want to give a quick shoutout to all the UCSB alums out there. Truly, an incredible institution that has produced an enormous amount of successful graduates. ￼#bethegood #seethegood #sharethegood #unlimitedgoodonstarfish ",
                    "key": "0mzc6",
                    "type": "unstyled",
                    "depth": 0,
                    "entityRanges": [
                        {
                            "key": 0,
                            "offset": 0,
                            "length": 38
                        },
                        {
                            "key": 1,
                            "offset": 214,
                            "length": 11
                        },
                        {
                            "key": 2,
                            "offset": 226,
                            "length": 13
                        },
                        {
                            "key": 3,
                            "offset": 240,
                            "length": 24
                        }
                    ]
                }
            ],
            "entityMap": [
                {
                    "type": "mention",
                    "mutability": "IMMUTABLE",
                    "data": {
                        "mention": {
                            "type": "nonProfitTag",
                            "id": "95-6006145",
                            "name": "University of California Santa Barbara"
                        }
                    }
                },
                {
                    "type": "mention",
                    "mutability": "IMMUTABLE",
                    "data": {
                        "mention": {
                            "type": "hashtag",
                            "hashtag": "#seethegood",
                            "name": "#seethegood"
                        }
                    }
                },
                {
                    "type": "mention",
                    "mutability": "IMMUTABLE",
                    "data": {
                        "mention": {
                            "type": "hashtag",
                            "hashtag": "#sharethegood",
                            "name": "#sharethegood"
                        }
                    }
                },
                {
                    "type": "mention",
                    "mutability": "IMMUTABLE",
                    "data": {
                        "mention": {
                            "type": "hashtag",
                            "hashtag": "#unlimitedgoodonstarfish",
                            "name": "#unlimitedgoodonstarfish"
                        }
                    }
                }
            ]
        },
        "creator": "johncervenka",
        "comments": 0,
        "np_post": false,
        "hashtags": [
            "#seethegood",
            "#sharethegood",
            "#unlimitedgoodonstarfish"
        ],
        "block_style": true,
        "scraped_ig": false,
        "creator_name": "John K Cervenka",
        "plaintext": "University of California Santa Barbara just want to give a quick shoutout to all the UCSB alums out there. Truly, an incredible institution that has produced an enormous amount of successful graduates. ￼#bethegood #seethegood #sharethegood #unlimitedgoodonstarfish ",
        "image_urls": [
            "https://firebasestorage.googleapis.com:443/v0/b/starfish-seethegood.appspot.com/o/uploads%2Fa56e6d04-aeff-4f8d-bca2-a6c37a178c6d?alt=media&token=d6daac10-34d6-4eea-9b6e-35d55d62caeb",
            "https://firebasestorage.googleapis.com:443/v0/b/starfish-seethegood.appspot.com/o/uploads%2Fd5ea08f0-0834-43f2-b65d-455f59948af0?alt=media&token=0c3a7271-836c-4b8e-9a96-c7f271177695",
            "https://firebasestorage.googleapis.com:443/v0/b/starfish-seethegood.appspot.com/o/uploads%2F50fd0b8f-4d8e-4c97-9c44-8e3cfd1cd3d3?alt=media&token=fa05ef8e-869d-4952-921d-dbbf7153aa5e",
            "https://firebasestorage.googleapis.com:443/v0/b/starfish-seethegood.appspot.com/o/uploads%2F5fc0649b-5bb7-40c7-983c-a7151492945c?alt=media&token=4842ce25-be8a-48a9-8bff-b2d9060d6745"
        ],
        "users": [],
        "likes": {
            "jack": true,
            "cory": true,
            "meredith": true
        },
        "videos": [],
        "num_likes": 153,
        "non_profits": [
            "95-6006145"
        ],
        "post_ID": "UuMzEflXyXxb9oAaOaDy",
        "liked": false
    },
    {
        "created_at": "2024-03-18 20:50:19.615975+00:00",
        "content": {
            "blocks": [
                {
                    "text": "McCourt Foundation Inc \nA big shout out to the McCourt Foundation for putting on an amazing marathon in Los Angeles yesterday. Extremely well run and organized. So impressive. Had a wonderful time. Thank you to everyone that worked and donated time to pull this event off. \nKerry Cervenka Georgia Cervenka Frank Cervenka Loryn Cervenka ",
                    "key": "0pt5x",
                    "type": "unstyled",
                    "depth": 0,
                    "entityRanges": [
                        {
                            "key": 0,
                            "offset": 0,
                            "length": 22
                        },
                        {
                            "key": 1,
                            "offset": 274,
                            "length": 14
                        },
                        {
                            "key": 2,
                            "offset": 289,
                            "length": 16
                        },
                        {
                            "key": 3,
                            "offset": 306,
                            "length": 14
                        },
                        {
                            "key": 4,
                            "offset": 321,
                            "length": 14
                        }
                    ]
                }
            ],
            "entityMap": [
                {
                    "type": "mention",
                    "mutability": "IMMUTABLE",
                    "data": {
                        "mention": {
                            "type": "nonProfitTag",
                            "id": "90-0623963",
                            "name": "McCourt Foundation Inc"
                        }
                    }
                },
                {
                    "type": "mention",
                    "mutability": "IMMUTABLE",
                    "data": {
                        "mention": {
                            "type": "userTag",
                            "userID": "kerrycervenka",
                            "name": "Kerry Cervenka"
                        }
                    }
                },
                {
                    "type": "mention",
                    "mutability": "IMMUTABLE",
                    "data": {
                        "mention": {
                            "type": "userTag",
                            "userID": "gcervenka",
                            "name": "Georgia Cervenka"
                        }
                    }
                },
                {
                    "type": "mention",
                    "mutability": "IMMUTABLE",
                    "data": {
                        "mention": {
                            "type": "userTag",
                            "userID": "franklyn.cervenka39@berkeley.edu",
                            "name": "Frank Cervenka"
                        }
                    }
                },
                {
                    "type": "mention",
                    "mutability": "IMMUTABLE",
                    "data": {
                        "mention": {
                            "type": "userTag",
                            "userID": "loryncervenka",
                            "name": "Loryn Cervenka"
                        }
                    }
                }
            ]
        },
        "creator": "johncervenka",
        "comments": 0,
        "np_post": false,
        "hashtags": [],
        "block_style": true,
        "creator_name": "John K Cervenka",
        "plaintext": "McCourt Foundation Inc \nA big shout out to the McCourt Foundation for putting on an amazing marathon in Los Angeles yesterday. Extremely well run and organized. So impressive. Had a wonderful time. Thank you to everyone that worked and donated time to pull this event off. \nKerry Cervenka Georgia Cervenka Frank Cervenka Loryn Cervenka ",
        "image_urls": [
            "https://firebasestorage.googleapis.com:443/v0/b/starfish-seethegood.appspot.com/o/uploads%2Ffb4b3394-0e7d-4b3f-8a59-7717df65929a?alt=media&token=47c0aaaa-05ed-491e-8a37-228629191904",
            "https://firebasestorage.googleapis.com:443/v0/b/starfish-seethegood.appspot.com/o/uploads%2F1168ab69-36c8-40d2-8422-869d4f68e7fa?alt=media&token=b4f6d340-36a4-4d48-b7b6-01de668939c3",
            "https://firebasestorage.googleapis.com:443/v0/b/starfish-seethegood.appspot.com/o/uploads%2F53ef852b-6784-409b-934a-aff3a8458956?alt=media&token=111155ef-2f42-4e82-a196-7d1234cc32fd"
        ],
        "users": [
            "kerrycervenka",
            "gcervenka",
            "franklyn.cervenka39@berkeley.edu",
            "loryncervenka"
        ],
        "likes": {
            "jack": true,
            "cory": true,
            "meredith": true
        },
        "videos": [],
        "num_likes": 45,
        "non_profits": [
            "90-0623963"
        ],
        "post_ID": "FLKeHD8KH9Er9Q7uxhdJ",
        "liked": false
    },
    {
        "created_at": "2024-03-12 18:19:14.635292+00:00",
        "content": {
            "blocks": [
                {
                    "text": "Had a great day with my daughters, Georgia Cervenka and lilah cervenka , as they enjoyed skiing in the mountains of Southern California.  #seethegood #bethegood #skiingisgood #AngelesNationalForest ",
                    "key": "e11s8",
                    "inlineStyleRanges": [],
                    "type": "unstyled",
                    "data": {},
                    "depth": 0,
                    "entityRanges": [
                        {
                            "key": 0,
                            "offset": 35,
                            "length": 16
                        },
                        {
                            "key": 1,
                            "offset": 56,
                            "length": 14
                        },
                        {
                            "key": 2,
                            "offset": 138,
                            "length": 11
                        },
                        {
                            "key": 3,
                            "offset": 150,
                            "length": 10
                        },
                        {
                            "key": 4,
                            "offset": 161,
                            "length": 13
                        },
                        {
                            "key": 5,
                            "offset": 175,
                            "length": 22
                        }
                    ]
                }
            ],
            "entityMap": {
                "0": {
                    "type": "mention",
                    "mutability": "IMMUTABLE",
                    "data": {
                        "mention": {
                            "type": "userTag",
                            "id": "gcervenka",
                            "userID": "gcervenka",
                            "name": "Georgia Cervenka"
                        }
                    }
                },
                "1": {
                    "type": "mention",
                    "mutability": "IMMUTABLE",
                    "data": {
                        "mention": {
                            "type": "userTag",
                            "id": "lilahcervenka",
                            "userID": "lilahcervenka",
                            "name": "lilah cervenka"
                        }
                    }
                },
                "2": {
                    "type": "#mention",
                    "mutability": "IMMUTABLE",
                    "data": {
                        "mention": {
                            "type": "hashtag",
                            "hashtag": "#seethegood",
                            "id": "seethegood",
                            "name": "#seethegood"
                        }
                    }
                },
                "3": {
                    "type": "#mention",
                    "mutability": "IMMUTABLE",
                    "data": {
                        "mention": {
                            "type": "hashtag",
                            "hashtag": "#bethegood",
                            "id": "bethegood",
                            "name": "#bethegood"
                        }
                    }
                },
                "4": {
                    "type": "#mention",
                    "mutability": "IMMUTABLE",
                    "data": {
                        "mention": {
                            "type": "hashtag",
                            "hashtag": "#skiingisgood",
                            "id": "skiingisgood",
                            "name": "#skiingisgood"
                        }
                    }
                },
                "5": {
                    "type": "#mention",
                    "mutability": "IMMUTABLE",
                    "data": {
                        "mention": {
                            "type": "hashtag",
                            "hashtag": "#AngelesNationalForest",
                            "id": "AngelesNationalForest",
                            "name": "#AngelesNationalForest"
                        }
                    }
                }
            }
        },
        "creator": "johncervenka",
        "comments": 0,
        "np_post": false,
        "hashtags": [
            "#seethegood",
            "#bethegood",
            "#skiingisgood",
            "#AngelesNationalForest"
        ],
        "block_style": true,
        "creator_name": "John K Cervenka",
        "plaintext": "Had a great day with my daughters, Georgia Cervenka and lilah cervenka , as they enjoyed skiing in the mountains of Southern California.  #seethegood #bethegood #skiingisgood #AngelesNationalForest ",
        "image_urls": [
            "https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/uploads%2F9570c96b-ebe7-4e55-84c2-8f91f7e8964f?alt=media&token=7112a573-732a-4b9f-9229-e5ce8b02e785"
        ],
        "users": [
            "gcervenka",
            "lilahcervenka"
        ],
        "likes": {
            "jack": true,
            "cory": true,
        },
        "videos": [],
        "num_likes": 52,
        "non_profits": [],
        "post_ID": "CUhI6L9jYcAau2KzB9Dr",
        "liked": false
    }
]

export const sample_users = [
    {
        "created_at": "2023-04-21 19:00:37.527882+00:00",
        "state": "NY",
        "followee_count": 0,
        "bio": "PR & marketing professional ",
        "suggested_non_profits": [
            "27-2460560",
            "95-3998111-1",
            "95-3117048",
            "99-0315110",
            "46-1084699",
            "95-6092809-1",
            "83-3737117",
            "95-4766473",
            "27-4209721",
            "23-7381137",
            "26-0086305",
            "92-0152268",
            "99-0337979",
            "94-1055967",
            "82-1206798",
            "25-1828488",
            "95-4382752",
            "26-2932191",
            "04-2899862",
            "95-3842082",
            "47-3893175",
            "23-7003516",
            "54-0788167",
            "45-2913544"
        ],
        "interests": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791f650>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791d7d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791dfd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077aff90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077aeb90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077ae650>"
        ],
        "tagline": "PR & marketing professional ",
        "followers": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077adbd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077afb50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077af390>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107965950>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079676d0>"
        ],
        "uninterested_non_profits": [],
        "suggested_users": [
            "jackstra",
            "peter",
            "jack",
            "octavianaugustuscaesar",
            "phifeann",
            "wilguenssley",
            "ajcartwright",
            "humptydumpty",
            "jaisapinn",
            "oliver",
            "ookleey",
            "starfishsocial",
            "ania",
            "cory",
            "kelleycervenka",
            "dersainte",
            "devinqb1",
            "devontaeford97",
            "jamal_hansen",
            "jenniferlove",
            "leahs",
            "samuel",
            "tigerwoodss",
            "ekarambelas"
        ],
        "birthday": "1998-03-26T20:00:39.000Z",
        "settings": {
            "light_mode": true,
            "private": true
        },
        "nux": {
            "follow_user_np": false,
            "add_bio": true,
            "participate_challenge": false,
            "create_post": false,
            "add_np": false,
            "add_picture": false
        },
        "city": "New York",
        "experiences": [],
        "followees": [],
        "uninterested_users": [],
        "follower_count": 3,
        "last_streak_update": "2024-05-02",
        "first_name": "Grayson",
        "onboarded": true,
        "fcm_token": null,
        "connections": {},
        "deactivated": false,
        "email": "hougegrayson@gmail.com",
        "username": "ghouge",
        "last_name": "Houge",
        "id": "ghouge"
    },
    {
        "created_at": "2023-01-26 16:57:07.923880+00:00",
        "state": "NY",
        "followee_count": 0,
        "bio": "Hello World",
        "suggested_non_profits": [
            "27-2460560",
            "95-3998111-1",
            "95-3117048",
            "99-0315110",
            "46-1084699",
            "95-6092809-1",
            "83-3737117",
            "95-4766473",
            "27-4209721",
            "23-7381137",
            "26-0086305",
            "92-0152268",
            "99-0337979",
            "94-1055967",
            "82-1206798",
            "25-1828488",
            "95-4382752",
            "26-2932191",
            "04-2899862",
            "95-3842082",
            "47-3893175",
            "23-7003516",
            "54-0788167",
            "45-2913544"
        ],
        "interests": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107967c90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107903790>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079027d0>"
        ],
        "followers": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107903010>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107902c50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107966790>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079004d0>"
        ],
        "tagline": "Hello World",
        "uninterested_non_profits": [],
        "suggested_users": [
            "jackstra",
            "ghouge",
            "peter",
            "ajcartwright",
            "devinqb1",
            "devontaeford97",
            "douejudicael",
            "jorrynech17",
            "leahs",
            "lolzz",
            "phifeann",
            "rstahley",
            "samuel",
            "sydneeblack",
            "jackstarch",
            "tarynkimmel_",
            "cory",
            "jack",
            "jaisapinn",
            "starfishsocial",
            "stevemott",
            "jfort24",
            "6cperritt",
            "agatha"
        ],
        "birthday": "2004-01-26T16:57:11.000Z",
        "settings": {
            "light_mode": true,
            "private": true
        },
        "nux": {
            "follow_user_np": false,
            "add_bio": true,
            "participate_challenge": false,
            "create_post": false,
            "add_np": false,
            "add_picture": false
        },
        "city": "New York",
        "experiences": [],
        "followees": [],
        "uninterested_users": [],
        "follower_count": 3,
        "last_streak_update": "2024-05-02",
        "first_name": "Humpty",
        "onboarded": true,
        "fcm_token": null,
        "email": "coryobotch@gmail.com",
        "deactivated": false,
        "connections": {},
        "username": "humptydumpty",
        "last_name": "Dumpty",
        "id": "humptydumpty"
    },
    {
        "created_at": "2024-01-12 18:14:42.595184+00:00",
        "followers": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107903290>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107902ed0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107900710>"
        ],
        "suggested_users": [
            "jackstra",
            "jack",
            "ania",
            "ghouge",
            "henlaidyduran",
            "jackstarch",
            "peter",
            "ajcartwright",
            "cory",
            "tommyhannan",
            "liv",
            "616westsideshiii",
            "devinqb1",
            "devontaeford97",
            "ekarambelas",
            "leahs",
            "mackenziep20@icloud.com",
            "meganhutch10",
            "octavianaugustuscaesar",
            "ookleey",
            "racheick",
            "samuel",
            "starfishsocial",
            "sydneeblack"
        ],
        "interests": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079038d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107900510>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107901b90>"
        ],
        "tagline": "Student at UCF, passionate about Healthcare",
        "admin_non_profits": [],
        "invite_link": null,
        "birthday": "2001-01-12T19:57:08.000Z",
        "nux": {
            "follow_user_np": true,
            "add_bio": true,
            "participate_challenge": false,
            "create_post": false,
            "add_np": false,
            "add_picture": true
        },
        "followees": [],
        "last_streak_update": "2024-05-02",
        "city": "Central Falls",
        "onboarded": true,
        "connections": {},
        "deactivated": false,
        "username": "joesepho",
        "last_name": "Johnson",
        "bio": "Student at UCF, passionate about Healthcare",
        "suggested_non_profits": [
            "27-2460560",
            "95-3998111-1",
            "95-3117048",
            "99-0315110",
            "46-1084699",
            "95-6092809-1",
            "83-3737117",
            "95-4766473",
            "27-4209721",
            "23-7381137",
            "26-0086305",
            "92-0152268",
            "99-0337979",
            "94-1055967",
            "82-1206798",
            "25-1828488",
            "95-4382752",
            "26-2932191",
            "04-2899862",
            "95-3842082",
            "47-3893175",
            "23-7003516",
            "54-0788167",
            "45-2913544"
        ],
        "categories": [
            "Health",
            "Education & Schools"
        ],
        "settings": {
            "light_mode": true,
            "private": true
        },
        "uninterested_users": [],
        "experiences": [],
        "first_name": "Joe Hicks",
        "fcm_token": null,
        "state": "RI",
        "uninterested_non_profits": [],
        "email": "joe@scopelabs.com",
        "id": "joesepho"
    },
    {
        "created_at": "2023-12-25 21:10:20.406028+00:00",
        "followers": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c5f50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4b90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c56d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c5790>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c7650>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4bd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c7210>"
        ],
        "suggested_users": [
            "dan",
            "starfishsocial",
            "gcervenka",
            "wilguenssley",
            "morgan98",
            "franklyn.cervenka39@berkeley.edu",
            "bob",
            "e",
            "ashleycarty",
            "dwilliams",
            "nanewalt",
            "phifeann",
            "jenniferlove",
            "garenishandsome",
            "everettpam",
            "rusty",
            "jphifer311",
            "octavianaugustuscaesar",
            "eman",
            "nataliesuh",
            "meredith",
            "bigeasyballer",
            "pav",
            "jacktest"
        ],
        "interests": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c7510>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c6bd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c6e90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4cd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c63d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c6750>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c7f50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c5cd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4510>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c44d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c5510>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c6110>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4910>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4590>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c64d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4a90>"
        ],
        "tagline": " ",
        "admin_non_profits": [],
        "birthday": "1999-01-01T05:00:00.000Z",
        "invite_link": null,
        "nux": {
            "follow_user_np": true,
            "add_bio": true,
            "participate_challenge": false,
            "create_post": false,
            "add_np": false,
            "add_picture": true
        },
        "city": "Lexington",
        "last_streak_update": "2024-05-02",
        "followees": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c7010>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c40d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4550>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4a10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c5090>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c6ad0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c55d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c7850>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c7ed0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4250>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4450>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c5bd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c5590>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4990>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4b50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c6950>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c7810>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4810>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4310>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c6fd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c41d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c5c50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c7950>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c5ed0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c5110>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4f90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4d90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4350>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798a850>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798b250>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798a0d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107988c90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107989ad0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798aa50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798aad0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107988310>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107989690>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107989410>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107988590>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798b690>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107988cd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079898d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798a910>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798a650>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798ae10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798a5d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798a2d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107988110>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107988190>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798ac90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798a890>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798a9d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079887d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798ba10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798a950>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107989590>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107988b50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798acd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798a590>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107988390>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107989110>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798ae50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798be50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798b390>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107989dd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798b550>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798ad50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107988850>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107988fd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798a690>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798b5d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798bc50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798b6d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798bb10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798bfd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798bf90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798b910>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798afd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798bd50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107989990>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798b510>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079897d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798a210>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798b850>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798b1d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107989290>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107989c50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798b3d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798af50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107989810>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798a290>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798a450>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798b110>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798a110>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107988f50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798be10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798b810>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079885d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107988ed0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107988a90>"
        ],
        "onboarded": true,
        "connections": {},
        "deactivated": false,
        "username": "kisikomumbere1",
        "last_name": "Fire",
        "bio": " ",
        "suggested_non_profits": [
            "95-3998111-1",
            "27-2460560",
            "53-0196605",
            "95-1690977",
            "95-6092809-1",
            "46-1084699",
            "27-4209721",
            "45-2913544",
            "52-1693387",
            "13-5562976",
            "83-3737117",
            "95-4766473",
            "23-7381137",
            "26-0086305",
            "92-0152268",
            "82-1206798",
            "25-1828488",
            "95-1643344",
            "13-1623829",
            "04-2103594",
            "95-4623022",
            "99-0315110",
            "26-0710805",
            "73-0739626"
        ],
        "categories": [
            "Arts & Museums",
            "Education & Schools",
            "Animals",
            "Environment",
            "Health",
            "Human Services & Housing",
            "Fraternal Societies & Clubs",
            "Religion & Faith",
            "International Relief & Human Rights",
            "Military & Veterans"
        ],
        "settings": {
            "light_mode": true,
            "private": true
        },
        "uninterested_users": [],
        "experiences": [],
        "first_name": "Justin",
        "fcm_token": "epw-48T1u0zXhgY1xPtNS_:APA91bHQ1hO4vvdtkeEBQn5LWnJRQoQF4N75hlZTqI8b_Zrz0CeWMbOKpTsuvbiLlCezpmbMemQLqlE50GBKDCiGfrBDWwYDhPyDru-rOFVQkFW44qwPKOt-yP8lHroF1GQAZWStkfcf",
        "email": "justinfire153@gmail.com",
        "state": "KY",
        "uninterested_non_profits": [],
        "id": "kisikomumbere1"
    },
    {
        "created_at": "2024-01-03 15:19:27.030716+00:00",
        "followers": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798ac10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798bc90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107989d50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798bd10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107989950>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798ab90>"
        ],
        "suggested_users": [
            "jackstra",
            "bohardin120",
            "cory",
            "tommyhannan",
            "johncervenka",
            "jack",
            "stevemott",
            "careymott",
            "jason10",
            "dan",
            "gmaul21",
            "gmrenollet@gmail.com",
            "meredith",
            "quanakita@yahoo.com",
            "morgan98",
            "lilahcervenka",
            "cmaxdudegmailcom",
            "jimmilley",
            "chrisfdunbar",
            "jfort24",
            "loryncervenka",
            "robyncervenka",
            "timscott",
            "viktoriia"
        ],
        "tagline": "Supporter of all things Memphis",
        "interests": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798a090>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107989310>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798a610>"
        ],
        "admin_non_profits": [
            "27-2460560",
            "54-0788167",
            "99-0334518"
        ],
        "longest_streak": 1,
        "birthday": "1997-01-01T05:00:00.000Z",
        "invite_link": null,
        "nux": {
            "follow_user_np": true,
            "add_bio": true,
            "participate_challenge": false,
            "create_post": false,
            "add_np": false,
            "add_picture": false
        },
        "city": "New York",
        "last_streak_update": "2024-07-12",
        "followees": [],
        "onboarded": true,
        "deactivated": false,
        "connections": {},
        "username": "nanewalt",
        "last_name": "Anewalt",
        "bio": "Student, University of Florida. I care about protecting marine wildlife!",
        "suggested_non_profits": [
            "27-2460560",
            "95-3998111-1",
            "95-3117048",
            "99-0315110",
            "46-1084699",
            "95-6092809-1",
            "83-3737117",
            "95-4766473",
            "27-4209721",
            "23-7381137",
            "26-0086305",
            "92-0152268",
            "99-0337979",
            "94-1055967",
            "82-1206798",
            "25-1828488",
            "95-4382752",
            "26-2932191",
            "04-2899862",
            "95-3842082",
            "47-3893175",
            "23-7003516",
            "54-0788167",
            "45-2913544"
        ],
        "categories": [
            "Environment"
        ],
        "current_streak": 1,
        "settings": {
            "light_mode": true,
            "private": true
        },
        "uninterested_users": [],
        "experiences": [],
        "first_name": "Nick",
        "fcm_token": "d6hW11TEX0-3nTaTt7IOV2:APA91bHSghu4R-ouKW5KlbmpqCKm9LXFGvu28UlwdoypLgpQPPTxSoa3EP1kSpPkuacmpU3DUA8kJzg0ruuVqgFxN5WjHYw_VGHkk0UPW6caAS1rAB6fNe_pMNmuILf88KlyDnyFFSPl",
        "state": "NY",
        "uninterested_non_profits": [],
        "email": "nick@scopelabs.com",
        "id": "nanewalt"
    },
    {
        "created_at": "2023-03-14 21:20:22.000617+00:00",
        "state": "PA",
        "followee_count": 3,
        "bio": "None yet?!?",
        "suggested_non_profits": [
            "46-1084699",
            "23-7003516",
            "95-6092809-1",
            "83-3737117",
            "27-4209721",
            "23-7381137",
            "26-0086305",
            "92-0152268",
            "94-1055967",
            "95-4382752",
            "04-2899862",
            "94-2699571",
            "26-0710805",
            "13-1610451-1",
            "22-1905062",
            "94-2494324-1",
            "38-6006309",
            "58-2368165",
            "13-5562976",
            "53-0193519",
            "33-0880998",
            "52-1086761",
            "13-1624103",
            "85-2270846"
        ],
        "interests": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798ab10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107989050>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10798a250>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107988e50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107988610>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107913f90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107912350>"
        ],
        "followers": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107913290>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107912210>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107912790>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107913f10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107911f90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107910210>"
        ],
        "tagline": "None yet?!?",
        "uninterested_non_profits": [],
        "suggested_users": [
            "samuel",
            "jack",
            "leahs",
            "devinqb1",
            "ookleey",
            "jackstra",
            "sydneeblack",
            "ghouge",
            "ania",
            "meganhutch10",
            "jenniferlove",
            "peter",
            "douejudicael",
            "giovanni",
            "wilguenssley",
            "tauris",
            "ajcartwright",
            "phifeann",
            "jackstarch",
            "ekarambelas",
            "devontaeford97",
            "mvanzetta",
            "gcervenka",
            "johncervenka"
        ],
        "birthday": "2000-11-12T22:21:30.000Z",
        "settings": {
            "light_mode": true,
            "private": true
        },
        "nux": {
            "follow_user_np": false,
            "add_bio": true,
            "participate_challenge": false,
            "create_post": false,
            "add_np": false,
            "add_picture": false
        },
        "city": "Butler ",
        "experiences": [],
        "followees": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079131d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107911c50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107911410>"
        ],
        "uninterested_users": [],
        "follower_count": 3,
        "last_streak_update": "2024-05-02",
        "first_name": "Noah",
        "onboarded": true,
        "fcm_token": null,
        "email": "noah2wisefrost@icloud.com",
        "connections": {},
        "deactivated": false,
        "username": "octavianaugustuscaesar",
        "last_name": "James",
        "id": "octavianaugustuscaesar"
    },
    {
        "created_at": "2023-03-12 02:33:17.229833+00:00",
        "state": "CA",
        "followee_count": 0,
        "bio": "Peace & Love",
        "suggested_non_profits": [
            "27-2460560",
            "95-3998111-1",
            "95-3117048",
            "99-0315110",
            "46-1084699",
            "95-6092809-1",
            "83-3737117",
            "95-4766473",
            "27-4209721",
            "23-7381137",
            "26-0086305",
            "92-0152268",
            "99-0337979",
            "94-1055967",
            "82-1206798",
            "25-1828488",
            "95-4382752",
            "26-2932191",
            "04-2899862",
            "95-3842082",
            "47-3893175",
            "23-7003516",
            "54-0788167",
            "45-2913544"
        ],
        "interests": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107910dd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107913450>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107911390>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107913dd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107911150>"
        ],
        "followers": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107911490>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107912fd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079105d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107910b90>"
        ],
        "tagline": "Peace & Love",
        "uninterested_non_profits": [],
        "suggested_users": [
            "jackstra",
            "jenniferlove",
            "wilguenssley",
            "kelleycervenka",
            "faneldabija2008",
            "phifeann",
            "ghouge",
            "jack",
            "devinqb1",
            "donsherry",
            "starfishsocial",
            "dk",
            "ookleey",
            "jessstovall22",
            "octavianaugustuscaesar",
            "given",
            "timmywong",
            "stevemott",
            "ekarambelas",
            "johncervenka",
            "cory",
            "dersainte",
            "douejudicael",
            "enrick"
        ],
        "birthday": "1995-06-04T02:01:29.000Z",
        "settings": {
            "light_mode": true,
            "private": true
        },
        "nux": {
            "follow_user_np": false,
            "add_bio": true,
            "participate_challenge": false,
            "create_post": false,
            "add_np": false,
            "add_picture": false
        },
        "followees": [],
        "experiences": [],
        "city": "Quebec",
        "uninterested_users": [],
        "follower_count": 3,
        "last_streak_update": "2024-05-02",
        "first_name": "Muhirwa",
        "onboarded": true,
        "fcm_token": null,
        "email": "muhirwazo@yahoo.fr",
        "deactivated": false,
        "connections": {},
        "username": "oliver",
        "last_name": "Olivier",
        "id": "oliver"
    },
    {
        "created_at": "2024-02-15 11:23:55.815878+00:00",
        "connections": {},
        "state": "MT",
        "bio": "Student - North Seattle College",
        "suggested_non_profits": [
            "27-2460560",
            "95-3998111-1",
            "95-3117048",
            "99-0315110",
            "46-1084699",
            "95-6092809-1",
            "83-3737117",
            "95-4766473",
            "27-4209721",
            "23-7381137",
            "26-0086305",
            "92-0152268",
            "99-0337979",
            "94-1055967",
            "82-1206798",
            "25-1828488",
            "95-4382752",
            "26-2932191",
            "04-2899862",
            "95-3842082",
            "47-3893175",
            "23-7003516",
            "54-0788167",
            "45-2913544"
        ],
        "interests": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107913610>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107913910>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107913250>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107912ad0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107912890>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107911110>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107912c90>"
        ],
        "categories": [
            "Arts & Museums",
            "Environment",
            "Health",
            "International Relief & Human Rights",
            "Animals",
            "Education & Schools"
        ],
        "admin_non_profits": [],
        "followers": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107911650>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107910110>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107912cd0>"
        ],
        "suggested_users": [
            "jack",
            "devinqb1",
            "starfishsocial",
            "octavianaugustuscaesar",
            "jackstra",
            "ania",
            "jenniferlove",
            "leahs",
            "samuel",
            "wilguenssley",
            "ajcartwright",
            "ghouge",
            "peter",
            "devontaeford97",
            "dk",
            "stevemott",
            "kelleycervenka",
            "ekarambelas",
            "oliver",
            "phifeann",
            "jessstovall22",
            "sydneeblack",
            "tauris",
            "meganhutch10"
        ],
        "invite_link": null,
        "settings": {
            "light_mode": true,
            "private": true
        },
        "nux": {
            "follow_user_np": true,
            "add_bio": true,
            "participate_challenge": false,
            "create_post": false,
            "add_np": false,
            "add_picture": false
        },
        "uninterested_users": [],
        "experiences": [],
        "followees": [],
        "birthday": "1999-07-30T05:00:00.000Z",
        "city": "Victor",
        "last_streak_update": "2024-05-02",
        "first_name": "Gabriel Okley",
        "fcm_token": null,
        "onboarded": true,
        "uninterested_non_profits": [],
        "deactivated": false,
        "email": "gteye45@gmail.com",
        "username": "ookleey",
        "last_name": "Teye",
        "id": "ookleey"
    },
    {
        "created_at": "2023-05-21 21:02:33.049045+00:00",
        "state": "MA",
        "uninterested_non_profits": [],
        "bio": "Pending",
        "suggested_non_profits": [
            "45-0998251",
            "04-2103594",
            "46-0565393",
            "20-4437286",
            "13-5562162",
            "13-1837442",
            "06-0726487",
            "13-1624016",
            "27-2460560",
            "46-1084699",
            "83-3737117",
            "95-4766473",
            "23-7381137",
            "25-1828488",
            "26-2932191",
            "54-0788167",
            "45-2913544",
            "45-3798803",
            "94-2699571",
            "82-0512146",
            "26-0710805",
            "73-0739626",
            "95-1642394",
            "95-3202116"
        ],
        "interests": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107911510>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107912050>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107910450>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107911350>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107910410>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107913b10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079106d0>"
        ],
        "tagline": "Pending",
        "followers": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107910f50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107912a90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107912510>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107912a50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107910910>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107910d50>"
        ],
        "suggested_users": [
            "jackstra",
            "ghouge",
            "starfishsocial",
            "wilguenssley",
            "oliver",
            "octavianaugustuscaesar",
            "ajcartwright",
            "cory",
            "stevemott",
            "gcervenka",
            "jenniferlove",
            "ekarambelas",
            "ookleey",
            "humptydumpty",
            "jaisapinn",
            "johncervenka",
            "leahs",
            "jfort24",
            "careymott",
            "bigeasyballer",
            "dersainte",
            "devinqb1",
            "devontaeford97",
            "samuel"
        ],
        "birthday": "1998-04-04T22:02:36.000Z",
        "settings": {
            "light_mode": true,
            "private": true
        },
        "nux": {
            "follow_user_np": true,
            "add_bio": true,
            "participate_challenge": false,
            "create_post": false,
            "add_np": false,
            "add_picture": false
        },
        "city": "Boston",
        "experiences": [],
        "followees": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107910d10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107912090>"
        ],
        "uninterested_users": [],
        "invite_link": null,
        "last_streak_update": "2024-05-02",
        "first_name": "Annie",
        "onboarded": true,
        "fcm_token": "epAoUhfQXUSEl2RGpK0cGj:APA91bHNcLee-CsNWYL9lDHDcwUEwUmNnWgUpLs6cSqCkgjxJCoIpjTcfKUR8n14cU8rDplsX_tNZQTtcxDPba1PfNiWdy-D9F7h6r07czThuwUNA9RiCSADToSJW18n1wm2WFy2HHXZ",
        "email": "phifeann@gmail.com",
        "connections": {},
        "deactivated": false,
        "username": "phifeann",
        "last_name": "Phifer",
        "id": "phifeann"
    },
    {
        "created_at": "2024-07-21 02:14:53.342834+00:00",
        "followers": [],
        "suggested_users": [
            "jenniferlove",
            "starfishsocial",
            "kelleycervenka",
            "kisikomumbere1",
            "oliver",
            "dk",
            "dan",
            "franklyn.cervenka39@berkeley.edu",
            "stal",
            "ghouge",
            "phifeann",
            "donsherry",
            "octavianaugustuscaesar",
            "devinqb1",
            "faneldabija2008",
            "moiseekyela",
            "ookleey",
            "morgan98",
            "gcervenka",
            "dwilliams",
            "timmywong",
            "jason10",
            "jamal_hansen",
            "alexwf"
        ],
        "tagline": "",
        "interests": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079115d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079111d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079136d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107912f50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107913c90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107910310>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107910810>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107913150>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107912a10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107913950>"
        ],
        "admin_non_profits": [],
        "longest_streak": 2,
        "invite_link": null,
        "birthday": "1995-09-16T02:34:17.350Z",
        "nux": {
            "follow_user_np": true,
            "add_bio": true,
            "participate_challenge": false,
            "create_post": false,
            "add_np": false,
            "add_picture": true
        },
        "city": "Santiago",
        "last_streak_update": "2024-07-21",
        "followees": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079112d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107911610>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107913650>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107912450>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107913990>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079129d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079127d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079107d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107910150>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107911810>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107912390>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107912e90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107911c10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107912550>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107911210>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107911050>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107910e50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079118d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079109d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107912810>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107910890>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107910f10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107912250>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107913a90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079114d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107912690>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107910cd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107911d90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107911c90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107911190>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107913bd0>"
        ],
        "education": {},
        "phone": "",
        "onboarded": true,
        "connections": {},
        "deactivated": false,
        "username": "wilguenssley",
        "last_name": "Marc Augustin ",
        "bio": "My name is Marc Augustin wilguens sley\nI am lawyer,teacher .\n\nThanks ",
        "suggested_non_profits": [
            "95-1690977",
            "27-2460560",
            "46-1084699",
            "23-7381137",
            "95-4623022",
            "83-3737117",
            "26-0086305",
            "25-1828488",
            "46-0565393",
            "95-1643344",
            "99-0315110",
            "95-4766473",
            "27-4209721",
            "92-0152268",
            "94-1055967",
            "26-0710805",
            "13-5562162",
            "04-2103594",
            "87-3499631",
            "13-1624100",
            "95-3998111-1",
            "95-6092809-1",
            "82-1206798",
            "54-0788167"
        ],
        "categories": [
            "International Relief & Human Rights",
            "Human Services & Housing",
            "Fraternal Societies & Clubs",
            "Health",
            "Environment",
            "Arts & Museums",
            "Education & Schools",
            "Religion & Faith",
            "Military & Veterans",
            "Animals"
        ],
        "current_streak": 2,
        "settings": {
            "light_mode": true,
            "private": true
        },
        "uninterested_users": [],
        "experiences": [],
        "first_name": "Wilguens Sley",
        "fcm_token": "eU7faxAKQ0uGIrJS7gWnBY:APA91bF5-dfiZi29CmbHqqNhrxNxQ6_UPdc6Jv5kfYI3L-krLfhtLGxyqFIzGzs4n8IGHDWSPGXNqIz39HTGEvgBZj2ZM8flBvJX-POn8YP0bTNk6UfVxGEQmmzsBuueYiokUsApIwu_",
        "state": "MN",
        "uninterested_non_profits": [],
        "email": "marcaugustinwitgensley@gmail.com",
        "id": "wilguenssley"
    },
    {
        "created_at": "2023-05-03 16:45:19.860905+00:00",
        "state": "RI",
        "followee_count": 0,
        "bio": "Spiritualist\nInto Fashion modeling \nBusinesses major",
        "suggested_non_profits": [
            "27-2460560",
            "95-3998111-1",
            "95-3117048",
            "99-0315110",
            "46-1084699",
            "95-6092809-1",
            "83-3737117",
            "95-4766473",
            "27-4209721",
            "23-7381137",
            "26-0086305",
            "92-0152268",
            "99-0337979",
            "94-1055967",
            "82-1206798",
            "25-1828488",
            "95-4382752",
            "26-2932191",
            "04-2899862",
            "95-3842082",
            "47-3893175",
            "23-7003516",
            "54-0788167",
            "45-2913544"
        ],
        "interests": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c6350>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c7190>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c6450>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4e50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c6790>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c6250>"
        ],
        "tagline": "Spiritualist\nInto Fashion modeling \nBusinesses major",
        "followers": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c6050>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c7c90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4f50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c7e10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c6410>"
        ],
        "uninterested_non_profits": [],
        "suggested_users": [
            "devinqb1",
            "douejudicael",
            "leahs",
            "peter",
            "samuel",
            "ania",
            "jackstra",
            "devontaeford97",
            "jack",
            "dersainte",
            "enrick",
            "ghouge",
            "jorrynech17",
            "lolzz",
            "ookleey",
            "rico",
            "sydneeblack",
            "meganhutch10",
            "octavianaugustuscaesar",
            "starfishsocial",
            "cory",
            "agatha",
            "humptydumpty",
            "phifeann"
        ],
        "birthday": "2004-03-01T17:45:20.000Z",
        "settings": {
            "light_mode": true,
            "private": true
        },
        "nux": {
            "follow_user_np": false,
            "add_bio": true,
            "participate_challenge": false,
            "create_post": false,
            "add_np": false,
            "add_picture": false
        },
        "followees": [],
        "experiences": [],
        "city": "Providence",
        "uninterested_users": [],
        "follower_count": 1,
        "last_streak_update": "2024-05-02",
        "first_name": "Adrianna",
        "onboarded": true,
        "fcm_token": null,
        "connections": {},
        "deactivated": false,
        "email": "09rhombus_gusher@icloud.com",
        "username": "ajcartwright",
        "last_name": "Cartwright",
        "id": "ajcartwright"
    },
    {
        "created_at": "2024-06-21 21:44:42.729382+00:00",
        "followers": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4690>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c5fd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c6990>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4650>"
        ],
        "suggested_users": [
            "starfishsocial",
            "qbcam",
            "jack",
            "jackstra",
            "emmafulleer",
            "porozco05",
            "robertfanto",
            "tayfanto",
            "devinqb1",
            "johncervenka",
            "stevemott",
            "belajimenez",
            "kenziefant",
            "dk",
            "ookleey",
            "phifeann",
            "ghouge",
            "octavianaugustuscaesar",
            "dan",
            "devontaeford97",
            "jenniferlove",
            "leahs",
            "oliver",
            "samuel"
        ],
        "tagline": "Uhhh",
        "interests": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c5d10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c5f10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c6c50>"
        ],
        "admin_non_profits": [],
        "birthday": "1998-10-27T08:00:00.000Z",
        "invite_link": null,
        "nux": {
            "follow_user_np": true,
            "add_bio": true,
            "participate_challenge": false,
            "create_post": false,
            "add_np": true,
            "add_picture": false
        },
        "followees": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c7dd0>"
        ],
        "city": "Portland",
        "education": {},
        "onboarded": true,
        "connections": {
            "45-4930539": {
                "end_date": null,
                "date": "2024",
                "donor": true,
                "connection": "",
                "role": "Volunteer"
            }
        },
        "deactivated": false,
        "username": "ekarambelas",
        "last_name": "Karambelas",
        "bio": "Massage therapist, i love to give back ",
        "suggested_non_profits": [
            "52-1693387",
            "27-2460560",
            "95-3998111-1",
            "95-3117048",
            "99-0315110",
            "46-1084699",
            "95-6092809-1",
            "83-3737117",
            "95-4766473",
            "27-4209721",
            "23-7381137",
            "26-0086305",
            "92-0152268",
            "99-0337979",
            "94-1055967",
            "82-1206798",
            "25-1828488",
            "95-4382752",
            "26-2932191",
            "04-2899862",
            "95-3842082",
            "47-3893175",
            "23-7003516",
            "54-0788167"
        ],
        "categories": [
            "Environment",
            "Human Services & Housing",
            "Education & Schools",
            "Animals"
        ],
        "settings": {
            "light_mode": true,
            "private": true
        },
        "uninterested_users": [],
        "experiences": [],
        "viewed_new_feature_modal": true,
        "first_name": "Elizabeth",
        "fcm_token": null,
        "state": "OR",
        "email": "karambelase@gmail.com",
        "uninterested_non_profits": [],
        "id": "ekarambelas"
    },
    {
        "created_at": "2023-01-07 18:50:17.591032+00:00",
        "followers": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4050>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c6a50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c6d50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c45d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c6510>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4710>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c5d90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c7390>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4490>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c6810>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c74d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c69d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c65d0>"
        ],
        "suggested_users": [
            "dan",
            "jackstra",
            "morgan98",
            "lilahcervenka",
            "kisikomumbere1",
            "jwc2",
            "cory",
            "jenniferlove",
            "cmaxdudegmailcom",
            "ashleycarty",
            "meredith",
            "arikarmstead",
            "donsherry",
            "jennifer50",
            "whitney",
            "wilguenssley",
            "stal",
            "jason10",
            "hpobrien",
            "johns",
            "timmywong",
            "man_dathis",
            "jfort24",
            "kerrycervenka"
        ],
        "interests": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c7690>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c6b90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c5410>"
        ],
        "tagline": "Tbd",
        "longest_streak": 2,
        "birthday": "1993-02-05T08:00:00.000Z",
        "nux": {
            "follow_user_np": true,
            "add_bio": true,
            "participate_challenge": false,
            "create_post": false,
            "add_np": false,
            "add_picture": false
        },
        "city": "Los Angeles",
        "last_streak_update": "2024-07-09",
        "followees": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c5250>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4d10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c6390>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c5350>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c7610>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c62d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c4390>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079e8bd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079c6b10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x106e209d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077ac750>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077ad490>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x106c19c10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x106c18350>"
        ],
        "follower_count": 13,
        "onboarded": true,
        "connections": {},
        "deactivated": false,
        "username": "franklyn.cervenka39@berkeley.edu",
        "last_name": "Cervenka",
        "followee_count": 5,
        "bio": "Tbd",
        "suggested_non_profits": [
            "23-7381137",
            "95-1690977",
            "27-2460560",
            "95-4623022",
            "95-1644589",
            "95-4766473",
            "95-1643307",
            "81-2003494",
            "95-3958741",
            "95-4382752",
            "95-1642394",
            "52-1693387",
            "95-1690990",
            "51-0454157",
            "85-1362612",
            "95-6133954",
            "95-1709293",
            "95-6205398",
            "95-1691306",
            "95-4377199",
            "53-0196605",
            "95-4201985",
            "95-3507283",
            "46-0937298"
        ],
        "current_streak": 1,
        "settings": {
            "light_mode": true,
            "private": true
        },
        "uninterested_users": [],
        "experiences": [],
        "viewed_new_feature_modal": true,
        "first_name": "Frank",
        "fcm_token": "e5JePWf3J0adq-zrj245Ki:APA91bFqc8HQ70y7Pr_eynUcylhYqWQeN5ddKAv8NFRYsqLFKXUOelo5HSB089OxUAxDx15QwdvkLuX4kH_j_5tGMDYxVYKeibPjRBW0VyQS11_DVK_ObdNdAt531Qpm8n1ZKlhR1lj8",
        "uninterested_non_profits": [],
        "email": "franklyn.cervenka39@berkeley.edu",
        "state": "CA",
        "id": "franklyn.cervenka39@berkeley.edu"
    },
    {
        "created_at": "2022-12-27 19:25:14.765233+00:00",
        "followers": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107781d10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107782250>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107782450>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790f850>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791ee10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107967810>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107964fd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x106c18710>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107964350>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790e850>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107966810>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107966950>"
        ],
        "suggested_users": [
            "tommyhannan",
            "jackstra",
            "garenishandsome",
            "e",
            "everettpam",
            "octavianaugustuscaesar",
            "kisikomumbere1",
            "vienna",
            "michael1jackson",
            "eman",
            "phifeann",
            "morgan98",
            "nataliesuh",
            "dwilliams",
            "ghouge",
            "leahs",
            "clairejolie",
            "bohardin120",
            "brycebarron",
            "jacobmayani",
            "peter",
            "tauris",
            "itsacoleworld",
            "jaisapinn"
        ],
        "interests": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107965210>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107965010>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079641d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079675d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107964610>"
        ],
        "longest_streak": 1,
        "birthday": "2000-06-27T07:00:00.000Z",
        "nux": {
            "follow_user_np": false,
            "add_bio": false,
            "participate_challenge": false,
            "create_post": false,
            "add_np": false,
            "add_picture": false
        },
        "city": "Pasadena",
        "last_streak_update": "2024-06-18",
        "followees": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107965f10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107966c50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107965250>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107964990>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107967190>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107965690>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10521ae50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x106bb8e50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1063a2d10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107964810>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10799ddd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10799ccd0>"
        ],
        "follower_count": 6,
        "education": {
            "clemson.edu": {
                "end_date": "2022",
                "major": "Industrial Engineering",
                "connection": "",
                "start_date": "2018",
                "name": "Clemson University"
            }
        },
        "onboarded": true,
        "connections": {},
        "deactivated": false,
        "username": "gcervenka",
        "last_name": "Cervenka",
        "followee_count": 9,
        "bio": "",
        "suggested_non_profits": [
            "23-7381137",
            "95-1690977",
            "95-4623022",
            "27-2460560",
            "95-1644589",
            "81-2003494",
            "95-4044568",
            "95-3117048",
            "95-4766473",
            "95-1642394",
            "95-1690990",
            "85-1362612",
            "95-1643307",
            "95-1691306",
            "95-4201985",
            "26-0086305",
            "53-0196605",
            "95-4382752",
            "82-4058203",
            "51-0454157",
            "95-6133954",
            "83-2300241",
            "92-2659436",
            "95-1648219"
        ],
        "current_streak": 1,
        "settings": {
            "light_mode": true,
            "private": true
        },
        "uninterested_users": [],
        "experiences": [],
        "viewed_new_feature_modal": true,
        "first_name": "Georgia",
        "fcm_token": "feHWdvLxl04Rk9gwvk3V_j:APA91bENDeJdiX7Ib3urwm8b5jZrQG1q91txuKmn6OZFoqqJYH-CjbE7WgctOqJdGKetuJnUeJdJ7gkBByPRHYtx0UOkbce5KWAmmayo7FNhNY_A0ojqYZh76JzEBWhkcOW9hA-wRvmd",
        "uninterested_non_profits": [],
        "email": "georgia.cervenka@gmail.com",
        "state": "CA",
        "id": "gcervenka"
    },
    {
        "created_at": "2023-05-18 00:23:28.597025+00:00",
        "state": "NY",
        "followee_count": 0,
        "bio": "I love animal and rainforest non-profits! ",
        "suggested_non_profits": [
            "27-2460560",
            "95-3998111-1",
            "95-3117048",
            "99-0315110",
            "46-1084699",
            "95-6092809-1",
            "83-3737117",
            "95-4766473",
            "27-4209721",
            "23-7381137",
            "26-0086305",
            "92-0152268",
            "99-0337979",
            "94-1055967",
            "82-1206798",
            "25-1828488",
            "95-4382752",
            "26-2932191",
            "04-2899862",
            "95-3842082",
            "47-3893175",
            "23-7003516",
            "54-0788167",
            "45-2913544"
        ],
        "interests": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10799c250>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10799f690>"
        ],
        "followers": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10799d150>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10799d190>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10799fd90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10799e610>"
        ],
        "tagline": "I love animal and rainforest non-profits! ",
        "suggested_users": [
            "jackstra",
            "ghouge",
            "peter",
            "phifeann",
            "humptydumpty",
            "jack",
            "ajcartwright",
            "devinqb1",
            "gcervenka",
            "itsacoleworld",
            "leahs",
            "michael1jackson",
            "octavianaugustuscaesar",
            "ookleey",
            "rstahley",
            "samuel",
            "starfishsocial",
            "tauris",
            "tigerwoodss",
            "vienna",
            "cory",
            "man_dathis",
            "rendor",
            "johncervenka"
        ],
        "uninterested_non_profits": [],
        "birthday": "2000-07-28T00:23:29.000Z",
        "settings": {
            "light_mode": true,
            "private": true
        },
        "nux": {
            "follow_user_np": false,
            "add_bio": true,
            "participate_challenge": false,
            "create_post": false,
            "add_np": false,
            "add_picture": false
        },
        "followees": [],
        "experiences": [],
        "city": "new york",
        "uninterested_users": [],
        "follower_count": 1,
        "last_streak_update": "2024-05-02",
        "first_name": "Jaisa",
        "onboarded": true,
        "fcm_token": null,
        "connections": {},
        "deactivated": false,
        "email": "jaisapinn@gmail.com",
        "username": "jaisapinn",
        "last_name": "Pinnock",
        "id": "jaisapinn"
    },
    {
        "created_at": "2024-01-13 01:12:54.670660+00:00",
        "followers": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107998650>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10799b390>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107999790>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10799a690>"
        ],
        "suggested_users": [
            "wilguenssley",
            "kelleycervenka",
            "starfishsocial",
            "oliver",
            "dk",
            "stal",
            "jackstra",
            "donsherry",
            "octavianaugustuscaesar",
            "faneldabija2008",
            "moiseekyela",
            "ookleey",
            "cory",
            "timmywong",
            "franklyn.cervenka39@berkeley.edu",
            "tommyhannan",
            "given",
            "jamal_hansen",
            "alexwf",
            "jessstovall22",
            "dwilliams",
            "ghouge",
            "robyncervenka",
            "dan"
        ],
        "interests": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079a89d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10799da10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079aaad0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079a9010>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ab110>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079a9ad0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079a9890>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079abf50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079a8a90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079aa110>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079abe90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079a9050>"
        ],
        "tagline": "Lets communicate and get a long together ",
        "admin_non_profits": [
            "86-1336741"
        ],
        "birthday": "1995-02-17T07:00:00.000Z",
        "invite_link": null,
        "nux": {
            "follow_user_np": true,
            "add_bio": true,
            "participate_challenge": false,
            "create_post": true,
            "add_np": false,
            "add_picture": true
        },
        "city": "Wellington",
        "last_streak_update": "2024-05-02",
        "followees": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079aa650>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ab150>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079a80d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079aa8d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079a82d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x106df1d50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x106df1190>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x106df0ad0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x106df1750>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079a9d50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x106df2350>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x106df2b90>"
        ],
        "onboarded": true,
        "connections": {
            "86-1336741": {
                "date": "2023",
                "connection": "",
                "role": "Employee"
            }
        },
        "deactivated": false,
        "username": "jenniferlove",
        "last_name": "Jennifer",
        "bio": "Lets communicate and get a long together ",
        "suggested_non_profits": [
            "95-1690977",
            "27-2460560",
            "46-1084699",
            "95-6092809-1",
            "83-3737117",
            "95-4766473",
            "27-4209721",
            "23-7381137",
            "92-0152268",
            "94-1055967",
            "82-1206798",
            "25-1828488",
            "46-0565393",
            "87-0217280",
            "95-4623022",
            "31-1728910",
            "26-0086305",
            "95-4382752",
            "23-7003516",
            "54-0788167",
            "45-2913544",
            "45-3798803",
            "94-2699571",
            "73-0739626"
        ],
        "categories": [
            "Education & Schools",
            "Health",
            "Religion & Faith",
            "Arts & Museums",
            "International Relief & Human Rights"
        ],
        "settings": {
            "light_mode": true,
            "private": true
        },
        "uninterested_users": [],
        "experiences": [],
        "first_name": "Byrrd",
        "fcm_token": "fWAbTqIetUG6rvBULAt2T4:APA91bH0S_taTcTTFLMVXP8879GWVCQjVfO4mW3MWViebWeir_P3Ubq7pIuh7MDBculcD2WRlt9c7zX04RJWGtu-3WcSthzAkLVNZy9dGXhtPojrhxQWibH9AW182Z77miPtwtPzwgGI",
        "state": "UT",
        "email": "byrrdjennifer@gmail.com",
        "uninterested_non_profits": [],
        "id": "jenniferlove"
    },
    {
        "created_at": "2023-06-28 15:31:04.992790+00:00",
        "followers": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107904890>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079073d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107905090>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107906310>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107904510>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107904250>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079063d0>"
        ],
        "suggested_users": [
            "jack",
            "jack123",
            "tommyhannan",
            "jackstra",
            "starfishsocial",
            "fobbarley",
            "hellogoodbye",
            "steverino21",
            "stevemott",
            "humptydumpty",
            "nikokaplan",
            "ilovejesus",
            "onetwothree",
            "brycebarron",
            "derrian",
            "e",
            "eman",
            "gcervenka",
            "jacktest",
            "kisikomumbere1",
            "michael1jackson",
            "sylvie",
            "careymott",
            "cmaxdudegmailcom"
        ],
        "interests": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107905bd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107907190>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107907c10>"
        ],
        "tagline": "Test",
        "invite_link": null,
        "birthday": "2000-03-11T16:01:13.000Z",
        "nux": {
            "follow_user_np": false,
            "add_bio": true,
            "participate_challenge": false,
            "create_post": false,
            "add_np": false,
            "add_picture": false
        },
        "followees": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107904290>"
        ],
        "last_streak_update": "2024-05-02",
        "city": "Tampa",
        "onboarded": true,
        "stripe_customer_id": "cus_OALC6pWCPJVvWU",
        "deactivated": false,
        "connections": {
            "87-2031991": {
                "date": "2022",
                "connection": "Best friend works here. Great cause",
                "role": "Supporter"
            }
        },
        "username": "jphifer311",
        "last_name": "Phifer",
        "bio": "Test",
        "suggested_non_profits": [
            "87-3296836",
            "84-3036723",
            "83-2914563",
            "91-1901103",
            "27-4345913",
            "59-1819652",
            "65-0594599",
            "20-2370934",
            "85-0630760",
            "59-6002321",
            "81-2511794",
            "27-2460560",
            "95-3117048",
            "99-0315110",
            "46-1084699",
            "95-6092809-1",
            "95-4766473",
            "27-4209721",
            "23-7381137",
            "26-0086305",
            "92-0152268",
            "94-1055967",
            "82-1206798",
            "25-1828488"
        ],
        "categories": [
            "Health / Human Services / Housing",
            "Fraternal Societies / Clubs"
        ],
        "settings": {
            "light_mode": true,
            "private": true
        },
        "uninterested_users": [],
        "experiences": [],
        "first_name": "Jack",
        "fcm_token": "ea005q7umk53lbOofdXXR-:APA91bE2ooK8bXhnG7uSIFqG2TxcWQ4jtG8z0zRNGPxs7NUSjKJhrgJT-UHA4cLIWbLNibWPVmO-77vsPYwSO7ryuRoAbHyWjvBy1oMLasEPjtsIc5IRn0UgofPwzO33CfcTPSBtzsws",
        "uninterested_non_profits": [],
        "email": "jack@scopelabs.com",
        "state": "FL",
        "id": "jphifer311"
    },
    {
        "created_at": "2023-01-16 18:42:45.591138+00:00",
        "followers": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107906ad0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107905a90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107904ad0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107906e10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107907a10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079070d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107906350>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107905490>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107907e90>"
        ],
        "suggested_users": [
            "cory",
            "rendor",
            "jason10",
            "christinerenee",
            "scottstar",
            "jackstra",
            "garmsusd",
            "jm06",
            "anna",
            "kind47",
            "octavianaugustuscaesar",
            "samuel",
            "jenniferlove",
            "johnua",
            "morgan98",
            "dwilliams",
            "kimburbank",
            "lpbaby229",
            "jackson",
            "gavin",
            "charles",
            "delgado101",
            "gomezelizabeth57yahoocom",
            "kgnoco"
        ],
        "interests": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107906a90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107904410>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107907110>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107907d90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107906a10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107905f50>"
        ],
        "longest_streak": 1,
        "birthday": "1982-01-21T18:42:49.000Z",
        "nux": {
            "follow_user_np": true,
            "add_bio": true,
            "participate_challenge": false,
            "create_post": false,
            "add_np": false,
            "add_picture": false
        },
        "city": "Manassas",
        "last_streak_update": "2024-07-29",
        "followees": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107907810>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107905dd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107905410>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107907d50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107907350>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107905c50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107904f10>"
        ],
        "follower_count": 4,
        "onboarded": true,
        "connections": {},
        "deactivated": false,
        "username": "mvanzetta",
        "last_name": "VanZetta",
        "followee_count": 7,
        "bio": "I have been in broadcasting for 20 years and now create my own content telling stories about people on the frontlines and who they help",
        "suggested_non_profits": [
            "13-1623829",
            "46-1084699",
            "53-0196605",
            "83-3737117",
            "92-0152268",
            "23-7003516",
            "54-0788167",
            "46-0565393",
            "13-5562162",
            "53-0193519",
            "52-0899215",
            "31-1728910",
            "13-1624016",
            "23-7381137",
            "27-2460560",
            "26-0710805",
            "95-1690977",
            "23-1907729",
            "54-2034061",
            "58-2368165",
            "95-4789334",
            "13-1656653",
            "13-3720473",
            "13-1760110"
        ],
        "current_streak": 1,
        "settings": {
            "light_mode": true,
            "private": true
        },
        "uninterested_users": [],
        "experiences": [],
        "first_name": "Michael",
        "fcm_token": null,
        "uninterested_non_profits": [],
        "email": "mike@pizettamedia.com",
        "state": "VA",
        "id": "mvanzetta"
    },
    {
        "created_at": "2023-02-01 23:40:02.104835+00:00",
        "state": "NY",
        "followee_count": 0,
        "bio": "Seeing the good!",
        "suggested_non_profits": [
            "27-2460560",
            "95-3998111-1",
            "95-3117048",
            "99-0315110",
            "46-1084699",
            "95-6092809-1",
            "83-3737117",
            "95-4766473",
            "27-4209721",
            "23-7381137",
            "26-0086305",
            "92-0152268",
            "99-0337979",
            "94-1055967",
            "82-1206798",
            "25-1828488",
            "95-4382752",
            "26-2932191",
            "04-2899862",
            "95-3842082",
            "47-3893175",
            "23-7003516",
            "54-0788167",
            "45-2913544"
        ],
        "interests": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107905fd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107904390>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107904dd0>"
        ],
        "followers": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107906510>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107904790>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107906650>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107907f90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107907010>"
        ],
        "tagline": "Seeing the good!",
        "uninterested_non_profits": [],
        "suggested_users": [
            "ajcartwright",
            "ania",
            "ghouge",
            "jack",
            "jackstra",
            "devinqb1",
            "devontaeford97",
            "henlaidyduran",
            "leahs",
            "octavianaugustuscaesar",
            "ookleey",
            "samuel",
            "humptydumpty",
            "jaisapinn",
            "cory",
            "elsiebyler",
            "jackstarch",
            "joesepho",
            "meganhutch10",
            "starfishsocial",
            "sydneeblack",
            "al",
            "dersainte",
            "douejudicael"
        ],
        "birthday": "2001-09-26T22:40:06.000Z",
        "settings": {
            "light_mode": true,
            "private": true
        },
        "nux": {
            "follow_user_np": false,
            "add_bio": true,
            "participate_challenge": false,
            "create_post": false,
            "add_np": false,
            "add_picture": false
        },
        "city": "New York",
        "experiences": [],
        "followees": [],
        "uninterested_users": [],
        "follower_count": 3,
        "last_streak_update": "2024-05-02",
        "first_name": "Peter",
        "onboarded": true,
        "fcm_token": null,
        "connections": {},
        "deactivated": false,
        "email": "cory@gmail.com",
        "username": "peter",
        "last_name": "O’Neel",
        "id": "peter"
    },
    {
        "created_at": "2024-02-15 19:47:47.230490+00:00",
        "state": "CA",
        "connections": {
            "92-2659436": {
                "date": "2023",
                "connection": "",
                "role": "Employee"
            }
        },
        "bio": " ",
        "suggested_non_profits": [
            "27-2460560",
            "95-3998111-1",
            "95-3117048",
            "99-0315110",
            "46-1084699",
            "95-6092809-1",
            "83-3737117",
            "95-4766473",
            "27-4209721",
            "23-7381137",
            "26-0086305",
            "92-0152268",
            "99-0337979",
            "94-1055967",
            "82-1206798",
            "25-1828488",
            "95-4382752",
            "26-2932191",
            "04-2899862",
            "95-3842082",
            "47-3893175",
            "23-7003516",
            "54-0788167",
            "45-2913544"
        ],
        "categories": [
            "Human Services & Housing",
            "Fraternal Societies & Clubs",
            "Animals",
            "Environment",
            "Education & Schools"
        ],
        "interests": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079052d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107905850>"
        ],
        "admin_non_profits": [
            "92-2659436"
        ],
        "followers": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107907c90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079065d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107904150>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107906b50>"
        ],
        "suggested_users": [
            "gcervenka",
            "johncervenka",
            "starfishsocial",
            "stevemott",
            "jack",
            "jackstra",
            "devinqb1",
            "ghouge",
            "jaisapinn",
            "jamal_hansen",
            "leahs",
            "michael1jackson",
            "octavianaugustuscaesar",
            "ookleey",
            "peter",
            "phifeann",
            "samuel",
            "tauris",
            "dan",
            "dwilliams",
            "miagreen",
            "morgan98",
            "twebb",
            "careymott"
        ],
        "birthday": "2000-11-02T08:00:00.000Z",
        "settings": {
            "light_mode": true,
            "private": true
        },
        "nux": {
            "follow_user_np": true,
            "add_bio": true,
            "participate_challenge": false,
            "create_post": false,
            "add_np": false,
            "add_picture": false
        },
        "uninterested_users": [],
        "experiences": [],
        "followees": [],
        "invite_link": null,
        "city": "Los Angeles",
        "last_streak_update": "2024-05-02",
        "first_name": "Vienna",
        "onboarded": true,
        "fcm_token": "dXTWSuDGx0rThwTrmWUw2V:APA91bE0FIYFmGZPyIzWr_xwD3D8MTXXUZglkAbMJlYIVD87_wdzgWtMMWJlchsJMmSA_8KUrgFG4LZsFsv8iQtTyGzOnxP3K0-5r_TzVZ5sc5lsLsQ01eYbi95t7JWeuwTmgK06D9O3",
        "uninterested_non_profits": [],
        "email": "vienna@stellify.org",
        "deactivated": false,
        "username": "vienna",
        "last_name": "Webb",
        "id": "vienna"
    },
    {
        "created_at": "2024-05-31 09:40:09.818046+00:00",
        "followers": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079dc290>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079dead0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079dd610>"
        ],
        "suggested_users": [
            "billy",
            "jackstra",
            "jerryrichardson",
            "johnstra",
            "cory",
            "johncervenka",
            "stevemott",
            "tommyhannan",
            "loryncervenka",
            "paulcervenka",
            "careymott",
            "jack",
            "dan",
            "gkonicov@gmail.com",
            "llansburgh",
            "morgan98",
            "starfishsocial",
            "lilahcervenka",
            "cmaxdudegmailcom",
            "jimmilley",
            "chrisfdunbar",
            "jfort24",
            "robyncervenka",
            "timscott"
        ],
        "tagline": "",
        "interests": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079dd990>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ddd90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079dd6d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079de3d0>"
        ],
        "admin_non_profits": [],
        "invite_link": null,
        "birthday": "1993-02-20T09:40:19.997Z",
        "nux": {
            "follow_user_np": true,
            "add_bio": true,
            "participate_challenge": false,
            "create_post": false,
            "add_np": false,
            "add_picture": true
        },
        "followees": [],
        "city": "New York",
        "education": {},
        "onboarded": true,
        "connections": {},
        "deactivated": false,
        "username": "akihiro",
        "last_name": "Ina",
        "bio": "checking app",
        "suggested_non_profits": [
            "27-2460560",
            "95-3998111-1",
            "95-3117048",
            "99-0315110",
            "46-1084699",
            "95-6092809-1",
            "83-3737117",
            "95-4766473",
            "27-4209721",
            "23-7381137",
            "26-0086305",
            "92-0152268",
            "99-0337979",
            "94-1055967",
            "82-1206798",
            "25-1828488",
            "95-4382752",
            "26-2932191",
            "04-2899862",
            "95-3842082",
            "47-3893175",
            "23-7003516",
            "54-0788167",
            "45-2913544"
        ],
        "categories": [
            "Arts & Museums"
        ],
        "settings": {
            "light_mode": true,
            "private": true
        },
        "uninterested_users": [],
        "experiences": [],
        "first_name": "Akihiro",
        "fcm_token": "faUthNmxRg-2vcqnhWpBgC:APA91bEYmB8w7pDFQ5vSQukcV2Z2izORO--pbTCC2W-P3ZuzIwQdR_xC-fGm7Opp3Fm8dmvsborUBwgqbB75TvqGd7AB8YV9Mij36pTZI2x2yfyzRWaCIn_osEqSrMEuwIsWmHn4blU-",
        "state": "NY",
        "uninterested_non_profits": [],
        "email": "akihiro999ina@gmail.com",
        "id": "akihiro"
    },
    {
        "created_at": "2023-06-21 18:43:43.970258+00:00",
        "state": "NY",
        "followers": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079dcc10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079dcdd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079dc8d0>"
        ],
        "bio": "I am determined, motivated, intelligent, nice, cool. ",
        "suggested_non_profits": [
            "27-2460560",
            "95-3998111-1",
            "95-3117048",
            "99-0315110",
            "46-1084699",
            "95-6092809-1",
            "83-3737117",
            "95-4766473",
            "27-4209721",
            "23-7381137",
            "26-0086305",
            "92-0152268",
            "99-0337979",
            "94-1055967",
            "82-1206798",
            "25-1828488",
            "95-4382752",
            "26-2932191",
            "04-2899862",
            "95-3842082",
            "47-3893175",
            "23-7003516",
            "54-0788167",
            "45-2913544"
        ],
        "interests": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079dd0d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10792d7d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079df4d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10792cc90>"
        ],
        "tagline": "I am determined, motivated, intelligent, nice, cool. ",
        "uninterested_non_profits": [],
        "suggested_users": [
            "ajcartwright",
            "peter",
            "jack",
            "devinqb1",
            "leahs",
            "meganhutch10",
            "octavianaugustuscaesar",
            "ookleey",
            "samuel",
            "jackstra",
            "devontaeford97",
            "ghouge",
            "henlaidyduran",
            "starfishsocial",
            "sydneeblack",
            "cory",
            "dersainte",
            "douejudicael",
            "elsiebyler",
            "enrick",
            "rico",
            "jackstarch",
            "joesepho",
            "agatha"
        ],
        "birthday": "2002-02-25T19:43:45.000Z",
        "settings": {
            "light_mode": true,
            "private": true
        },
        "nux": {
            "follow_user_np": false,
            "add_bio": true,
            "participate_challenge": false,
            "create_post": false,
            "add_np": false,
            "add_picture": false
        },
        "followees": [],
        "experiences": [],
        "city": "Queens",
        "uninterested_users": [],
        "invite_link": null,
        "last_streak_update": "2024-05-02",
        "first_name": "Ania Eloïe",
        "onboarded": true,
        "fcm_token": null,
        "connections": {},
        "deactivated": false,
        "email": "ania.sylvain74@student.qcc.cuny.edu",
        "username": "ania",
        "last_name": "Sylvain",
        "id": "ania"
    },
    {
        "created_at": "2023-09-25 22:13:39.247498+00:00",
        "followers": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10792ffd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107949b10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079490d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079486d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10794bfd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10794bad0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107949610>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107948650>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107949190>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10792c9d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10794a550>"
        ],
        "suggested_users": [
            "dwilliams",
            "dan",
            "jack",
            "jsiegrist",
            "careymott",
            "chrisfdunbar",
            "jackstra",
            "aj",
            "gmrenollet@gmail.com",
            "jimmilley",
            "sruiz",
            "al",
            "bryan",
            "jodiburns",
            "deointx",
            "msaffren",
            "jfort24",
            "franklyn.cervenka39@berkeley.edu",
            "sfoley",
            "cherry",
            "tammi9691",
            "abbypodcast",
            "katherineblair",
            "kangaroux"
        ],
        "tagline": "Air Force Brat/Mom/Producer/Filmmaker",
        "interests": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107948f50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10794bb10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079494d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10794a950>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107948e50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10794b790>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107949090>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107949d90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107948fd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10794b450>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790ef50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790fbd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790d890>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790fe10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790eb10>"
        ],
        "admin_non_profits": [],
        "birthday": "1970-03-01T23:13:40.000Z",
        "invite_link": null,
        "nux": {
            "follow_user_np": true,
            "add_bio": true,
            "participate_challenge": true,
            "create_post": true,
            "add_np": true,
            "add_picture": true
        },
        "city": "Glendale",
        "last_streak_update": "2024-05-02",
        "followees": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790c210>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790fc90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790ea10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790ca50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790f950>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790f590>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790d990>"
        ],
        "onboarded": true,
        "connections": {
            "36-2182171": {
                "date": "1989",
                "connection": "Attended and now children attending",
                "role": "Supporter"
            },
            "20-3557068": {
                "date": "2013",
                "connection": "Hapoy to support the governing body of pickleball",
                "role": "Supporter"
            },
            "86-1210532": {
                "date": "2018",
                "connection": "Pickleball Channel aupports the Pickleball Hall of Fame!",
                "role": "Volunteer"
            },
            "95-1971148": {
                "date": "2013",
                "connection": "Children attended here for 8 years. ",
                "role": "Supporter"
            }
        },
        "deactivated": false,
        "username": "meredith",
        "last_name": "Howes",
        "bio": "Following God; coaching my adult kids through life; seeking joy",
        "suggested_non_profits": [
            "27-2460560",
            "23-7381137",
            "95-1690977",
            "95-4623022",
            "95-4766473",
            "95-1642394",
            "51-0454157",
            "95-6133954",
            "95-1643307",
            "81-2003494",
            "01-0865460",
            "95-1644589",
            "95-6205398",
            "95-4377199",
            "95-3507283",
            "46-0937298",
            "26-0086305",
            "95-4382752",
            "95-1690990",
            "85-1362612",
            "92-2659436",
            "95-1709293",
            "87-3499631",
            "95-4834034"
        ],
        "categories": [
            "Education / Schools",
            "Religion / Faith",
            "International Relief / Human Rights",
            "Environment / Animals",
            "Arts / Museums / Veterans",
            "Health / Human Services / Housing"
        ],
        "settings": {
            "light_mode": true,
            "private": true
        },
        "uninterested_users": [],
        "experiences": [],
        "first_name": "Meredith",
        "fcm_token": "dzXTlgwWs0DFli5unuryVr:APA91bEBkV2DgCVfhG0PHJKw6yRw9SLneunAOI2fx3bsXOK4b94KCtDKhcaIzwFMJc-sqXnZi44MpLieDhTDV57ntDKrAX0mIvrSnSyKYP5g1yg3BuYJmbnDcJR9fYiV2GxVuBymYzPA",
        "state": "CA",
        "email": "merhowes@gmail.com",
        "uninterested_non_profits": [],
        "id": "meredith"
    },
    {
        "created_at": "2023-02-12 18:02:34.424493+00:00",
        "state": "CA",
        "followee_count": 1,
        "bio": "I am Timothy and i have black curly hair",
        "suggested_non_profits": [
            "13-1644147",
            "27-2460560",
            "95-3998111-1",
            "95-3117048",
            "99-0315110",
            "46-1084699",
            "95-6092809-1",
            "83-3737117",
            "95-4766473",
            "27-4209721",
            "23-7381137",
            "26-0086305",
            "92-0152268",
            "99-0337979",
            "94-1055967",
            "82-1206798",
            "25-1828488",
            "95-4382752",
            "26-2932191",
            "04-2899862",
            "95-3842082",
            "47-3893175",
            "23-7003516",
            "54-0788167"
        ],
        "interests": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790fd10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790c5d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790f190>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790f910>"
        ],
        "followers": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790d110>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790f650>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790dcd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790ee10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790f5d0>"
        ],
        "tagline": "I am Timothy and i have black curly hair",
        "uninterested_non_profits": [],
        "suggested_users": [
            "jackstra",
            "jenniferlove",
            "oliver",
            "starfishsocial",
            "wilguenssley",
            "stevemott",
            "johncervenka",
            "morgan98",
            "loryncervenka",
            "careymott",
            "jack",
            "robyncervenka",
            "kelleycervenka",
            "dan",
            "devinqb1",
            "dk",
            "donsherry",
            "faneldabija2008",
            "ghouge",
            "given",
            "kenziefant",
            "moiseekyela",
            "octavianaugustuscaesar",
            "ookleey"
        ],
        "birthday": "1995-07-12T17:02:37.000Z",
        "settings": {
            "light_mode": true,
            "private": true
        },
        "nux": {
            "follow_user_np": false,
            "add_bio": true,
            "participate_challenge": false,
            "create_post": false,
            "add_np": false,
            "add_picture": false
        },
        "city": "Los Angeles",
        "experiences": [],
        "followees": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790d2d0>"
        ],
        "uninterested_users": [],
        "follower_count": 3,
        "last_streak_update": "2024-05-02",
        "first_name": "Timothy",
        "onboarded": true,
        "fcm_token": null,
        "connections": {},
        "email": "timothynotwong95@gmail.com",
        "deactivated": false,
        "username": "timmywong",
        "last_name": "Wong",
        "id": "timmywong"
    }
]

export const sample_nps = [
    {
        "address1": "394 Taugwonk Rd",
        "website_url": "coastguardfoundation.org",
        "code": "W",
        "contact_title": "Senior Director, Communications",
        "financials": {
            "total_revenue": 10406014,
            "total_assets": 21630462,
            "fiscal_year": 2021,
            "form_type": "990",
            "total_expenses": 7663013
        },
        "mission": "The Coast Guard Foundation partners with the Coast Guard to provide resources to members and families that build resilience and strengthen the entire community.",
        "ein": "04-2899862",
        "city": "Stonington",
        "contact_email": "jfyke@coastguardfoundation.org",
        "contact_name": "Ms. Jennifer Fyke",
        "admins": {
            "chriscoastguardfoundation": {
                "name": "Chris McBriarty",
                "role": "Employee"
            }
        },
        "name": "COAST GUARD FOUNDATION INC",
        "queried": true,
        "cover_photo": "",
        "logo_url": "https://storage.googleapis.com/starfish-seethegood.appspot.com/non_profits/profile_picture/04-2899862%281%29?Expires=33134745600&GoogleAccessId=firebase-adminsdk-pqols%40starfish-seethegood.iam.gserviceaccount.com&Signature=qcLPHjoOsPEOUYkOmYKnqWVBZUaLtt0cz2Bd99U3CiA3KRTnBclHGIKpp%2Bhj5SoSOOT3Mey7yT3hOqWKCvb7xpAflgocqReTY2tgOvH9HHa7o3VKrg5JthKxpWZEx0EKGBPpNUbLXZC8QmRll3zk%2FGVAPmhNh6kI29tTKfjJu6qlJv84QDr44iwWo%2BG8orM2gWGfHtTf1HZ1D7QWJDtI6f47T5wEugWq%2BCyXsX97YMQaZrnLpAUKva0Pf6SJHgAXVjlS8Xz%2FESm6NIcUR9xT88%2BX136rIyzLpDBUUOpv84S3QZ7tJc2sSHIb5yHvij%2F6qfRFT2HBbRaHY0rzBLpoDg%3D%3D",
        "ruling_year": 1986,
        "interested": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079908d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107991cd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107990410>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107992950>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079929d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079bcc90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079bce90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079beb90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079bfc50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107961e50>"
        ],
        "category": "Military/Veterans' Organizations",
        "affiliation": "3",
        "num_employees": "21",
        "zip": "06378",
        "state": "CT",
        "change_id": "n_A6ameIzSsgx1b2cxTW5zUjn0",
        "id": "04-2899862"
    },
    {
        "address1": "501 Kings Hwy E Ste 400",
        "website_url": "www.savethechildren.org",
        "code": "Q",
        "contact_title": "",
        "financials": {
            "total_revenue": 808658178,
            "total_assets": 418433982,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 765901311
        },
        "mission": "Save the Children believes every child deserves a future. In the United States and around the world, we work every day to give children a healthy start in life, the opportunity to learn and protection from harm. When crisis strikes, and children are most vulnerable, we are always among the first to respond and the last to leave. We ensure children’s unique needs are met and their voices are heard. We deliver lasting results for millions of children, including those hardest to reach. We do whatever it takes for children – every day and in times of crisis – transforming their lives and the future we share.",
        "ein": "06-0726487",
        "city": "Fairfield",
        "contact_email": "erossetti@savechildren.org",
        "contact_name": "Ettore Rossetti",
        "admins": {
            "jack": {
                "name": "Jack Phifer",
                "role": "Employee"
            }
        },
        "name": "Save the Children Federation, Inc.",
        "queried": true,
        "cover_photo": "https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/placeholders%2Fnon_profit_covers%2Fabstract%2F1.jpg?alt=media",
        "logo_url": "https://storage.googleapis.com/starfish-seethegood.appspot.com/non_profits/profile_picture/06-0726487%281%29?Expires=33134745600&GoogleAccessId=firebase-adminsdk-pqols%40starfish-seethegood.iam.gserviceaccount.com&Signature=F2YZMoXdb4JuMsXFi0BNWurhjyhmwotRlr%2BI4Oo4OqL871yslacoB%2BJ%2FBum5qGpNceuY99i6YBlGODhgwO8AGhmEpXcrABh3eeTvdIhFTGyWZHiTW8eQV2etaZL9nkM6wAnz6KEPubfojvZouiyWBaCIdF2eh6BVlafQoEedbngT6NpmpsUMG0TQiYeL3u%2BXxu1oDZhbE%2FXLR%2FI0Hat7w3JejE6oNTMylQDEO42IbR%2BicIR9LOq%2FYRgxjvBrbcrg4MKURv5qKO44qIu%2BfoEHdFtZycaE7Al2%2Bz%2FfuxFaa7EyRRAachIWrCCJ9jByk1BcJrcXZn0Ya9FdBwb9BOwU4g%3D%3D",
        "ruling_year": 1964,
        "affiliation": "3",
        "category": "International Development, Relief Services",
        "interested": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079bec50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079bd290>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079bfd50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x106e07c10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x106e06bd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x106e05b90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x106e07410>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x106e06410>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x106e06010>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077ac310>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x106e05a50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10793ca90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10793ce10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10793d9d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10793cf90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10793ddd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10793e490>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10793c950>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ef5d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ec290>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ef350>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ed750>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ef010>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ef910>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079eed10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079eeb90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ef310>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ef790>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079efcd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079edf50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ed190>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079edf90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079eefd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079efc90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079eea90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ee310>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079eeed0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ee9d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ef850>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ecc50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ee1d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791ee90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791e790>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791c3d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791c350>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791d5d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791f8d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791e550>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791f450>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791c5d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791ed90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791e8d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791c9d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791d950>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791f750>"
        ],
        "num_employees": "1469",
        "zip": "06825",
        "state": "CT",
        "change_id": "n_IcMwcjqOLDMdMYzyIWjztaC8",
        "id": "06-0726487"
    },
    {
        "address1": "420 5th Ave",
        "website_url": "www.girlscouts.org",
        "code": "O",
        "contact_title": "Finance Coordinator",
        "financials": {
            "total_revenue": 112222860,
            "total_assets": 258877900,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 122770123
        },
        "mission": "WITH THE SUPPORT OF CARING ADULT VOLUNTEERS AND MENTORS, GIRL SCOUTS     EXPLORE STEM (SCIENCE, TECHNOLOGY, ENGINEERING, AND MATH), THE     OUTDOORS, AND ENTREPRENEURSHIP, ALL WHILE DEVELOPING CRUCIAL LIFE     SKILLS THAT SERVE THEM WELL BEYOND THEIR TIME AS GIRL MEMBERS. THE GIRL     SCOUT PROGRAM IS PROVEN TO HELP GIRLS THRIVE IN FIVE KEY WAYS     DEVELOPING A STRONG SENSE OF SELF; SEEKING CHALLENGES AND LEARNING FROM     SETBACKS; DISPLAYING POSITIVE VALUES; FORMING AND MAINTAINING HEALTHY     RELATIONSHIPS; AND IDENTIFYING AND SOLVING PROBLEMS.",
        "ein": "13-1624016",
        "city": "New York City",
        "contact_email": "OfficeoftheCFO@girlscouts.org",
        "contact_name": "Ms. Liana Gudin",
        "admins": {},
        "name": "GIRL SCOUTS OF THE UNITED STATES OF AMERICA",
        "queried": true,
        "cover_photo": "",
        "logo_url": "https://storage.googleapis.com/starfish-seethegood.appspot.com/non_profits/profile_picture/13-1624016%281%29?Expires=33134745600&GoogleAccessId=firebase-adminsdk-pqols%40starfish-seethegood.iam.gserviceaccount.com&Signature=RDUA%2FayReQFyyB8jNeDZm6eUNWrs7dP3TkQwrNnFCXyVfrS8%2FEs4o0k%2Bg8pjYE8jkXPNtaDQjask7DrcLIA9%2BlUGj171s0FWYa%2Fy%2ByAYCuj9NIbczMlYXq90RhRV6GdwVwMVvCvUnS6EyE1gZnkYBLMvVGTjKyQfCnwk31ojEPM7A8QsCOPsnk%2BwBROWY%2FnOyNIHVEmwFR1OaxHkMNATPMGo2uBuudCv6kwzwh%2BM9cM977D8EPD70jzChwukBYWm92W46W6ED5nz9g3OMOZ8QDy3VYWzNihWm8QkAr7dFkc8p%2Fgq102wfMPFkxlPVFPEUywaQdwIEEPcxlu%2BYTHQpg%3D%3D",
        "ruling_year": 1919,
        "affiliation": "1",
        "category": "Girl Scouts",
        "interested": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791f510>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791c050>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791df10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791ec10>"
        ],
        "num_employees": "558",
        "zip": "10018",
        "state": "NY",
        "change_id": "n_OckAhQQuIP9oD6z9PFrivdHm",
        "id": "13-1624016"
    },
    {
        "address1": "350 5th Ave Fl 34",
        "website_url": "www.hrw.org",
        "code": "Q",
        "contact_title": "Executive Director",
        "financials": {
            "total_revenue": 96702825,
            "total_assets": 242488346,
            "fiscal_year": 2021,
            "form_type": "990",
            "total_expenses": 83625796
        },
        "mission": "Human Rights Watch defends the rights of people worldwide. We scrupulously investigate abuses, expose the facts widely, and pressure those with power to respect rights and secure justice. Human Rights Watch is an independent, international organization that works as part of a vibrant movement to uphold human dignity and advance the cause of human rights for all.",
        "ein": "13-2875808",
        "city": "New York City",
        "contact_email": "hrwpress@hrw.org",
        "name": "Human Rights Watch, Inc.",
        "admins": {},
        "contact_name": "Kenneth Roth",
        "queried": true,
        "cover_photo": "",
        "logo_url": "https://storage.googleapis.com/starfish-seethegood.appspot.com/non_profits/profile_picture/13-2875808%281%29?Expires=33134745600&GoogleAccessId=firebase-adminsdk-pqols%40starfish-seethegood.iam.gserviceaccount.com&Signature=EVh1rleyzBCz1mlLgzCn6y883UkYz1nle1xHeCgTqEdPm0jWU0qg6tDWt5on%2BFFoK7xPiChgvfhTYoZjX8asoBeDa%2FM4MeiknoxQOvUBs24kgPOTe9XwLhQV%2Fuye1Ngy6wlVLGtQFxRZqhRg1puzWTcHOfMO8OjWptTEdSUeJKZwEJwfnDi%2BK28%2F1jpXcCT3I01wzHZzSxQ2eCcsb7lADlR7IAGF26hoqUMFDA1dC0Lj5CL6WYu0U1k6cAzcUOLNr1DgfG7nsrXulwFXVIo0DFvhk5VGCguTe8tVBAEwaUiiF63IrO5rVYI7XuOMxA%2F90Z%2BOa9u2TE46Ih56bjsnUQ%3D%3D",
        "ruling_year": 1976,
        "interested": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791ded0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791f690>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791fd10>"
        ],
        "category": "International Human Rights",
        "affiliation": "3",
        "num_employees": "345",
        "zip": "10118",
        "state": "NY",
        "change_id": "n_l31QELFxmEBO7tp6pf7dsWA5",
        "id": "13-2875808"
    },
    {
        "address1": "1202 Lexington Ave Ste 305",
        "website_url": "www.dosomething.org",
        "code": "O",
        "contact_title": "CEO",
        "financials": {
            "total_revenue": 8284413,
            "total_assets": 17753347,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 11423421
        },
        "mission": "DoSomething, Inc was founded in 1993 to advocate and promote social leadership, community activism and citizenship.  Also known as DoSomething.org, we are one of the largest global organizations for young people and social change.Do Something.org inspires, empowers and celebrates a generation of doers.  The services are directed to young people (ages 13-25) who want to make a difference, but need help actively participating and leading in community change efforts. DoSomething.org makes the world suck less. Our 5.5 million members tackle campaigns that impact every cause, from poverty to violence to the environment to literally everything else. Any cause, anytime, anywhere. The call to action is always something that has a real impact and doesn't require money, an adult or a car.",
        "ein": "13-3720473",
        "city": "New York City",
        "contact_email": "dgetachew@dosomething.org",
        "name": "Do Something, Inc.",
        "admins": {},
        "contact_name": "Mrs. DeNora Getachew",
        "queried": true,
        "cover_photo": "",
        "logo_url": "https://storage.googleapis.com/starfish-seethegood.appspot.com/non_profits/profile_picture/13-3720473%284%29?Expires=33134745600&GoogleAccessId=firebase-adminsdk-pqols%40starfish-seethegood.iam.gserviceaccount.com&Signature=F8c3GWCiRJbLMkcUPqNcLd8ibx8URgjimU%2BTNQflhQHIOf%2F0IWiqAgQP07KGYaUzz478X%2BkUavrDQtFnfnu8Pg7Ak90Q5GMmRgx4c9PCgkWsGpOf41R%2FNJ0q4fx18wPYNvtz1YW0SPk%2BjKgR7wntbdZxnLapD8l0qUYMpOQN8bHl%2FXV3PhuplUgug2jfstiAFzpCQFddZqfa0Hv5b9oSAI1vhwKHKbwNnH3FbxgLLNx5%2F8yoMHlFORWLivykAs4%2FrQeOtgcHPuDRKYfDFNGKr1NKZPR1Malp6g9tEYBL6sjwFCHIsaeki%2FefX%2BHF7yewZm8CFUzGOrKhXiYUcEia%2Fg%3D%3D",
        "ruling_year": 1995,
        "interested": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791f2d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791e6d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791ea90>"
        ],
        "category": "Civil Rights, Social Action, and Advocacy N.E.C.",
        "affiliation": "6",
        "num_employees": "85",
        "zip": "10028",
        "state": "NY",
        "change_id": "n_6JcJtMqlD8G8ScmZDjjc02es",
        "id": "13-3720473"
    },
    {
        "address1": "200 Vesey St Fl 28",
        "website_url": "https://www.jdrf.org/",
        "code": "G",
        "contact_title": "CFO",
        "financials": {
            "total_revenue": 223022643,
            "total_assets": 361315226,
            "fiscal_year": 2021,
            "form_type": "990",
            "total_expenses": 115470447
        },
        "mission": "JDRF’s mission is to improve lives by accelerating life-changing breakthroughs to cure, prevent and treat T1D and its complications. To accomplish this, JDRF has invested more than $2.5 billion in research funding since our inception. We are an organization built on a grassroots model of people connecting in their local communities, collaborating regionally for efficiency and broader fundraising impact, and uniting on a national stage to pool resources, passion, and energy. We collaborate with academic institutions, policymakers, and corporate and industry partners to develop and deliver a pipeline of innovative therapies to people living with T1D. For more information, please visit jdrf.org or follow us on Twitter (@JDRF), Facebook (@myjdrf), and Instagram (@jdrfhq).",
        "ein": "23-1907729",
        "city": "New York City",
        "contact_email": "rking@jdrf.org",
        "name": "JDRF International",
        "admins": {},
        "contact_name": "Rob King",
        "queried": true,
        "cover_photo": "",
        "logo_url": "https://storage.googleapis.com/starfish-seethegood.appspot.com/non_profits/profile_picture/23-1907729%281%29?Expires=33134745600&GoogleAccessId=firebase-adminsdk-pqols%40starfish-seethegood.iam.gserviceaccount.com&Signature=LzZNWl4gcyVlm7PuN0Ni2Dc%2BhAo2AzcYTyvFP3FabApHaJ5hsh4owRnN6pRnlS7Izx5rV2lCP9pRMCJoFiZLeFmNABgQzqYOwY9GS3%2FVkshcDI%2FbtDrtegtG1ecr1HOu36pPy01vGmL9xeRMrIz4%2Fm6kZ6RTs8VDHuY7tRJX04il65J7qMNJ8pB4btgCLqvGh7aqxhu6jVboomrcPbLOVES6r8fFhv1KBhpl5DK6uU9xdLKsgJyHdOL3cLvFu1PFAiBxMz4ngZnf3ryZu%2F2H9twWowS0zHWy2e3Pirap9WCR%2F6AdWtK1LANW9RLXLHLtueHbs%2BFGp4HXAd%2BFH4CnNQ%3D%3D",
        "ruling_year": 1973,
        "interested": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791d390>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791c8d0>"
        ],
        "category": "Specifically Named Diseases",
        "affiliation": "3",
        "num_employees": "748",
        "zip": "10281",
        "state": "NY",
        "change_id": "n_hNiyDCgo6wbMYICpJ2Izuxcr",
        "id": "23-1907729"
    },
    {
        "address1": "301 King George St",
        "website_url": "http://WWW.USNA.COM",
        "code": "B",
        "contact_title": "",
        "financials": {
            "total_revenue": 47603619,
            "total_assets": 305968176,
            "fiscal_year": 2022,
            "form_type": "990",
            "total_expenses": 48937066
        },
        "mission": "SEE SCHEDULE O.",
        "ein": "23-7003516",
        "city": "Annapolis",
        "contact_email": "",
        "name": "United States Naval Academy Foundation Inc",
        "admins": {},
        "contact_name": "",
        "queried": true,
        "cover_photo": "",
        "logo_url": "https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fprofile_picture%2FUnited_States_Naval_Academy.png?alt=media&token=20994c78-33a0-4243-b024-3a1f00024d4b",
        "ruling_year": 1969,
        "interested": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791e4d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791dc50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791c650>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791ca90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791d010>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791e350>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791ee50>"
        ],
        "category": "Fund Raising and/or Fund Distribution",
        "affiliation": "3",
        "num_employees": "53",
        "zip": "21402",
        "state": "MD",
        "change_id": "n_SmpSm1prWY31jhiKtRZEzdLE",
        "id": "23-7003516"
    },
    {
        "address1": "161 N Clark St Ste 700",
        "website_url": "www.feedingamerica.org",
        "code": "K",
        "contact_title": "",
        "financials": {
            "total_revenue": 3571430346,
            "total_assets": 432594156,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 3309075710
        },
        "mission": "Our mission is to advance change in America by ensuring equitable access to nutritious food for all in partnership with food banks, policymakers, supporters, and the communities we serve.",
        "ein": "36-3673599",
        "city": "Chicago",
        "contact_email": "adepaul@feedingamerica.org",
        "contact_name": "Angela DePaul",
        "admins": {},
        "name": "Feeding America",
        "queried": true,
        "cover_photo": "https://firebasestorage.googleapis.com:443/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fcover_photo%2F36-3673599?alt=media&token=e2208872-e17f-4f37-94e1-e98f4c8b6026",
        "logo_url": "https://storage.googleapis.com/starfish-seethegood.appspot.com/non_profits/profile_picture/36-3673599%284%29?Expires=33134745600&GoogleAccessId=firebase-adminsdk-pqols%40starfish-seethegood.iam.gserviceaccount.com&Signature=V%2BljvT%2FqJrCqnljQHKtX2%2FNoHMjtamzaoLt1USKzdEV6p1u6VWgBUhqmsPozxTcY3aHHkRlCr9xvBiWwMGMd9nbYy08GZsOK8zwB6WfR5OV3PVm6R1wmMMepd2qyy6P6X%2FxJMO%2F2XvR0h4YeTAH7GVArZOQLCjFviAdJr8eLOBj9Cj01%2Fmnf0%2B4MI91MbY2l1rgFJjbQBng%2FgAIOWIDkbGnTnTvWqdi6k7hYz1yJ6g9cyOddN0urGPyh0hRZlsWiY1JqjrIUiktse%2BUm139t0Fqcw9q5zqbW36becb8gf1tuyxaao%2BCDBR1ze99GcGOyJbVHrym0myLY9OYzJ0o61A%3D%3D",
        "ruling_year": 1990,
        "affiliation": "3",
        "category": "Food Service, Free Food Distribution Programs",
        "interested": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791c890>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791c310>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791cf50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791d790>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791e190>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791fed0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791d450>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791f910>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791da90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791d650>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791e5d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791f810>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791fe50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791f5d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791f590>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791c110>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791f990>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791f210>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791ed50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791fb10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791c490>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791ee10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791f150>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791ebd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791d090>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791c7d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791fb90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791f890>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791d290>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791f310>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791dd90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791ef10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791d1d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791ffd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791d710>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791dcd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791e950>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791de10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791c190>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791e250>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791e750>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791f1d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791de90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791c850>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791d910>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791f9d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791cf90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791c1d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791ccd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791d4d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791e650>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791ef90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791c250>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791cdd0>"
        ],
        "num_employees": "313",
        "zip": "60601",
        "state": "IL",
        "change_id": "n_ykwxyhbBfPOSnGxh6EdAq2iK",
        "id": "36-3673599"
    },
    {
        "address1": "32 3rd Ave # 393",
        "website_url": "www.sealff.org",
        "code": "T",
        "contact_title": "",
        "financials": {
            "total_revenue": 3651337,
            "total_assets": 2289159,
            "fiscal_year": 2021,
            "form_type": "990",
            "total_expenses": 1933969
        },
        "mission": "To provide Navy SEALs a foundation that supports their well-being, education and career to continue a life of service within their communities.",
        "ein": "46-0565393",
        "city": "New York City",
        "contact_email": "michael@sealff.org",
        "name": "SEAL Future Foundation, Inc.",
        "admins": {
            "sealfuturefoundation": {
                "name": "Anthony  Page ",
                "role": "Director"
            }
        },
        "contact_name": "Michael Hetrick",
        "queried": true,
        "cover_photo": "",
        "logo_url": "https://storage.googleapis.com/starfish-seethegood.appspot.com/non_profits/profile_picture/46-0565393%284%29?Expires=33134745600&GoogleAccessId=firebase-adminsdk-pqols%40starfish-seethegood.iam.gserviceaccount.com&Signature=jnXD3HnYijr3TpERys%2BBqkr6n5CsqKypsTEndLvrJapmk2kGPipBk7wMbMY6t8aNShUhT7r46p8OEQZxQhe51BPt1%2BbOzj2DIyVVsZHfJaHR%2FaTXiWPg9C0mWVL5cj7Nzfhx%2BHUr4pNSSQPfH4nnXFojq4x3ZPv0oJVoxY57Jszo0zA0dHolILl%2Bkt9AXmvbpoie88rGSt2l9QLZwR3VzyvDoMfiPPhJiNZcSQ8UFikLUhSAtViJaO4jgdPME6rGtfHYc9BO%2BkzyrSraBpSIvNNb5DiKW3JdQaAHxVR0krVbXxY4mPyPo8eX3tQ5lhN9rtgdBS0tiZWajt6w%2B5eZeA%3D%3D",
        "ruling_year": 2014,
        "interested": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791f610>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791c210>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791f950>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791fc10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791ea10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791fa50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791f290>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791e390>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791cd50>"
        ],
        "category": "Vocational Counseling / Guidance / Testing",
        "affiliation": "3",
        "num_employees": "11",
        "zip": "10003",
        "state": "NY",
        "id": "46-0565393"
    },
    {
        "address1": "361 S Raymond Ave",
        "website_url": "www.pasadenahumane.org",
        "code": "D",
        "contact_title": "",
        "financials": {
            "total_revenue": 12182038,
            "total_assets": 42305923,
            "fiscal_year": 2019,
            "form_type": "990",
            "total_expenses": 13089540
        },
        "mission": "Compassion and care for all animals.",
        "ein": "95-1643344",
        "city": "Pasadena",
        "contact_email": "klamas@pasadenahumane.org",
        "contact_name": "Kristina Lamas",
        "admins": {},
        "name": "PASADENA HUMANE SOCIETY AND S.P.C.A.",
        "queried": true,
        "cover_photo": "",
        "logo_url": "https://storage.googleapis.com/starfish-seethegood.appspot.com/non_profits/profile_picture/95-1643344%284%29?Expires=33134745600&GoogleAccessId=firebase-adminsdk-pqols%40starfish-seethegood.iam.gserviceaccount.com&Signature=dVicHyfie7ARgt4ZpGmSHL5xFIdXrbI7MYt61cMow5bejhvj8WVwc79Hvy2nlkUWxrROwkLB8KiAzA1gc7ekGcbf7q9l7Bt9tkm9I3bNpirBhHeBn2dxJYPeDzQQm8Z4moT1iLvyUqE5B4yAMwxeiMl7NAo%2B2cMXvjqxjOfLJQOszCnFixtPnTqEW7r9gnHoWaq7LldTkUFGksUKYeRX6QSYrzUp6VUxdsGRUcwrTrCwCPOj8iGOxi2ajDAsgX0%2BYO1fRKZbW5czgPXVorkaSRvDftfxanoDeH0NDKFKBNQxPSqEj5YLQ%2FTGjiGH6scN80OKCvJxa6C2ub%2FcZSnEVw%3D%3D",
        "ruling_year": 1939,
        "affiliation": "3",
        "category": "Animal Protection and Welfare (includes Humane Societies and SPCAs)",
        "interested": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791d3d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791db10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791f490>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791fad0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791df50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791e1d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791fcd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791e910>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791cc10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791d6d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791fe10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791cf10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791e2d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791dc10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791c590>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791c450>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791c610>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791f390>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791db90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791cad0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791c990>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791fb50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791fc50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791ce50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791d0d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791cc90>"
        ],
        "num_employees": "185",
        "zip": "91105",
        "state": "CA",
        "change_id": "n_O0IggO79ybpFakW01NsXh8wQ",
        "id": "95-1643344"
    },
    {
        "address1": "125 Maiden Ln",
        "website_url": "www.unicefusa.org",
        "code": "Q",
        "contact_title": "Assistant Director, Marketing",
        "financials": {
            "total_revenue": 573865026,
            "total_assets": 394704546,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 588576039
        },
        "mission": "Since its founding in 1947, the United Nations Children’s Fund (UNICEF) has built an unprecedented global support system for the world’s children. Through its many programs and partnerships in health care and immunization, safe water and sanitation, nutrition, education, emergency relief and other critical areas, UNICEF delivers the essentials needed to give children an equitable chance in life. UNICEF USA shares UNICEF’s vision of a world that upholds the rights of all children and helps every child thrive, and works to advance UNICEF’s mission through fundraising, advocacy and education in the United States. Together, we have helped meaningfully improve more children’s lives than any other humanitarian organization.",
        "ein": "13-1760110",
        "city": "New York City",
        "contact_email": "aerikson@unicefusa.org",
        "contact_name": "Amelia Erikson",
        "admins": {},
        "name": "UNICEF USA",
        "queried": true,
        "cover_photo": "",
        "logo_url": "https://storage.googleapis.com/starfish-seethegood.appspot.com/non_profits/profile_picture/13-1760110%281%29?Expires=33134745600&GoogleAccessId=firebase-adminsdk-pqols%40starfish-seethegood.iam.gserviceaccount.com&Signature=PS7ptSvIJjc20U3nsRv1RrsGhCMUZ7YHFCrCqu53cTNRZZJGGWhdf%2BEUs4gOgCFDZ56h%2B0u52Ju6YKauSO%2BClEPrx52ylaeyKFd3WuML8je5NFQ7IVnxafy9oD4wJ9gHQcFf0th8xp6apARj%2F0PkoIkEnPIA6dYjU3%2FA6Y5%2B1vilJDldpgQfDjtG7XtUaMkfDPl2lsRgdKe4hqvW3BhhKYfQiMgZ5x8Vd3o10r3LoawuxFqyUg2GO%2FY8auzSojHyORgFfengUFCpUXb3%2B6xFa7kNMt%2BPqh%2FjUKyDHPtuYamZLsudDpEK%2FtWSoGaH4QjsaMy8m3epC9FTNJRNdVcqVg%3D%3D",
        "ruling_year": 1949,
        "interested": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079b1b50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079b06d0>"
        ],
        "category": "International Relief",
        "affiliation": "3",
        "num_employees": "379",
        "zip": "10038",
        "state": "NY",
        "change_id": "n_AalD8toz3ASoAjH4eIblTit5",
        "id": "13-1760110"
    },
    {
        "address1": "40 Rector St Fl 16",
        "website_url": "www.doctorswithoutborders.org",
        "code": "Q",
        "contact_title": "",
        "financials": {
            "total_revenue": 558340480,
            "total_assets": 391676625,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 499587775
        },
        "mission": "The mission of Doctors Without Borders/Médecins Sans Frontières (MSF) is to provide impartial medical relief to the victims of war, disease, and natural or man-made disaster, without regard to race, religion, or political affiliation.",
        "ein": "13-3433452",
        "city": "New York City",
        "contact_email": "donations@newyork.msf.org",
        "name": "DOCTORS WITHOUT BORDERS USA INC",
        "admins": {},
        "contact_name": "Donor Services",
        "queried": true,
        "cover_photo": "",
        "logo_url": "https://storage.googleapis.com/starfish-seethegood.appspot.com/non_profits/profile_picture/13-3433452%281%29?Expires=33134745600&GoogleAccessId=firebase-adminsdk-pqols%40starfish-seethegood.iam.gserviceaccount.com&Signature=iLS81Ig%2BVjd907qhouLGSEhqjK4KiGCK58lBwrQ6l1GVrARorYXrxSh3Ym36SmO%2BTabSEI5yT7d7xeVJpJaAnybgkPdXucN4wTtiTl48S8NVZySaGCkjC71CijKpgdRP7K1schCPs5y3GuJgyJGBfeHNylyYjapHBS2tFn7pXqwDJvFuakARjnu%2BWhdhHMTCjRHciO8sp1vtLhMsLJdsROXI6D92zIvudVX8bz6gbg%2FykBcgNmbu0dViUyKfW%2FvHXPzWNi8sQ5liRDKXLRAKPf3BuGv8HI%2FIol4v4Hjuz4h9VO5noKkZLZ%2FdF7zdUhJE8gCs0%2FWRip%2FGUcqqXXEJcw%3D%3D",
        "ruling_year": 1989,
        "interested": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079b3cd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079b3490>"
        ],
        "category": "Disaster Preparedness and Relief Services",
        "affiliation": "3",
        "num_employees": "603",
        "zip": "10006",
        "state": "NY",
        "change_id": "n_LVefvosmlNukcqYBTotxxar8",
        "id": "13-3433452"
    },
    {
        "address1": "200 Central Park W",
        "website_url": "www.amnh.org",
        "code": "A",
        "contact_title": "Special Assistant to the President and Assistant Secretary",
        "financials": {
            "total_revenue": 198055556,
            "total_assets": 1531677996,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 210518219
        },
        "mission": "The mission of the American Museum of Natural History is to discover, interpret, and disseminate - through scientific research and education - knowledge about human cultures, the natural world, and the universe.",
        "ein": "13-6162659",
        "city": "New York City",
        "contact_email": "jpowers@amnh.org",
        "name": "American Museum of Natural History",
        "admins": {},
        "contact_name": "Ms. Jackie Powers",
        "queried": true,
        "cover_photo": "",
        "logo_url": "https://storage.googleapis.com/starfish-seethegood.appspot.com/non_profits/profile_picture/13-6162659%281%29?Expires=33134745600&GoogleAccessId=firebase-adminsdk-pqols%40starfish-seethegood.iam.gserviceaccount.com&Signature=rEvJDpmPVjopVR68e8YdyZSOlfG%2BkQEB7OL6hIni1BrzjrcimVeosgquGHuctBLIy10Ty2inZ0tKwohysO0fEDZcuDtqGdgbcOrEbN%2BSVVoLYX10hNmHaFa4mdf6RVo69WwHHiu2v7dhCUVS9ZZfUuBwezF%2B9CtSSP97j3hu6TaNq2lTm67xL0YqT%2BcOSaRNjSjKWBgXhIjD0%2FoRlMKa69s%2FzkK4lecvIU85%2FJcqwEczuBSxMz7iav8EJhsPNEkp2wzEHceK4lV24MUDziUdbJplbNUEpKlP8ejYgP9KC8DqxHNtGRwkAYm4DaaDY5mViddPeQrPvkm2stfQoK57NQ%3D%3D",
        "ruling_year": 1943,
        "interested": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079b1510>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079b35d0>"
        ],
        "category": "Natural History, Natural Science Museums",
        "affiliation": "3",
        "num_employees": "1547",
        "zip": "10024",
        "state": "NY",
        "change_id": "n_eprxrsW4rUSlLYhtAPOSqasn",
        "id": "13-6162659"
    },
    {
        "address1": "1022 Farmington Ave",
        "website_url": "www.bridgefamilycenter.org",
        "code": "Z",
        "contact_title": "Director of Development and Communications",
        "financials": {
            "total_revenue": 8870553,
            "total_assets": 5589604,
            "fiscal_year": 2021,
            "form_type": "990",
            "total_expenses": 8010998
        },
        "mission": "Formed in 1969 to help youth engaged in high-risk behaviors, the Bridge today is a comprehensive, regional nonprofit agency offering a broad range of services to children and families in Greater Hartford. We have 45 residential beds for young people; four mental health clinics that serve children, families, and adults; a Teen Center; a Mosaic Parenting Center for families working towards reunification, and a nationally recognized Family Resource Center. Last year, we served more than 8,500 children and families. Our mission is to foster the courage and strength in children and families to meet life's challenges and build fulfilling lives.",
        "ein": "23-7013563",
        "city": "West Hartford",
        "contact_email": "dtarascio@bridgefamilycenter.org",
        "contact_name": "Ms. Doreen Tarascio",
        "admins": {},
        "name": "The Bridge Family Center, Inc.",
        "queried": true,
        "cover_photo": "",
        "logo_url": "https://storage.googleapis.com/starfish-seethegood.appspot.com/non_profits/profile_picture/23-7013563%281%29?Expires=33134745600&GoogleAccessId=firebase-adminsdk-pqols%40starfish-seethegood.iam.gserviceaccount.com&Signature=nZUIDLNvnutRIsk%2BBseSehkfGHgRJrzGvT5V%2Fd2fWDq74ADlh0vTM%2BEq8qzhxjJvsVa%2FpdKp%2BrZgkwI7hpQOWnk1B2Q8mCf5EyotovK9g12Dg0dx2FVov0tkCtX3XbB88Xd4Xogu8m46sB8X6UewwzORm%2F2bXz735OCHaRM5dixhHbUqkSOa5meCnzAYxidyVBdfHAQsCO0GrZ%2Fl63VyTS3PJfHlNSQ5lSv%2FxBuC9StP4zcikm%2BItgDul18nQOpXqlz0BC9mxO%2FJrsriZJ3mdEmOVQXqEOmqEGTJvddzWbpOzwBwXmhySeLlBQ1H52hEHoTZI7wXNBBGV0royuKZig%3D%3D",
        "ruling_year": 1969,
        "affiliation": "3",
        "category": "Children's and Youth Services",
        "interested": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079b0350>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079b2d90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10794ca50>"
        ],
        "num_employees": "196",
        "zip": "06107",
        "state": "CT",
        "change_id": "n_oZE2QX0CJrl3RKdHDNwf0hOV",
        "id": "23-7013563"
    },
    {
        "address1": "825 College Blvd Ste 102",
        "website_url": "www.TheFund.org",
        "code": "E",
        "contact_title": "CFO",
        "financials": {
            "total_revenue": 39775196,
            "total_assets": 35832824,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 38890105
        },
        "mission": "Semper Fi & America’s Fund cares for our nation’s critically wounded, ill, and injured service members, veterans, and military families. Supporting all branches of the U.S. Armed Forces, we provide one-on-one case management, connection, and lifetime support. Today. Tomorrow. Together.",
        "ein": "26-0086305",
        "city": "Oceanside",
        "contact_email": "tom.benoit@TheFund.org",
        "contact_name": "Tom Benoit",
        "admins": {
            "carlynicole": {
                "name": "Carly  Kenworthy ",
                "role": "Employee"
            }
        },
        "name": "Semper Fi & America's Fund",
        "queried": true,
        "cover_photo": "https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fcover_photo%2F26-0086305.jpeg?alt=media",
        "logo_url": "https://storage.googleapis.com/starfish-seethegood.appspot.com/non_profits/profile_picture/26-0086305%281%29?Expires=33134745600&GoogleAccessId=firebase-adminsdk-pqols%40starfish-seethegood.iam.gserviceaccount.com&Signature=kxXvMwz1pPayanYH%2Bub%2BSHEVF5z%2BHn82ON%2Bpp8MzjRXY7SRAdmMGlpdN%2BKM%2B0FGi7wgG8QdXoHt4HdB501OoMoEV6Yn3iJf5%2FQUIQG%2Bl6UWOmFeJCnd%2FeI6vYkiP7QXg4SbUDmVVMSdzCXQHAOvyh4WY20iMUsirKVA3c95bbS9KRAXMSjI3FIHjwIb4J1W1uHJ8ji27gvvlfRIy4%2BL3R4KIUibZImC%2Fc7pQlanukDdOUWTQsMKeKmpCR%2F5aemIDM7dwoHBQd%2Fr9qtoyHppYZZOQOnRmeRQzTp%2B9G6oq6UnPcZGlkIYoPrQlEPN75dLCX3XlHecu6eB5Yvm9pMx8HA%3D%3D",
        "ruling_year": 2004,
        "interested": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10794d550>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x106e06e50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10794ce50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107989c90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107988c50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077d5990>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079b0090>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107a0ab10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107a0aad0>"
        ],
        "category": "Military/Veterans' Organizations",
        "affiliation": "3",
        "num_employees": "176",
        "zip": "92057",
        "state": "CA",
        "change_id": "n_ducAQ9cAhomt7PNIJoduyGqb",
        "id": "26-0086305"
    },
    {
        "address1": "13191 Crossroads Pkwy N Fl 6",
        "website_url": "HTTP://WWW.LANDOFTHEFREEFOUNDATION.COM/",
        "code": "T",
        "contact_title": "",
        "financials": {
            "total_revenue": 907894,
            "total_assets": 15071,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 945441
        },
        "mission": "THE CORPORATION IS COMMITTED TO CELEBRATING THE MEN AND WOMEN OF THE ARMED FORCES, PAST, PRESENT, AND FUTURE. THE PRIMARY PURPOSE OF THE CORPORATION IS TO SUPPORT PROGRAMS THAT HELP UNITES STATES ARMED SERVICES PERSONNEL AND THEIR FAMILIES.",
        "ein": "26-0710805",
        "city": "City of Industry",
        "contact_email": "",
        "contact_name": "",
        "admins": {},
        "name": "Land of the Free Foundation",
        "logo_url": "https://firebasestorage.googleapis.com:443/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fprofile_picture%2F26-0710805?alt=media&token=65640094-0176-4374-8115-35b79d31e99c",
        "cover_photo": "https://firebasestorage.googleapis.com:443/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fcover_photo%2F26-0710805?alt=media&token=9b4c60e4-5f48-42a1-baf4-4abbbde1dabc",
        "queried": true,
        "ruling_year": 2007,
        "interested": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107a0b490>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107a0a1d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077afb50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077af650>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077acc10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077ac1d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077ae810>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077ad110>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077af490>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079b0a10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077ad350>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077aff10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077ac910>"
        ],
        "category": "Public Foundations",
        "affiliation": "3",
        "zip": "91746",
        "num_employees": "0",
        "state": "CA",
        "change_id": "n_Rj6EG4dbCxi3puMcmLnn353b",
        "id": "26-0710805"
    },
    {
        "address1": "1145 17th St NW",
        "website_url": "http://www.nationalgeographic.org",
        "code": "B",
        "contact_title": "Vice President, Advancement Operations",
        "financials": {
            "total_revenue": 70469785,
            "total_assets": 1696779808,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 145411426
        },
        "mission": "The National Geographic Society uses the power of science, exploration, education and storytelling to illuminate and protect the wonder of our world.",
        "ein": "53-0193519",
        "city": "Washington",
        "contact_email": "miking@ngs.org",
        "contact_name": "Mikaela King",
        "admins": {},
        "name": "National Geographic Society",
        "queried": true,
        "cover_photo": "https://firebasestorage.googleapis.com:443/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fcover_photo%2F53-0193519?alt=media&token=3d08650c-e28a-47e2-9c4e-3824c05db64b",
        "logo_url": "https://storage.googleapis.com/starfish-seethegood.appspot.com/non_profits/profile_picture/53-0193519%284%29?Expires=33134745600&GoogleAccessId=firebase-adminsdk-pqols%40starfish-seethegood.iam.gserviceaccount.com&Signature=LDDnp%2BquxVqRFG2nTxQ3J6XHmgWtkSlxAAtWMrNFBpWNP2H8btIfHMdzwH%2BJnqxWNLoGcQhMMwENLq%2FaDvU7LGKkAMRRVtc7d1i%2BjuerU6Ct9PTs0Hjmqi70jB7Eb%2BtJzxfD4Ewuvt4aL85d0GM8LX7Tl9Bqnnp4N887MUNL%2B2S0ZnoiQQehGZyO%2FsdPSTt7m%2FXNnGtyYTMmapcL8hprEDGWy6ZV3gbQ6fgmNY1eeZkR%2BQ1HZwZzaBMp4yuGjfTo6VPowI6Y9c74xZ5J3Pgnb418Hf0IYr6gawBq%2BZZKV1RRZy%2BO0rgWdF7bS%2FwqSukVxAqGa9tdeF9O%2BlFUVwdSXA%3D%3D",
        "ruling_year": 1938,
        "interested": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077aeed0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077af250>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077ae910>"
        ],
        "category": "Fund Raising and/or Fund Distribution",
        "affiliation": "3",
        "num_employees": "481",
        "zip": "20036",
        "state": "DC",
        "change_id": "n_Eiq9dQKaPlT0tVCLp1DxPemj",
        "id": "53-0193519"
    },
    {
        "address1": "7777 S Lewis Ave",
        "website_url": "https://WWW.ORU.EDU",
        "code": "B",
        "contact_title": "",
        "financials": {
            "total_revenue": 154062702,
            "total_assets": 217101952,
            "fiscal_year": 2021,
            "form_type": "990",
            "total_expenses": 134319552
        },
        "mission": "To develop Holy-Spirit-Empowered leaders through whole person education to impact the world.",
        "ein": "73-0739626",
        "city": "Tulsa",
        "contact_email": "",
        "contact_name": "",
        "admins": {
            "jack": {
                "name": "Jack Phifer",
                "role": "Director"
            }
        },
        "name": "Oral Roberts University",
        "logo_url": "https://firebasestorage.googleapis.com:443/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fprofile_picture%2F73-0739626?alt=media&token=7efd4c45-5f05-4e58-be5d-65f67227597b",
        "cover_photo": "https://firebasestorage.googleapis.com:443/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fcover_photo%2F73-0739626?alt=media&token=b4b5c121-019b-42f0-b5c9-5a11d8f38918",
        "queried": true,
        "ruling_year": 1964,
        "interested": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077aeb50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077ad910>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077afa50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077aee50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077adbd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077acc90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077d7950>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ec250>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ec150>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1077aded0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ee950>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ef550>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079eda90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ee890>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ed2d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ecad0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ec390>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ef610>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ed990>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ec190>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ec790>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079eee10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ee050>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079eff10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079eeb50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079eca10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ecdd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ee510>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079efa10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ee450>"
        ],
        "category": "University or Technological",
        "stripe_connect_id": "acct_1OJga3Gb7YWXPg3l",
        "zip": "74171",
        "num_employees": "2086",
        "state": "OK",
        "affiliation": "3",
        "change_id": "n_eSKNkzyUiYX50WXI0x3ZcZow",
        "id": "73-0739626"
    },
    {
        "address1": "6666 Green Valley Circle",
        "website_url": "https://shareselfhelp.org/donate",
        "code": "Z",
        "contact_title": "",
        "financials": {},
        "mission": "SHARE! empowers people, including veterans, to change their own lives and provides them a loving, safe, non-judgmental place where they can find community, information, and support. Visit the website to learn about SHARE! Collaborative Housing and other programs including access to over 100 Free Self-Help Support Group meetings for mental health, addiction, and all life Issues.\n \nSince 1993        Website: www.shareselfhelp.org/donate\n ",
        "ein": "95-6092809-1",
        "city": "Culver City",
        "contact_email": "",
        "name": "SHARE!",
        "admins": {
            "melaniee": {
                "name": "Melanie Elliott",
                "role": "Other"
            },
            "clieberman": {
                "name": "Cynthia Lieberman",
                "role": "Employee"
            },
            "bryan": {
                "name": "Bryan Harrison",
                "role": "Employee"
            }
        },
        "contact_name": "",
        "queried": true,
        "cover_photo": "https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fcover_photo%2F95-6092809-1?alt=media&token=65c4f2fd-cf93-4096-a42d-3e7af87aca20",
        "logo_url": "https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fprofile_picture%2F95-6092809-1?alt=media&token=1e307d59-38b8-4418-9e53-af1a2b96b4bb",
        "ruling_year": "1993",
        "affiliation": "",
        "category": "Military/Veterans' Organizations",
        "interested": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ede10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ee790>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ece10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079efe10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ec050>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ec210>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ee750>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079eedd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ee3d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ee010>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ec950>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ed350>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079efbd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079efb10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ee7d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107965150>"
        ],
        "zip": "90230",
        "num_employees": "",
        "state": "CA",
        "id": "95-6092809-1"
    },
    {
        "address1": "760 Kolu St",
        "website_url": "www.mauifoodbank.org",
        "code": "K",
        "contact_title": "Executive Director",
        "financials": {
            "total_revenue": 9472622,
            "total_assets": 6738660,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 8361656
        },
        "mission": "Maui Food Bank's mission is to help the hungry in Maui County by collecting and distributing food through community partnerships.",
        "ein": "99-0315110",
        "city": "Wailuku",
        "contact_email": "rich@mauifoodbank.org",
        "contact_name": "Mr. Richard Yust",
        "admins": {},
        "name": "Maui Food Bank, Inc.",
        "queried": true,
        "cover_photo": "",
        "logo_url": "https://storage.googleapis.com/starfish-seethegood.appspot.com/non_profits/profile_picture/99-0315110%284%29?Expires=33134745600&GoogleAccessId=firebase-adminsdk-pqols%40starfish-seethegood.iam.gserviceaccount.com&Signature=Ft0m3lQkS7S3zdn%2FOcvUQEKoCjBLrG%2FIOkVyplMF09SadLftDcwkiucgroAVcniQCKgi9bNdJD%2BqUuTOdSnoyLfeyDS7eeVll6vvRETPAlzLQTf27mIlx9S3RhDMPwU%2Fmaixa1FjyUff0G%2FGy7sjK48q%2F1UUKEEssNaHpiFF2OBAcQIT465C%2BFWvEIXksOQqkYJL5%2FXbC%2BzEcRtVyD3%2BGt6NnKAmSVMWDFXqIOpgPPCoWRN0DYG9gfrT2LmNLyUNsVRpfJrSYzwr%2FwyPY%2Byr1dkro5WWKHaNFf92vqfWLCrmx4MOu%2BXg%2Fge1v8b%2FtDkKi%2Fhl68L0XhInxxBQJemSBw%3D%3D",
        "ruling_year": 1995,
        "affiliation": "3",
        "category": "Food Banks, Food Pantries",
        "interested": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107964450>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107966c50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107964610>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107967850>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107966950>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107964250>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107967150>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107964150>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107967290>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x107964750>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079678d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079676d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079666d0>"
        ],
        "num_employees": "14",
        "zip": "96793",
        "state": "HI",
        "change_id": "n_Q5LTTsVAL9HhrFYMGlcQEnJb",
        "id": "99-0315110"
    },
    {
        "address1": "40 Patriots Point Rd",
        "website_url": "www.cmohs.org",
        "code": "A",
        "contact_title": "Executive Assistant",
        "financials": {
            "total_revenue": 3671265,
            "total_assets": 12099872,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 3552907
        },
        "mission": "Perpetuate the legacy of the Medal of Honor and support the objectives, programs, and activities of the Congressional Medal of Honor Society and Medal of Honor recipients. Promote American values and the qualities of courage, sacrifice, and patriotism through increased awareness, education, ethical behavior, and example.",
        "ein": "25-1828488",
        "city": "Mt Pleasant",
        "contact_email": "kturpin@themedalofhonor.com",
        "contact_name": "Ms. Karen Turpin",
        "admins": {
            "mohf": {
                "name": "John Shertzer",
                "role": "Director"
            }
        },
        "name": "Medal of Honor Foundation, Inc.",
        "queried": true,
        "cover_photo": "https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fcover_photo%2F25-1828488.jpeg?alt=media",
        "logo_url": "https://storage.googleapis.com/starfish-seethegood.appspot.com/non_profits/profile_picture/25-1828488%281%29?Expires=33134745600&GoogleAccessId=firebase-adminsdk-pqols%40starfish-seethegood.iam.gserviceaccount.com&Signature=jMQBa6LjjnnKtaytivLqiCy2u0Vxla4W%2FB3tmIOZY%2BJyjmIZu5bEYvO1zuH%2FuBeDQS4MoTV%2F5QuYKs303vDGvHgOPUOUSB4MB9pxKvCyyJCcdTIqc9CBPHFsjmR%2BmABN4mEFozeWK9wRxMeXGl7%2B5g8vd3jYbMFq9uaGFfcO%2FteBi7k5%2Bs%2FsPydfUGW4fEIC3Pehg%2F%2BnzO7XrdCBhQTgY%2FKr9hv%2Fdua1kdbw5%2Fff8SZ%2F95KL6I3Y65hP2jGdwX6fzkYZqfZSjHCWK68YVcqHgNd6ULxHuzsUyvZFnkIq9LhX7BAFBq3UhJmqHHJCC%2FJW6qIj%2BIzr8xVzqlg8FUnn1A%3D%3D",
        "ruling_year": 1999,
        "interested": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791f4d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790e890>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790cd90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790c390>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790e910>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790d6d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790e2d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790ea50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790edd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10791fd50>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790e790>"
        ],
        "category": "Military/Veterans' Organizations",
        "affiliation": "3",
        "num_employees": "11",
        "zip": "29464",
        "state": "SC",
        "id": "25-1828488"
    },
    {
        "address1": "3033 Wilson Blvd Ste 300",
        "website_url": "http://www.taps.org",
        "code": "F",
        "contact_title": "",
        "financials": {
            "total_revenue": 10149599,
            "total_assets": 6482761,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 12303997
        },
        "mission": "The Tragedy Assistance Program for Survivors (TAPS) is the national organization providing compassionate care for all those grieving the death of a military loved one. TAPS honors our military men and women by caring for all those they loved and left behind.",
        "ein": "92-0152268",
        "city": "Arlington",
        "contact_email": "katie.maness@taps.org",
        "contact_name": "Katie Maness/TAPS",
        "admins": {
            "ktmaness": {
                "name": "Katie Maness",
                "role": "Employee"
            }
        },
        "name": "TRAGEDY ASSISTANCE PROGRAM FOR SURVIVORS",
        "queried": true,
        "cover_photo": "https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fcover_photo%2F92-0152268.jpeg?alt=media",
        "logo_url": "https://storage.googleapis.com/starfish-seethegood.appspot.com/non_profits/profile_picture/92-0152268%281%29?Expires=33134745600&GoogleAccessId=firebase-adminsdk-pqols%40starfish-seethegood.iam.gserviceaccount.com&Signature=HggRGXQ0pipyGQrwibRhHcz6R0Nd79AlZe5AArmi%2F16GW3o5LnXHneHwwPQu1YrAjda9Ctz5VkwwvI54dRkhSfsqdlCoGSSGOgVGj5wYprG%2FLbnelZC2eAZFDv5osnDFbVyF9CvBrhYUFBz4wzAUjihX%2B84XK4Sn8rceK1vrmQsIhbd48yyWFH211mpSiSkFjZcr8sgKWoudsaoaQ2cpuNw0NR72Ti%2BtNeMxPcQMNMgQXQB4CHwRVqFmR4PtZzl7aBgc2IaTHW1r2PUCF8mWuEx3lvqloHAhdQ9qjNKqW4nsFFZCvPyTga9X6fB7W%2FFPCVb4TAZ9R4Zc9OhIb1bYoA%3D%3D",
        "ruling_year": 1995,
        "interested": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790f510>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790eb90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790de90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790e010>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790cb90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790ea10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790d8d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790ffd0>"
        ],
        "category": "Family Services",
        "affiliation": "3",
        "num_employees": "111",
        "zip": "22201",
        "state": "VA",
        "change_id": "n_wigqp4lGHmTbM9lZC2BtPtes",
        "id": "92-0152268"
    },
    {
        "address1": "University Park Campus 3551 Trousdale Parkway Ste 160",
        "website_url": "www.usc.edu",
        "code": "B",
        "contact_title": "Contract and Grant Administrator III-Data Specialist",
        "financials": {
            "total_revenue": 6447260379,
            "total_assets": 13930666634,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 5942714632
        },
        "mission": "The University of Southern California is one of the world’s leading private research universities. An anchor institution in Los Angeles, a global center for arts, technology and international trade, USC enrolls more international students than any other U.S. university and offers extensive opportunities for internships and study abroad. With a strong tradition of integrating liberal and professional education, USC fosters a vibrant culture of public service and encourages students to cross academic as well as geographic boundaries in their pursuit of knowledge.",
        "ein": "95-1642394",
        "city": "Los Angeles",
        "contact_email": "dsteele@usc.edu",
        "contact_name": "Dorothy Steele",
        "admins": {},
        "name": "University of Southern California",
        "logo_url": "https://firebasestorage.googleapis.com:443/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fprofile_picture%2F95-1642394?alt=media&token=de73108c-af92-4ae9-b548-7b7a86b3cb63",
        "cover_photo": "https://firebasestorage.googleapis.com:443/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fcover_photo%2F95-1642394?alt=media&token=ad342c53-828e-49ab-90ef-a320f5537789",
        "queried": true,
        "ruling_year": 1934,
        "interested": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790ff10>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x10790d290>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079fc2d0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079fcfd0>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079fdd90>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079fcbd0>"
        ],
        "category": "University or Technological",
        "affiliation": "3",
        "zip": "90089",
        "num_employees": "43119",
        "state": "CA",
        "change_id": "n_ETo7NbuvMBj3I0xpLnhzIEXY",
        "id": "95-1642394"
    },
    {
        "address1": "1200 E California Blvd # Mc2346",
        "website_url": "http://www.caltech.edu",
        "code": "B",
        "contact_title": "",
        "financials": {
            "total_revenue": 3522009000,
            "total_assets": 6252584000,
            "fiscal_year": 2020,
            "form_type": "990",
            "total_expenses": 3467103000
        },
        "mission": "SEE SCHEDULE O",
        "ein": "95-1643307",
        "city": "Pasadena",
        "contact_email": "",
        "name": "California Institute of Technology",
        "admins": {},
        "contact_name": "",
        "logo_url": "",
        "cover_photo": "",
        "queried": true,
        "ruling_year": 1937,
        "interested": [
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079ff890>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079fd890>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079fc050>",
            "<google.cloud.firestore_v1.document.DocumentReference object at 0x1079fc090>"
        ],
        "category": "University or Technological",
        "affiliation": "3",
        "num_employees": "12035",
        "zip": "91125",
        "state": "CA",
        "change_id": "n_AR2g4WU6AlVvhl2LlGoR37nt",
        "id": "95-1643307"
    }
]

export const sample_challenges = [
    {
        "created_at": "2024-04-29 15:25:00.403116+00:00",
        "subpage": "",
        "type": "awareness",
        "creator": "cory",
        "campaign_id": "",
        "awareness_progress": 3,
        "end_date": "2024-07-31 15:24:12.039000+00:00",
        "description": "Raising awareness and funds for MIT '22 new group on Starfish!",
        "pledge": "I pledge allegiance",
        "images": [],
        "donations_goal": 100,
        "challenge_name": "MIT Class of 2022 Donation Challenge",
        "donations_progress": 55,
        "creator_name": "Cory O’Shea",
        "challenge_id": "HoIOSmY3rEFV6ZKt38pQ",
        "cover_image": "https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/challenges%2Fcover_photo%2FMIT%20Class%20of%202022%20Awareness%20Challenge-undefined.jpeg?alt=media&token=c0941933-7cb1-45c3-8642-d118d627da35",
        "non_profit_id": "04-2103594",
        "pledge_progress": 8,
        "pledge_goal": 0,
        "awareness_goal": 0,
        "approved": true,
        "donation_match": 0,
        "non_profit": {
            "id": "04-2103594",
            "ein": "04-2103594",
            "name": "Massachusetts Institute of Technology (MIT)",
            "mission": "THE MISSION OF MIT IS TO ADVANCE KNOWLEDGE AND EDUCATE STUDENTS IN SCIENCE, TECHNOLOGY, AND OTHER AREAS OF SCHOLARSHIP THAT WILL BEST SERVE THE NATION AND THE WORLD IN THE 21ST CENTURY.",
            "ruling_year": 1926,
            "num_employees": "26214",
            "website_url": "www.web.mit.edu/",
            "contact_email": "rscott@mit.edu",
            "contact_name": "Rob Scott",
            "contact_title": "",
            "address1": "77 Massachusetts Ave # 49-3142",
            "city": "Cambridge",
            "state": "MA",
            "zip": "02139",
            "financials": {
                "total_revenue": 4804195000,
                "total_assets": 30065085000,
                "fiscal_year": 2020,
                "form_type": "990",
                "total_expenses": 4165517000
            },
            "category": "University or Technological",
            "logo_url": "https://firebasestorage.googleapis.com:443/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fprofile_picture%2F04-2103594?alt=media&token=550ac4b4-b042-4359-a9d7-265694ffe152",
            "cover_photo": "https://firebasestorage.googleapis.com:443/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fcover_photo%2F04-2103594?alt=media&token=0b63f632-6058-4f90-b277-1038c6d13145",
            "interested": [
                "johncervenka",
                "jack",
                "morgan98",
                "bohardin120",
                "jason10",
                "jackstra",
                "nanewalt",
                "stevemott",
                "cory"
            ],
            "admins": {
                "cory": "Cory O'Shea"
            },
            "queried": true,
            "change_id": "n_bHgjjMOZAI7wPW5JWeJfm5lH",
            "code": "B"
        }
    }
]
