import React, {PureComponent} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import SignUpCard from "../../components/SignUpCard";
import icon from "../../img/brand/logo.png";
import logo from "../../img/brand/LOGO-PRIMARY.png";

class AuthenticationPage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            path: ""
        };
    }

    componentDidMount = () => {
        const queryParameters = new URLSearchParams(window.location.search);
        const path = queryParameters.get("path");

        if (path) {
            this.setState({path});
        }
    };

    render() {
        const commonProps = {history: this.props.history, mobile: this.props.mobile, path: this.state.path};

        return (
            <div className="full-page flex flex-col relative items-center justify-center min-h-[100vh] bg-brand">
                <div className='burst z-[0]'/>
                <div className='burst2 z-[0]'/>
                <div className='mb-5 absolute top-0 left-0 right-0 items-center flex flex-col pt-5'>
                    <div className='row-ac'>
                        <img src={icon} alt={'Icon'} className='w-[60px]'/>
                        <div className='relative'>
                            <img src={logo} alt={'Starfish'} className='w-[120px] mb-1'/>
                        </div>
                    </div>
                </div>
                <div className='relative z-10 pt-[100px]'>
                    <AnimatePresence mode={'wait'}>
                        <motion.div
                            key="signup"
                            initial={{opacity: 0, y: 20}}
                            animate={{opacity: 1, y: 0}}
                            exit={{opacity: 0, y: 50}}
                            transition={{duration: 0.3, ease: "easeInOut"}}
                        >
                            <SignUpCard binghamton={true} {...commonProps} />
                        </motion.div>
                    </AnimatePresence>
                </div>
            </div>
        );
    }
}

export default AuthenticationPage;
