import React, {Component} from "react";
import moment from "moment";
import ProfilePicture from "./atoms/ProfilePicture";
import {CARD_STYLE_NO_HOVER} from "../utils/dummy";

class PledgeCard extends Component {

    render() {
        const action = this.props.action || {};
        const createdAt = moment(action.created_at);
        const nonProfit = action.non_profit || {};
        console.log("AC", action)
        return (
            <div className={`${CARD_STYLE_NO_HOVER}`} key={action.id}>
                <ProfilePicture
                    initial={action.creator_name[0]}
                    userID={action.user_id}
                />
                <div className='flex flex-col'>
                    <div className='col-plain'>
                        <p className='body2 black-100'>
                        <span
                            style={{fontWeight: 'bold'}}>{action.creator_name}</span>
                            &nbsp;pledged to support&nbsp;
                            <span
                                style={{fontWeight: 'bold'}}>
                            {nonProfit.name}
                        </span>
                            &nbsp;on Starfish.
                        </p>
                    </div>
                    <p className='body3 black-50'>
                        {createdAt.fromNow()}
                    </p>
                </div>
            </div>
        )
    }

}


export default PledgeCard;
