import SvgSparkle from "../icons/SvgSparkle";
import React from "react";

function SuggestedPostBanner({post}) {
    if (post.suggested_post) {
        return (
            <div className='flex bg-blue-500/10 p-2 px-4 text-white rounded-full flex-row items-end gap-2 primary mb-16'>
                <SvgSparkle className='text-blue-500 scale-[.8]' size={24}/>
                <p className='text-blue-500 font-semibold'>
                    Suggested Post
                </p>
            </div>
        )
    }
}

export default SuggestedPostBanner
