import React, {Component} from "react";
import {CARD_STYLE_NO_HOVER} from "../utils/dummy";
import SvgMail from "../components/icons/SvgMail";
import SvgHome from "../components/icons/SvgHome";
import ProfilePicture from "./atoms/ProfilePicture";



class NpoInfo extends Component {

    render() {
        const nonProfit = this.props.nonProfit || {};
        const financials = nonProfit.financials || {};
        const admins = nonProfit.admins || {};
        return (
            <div className='flex flex-col flex-1 gap-5'>
                <div className='flex flex-col flex-1'>
                    <div className={`${CARD_STYLE_NO_HOVER} flex flex-row items-center justify-between`}>
                        <div className='flex flex-row items-center gap-4'>
                            <SvgHome className={'text-slate-500'} width={24} height={24}/>
                            <div className="flex flex-col text-slate-800 text-lg">
                                <p>
                                    {nonProfit.address1}
                                </p>
                                <p>
                                    {nonProfit.city}, {nonProfit.state} {nonProfit.zip}
                                </p>
                            </div>
                        </div>
                    </div>
                    {!!nonProfit.contact_name ?
                        <a className={`${CARD_STYLE_NO_HOVER} flex flex-row items-center justify-between`} href={`mailto:${nonProfit.contact_email}`} >
                            <div className="flex flex-row items-center gap-4">
                                <SvgMail className={'text-slate-500'} width={24} height={24}/>
                                <div className="flex flex-col text-slate-800 text-lg">
                                    <p>
                                        {nonProfit.contact_name}
                                    </p>
                                    {!!nonProfit.contact_title ?
                                        <p>
                                            {nonProfit.contact_title}
                                        </p>
                                    : null}
                                </div>
                            </div>
                        </a>
                    : null}
                    {!!financials.total_assets ?
                        <div className={`${CARD_STYLE_NO_HOVER} flex flex-col items-left `}>
                            <p className='text-lg text-slate-1000 font-medium mb-2'>
                                FY{financials.fiscal_year}
                            </p>
                            <div className='flex flex-row justify-between border-b border-slate-200 mb-2 pb-2'>
                                <p className='text-lg text-slate-800 font-medium'>
                                    Total Assets
                                </p>
                                <p className='text-lg text-slate-500 font-medium'>
                                    ${financials.total_assets.toLocaleString()}
                                </p>
                            </div>
                            {!!financials.total_revenue ?
                                <div className='flex flex-row justify-between border-b border-slate-200 mb-2 pb-2'>
                                    <p className='text-lg text-slate-800 font-medium'>
                                        Total Revenue
                                    </p>
                                    <p className='text-lg text-slate-500 font-medium'>
                                        ${financials.total_revenue.toLocaleString()}
                                    </p>
                                </div>
                            : null}
                            {!!financials.total_expenses ?
                                <div className='flex flex-row justify-between'>
                                    <p className='text-lg text-slate-800 font-medium'>
                                        Total Expenses
                                    </p>
                                    <p className='text-lg text-slate-500 font-medium'>
                                        ${financials.total_expenses.toLocaleString()}
                                    </p>
                                </div>
                            : null}
                        </div>
                    : null}
                    {Object.keys(admins).length > 0 ?
                        <div className={`${CARD_STYLE_NO_HOVER} flex flex-col items-left`}>
                            <p className='text-xl text-slate-1000 font-medium mb-12'>
                                People
                            </p>
                            {Object.keys(admins).map(username => {
                                const admin = admins[username];
                                return (
                                    <div className="flex flex-row">
                                        <ProfilePicture
                                            initial={admin.name[0]}
                                            userID={username}/>
                                        <div className='flex flex-col'>
                                            <p className='text-md text-slate-800 font-medium'>
                                                {admin.name}
                                            </p>
                                            <p className='text-sm text-slate-500 font-medium'>
                                                {admin.role}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    : null}
                </div>
            </div>
        )
    }
}


export default NpoInfo;
