import React, {Component} from "react";
import {motion} from "framer-motion";
import BGGraphic10 from "../../img/graphics/BGGraphic10";
import {Button} from "../../components/atoms/Button";

class LabHeaderSection extends Component {
    render() {
        const TYPEFORM_URL = "https://v9ga2rfswmh.typeform.com/to/pYeftGzf"
        return (
            <div className='col-plain text-center'>
                <motion.div
                    initial={{opacity: 0, transform: 'scale(1) translateY(8px)'}}
                    whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                    transition={{default: {ease: "linear"}}}
                    className='heading1 black-80 relative' style={{fontSize: this.props.mobile && 48}}>
                    {this.props.mobile ?
                        <BGGraphic10 className={'absolute primary'} width={80}
                                     style={{left: '-16vw', top: -40, rotate: '38deg', zIndex: 0}}/>
                        :
                        <BGGraphic10 className={'absolute primary'} width={200}
                                     style={{left: '-16vw', top: 40, rotate: '38deg', zIndex: 0}}/>
                    }
                    Introducing Starfish Lab
                </motion.div>
                <motion.div
                    initial={{opacity: 0, transform: 'scale(1) translateY(8px)'}}
                    whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                    transition={{default: {ease: "linear"}, delay: .2}}
                    className='black-50 title1 mt-20'>
                    The mission of Starfish Lab is to bring together thought leaders in the nonprofit sector to
                    collaborate and innovate on behalf of Starfish Social Media. Assembled by invitation only, Starfish
                    Lab serves as a think tank to ideate, develop, and share new features and ideas to help guide the
                    product development and advancement of Starfish Social Media. Through collective expertise and
                    vision of its members, the purpose is to drive positive change, foster meaningful connections, and
                    empower organizations and their supporters, to make a difference in the digital world of social
                    impact.
                </motion.div>
                <div className={"justify-center mt-10 w-full mx-auto"}>
                    <Button variant={"default"} className={"md:w-1/4"}
                            onClick={() => window.open(TYPEFORM_URL, '_blank')}>
                        Apply to Starfish Lab
                    </Button>
                </div>
                {/*<div*/}
                {/*    className={this.props.mobile ? 'col-ac-jb' : 'row-ac-jb'}>*/}
                {/*    <motion.div*/}
                {/*        initial={{opacity: 0, transform: 'scale(1) translateY(8px)'}}*/}
                {/*        whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}*/}
                {/*        transition={{default: {ease: "linear"}, delay: .5}}*/}
                {/*        className={this.props.mobile ? 'body1 black-80 mt-40 w-100p' : 'body1 black-80 mt-100 w-50p'}>*/}
                {/*        Starfish is where all users come together to share stories of good and to stay better*/}
                {/*        connected with other like-minded people and the causes and nonprofits they care about.*/}
                {/*        It’s*/}
                {/*        a place to learn more about the people and the organizations that are doing good.*/}
                {/*        Starfish helps users promote and be known for their good acts and to stay better*/}
                {/*        informed of how to support the nonprofit causes they believe in.*/}
                {/*        /!*<br/><br/>*!/*/}
                {/*        /!*Starfish is where all users bring and share conversations that are thoughtful, safe,*!/*/}
                {/*        /!*uniting*!/*/}
                {/*        /!*and inspiring. A place where messages containing profanity, bullying, racist*!/*/}
                {/*        /!*comments or*!/*/}
                {/*        /!*violence are not welcomed. Thank you for making Starfish a place where users treat*!/*/}
                {/*        /!*each*!/*/}
                {/*        /!*other with respect and*!/*/}
                {/*        /!*encouragement.*!/*/}

                {/*        /!*<br/>*!/*/}
                {/*    </motion.div>*/}
                {/*    <motion.div*/}
                {/*        initial={{opacity: 0, transform: 'scale(1) translateY(8px)'}}*/}
                {/*        whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}*/}
                {/*        transition={{default: {ease: "linear"}, delay: .4}}*/}
                {/*        className={this.props.mobile ? 'w-100p col-ac-jc' : 'w-50p col-ac-jc'}>*/}
                {/*        {this.props.mobile ?*/}
                {/*            <img src={require('../img/brand/big-logo.png')} className='w-80p'*/}
                {/*                 style={{}}/>*/}
                {/*            :*/}
                {/*            <img src={require('../img/brand/big-logo.png')} className='w-80p'*/}
                {/*                 style={{marginTop: -80, marginRight: -300}}/>*/}
                {/*        }*/}
                {/*    </motion.div>*/}
                {/*</div>*/}
            </div>
        )
    }

}


export default LabHeaderSection;
