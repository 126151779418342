import React, {Component} from "react";
import ProfilePicture from "./atoms/ProfilePicture";
import moment from "moment";
import PostContent from "./posts/PostContent";
import SvgCheck from "./icons/SvgCheck";
import MessageAttachments from "./messageAttachments/MessageAttachments";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
    }
}
class Message extends Component {
    render() {
        const ownMessage = this.props.message?.creator === this.props.authUser.uid;
        return (
            <div className={`flex flex-row gap-1 ${ownMessage && 'justify-end'}`}
                 key={this.props.message.timestamp}>
                {!ownMessage &&
                    <ProfilePicture
                        initial={'32'}
                        userID={this.props.message.creator}
                        size={40}
                    />
                }
                <div className={`flex flex-col flex-1 ${ownMessage && 'items-end'}`}>
                    <div className='flex flex-row gap-1'>
                        <p className='text-md font-semibold text-slate-800'>
                            {ownMessage ? 'You' : this.props.chat.memberNames[this.props.message?.creator]}
                        </p>
                        {this.props.actions.includes(this.props.message.creator) && <SvgCheck className={'primary'}/>}
                        <p className='text-md text-slate-500'>
                            {moment.unix(this.props.message?.timestamp).format('LT')}
                        </p>
                    </div>
                    <div className={`px-3 py-2 break-all ${ownMessage ? 'bg-blue-500 ml-auto rounded-l-xl' : 'bg-slate-200 rounded-r-xl mr-auto'} rounded-b-xl text-lg`}>
                        <div className={`text-md font-medium  ${ownMessage ? 'text-white' : 'text-slate-700'}`}>
                            <PostContent post={{...this.props.message, block_style: true}} />
                        </div>
                    </div>
                    <MessageAttachments message={this.props.message}/>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, null)(Message)
