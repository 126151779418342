import React, {Component} from "react";
import {getPublicDonations} from "../../api/donations";
import {connect} from "react-redux";
import LoadingSection from "../../components/LoadingSection";
import ProfileDonationsListItem from "./ProfileDonationsListItem";
import ZeroStateArea from "../atoms/ZeroStateArea";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class ProfileDonationsSection extends Component {
    state = {
        activeTab: '2023',
        donations: [],
        nonProfits: {},
        loading: true,
    }


    componentDidMount = async () => {
        const res = await getPublicDonations(this.props.authUser, this.props.user.username);
        this.setState({
            loading: false,
            donations: res.donations,
            nonProfits: res.non_profits
        })
    }

    render() {
        return (
            <>
                {this.state.loading ?
                    <LoadingSection/>
                    :
                    <div className='flex flex-col gap-5'>
                        {/* <ProfileDonationsSectionHeader
                            totalGiven={this.state.totalGiven}
                            donations={this.state.donations}
                            nonProfits={this.state.nonProfits}
                        /> */}
                        <div className='flex flex-col flex-1'>
                            {this.state.donations.length === 0 ?
                                <ZeroStateArea
                                    title={'Nothing here...'}
                                    sub={`No donations to show yet`}
                                />
                                :
                                this.state.donations.map(item => {
                                        const nonProfit = this.state.nonProfits[item.non_profit_id];
                                        const challenge = !!item.challenge_id ? this.props.challenges.find(challenge => challenge.challenge_id === item.challenge_id) : {};
                                        return (
                                            <ProfileDonationsListItem
                                                item={item}
                                                nonProfit={nonProfit}
                                                challenge={challenge}
                                            />
                                        )
                                    }
                                )}
                        </div>
                    </div>
                }
            </>
        )
    }

}


export default connect(mapStateToProps)(ProfileDonationsSection);
