import React, {useState} from 'react';
import SvgAddCircle from "../icons/SvgAddCircle";
import SvgCheck from "../icons/SvgCheck";
import NPOPicture from "./NPOPicture";
import SvgClose from '../icons/SvgClose';

function NonProfitSearchDropdownResult({item, selectNp, removeNp}) {
    const [following, setFollowing] = useState(false);
    return (
        <div
            key={item.name}
            onClick={() => {
                setFollowing(!following)
                selectNp(item)
            }}
            className='group flex flex-row items-center gap-3 py-2 mb-2 border border-slate-200 cursor-pointer hover:bg-slate-100 rounded-xl px-2'>
            <NPOPicture
                logoUrl={item.logo_url}
                np={item}
                size={40}
                className={'bg-white border border-slate-200'}
            />
            <div className='flex flex-col flex-1 mr-2'>
                <p className='text-md text-slate-800'>
                    {item.name}
                </p>
                {item.location &&
                    <p className='text-md text-slate-800'>
                        {item.location}
                    </p>
                }
            </div>
            {following ?
                <div className='text-blue-500'>
                    <SvgCheck/>
                </div>
                :
                !!removeNp ?
                    <div className='text-red-500'>
                        <SvgClose/>
                    </div>
                    :
                    <div className='text-slate-500 group-hover:text-blue-500'>
                        <SvgAddCircle/>
                    </div>
            }
        </div>
    );
}

export default NonProfitSearchDropdownResult;
