import React, {Component} from "react";
import LoadingSection from "../components/LoadingSection";
import MiddleViewHeader from "../components/layout/MiddleViewHeader";
import ProfilePicture from "../components/atoms/ProfilePicture";
import moment from "moment";
import PostAttachments from "../components/posts/PostAttachments";
import SvgSparkle from "../components/icons/SvgSparkle";
import PostContent from "../components/posts/PostContent";
import ReplyComponent from "../components/posts/ReplyComponent";
import {withRouter} from "react-router-dom";
import {getPublicPost} from "../api/posts";
import NPOPicture from "../components/atoms/NPOPicture";
import TextInput from "../components/inputs/TextInput";
import { Button } from "../components/atoms/Button";
import PostInteractionsRow from "../components/posts/PostInteractionsRow";
import DownloadAppCTA from "./Main/DownloadAppCTA";
import {connect} from "react-redux";
import SuggestedPostBanner from "../components/atoms/SuggestedPostBanner";


function mapStateToProps(state) {
    return {
        mobile: state.userReducer.mobile,
    }
}

class PublicPostPage extends Component {
    state = {
        loading: true,
        post: {},
        content: '',
        postId: "",
    }

    componentDidMount = async () => {
        this.getPostInfo()
    }

    getPostInfo = async () => {
        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const postId = lastArg.split("?")[0];

        const res = await getPublicPost(postId);
        this.setState({loading: false, postId, post: res.post});
    }

    clickProfile = () => {
        this.props.history.push({pathname: `/authentication`, state: {previous: "Back"}})
    }


    render() {
        const post = this.state.post || {};
        const np = post.non_profit || {};
        const deleted = post.post_content === "This post has been deleted."
        const user = {};

        return (
            <div className='col-plain page-wrapper col-ac'
                 style={{backgroundColor: "rgba(150,204,248,0.13)", paddingTop: 120}}>
                <div className='absolute cover-parent hero-wrapper' style={{zIndex: -1, opacity: .3}}/>
                {this.state.loading ?
                        <LoadingSection/>
                        :
                    <div className='flex flex-col flex-1 overflow-hidden' style={{maxWidth: this.props.mobile ? '100vw' : '60vw', minWidth: this.props.mobile ? '100vw' : '60vw'}}>
                        <div className='flex flex-col flex-1 overflow-y-scroll'>
                            <div className='bg-white  p-5 flex flex-col gap-3 border-b border-slate-100'>
                                <SuggestedPostBanner post={post}/>
                                <div className='flex flex-row gap-3 justify-between'>
                                    <div className='flex flex-row items-center gap-1 cursor-pointer' onClick={this.clickProfile}>
                                        {post.np_post ?
                                            <NPOPicture
                                                np={np}
                                                logoUrl={np.logo_url}
                                                initial={np.name[0]}
                                            />
                                            :
                                            <ProfilePicture
                                                initial={post.creator_name[0]}
                                                userID={post.creator}
                                            />
                                        }
                                        <p className='text-lg font-semibold text-slate-800'>
                                            {post.creator_name}
                                        </p>
                                        <p className='text-md text-slate-500'>
                                            {moment(post.created_at).fromNow('')}
                                        </p>
                                    </div>
                                </div>
                                {deleted ?
                                    <p>This post has been deleted.</p>
                                    :
                                    <div className='flex flex-col gap-5'>
                                        <PostContent post={post} />
                                        <PostAttachments
                                            post={post}
                                        />
                                        <div className='bg-slate-200 w-full h-[1px]'/>
                                        <PostInteractionsRow
                                            user={user}
                                            post={post}
                                            inFullPage
                                            static
                                        />
                                        <div className='bg-slate-200 w-full h-[1px] '/>
                                        {/* <p className='text-lg font-semibold text-slate-800 m-4'>
                                            {comments.length === 1 ? "Reply" : "Replies"} ({comments.length})
                                        </p> */}
                                        {/* <div className='flex flex-row items-center gap-3'>
                                            <div className='flex flex-row items-center gap-1 flex-1'>
                                                <ProfilePicture
                                                    initial={"ME"}
                                                    userID={""}
                                                    size={48}
                                                />
                                                <TextInput
                                                    value={this.state.content}
                                                    onChange={this.clickProfile}
                                                    className={'flex-1'}
                                                    placeholder={`Reply to ${post.creator_name}`}
                                                />
                                            </div>
                                            <div className='ml-auto mt-[2px]'>
                                                <Button variant={'default'} onClick={this.clickProfile}>
                                                    Reply
                                                </Button>
                                            </div>
                                        </div> */}
                                        {/* <div>
                                            {comments.map((comment) =>
                                                <ReplyComponent reply={comment} userId={user.username} likeComment={this.likeComment} />
                                            )}
                                        </div> */}
                                    </div>
                                }
                                <DownloadAppCTA mobile={this.props.mobile} ctaText={"Join the conversation"}/>
                            </div>
                        </div>
                </div>
                }
            </div>
        )
    }

}


export default connect(mapStateToProps)(withRouter(PublicPostPage));
