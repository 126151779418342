import React from 'react';

class RadioButtonSet extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(option) {
        this.props.onSelect(option);
    }

    render() {
        const {options, selectedOption} = this.props;

        return (
            <div className='row-ac' style={{flexWrap:'wrap'}}>
                {options.map(option => (
                    <div key={option}
                         onClick={() => this.handleChange(option)}
                         className='mr-20 mb-8 row-ac pointer'>
                        <div
                            className='mr-4'
                            style={{
                                appearance: 'none',
                                borderRadius: '50%',
                                width: 16,
                                height: 16,
                                margin: 0,
                                marginBottom: 0,
                                border: `2px solid ${selectedOption === option ? 'transparent' : '#999'}`,
                                backgroundColor: selectedOption === option ? '#054A91' : 'transparent',
                            }}
                        />
                        <p style={{color: selectedOption === option ? '#054A91' : '#999'}} className='ml-4'>
                            {option}
                        </p>
                    </div>
                ))}
            </div>
        );
    }
}

export default RadioButtonSet;
