import * as React from "react"

function SvgComponent(props) {
  return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          width="32px"
          height="32px"
          {...props}
          className={props.className}
      >
        <path fill={'currentColor'} d="M15.375 3c-.797.008-1.531.664-1.719 1.469-.015.05-.023.101-.031.156 0 0-.375 3.988-1.188 5.906-.16.38-.164.32-.218.344-.055.023-.364.078-.906.125-3.165.285-5.61.434-6.47.5a2.012 2.012 0 00-1.75 1.344c-.238.71.048 1.68.782 2.156 0 0 1.824 1.184 5.594 3.406.304.18.41.336.469.5.058.164.085.399-.063.813-.383 1.078-2.484 6.61-2.5 6.656v.031c-.309.832.016 1.754.625 2.219.559.426 1.453.438 2.125 0l.031.031s.172-.117.188-.125c.285-.164 2.953-1.715 5.344-4.156.37-.379.503-.344.468-.344-.035 0 .043-.023.25.188 2.438 2.523 5.024 4.172 5.25 4.312.012.008.02.024.032.032l.125.062.03-.031c.673.465 1.587.465 2.157.031.57-.434.844-1.277.625-2.063h.031l-.031-.156v-.031c-.063-.3-1.172-5.77-1.406-6.75-.114-.48-.192-.785-.188-.906.004-.121-.047-.13.25-.375.332-.278 1.633-1.18 2.75-1.938 1.102-.746 2.059-1.383 2.094-1.406l.031-.031c.672-.461.961-1.406.719-2.094-.246-.7-.82-1.203-1.563-1.375-.675-.156-1.832-.215-3.093-.313a136.653 136.653 0 00-3.063-.218c-.562-.024-.957-.23-1.156-.719-1.57-3.852-2.742-5.898-2.781-5.969-.016-.035-.047-.058-.063-.093-.355-.7-1.023-1.196-1.781-1.188zm.188 2.438c.093.164 1.105 1.914 2.593 5.562.512 1.258 1.758 1.918 2.938 1.969.48.02 1.73.09 2.968.187 1.227.094 2.532.266 2.75.313-.183.12-.894.594-1.906 1.281-1.129.766-2.336 1.586-2.906 2.063-.61.507-.953 1.222-.969 1.843-.015.621.16 1.067.25 1.438.16.676 1.332 6.5 1.375 6.718-.277-.175-2.61-1.687-4.812-3.968-.36-.371-.903-.782-1.625-.813-.723-.031-1.387.34-1.969.938-2.145 2.191-4.586 3.644-4.875 3.812.18-.472 1.969-5.234 2.375-6.375.258-.722.328-1.484.094-2.156-.235-.672-.742-1.207-1.344-1.563-3.59-2.117-5.172-3.125-5.313-3.218.833-.063 3.2-.188 6.313-.469.512-.047.977-.035 1.531-.281.555-.246.985-.781 1.25-1.406.93-2.196 1.211-5 1.281-5.876zM17 13c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm2.5 3c-.277 0-.5.223-.5.5s.223.5.5.5.5-.223.5-.5-.223-.5-.5-.5zM15 18c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z" />
      </svg>
  )
}

export default SvgComponent
