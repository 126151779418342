import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button} from '../../components/atoms/Button';
import SvgClose from '../../components/icons/SvgClose';
import SvgSearch from "../../components/icons/SvgSearch";
import {searchNonProfits, searchUsers} from "../../utils/typesense";
import GroupSearchResultItem from "../../components/groups/GroupSearchResultItem";
import ZeroStateArea from "../../components/atoms/ZeroStateArea";
import {getDefaultSuggestions, getSuggestions} from "../../api/users";
import GroupNonProfitListItem from "../../components/groups/GroupNonProfitListItem";

const SearchNonProfitsSheet = ({isOpen, close, group}) => {
    // const user = useSelector(state => state.userReducer.user);
    const dispatch = useDispatch();
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const [nonProfitsList, setNonprofitsList] = useState([])


    const searchChanged = (e) => {
        setSearch(e.target.value)
        searchNonProfits(e.target.value).then(nonProfitResults => {
            setNonprofitsList(nonProfitResults)
            setLoading(false)
        });
    }

    const closeSheet = () => {
        dispatch(close());
    };

    useEffect(() => {
        getDefault();
    }, [])

    const getDefault = async () => {
        const res = await getDefaultSuggestions();
        setNonprofitsList(res.non_profits);
    }
    return (
        <div
            className={`absolute ${isOpen ? 'h-full overflow-y-scroll' : 'h-0 overflow-hidden'} transition-all left-0 right-0 bottom-0 bg-white z-[10]`}>
            <div className={`flex flex-col flex-1 h-full p-5 mb-`}>
                <div className='flex flex-row items-center justify-between'>
                    <p className='text-2xl font-semibold text-slate-800'>
                        Add a supported cause to this group
                    </p>
                    <Button onClick={closeSheet} variant={'secondary'} size={'icon'} radius={'full'}>
                        <SvgClose/>
                    </Button>
                </div>
                <div className='flex flex-col gap-5 mt-20 pb-20'>
                    <div className='row-ac relative mb-3'>
                        <input
                            onChange={searchChanged}
                            value={search}
                            type="text"
                            className={'w-100p search-input rounded-lg'}
                            placeholder="Search nonprofits..."
                        />
                        <SvgSearch className={'absolute black-50'}
                                   style={{top: 10, left: 24, width: 24, height: 24}}/>
                    </div>
                </div>
                <div>
                    {nonProfitsList.map(nonProfit =>
                        <GroupNonProfitListItem nonProfit={nonProfit} key={nonProfit.id} group={group} isAdmin={true}/>
                    )}
                    <div className='hidden only:block mt-32'>
                        <ZeroStateArea
                            title={'Nothing found...'}
                            sub={`Search a nonprofit to add to your group`}
                        />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default SearchNonProfitsSheet;
