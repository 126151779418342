import React, {Component} from "react";
import {motion} from "framer-motion";
import BGGraphic4 from "../img/graphics/BGGraphic4";
import BGGraphic10 from "../img/graphics/BGGraphic10";
import Blueprint from "../components/icons/Blueprint";
import Ceremony from "../components/icons/Ceremony";
import Todo from "../components/icons/Todo";
import TimelineBlock from "../components/TimelineBlock";
import {TIMELINE} from "../utils/dummy";

class TimelineSection extends Component {

    render() {
        return (
            <div className='body1 black-80 mt-100' style={{marginTop: this.props.mobile ? 100 : 200}}>
                {TIMELINE.map((block, i) =>
                    <TimelineBlock key={block.label}
                                   block={block}
                                   i={i}
                                   isEvenIndex={i % 2 === 0}
                                   length={TIMELINE.length}
                                   mobile={this.props.mobile}
                    />
                )}
                <div
                    className={this.props.mobile ? 'col-ac-jb mt-80' : 'row-ac-jb mt-100'}>
                    <motion.div
                        initial={{opacity: 0, transform: 'scale(1) translateY(8px)'}}
                        whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                        transition={{default: {ease: "linear"}, delay: .5}}
                        className={this.props.mobile ? 'body1 black-80 mt-40 w-100p' : 'body1 black-80 mt-100 w-50p'}>
                        Each day the Starfish story is shared by more and more people – a reflection of their
                        compassion, values, and beliefs. On Starfish everyone is welcome to share their good, regardless
                        of their economic situation, educational background, geographical location or political beliefs.
                        {/*<br/><br/>*/}
                        {/*Starfish is where all users bring and share conversations that are thoughtful, safe,*/}
                        {/*uniting*/}
                        {/*and inspiring. A place where messages containing profanity, bullying, racist*/}
                        {/*comments or*/}
                        {/*violence are not welcomed. Thank you for making Starfish a place where users treat*/}
                        {/*each*/}
                        {/*other with respect and*/}
                        {/*encouragement.*/}

                        {/*<br/>*/}
                    </motion.div>
                    <motion.div
                        initial={{opacity: 0, transform: 'scale(1) translateY(8px)'}}
                        whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                        transition={{default: {ease: "linear"}, delay: .4}}
                        className={this.props.mobile ? 'w-100p col-ac-jc' : 'w-50p col-ac-jc'}>
                        {this.props.mobile ?
                            <img src={require('../img/brand/big-logo.png')} className='w-80p'
                                 style={{}}/>
                            :
                            <img src={require('../img/brand/big-logo.png')} className='w-80p'
                                 style={{marginTop: -80, marginRight: -300}}/>
                        }
                    </motion.div>
                </div>
            </div>
        )
    }

}


export default TimelineSection;
