import { useEffect } from 'react';
import { addRemoveGroupMember } from '../api/messaging';
import { connect } from 'react-redux';
import Spinner from '../components/Spinner';

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setPath: (path) => {
            dispatch({
                type: "SET_PATH",
                path,
            })
        },
    }
}

const InvitePage = (props) => {

    useEffect(() => {
        acceptInvite();
    }, [])

    const acceptInvite = async () => {
        const groupId = props.match.params.groupId;
        props.setPath("");
        if (!!groupId) {
            const user = props.user;
            try {
                await addRemoveGroupMember(user, groupId, user.username, `${user.first_name} ${user.last_name}`, true);
                props.history.push("/groups");
            } catch {
                props.history.push("/")
            }
        } else {
            props.history.push("/")
        }
    }

    return (
        <Spinner size={100} />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(InvitePage);
