import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            width="100px"
            height="100px"
            {...props}
        >
            <path fill="#f17300" d="M18.562 82.761L33.854 38.655 62.845 67.646z" />
            <path
                fill="#edf7f5"
                transform="rotate(-45.001 40.125 61.126)"
                d="M38.125 44.331H42.125V77.91900000000001H38.125z"
            />
            <path
                fill="#054a91"
                d="M33.854 38.655l28.991 28.991-44.283 15.115 15.292-44.106m-2.532-11.016l-3.138 9.051-15.29 44.106L8.867 92.41 20.5 88.439l44.282-15.115 9.115-3.111-6.81-6.81-28.991-28.991-6.774-6.773zM51.177 34.617l-2.354-3.234c2.778-2.021 4.325-4.324 4.599-6.847.47-4.338-2.879-8.165-2.913-8.203l2.978-2.671c.187.207 4.56 5.144 3.916 11.266-.389 3.708-2.484 6.968-6.226 9.689zM57 43v-4c1.95 0 12.747-1.955 14.181-5.617.609-1.558-1.044-3.532-2.538-4.914-1.401-1.296-5.665-5.238-4.115-9.069 1.779-4.396 9.922-4.433 11.518-4.398L75.956 19c-3.236-.063-7.248.729-7.721 1.9-.19.472.253 1.978 3.122 4.631 3.504 3.24 4.698 6.372 3.548 9.31C72.201 41.749 57.147 43 57 43zM81.558 54.492c-2.12-2.675-4.358-4.068-6.652-4.143-3.554-.083-6.388 2.955-6.416 2.985l-2.984-2.664c.163-.182 4.01-4.502 9.487-4.32 3.54.1 6.804 2.003 9.7 5.657l-3.135 2.485z"
            />
            <path
                fill="#81a4cd"
                transform="rotate(-44.433 50.998 49.498)"
                d="M46 13.789H55.999V85.21000000000001H46z"
            />
            <circle cx={85} cy={35} r={4} fill="#f17300" />
            <path
                fill="#054a91"
                d="M85 42c-3.859 0-7-3.141-7-7s3.141-7 7-7 7 3.141 7 7-3.141 7-7 7zm0-10c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3z"
            />
            <circle cx={40} cy={14} r={4} fill="#e3d265" />
            <path
                fill="#054a91"
                d="M40 21c-3.859 0-7-3.141-7-7s3.141-7 7-7 7 3.141 7 7-3.141 7-7 7zm0-10c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3z"
            />
        </svg>
    )
}

export default SvgComponent
