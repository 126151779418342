import React, {Component} from "react";
import { getDonations, getDonationSummary } from "../api/donations";
import MiddleViewHeader from "../components/layout/MiddleViewHeader";
import SectionTabs from "../components/SectionTabs";
import {CARD_STYLE_NO_HOVER} from "../utils/dummy";
import {connect} from "react-redux";
import { Button } from "../components/atoms/Button";
import SvgMoney from "../components/icons/SvgMoney";
import moment from "moment";
import LoadingSection from "../components/LoadingSection";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class GivingHistory extends Component {
    state = {
        activeTab: '2023',
        donations: [],
        totalGiven: 0,
        nonProfits: {},
        yearly: {},
        loading: true,
        buttonText: "Email My Donation History",
        buttonDisabled: false,
    }

    changeTab = (tab) => {
        const info = this.state.yearly[tab];
        this.setState({
            activeTab: tab,
            donations: info.donations,
            totalGiven: info.total_given,
            nonProfits: info.non_profits,
        });

    };

    componentDidMount = async () => {
        const res = await getDonations(this.props.authUser);
        const info = res[this.state.activeTab];
        this.setState({
            yearly: res,
            loading: false,
            donations: info.donations,
            totalGiven: info.total_given,
            nonProfits: info.non_profits
        })
    }

    donationSummary = async () => {
        this.setState({buttonDisabled: true, buttonText: "Check your email!"});
        await getDonationSummary(this.props.authUser);
    }

    render() {
        return (
            <div className='flex flex-col flex-1 overflow-hidden'>
                <MiddleViewHeader
                    title={'My Giving History'}
                    className={'pb-0'}
                    RightComponent={
                        <>
                            {this.state.activeTab === "2023" ?
                                <Button
                                    disabled={this.state.buttonDisabled}
                                    onClick={this.donationSummary}
                                >
                                    {this.state.buttonText}
                                </Button>
                                :
                                <Button
                                    disabled={true}
                                >
                                    {this.state.buttonText}
                                </Button>
                            }
                        </>

                    }
                >
                    <SectionTabs
                        activeTab={this.state.activeTab}
                        changeTab={this.changeTab}
                        tabs={['2023', '2024']}
                    />
                </MiddleViewHeader>
                {this.state.loading ?
                    <LoadingSection />
                    :
                    <div className='flex flex-col flex-1 p-5 gap-5 overflow-y-scroll'>
                        <div className='rounded-2xl bg-blue-500 p-5 flex flex-col gap-4'>
                            <div className='flex flex-col gap-0'>
                                <p className='text-white text-5xl font-semibold'>
                                    ${this.state.totalGiven.toFixed(2)}
                                </p>
                                <p className='text-white/50 text-md'>
                                    Total Given
                                </p>
                            </div>
                            <div className='flex flex-row items-center gap-8'>
                                <div className='flex flex-row items-center gap-3'>
                                    <p className='text-white text-4xl font-semibold'>
                                        {this.state.donations.length}
                                    </p>
                                    <p className='text-white/50 text-sm'>
                                        Separate<br/>Donations
                                    </p>
                                </div>
                                <div className='flex flex-row items-center gap-3'>
                                    <p className='text-white text-4xl font-semibold'>
                                        {Object.keys(this.state.nonProfits).length}
                                    </p>
                                    <p className='text-white/50 text-sm'>
                                        Different<br/>Nonprofits
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col flex-1 overflow-y-scroll'>
                            {this.state.donations.map(item =>
                                <div key={item} className={`${CARD_STYLE_NO_HOVER} flex flex-row items-center justify-between`} style={{cursor:'auto'}}>
                                    <div className='flex flex-col'>
                                        <p className='text-lg text-slate-800 font-medium'>
                                            {this.state.nonProfits[item.non_profit_id] }
                                        </p>
                                        <p className='text-md text-slate-500 font-medium'>
                                            EIN: {item.non_profit_id}  |  {moment(item.created_at).format('LLLL')}
                                        </p>
                                    </div>
                                    <p className='text-2xl font-semibold text-emerald-500'>
                                        ${item.amount}
                                    </p>
                                </div>
                            )}
                            {/* <p className='text-md text-slate-500 font-medium'>
                                A downloadable version of your donation history will be made available by January 15, 2024 for tax purposes.
                            </p> */}
                        </div>
                    </div>
                }
            </div>
        )
    }

}


export default connect(mapStateToProps)(GivingHistory);
