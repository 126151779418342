import React, {PureComponent} from "react";
import SvgPin from "./icons/SvgPin";
import SvgCalendar from "./icons/SvgCalendar";
import SvgVolunteer from "./icons/SvgVolunteer";
import SvgStarfish from "./icons/SvgStarfish";
import SvgComment from "./icons/SvgComment";
import SvgRepost from "./icons/SvgRepost";


class PostCard extends PureComponent {

    switchContent = () => {
        switch (this.props.post.id) {
            case 'npo1':
                return (
                    <div className='mt-4 body2 black-100' style={{wordBreak: 'break-word'}}>
                        Happy <span className='primary' style={{fontWeight: 600}}>#WorldBonoboDay</span>!
                        Bonobos (along with chimpanzees) share 98.7% of their DNA
                        with humans. Bonobo groups tend to be peaceful and led by females. Wild bonobos can
                        only be found in forests south of the Congo River 🦍.
                    </div>
                )
            case 'user1':
                return (
                    <div className='mt-4 body2 black-100' style={{wordBreak: 'break-word'}}>
                        The "Protect the Environment" Invitational starts tomorrow! The winner will be able to
                        'adopt' a Barn Owl & receive the complimentary Gift Basket from
                        <span className='primary' style={{
                            fontWeight: 600,
                            color: '#e39165',
                            backgroundColor: '#e3916530',
                            padding: '1px 3px',
                            borderRadius: 4
                        }}>World Wildlife Fund</span>
                        !
                    </div>
                )
            case 'um':
                return (
                    <div className='mt-4 body2 black-100' style={{wordBreak: 'break-word'}}>
                        Had a blast meeting you all and helping with sign in people for the “Clean Up Campus” event. GO
                        BLUE!
                        <span className='body2-bold primary'>#universityofmichigan #goblue #keepourcampusclean</span>
                        !
                    </div>
                )
            case 'aspca':
                return (
                    <div className='mt-4 body2 black-100' style={{wordBreak: 'break-word'}}>
                        My roommates and I got to take some shelter dogs out for a walk this weekend. I think we had as
                        much fun as they did!
                        <span className='body2-bold primary'>#seethegood #bethegood #aspca #dogsneedlove</span>
                        !
                    </div>
                )
            default:
                return null
        }
    }

    render() {
        return (
            <div className='bg-white-pure br-12 box profile-card' style={{maxWidth: 500}}>
                <div className={this.props.mobile ? 'p-3' : 'p-4'}>
                    <div className='row-plain' style={{alignItems: "stretch"}}>
                        {this.props.post.npo ?
                            <img src={this.props.post.img}
                                 style={{
                                     width: this.props.mobile ? 40 : 54,
                                     height: this.props.mobile ? 40 : 54,
                                     objectFit: "cover",
                                     borderRadius: 12,
                                     border: '4px solid #ffffff'
                                 }}/>
                            :
                            <img src={this.props.post.img}
                                 style={{
                                     width: this.props.mobile ? 40 : 54,
                                     height: this.props.mobile ? 40 : 54,
                                     objectFit: "cover",
                                     borderRadius: 100,
                                     border: '4px solid #ffffff'
                                 }}/>
                        }
                        <div className={this.props.mobile ? 'col-jb mv-4 ml-4' : 'col-jb mv-4 ml-8'}>
                            <div className='row-ac'>
                                <div className='body2-bold mr-8'>
                                    {this.props.post.name}
                                </div>
                                <div className='body2 black-50'>
                                    {this.props.post.time}
                                </div>
                            </div>
                            {this.switchContent()}
                            <div className='row-ac mt-12 black-50'>
                                <div className='row-ac mr-20 black-50'>
                                    <div className='col-ac-jc' style={{height: 20}}>
                                        <SvgStarfish strokeWidth={2} stroke={'currentColor'} width={16} height={16}
                                                     className={'mr-4'} style={{marginTop: -2}}/>
                                    </div>
                                    <div className='body3-bold'>
                                        {this.props.post.num_likes}
                                    </div>
                                </div>
                                <div className='row-ac mr-20'>
                                    <div className='col-ac-jc' style={{height: 20}}>
                                        <SvgComment width={18} height={18}
                                                    className={'mr-4'} style={{marginTop: 2}}/>
                                    </div>
                                    <div className='body3-bold'>
                                        {this.props.post.comments}
                                    </div>
                                </div>
                                <div className='row-ac'>
                                    <div className='col-ac-jc' style={{height: 20}}>
                                        <SvgRepost width={18} height={18}
                                                   className={'mr-4'}/>
                                    </div>
                                    <div className='body3-bold'>
                                        {this.props.post.reposts}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PostCard
