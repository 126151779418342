import React, {PureComponent} from "react";
import BGGraphic7 from "../../img/graphics/BGGraphic7";
import {motion} from "framer-motion";
import BGGraphic9 from "../../img/graphics/BGGraphic9";
import NPOCard from "../../components/NPOCard";
import ConnectionCard from "../../components/ConnectionCard";
import PostCard from "../../components/PostCard";
import SvgCommunication from "../../components/icons/SvgCommunication";
import SvgPin from "../../components/icons/SvgPin";
import SvgProfile from "../../components/icons/SvgProfile";
import SvgCrowd from "../../components/icons/SvgCrowd";
import ProfileCard from "../../components/ProfileCard";
import {NPO_DUMMY_SLIDES, PROFILE_CAROUSEL_CONTENT} from "../../utils/dummy";
import {wait} from "@testing-library/user-event/dist/utils";
import ArrowBack from "../../components/icons/ArrowBack";
import ArrowForward from "../../components/icons/ArrowForward";


const STEP_ORDER = {
    first: 0,
    second: 1,
    third: 2,
}

class Section2 extends PureComponent {
    state = {
        step: 'first',
        firstTranslation: 20,
        secondTranslation: 20,
        thirdTranslation: 20,
        autoPlay: true,
    }

    componentDidMount() {
        this.unfreezeCarousel()
    }

    unfreezeCarousel = () => {
        this.interval = setInterval(() => {
            this.next()
        }, 6000);
    }

    freezeCarousel = () => {
        clearInterval(this.interval)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.autoPlay && !this.state.autoPlay) {
            this.freezeCarousel()
        }
        if (!prevState.autoPlay && this.state.autoPlay) {
            this.unfreezeCarousel()
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    navigateToStep = step => {
        const currentStep = this.state.step
        const movingForwards = STEP_ORDER[step] > STEP_ORDER[currentStep]
        this.setState(
            {opacity: 0, [`${currentStep}Translation`]: movingForwards ? -20 : 20},
            () => {
                wait(600)
                    .then(() => {
                        this.setState(
                            {step},
                            () => wait(300)
                                .then(() => this.setState({opacity: 1, [`${step}Translation`]: 0}))
                        )
                    })
            }
        )
    }

    next = () => {
        if (this.state.step === 'first') {
            this.navigateToStep('second')
        }
        if (this.state.step === 'second') {
            this.navigateToStep('third')
        }
        if (this.state.step === 'third') {
            this.navigateToStep('first')
        }
    }

    back = () => {
        if (this.state.step === 'second') {
            this.navigateToStep('first')
        }
        if (this.state.step === 'third') {
            this.navigateToStep('second')
        }
    }

    switchGraphic = () => {
        switch (this.state.step) {
            case 'first':
                return (
                    <div
                        style={{
                            transition: 'opacity 600ms ease, transform 300ms ease',
                            opacity: this.state.opacity,
                            transform: `translateY(${Math.abs(this.state.firstTranslation) / 2}px)`
                        }}
                    >
                        <div style={{marginRight: this.props.mobile && '4%'}}>
                            <NPOCard npo={NPO_DUMMY_SLIDES[0].npo} mobile={this.props.mobile}/>
                        </div>
                        <div style={{
                            marginTop: this.props.mobile ? -12 : -16,
                            marginLeft: this.props.mobile ? '4%' : '12%',
                            marginRight: this.props.mobile ? '0%' : '-4%'
                        }}>
                            <PostCard npo post={NPO_DUMMY_SLIDES[0].posts[0]} mobile={this.props.mobile}/>
                        </div>
                        <div style={{
                            marginTop: this.props.mobile ? -12 : -16,
                            marginRight: this.props.mobile ? '8%' : '12%'
                        }}>
                            <PostCard post={NPO_DUMMY_SLIDES[0].posts[1]} mobile={this.props.mobile}/>
                        </div>
                    </div>
                )
            case 'second':
                return (
                    <div
                        style={{
                            transition: 'opacity 600ms ease, transform 300ms ease',
                            opacity: this.state.opacity,
                            transform: `translateY(${Math.abs(this.state.secondTranslation) / 2}px)`
                        }}
                    >
                        <div style={{marginRight: this.props.mobile && '4%'}}>
                            <NPOCard npo={NPO_DUMMY_SLIDES[1].npo} mobile={this.props.mobile}/>
                        </div>
                        <div style={{
                            marginTop: this.props.mobile ? -12 : -8,
                            marginLeft: this.props.mobile ? '4%' : '20%',
                            marginRight: this.props.mobile ? '0%' : '-4%'
                        }}>
                            <PostCard npo post={NPO_DUMMY_SLIDES[1].posts[0]} mobile={this.props.mobile}/>
                        </div>
                    </div>
                )
            case 'third':
                return (
                    <div
                        style={{
                            transition: 'opacity 600ms ease, transform 300ms ease',
                            opacity: this.state.opacity,
                            transform: `translateY(${Math.abs(this.state.thirdTranslation) / 2}px)`
                        }}
                    >
                        <div style={{marginLeft: this.props.mobile ? '4%' : '15%'}}>
                            <NPOCard npo={NPO_DUMMY_SLIDES[2].npo} mobile={this.props.mobile}/>
                        </div>
                        <div style={{
                            marginTop: this.props.mobile ? -12 : -16,
                            marginLeft: this.props.mobile ? '-2%' : '-2%'
                        }}>
                            <PostCard post={NPO_DUMMY_SLIDES[2].posts[1]} mobile={this.props.mobile}/>
                        </div>
                        {/*<div style={{*/}
                        {/*    marginTop: this.props.mobile ? -12 : -16,*/}
                        {/*    // marginLeft: this.props.mobile ? '4%' : '12%',*/}
                        {/*    marginRight: this.props.mobile ? '0%' : '-4%'*/}
                        {/*}}>*/}
                        {/*    <PostCard post={NPO_DUMMY_SLIDES[2].posts[0]} mobile={this.props.mobile}/>*/}
                        {/*</div>*/}
                    </div>
                )
            default:
                return null
        }
    }


    render() {
        return (
            <section className={this.props.mobile ? 'col-ac relative' : 'row-ac-jb relative'}
                     style={{
                         height: !this.props.mobile && '90vh',
                         backgroundColor: "rgb(245,248,255)",
                         padding: this.props.mobile ? '80px 24px' : '60px 10vw'
                     }}>
                {/*<div className='absolute cover-parent hero-wrapper' style={{zIndex: 0}}/>*/}
                <BGGraphic9 className='absolute' style={{
                    bottom: this.props.mobile ? -50 : -100,
                    left: this.props.mobile ? -12 : 60,
                    width: this.props.mobile ? '25vw' : '14vw',
                    opacity: 1
                }}/>
                <motion.div style={{flex: 1, maxWidth: !this.props.mobile && '30vw'}}
                            initial={{opacity: 0, transform: 'scale(1) translateY(12px)'}}
                            whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            transition={{default: {ease: "linear"}}}
                            className={this.props.mobile ? 'mb-40 col-plain' : 'col-plain'}>
                    {/*<div className='ph-24 pv-12 br-64 body1-bold mb-12 white-pure'*/}
                    {/*     style={{backgroundColor: "#956DE6A0", marginRight: "auto"}}>*/}
                    {/*    <SvgCommunication width={36} height={36} fill={'#FFFFFF'}/>*/}
                    {/*</div>*/}
                    <div className='body3-bold black-50 mb-8' style={{textTransform: 'uppercase'}}>
                        Discover
                    </div>
                    <h1 className='heading2 black-100 mb-24 mt-0' >
                        A new way to<br/>support your causes
                    </h1>
                    <p className='mt-0 body1 black-70'>
                        Explore the nonprofits you care about most. Starfish connects you
                        with over 1.8M organizations that align with your values and beliefs. Discover new ways to make
                        a
                        difference and connect with others in your
                        communities of good.
                    </p>
                </motion.div>
                <div style={{flex: 1, maxWidth: this.props.mobile ? '100%' : '40vw'}} className='col-plain'>
                    <motion.div
                        initial={{opacity: 0, transform: 'scale(1) translateY(12px)'}}
                        whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                        transition={{default: {ease: "linear"}, delay: .2}}
                        onMouseEnter={this.freezeCarousel}
                        onMouseLeave={this.unfreezeCarousel}
                    >
                        {this.switchGraphic()}
                        {/*<div className='row-ac mt-32'>*/}
                        {/*    <div onClick={() => {*/}
                        {/*        this.back()*/}
                        {/*        this.setState({autoPlay: false})*/}
                        {/*    }}*/}
                        {/*         style={{marginRight: 8}}*/}
                        {/*         className={`${this.state.step === 'first' ? 'black-30 next-button-disabled' : 'black-100 pointer next-button'}`}>*/}
                        {/*        <ArrowBack width={30} height={30}/>*/}
                        {/*    </div>*/}
                        {/*    <div onClick={() => {*/}
                        {/*        this.next()*/}
                        {/*        this.setState({autoPlay: false})*/}
                        {/*    }}*/}
                        {/*         style={{marginLeft: 8}}*/}
                        {/*         className={`${'black-100 pointer next-button'}`}>*/}

                        {/*        <ArrowForward width={30} height={30}/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </motion.div>
                </div>
            </section>
        )
    }
}

export default Section2
