import React, {useState, useEffect} from 'react';
import {uploadDoc} from '../../api/firebase';
import OnboardingStepHeader from "../Onboarding/OnboardingStepHeader";
import OnboardingButtons from "../Onboarding/OnboardingButtons";
import Dropzone from "react-dropzone";
import SvgAddCircle from "../../components/icons/SvgAddCircle";
import ChallengeDonationBar from "../../components/ChallengeDonationBar";
import ChallengeAwarenessBar from "../../components/ChallengeAwarenessBar";
import ChallengePledgeBar from "../../components/ChallengePledgeBar";
import NPOPicture from "../../components/atoms/NPOPicture";
import ChallengeEndDate from "../../components/ChallengeEndDate";

function CreateChallengePictures({
                                     goNextStep,
                                     goBackStep,
                                     name,
                                     ein,
                                     description,
                                     setCoverPic,
                                     coverPic,
                                     donationsGoal,
                                     awarenessGoal,
                                     pledgeGoal,
                                     pledgeText,
                                     selectedNp,
                                     endDate
                                 }) {
    const [coverUrl, setCoverUrl] = useState('')

    useEffect(() => {
        setCoverUrl(coverPic)
    }, [coverPic]);

    const handleSubmit = () => {
        goNextStep();
    };

    const onDropCover = async (acceptedFiles) => {
        const url = await uploadDoc("challenges/cover_photo/", name + "-" + ein + ".jpeg", acceptedFiles[0]);
        setCoverPic(url)
        setCoverUrl(url)
    }

    return (
        <div className='flex flex-col gap-8 w-[460px]'>
            <OnboardingStepHeader
                title={'Add a cover photo!'}
                description={'Images help your challenge stand out'}
            />
            <div className='flex flex-col gap-2 border border-slate-300 rounded-2xl p-3'>
                <Dropzone onDrop={(files) => onDropCover(files)}>
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} className='cursor-pointer'>
                            <input {...getInputProps()} />
                            <div style={{}} className=''>
                                {!!coverUrl ? (
                                    <div
                                        className='h-24 w-full bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square overflow-hidden flex flex-col items-center justify-center border border-gray-200 rounded-lg'
                                    >
                                        <img
                                            className='h-24 w-full object-cover bg-white aspect-square cursor-pointer  rounded-lg'
                                            src={coverUrl}
                                            alt="profile-pic"
                                        />
                                        {/* <div className='absolute flex flex-col items-center justify-center'>
                                                        <SvgEdit className={'text-blue-500'}/>
                                                        <p className='text-xs mt-2'>
                                                            Edit image
                                                        </p>
                                                    </div> */}
                                    </div>
                                ) : (
                                    <div
                                        className='h-24 w-full bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square flex flex-col items-center justify-center border border-gray-200 rounded-lg'
                                    >
                                        <SvgAddCircle className={'text-blue-500'}/>
                                        <p className='text-xs mt-2'>
                                            Add cover
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </Dropzone>
                {/* <div style={{}} className=''>
                    <div
                        className='w-20 h-20 bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square overflow-hidden flex flex-col items-center justify-center border border-gray-200 rounded-full'
                    >
                        <img
                            className='w-20 h-20 object-cover bg-white aspect-square cursor-pointer  rounded-full'
                            src={"profileImageUrl"}
                            alt="profile-pic"
                        />
                    </div>
                </div> */}
                <div className='flex flex-col gap-1'>
                   <div className='mr-a'>
                       <NPOPicture
                           np={selectedNp}
                           size={60}
                           className={'bg-white border border-slate-200 ml-2 -mt-16'}
                       />
                   </div>
                    <div className='flex flex-row items-center justify-between'>
                        <div className='flex flex-col'>
                            <p className={'text-lg text-slate-800 font-semibold'}>
                                {name}
                            </p>
                        </div>
                        <div className='-mt-12'>
                            <ChallengeEndDate challenge={{end_date: endDate}}/>
                        </div>
                    </div>
                    <p>
                        {description}
                    </p>
                </div>
                <div className='flex flex-col gap-3'>
                    {donationsGoal > 0 ?
                        <div className='mb-12'>
                            <ChallengeDonationBar challenge={{
                                donations_goal: donationsGoal,
                                donations_progress: 0
                            }}/>
                        </div>
                        : null}
                    {awarenessGoal > 0 ?
                        <div>
                            <ChallengeAwarenessBar challenge={{
                                awareness_goal: awarenessGoal,
                                awareness_progress: 0
                            }}/>
                        </div>
                        : null}
                    {pledgeGoal > 0 ?
                        <div>
                            <div className='bg-blue-500/10 rounded-xl p-3 mb-12'>
                                <p className='text-lg font-medium text-blue-500'>
                                    <span className='font-semibold'>Pledge:</span> {pledgeText}
                                </p>
                            </div>
                            <ChallengePledgeBar challenge={{
                                pledge_goal: pledgeGoal,
                                pledge_progress: 0
                            }}/>
                        </div>
                        : null}
                </div>
            </div>
            <OnboardingButtons
                goNextStep={handleSubmit}
                goBackStep={goBackStep}
                nextDisabled={!coverPic}
                finish
            />
        </div>
    );
}

export default CreateChallengePictures;
