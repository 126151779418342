import React from 'react';

function OnboardingStepHeader({title, description}) {

    return (
        <div className='flex flex-col md:gap-3'>
            <p className='text-2xl md:text-4xl font-semibold text-center'>
                {title}
            </p>
            <p className='font-medium text-slate-700 text-center text-sm md:text-base'>
                {description}
            </p>
        </div>
    );
}

export default OnboardingStepHeader;
