import React, {Component} from "react";
import moment from "moment/moment";
import PostLikedCount from "../PostLikedCount";
import SvgMessage from "../icons/SvgMessage";
import PostContent from "./PostContent";
import ProfilePicture from "../atoms/ProfilePicture";

class ReplyComponent extends Component {
    state = {
        liked: false,
    }

    componentDidMount = () => {
        this.setLiked();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.reply !== this.props.reply) {
            this.setLiked();
        }
    }

    setLiked = () => {
        const reply = this.props.reply || {};
        const likes = reply.likes || {};
        if (likes[this.props.userId]) {
            this.setState({liked: true});
        }
    }

    render() {
        const reply = this.props.reply || {};
        const likes = reply.likes || {}
        return (
            <div className='flex flex-col border-b last:border-b-transparent py-4 border-slate-200'>
                <div className='flex flex-row gap-1'>
                    <div>
                        {/* TODO NP profile if np_reply */}
                        <ProfilePicture
                            initial={reply.creator_name[0]}
                            userID={reply.creator}
                        />
                    </div>
                    <div className='flex flex-col flex-1 gap-2'>
                        <div>
                            <div className='flex flex-row items-end gap-2'>
                                <p className='text-lg font-semibold text-slate-800'>
                                    {reply.creator_name}
                                </p>
                                <p className='text-md text-slate-500 mb-0.5'>
                                    {moment(reply.created_at).fromNow('')}
                                </p>
                            </div>
                            {/* <p className='text-lg text-slate-700 '>
                                {reply.content}
                            </p> */}
                            <PostContent post={reply}/>
                        </div>
                        <div className='flex flex-row items-center gap-5 font-semibold text-slate-500'>
                            <PostLikedCount
                                active={this.state.liked}
                                toggle={() => this.props.likeComment(reply.id)}
                                numLikes={Object.keys(likes).length}
                            />
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}


export default ReplyComponent;
