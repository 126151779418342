import React, {Component} from "react";
import {motion} from "framer-motion";
import BGGraphic4 from "../img/graphics/BGGraphic4";
import BGGraphic10 from "../img/graphics/BGGraphic10";

class VideoSeciton extends Component {

    render() {
        return (
            <div className='body1 black-80 mt-100'>
                <div className='relative'>
                    <BGGraphic4 className='absolute black-10' width={'12vw'} height={'12vw'}
                                style={{right: 0, zIndex: 0, top: -40, transform: 'rotate(12deg)'}}/>
                    <motion.div
                        initial={{opacity: 0, transform: 'scale(1) translateY(8px)'}}
                        whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                        transition={{default: {ease: "linear"}, delay: .2}}
                        className={this.props.mobile ? 'heading2 w-100p mb-20' : 'heading2 w-60p mb-20'} style={{lineHeight: 1.4}}>
                        Learn more about <i>Starfish</i>
                    </motion.div>
                </div>
                <motion.div
                    initial={{opacity: 0, transform: 'scale(1) translateY(8px)'}}
                    whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                    transition={{default: {ease: "linear"}, delay: .2}}
                    className='w-80p'>

                </motion.div>
                <div className={this.props.mobile ? 'col-plain mt-40 flex-1' :'row-plain mt-40 flex-1'} style={{flexWrap: 'wrap'}}>

                </div>
            </div>
        )
    }

}


export default VideoSeciton;
