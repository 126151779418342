import axios from "./axios";
import {firebaseAuth, googleProvider} from "./firebase";
import {signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup} from "firebase/auth";

export const signUp = async (email, uid, password, first_name, last_name) => {
    const body = {
        email,
        password,
        first_name,
        last_name,
        uid, 
    };
    const res = await axios.post("/auth/signup", body);
    return res.data;
};

export async function signInWithGoogle() {
    try {
        // Sign in with Google
        const result = await signInWithPopup(firebaseAuth, googleProvider);

        // Access the Google ID token from the signed-in user
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const idToken = await result.user.getIdToken(true);

        // Send the ID token to the backend
        const response = await axios.post('/auth/google-signin', { idToken });

        if (response.data.success) {
            console.log("User successfully signed in:", response.data);
            // You can store the user info or perform additional operations
        } else {
            console.error("Failed to sign in:", response.data.error);
        }
    } catch (error) {
        console.error("Error during Google sign-in:", error.message);
    }
}

export const login = async (email, password) => {
    return await signInWithEmailAndPassword(firebaseAuth, email, password)
        .then(async credential => {
            return credential.user
        })
        .catch(async error => {
            if (error.code === 'auth/invalid-email') {
                const uid = email;
                const config = {
                    params: {uid, email, password}
                };
                try {
                    const response = await axios.get("/auth/email", config);
                    return await login(response.data.email, password);
                } catch (err) {
                    throw new Error(err.request.status, err.message);
                }
            } else {
                throw new Error(error.code, error.message)
            }
        })
};

export const logout = async () => {
    return await firebaseAuth.signOut()
        .then(() => true)
        .catch(error => {
            console.log(error.code, error.message);
            return false
        })
};

export const disableAccount = async (user) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post("/auth/disable", {}, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
};