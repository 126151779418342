import React, {PureComponent} from "react";
import ModalComponent from "../ModalComponent";
import {Button} from "../atoms/Button";
import TextInput from "../inputs/TextInput";
import CollegeSearchDropdown from "../atoms/CollegeSearchDropdown";
import NonProfitSearchDropdownResult from "../atoms/NonProfitSearchDropdownResult";
import { addLinkedin } from "../../api/users";


class AddLinkedinModal extends PureComponent {
    state = {
        linkedin: "",
        loading: false,
    }

    handleInputChange = (inputName, e) => {
        this.setState({[inputName]: e.target.value})
    }

    submit = async () => {
        this.setState({loading: true})
        await addLinkedin(this.props.authUser, this.state.linkedin);
        this.setState({loading: false});
        window.location.reload();
    }

    render() {
        const disabled = !this.state.linkedin.startsWith("https://www.linkedin.com/");
        return (
            <ModalComponent toggle={this.props.close}
                            isOpen={this.props.isOpen}
                            noPadding
                            size={'lg'}
                            header={`Add your LinkedIn URL`}
                            headerToggle
                            FooterComponent={
                                <div className='flex flex-row items-center gap-3'>
                                    <Button variant={'secondary'} onClick={this.props.close}>
                                        Cancel
                                    </Button>
                                    <Button variant={'default'}  onClick={this.submit} disabled={disabled} loading={this.state.loading}>
                                        Submit
                                    </Button>
                                </div>
                            }
            >
                <div className="flex flex-col px-5 pb-3">
                    <TextInput
                        label={'LinkedIn'}
                        value={this.state.linkedin}
                        onChange={(e) => this.handleInputChange("linkedin", e)}
                        placeholder={'e.g. https://www.linkedin.com/john-smith'}
                    />
                </div>
            </ModalComponent>
        );
    }
}

export default (AddLinkedinModal);
