import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import ChallengeCard from "../ChallengeCard";
import {getUser} from "../../api/users";
import {getNonProfit} from "../../api/nonProfits";
import {getChallenge} from "../../api/challenges";
import {getLinkPreview, getPost} from "../../api/posts";
import PostComponent from "../posts/PostComponent";
import LinkPreviewWrapper from "../posts/LinkPreviewWrapper";
import Spinner from "../Spinner";
import PostImages from "../PostImages";

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setImageViewerModalOpen: (images, index) => {
            dispatch({
                type: "SET_IMAGE_VIEWER_MODAL_OPEN",
                images,
                index
            })
        },
    }
}

function MessageAttachments({message, authUser, setImageViewerModalOpen}) {
    const [loading, setLoading] = useState(true)
    const [userProfile, setUserProfile] = useState(null)
    const [nonProfit, setNonProfit] = useState(null)
    const [challenge, setChallenge] = useState(null)
    const [post, setPost] = useState(null)
    const [linkPreview, setLinkPreview] = useState(null)
    const [imageUrls, setImageUrls] = useState([])

    // const openImageLightbox = (imageURLs, index, creator, creator_name) => {
    //     props.setImageViewerModalOpen(imageURLs, index);
    // }

    useEffect(() => {
        async function fetchData() {
            const messageAttachment = message?.messageAttachment;
            const messageAttachmentId = messageAttachment?.id;
            const messageAttachmentType = messageAttachment?.type;
            setLoading(true);
            try {
                if (messageAttachmentType === "user") {
                    const userProfileData = await getUser(messageAttachmentId);
                    setUserProfile(userProfileData.user);
                } else if (messageAttachmentType === "nonProfit") {
                    const nonProfitData = await getNonProfit(messageAttachmentId);
                    setNonProfit(nonProfitData.non_profit);
                } else if (messageAttachmentType === "challenge") {
                    const challengeData = await getChallenge(messageAttachmentId);
                    setChallenge(challengeData.challenge);
                } else if (messageAttachmentType === "post") {
                    const postData = await getPost(authUser, 'Wm0yms6VRF7JQy7XNjIH');
                    setPost(postData.post);
                } else if (messageAttachmentType === "link") {
                    setLinkPreview(messageAttachment);
                } else if (messageAttachmentType === "image") {
                    const imageIds = Object.values(messageAttachment.image_urls);
                    setImageUrls(imageIds.map(id => `https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/chats%2F${id}?alt=media`))
                    setLoading(false)
                }
                setLoading(false);
            } catch (error) {
                // Handle or log error as appropriate
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, [message]);

    const openImageLightbox = (imageURLs, index, creator, creator_name) => {
        setImageViewerModalOpen(imageURLs, index);
    }

    const renderAttachment = () => {
        const messageAttachment = message?.messageAttachment
        const messageAttachmentId = messageAttachment?.id;
        const messageAttachmentType = messageAttachment?.type;
        if (messageAttachmentType === 'challenge') {
            return (
                <div className='w-full'>
                    <ChallengeCard
                        key={challenge?.challenge_id}
                        challenge={challenge}
                        border
                    />
                </div>
            )
        } else if (messageAttachmentType === 'post') {
            return (
                <div className='border border-slate-200 rounded-xl p-0 flex flex-col gap-2 overflow-hidden'>
                    <PostComponent
                        post={post}
                        hidePostInteractions
                    />
                </div>
            )
        } else if (messageAttachmentType === 'link') {
            return (
                <LinkPreviewWrapper link_preview={linkPreview}/>
            )
        } else if (messageAttachmentType === 'image') {
            return (
                <div>
                    <PostImages
                        id={message.id}
                        openImageLightbox={openImageLightbox}
                        imageURLs={imageUrls}
                        videos={[]}
                        creator={message.creator}
                        creator_name={message.creator_name}
                        paused={true}
                        inFeed={false}
                        // navigateToPost={this.navigateToPost}
                    />
                </div>
            )
        }
    }

    return (
        <div className='mt-2'>
            {loading ? <Spinner/> : renderAttachment()}
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageAttachments)
