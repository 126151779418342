import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            width="48px"
            height="48px"
            {...props}
        >
            <path fill={'currentColor'} d="M15 9c-2.206 0-4 1.794-4 4v4.672c0 1.614.96 3.06 2.447 3.685l15.946 6.715-3.202 6.489-8.978 1.304a1.998 1.998 0 00-1.11 3.41l6.499 6.334-1.536 8.942a2 2 0 002.902 2.11L32 52.436l8.031 4.223a1.996 1.996 0 002.105-.152 2.004 2.004 0 00.798-1.957l-1.536-8.942 6.498-6.334a1.998 1.998 0 00-1.109-3.41l-8.978-1.304-3.202-6.489 15.946-6.713A3.993 3.993 0 0053 17.672V13c0-2.206-1.794-4-4-4H15zm.2 4H23v8.041l-7.879-3.318a.198.198 0 01-.121-.184V13.2c0-.11.09-.199.2-.199zM27 13h10v9.725l-5 2.105-5-2.105V13zm14 0h7.8c.11 0 .2.09.2.2l.002 4.34a.2.2 0 01-.123.183L41 21.04V13zm-9 18.828l2.688 5.445c.291.591.854 1 1.505 1.094l6.01.873-4.35 4.239a2.007 2.007 0 00-.574 1.771l1.028 5.984-5.375-2.826a2.017 2.017 0 00-1.864 0l-5.375 2.826 1.028-5.982a2.003 2.003 0 00-.575-1.772l-4.35-4.238 6.01-.875a1.999 1.999 0 001.506-1.094L32 31.828z" />
        </svg>
    )
}

export default SvgComponent
