import React, {PureComponent, useEffect, useState} from "react";
import ModalComponent from "../ModalComponent";
import {Button} from "../atoms/Button";
import GlobalInvitesWidget from "../invites/GlobalInvitesWidget";
import SvgAddCircle from "../icons/SvgAddCircle";
import InviteListItem from "../invites/InviteListItem";
import {useDispatch} from "react-redux";
import {searchNonProfits} from "../../utils/typesense";
import {getDefaultSuggestions} from "../../api/users";
import SvgClose from "../icons/SvgClose";
import SvgSearch from "../icons/SvgSearch";
import GroupNonProfitListItem from "../groups/GroupNonProfitListItem";
import ZeroStateArea from "../atoms/ZeroStateArea";
import NonprofitSearchResultItem from "../invites/NonprofitSearchResultItem";

function SearchNonprofitsModal({isOpen, close, title, onSelect}) {
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const [nonProfitsList, setNonprofitsList] = useState([])


    const searchChanged = (e) => {
        setSearch(e.target.value)
        searchNonProfits(e.target.value).then(nonProfitResults => {
            setNonprofitsList(nonProfitResults)
            setLoading(false)
        });
    }

    const closeModal = () => {
        close()
    };

    useEffect(() => {
        getDefault();
    }, [])

    const getDefault = async () => {
        const res = await getDefaultSuggestions();
        setNonprofitsList(res.non_profits);
    }

    const selectResult = (item) => {
        onSelect(item)
        closeModal()
    }

    return (
        <ModalComponent toggle={closeModal}
                        isOpen={isOpen}
                        size={'lg'}
                        header={title || `Search nonprofits`}
                        headerToggle
        >
            <div className='flex flex-col gap-5 mt-20 pb-1'>
                <div className='row-ac relative mb-3'>
                    <input
                        onChange={searchChanged}
                        value={search}
                        type="text"
                        className={'w-100p search-input rounded-lg'}
                        placeholder="Search nonprofits..."
                    />
                    <SvgSearch className={'absolute black-50'}
                               style={{top: 10, left: 24, width: 24, height: 24}}/>
                </div>
            </div>
            <div className='max-h-[50vh] overflow-y-scroll'>
                {nonProfitsList.map(nonProfit =>
                    <NonprofitSearchResultItem onSelect={selectResult} nonProfit={nonProfit} key={nonProfit.id}/>
                )}
                <div className='hidden only:block mt-32'>
                    <ZeroStateArea
                        title={'Nothing found...'}
                        sub={`Search a different nonprofit`}
                    />
                </div>
            </div>
        </ModalComponent>
    );
}

export default (SearchNonprofitsModal);
