import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import SvgPin from "../components/icons/SvgPin";
import {getFollowersFollowees, getInterestsPreview} from '../api/users';
import ProfilePicture from "../components/atoms/ProfilePicture";
import {connect} from "react-redux";
import LoadingSection from "../components/LoadingSection";
import MiddleViewHeader from "../components/layout/MiddleViewHeader";
import {CARD_STYLE} from "../utils/dummy";
import {Button} from "../components/atoms/Button";
import SectionTabs from "../components/SectionTabs";
import NPOPicture from "../components/atoms/NPOPicture";


function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
        mobile: state.userReducer.mobile,
        interests: state.userReducer.interests,
        followees: state.userReducer.followees
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addInterest: (interest) => {
            dispatch({
                type: "ADD_INTEREST",
                interest,
            })
        },
        removeInterest: (interest) => {
            dispatch({
                type: "REMOVE_INTEREST",
                interest,
            })
        },
    }
}

class UserListPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            search: '',
            nonProfits: [],
            users: [],
            title: "",
            activeTab: 'Nonprofits',
            tabs: ["Nonprofits", "Users"]
        }
    }

    componentDidMount = async () => {
        this.getList();
    }

    getList = async () => {
        const parts = window.location.pathname.split("/");
        const word = parts[1];
        const title = word.charAt(0).toUpperCase() + word.slice(1);

        const lastArg = parts[2];
        const userId = lastArg.split("?")[0];

        if (title === "Followers") {
            this.setState({tabs: ["Users"], activeTab: "Users"})
        } else if (title === "Following") {
            const causesData =  await getInterestsPreview(this.props.authUser, userId);
            this.setState({nonProfits: causesData.non_profits})
        }
        const res = await getFollowersFollowees(this.props.authUser, title, userId);
        this.setState({users: res.users, loading: false, title});
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.user !== prevProps.user) {
            this.getList();
        }
    }

    navigateToUser = (username) => {
        this.props.history.push({pathname: `/profile/${username}`, state: {previous: "Back"}})
    }

    navigateToNPO = (ein) => {
        this.props.history.push({pathname: `/non-profit/${ein}`, state: {previous: "Back"}})
    }

    changeTab = (tab) => {
        this.setState({activeTab: tab});
    };

    render() {
        const interests = this.props.interests;
        return (
            <div className='flex flex-col flex-1 overflow-hidden'>
                <MiddleViewHeader
                    title={this.state.title}
                    className={'pb-0'}
                >
                    <div className='row-ac relative'>
                        <SectionTabs
                            activeTab={this.state.activeTab}
                            changeTab={this.changeTab}
                            tabs={this.state.tabs}
                        />
                    </div>
                </MiddleViewHeader>
                {this.state.loading ?
                    <LoadingSection/>
                    :
                    <>
                    {this.state.activeTab === 'Nonprofits' &&
                        <>
                            {(this.state.search.length > 0 && this.state.nonProfits.length === 0) ?
                                <div
                                    className='flex flex-col items-center justify-center flex-1 text-slate-400 pv-40'>
                                    <p>
                                        No results for this user.
                                    </p>
                                </div>
                                :
                                <div className='p-5 flex flex-col flex-1 overflow-y-scroll'>
                                    {this.state.nonProfits.map((np, index) =>
                                        <div
                                            className={`${CARD_STYLE} flex flex-row items-center`} key={np.ein}
                                            onClick={() => this.navigateToNPO(np.ein)}>
                                            <NPOPicture
                                                size={64}
                                                np={np}
                                                logoUrl={np.logo_url}
                                                className={'border border-slate-200'}
                                            />
                                            <div className='flex flex-col flex-1 ml-12' style={{textAlign: "left"}}>
                                                <p className='text-lg text-slate-800 font-semibold'>
                                                    {np.name}
                                                </p>
                                                <p className='text-md text-slate-500'>
                                                    {np.category}
                                                </p>
                                                {this.state.activeTab === "Users" ?
                                                    <div className='row-ac'>
                                                        <SvgPin className={'text-blue-500 w-3 h-3'}/>
                                                        <p className='text-md text-blue-500 ml-4'>
                                                            {np.city}, {np.state}
                                                        </p>
                                                    </div>
                                                    : null}
                                            </div>
                                            <div className='flex flex-row items-center gap-3'>
                                            {interests.some(interest => interest.id === np.ein) ?
                                                <Button
                                                    onClick={() => {this.props.removeInterest({id: np.ein, name: np.name})}}
                                                    variant={'default'}
                                                    size={'sm'}
                                                    radius={'full'}
                                                >
                                                    Unfollow
                                                </Button>
                                                :
                                                <Button
                                                    onClick={() => {this.props.addInterest({id: np.ein, name: np.name})}}
                                                    variant={'default'}
                                                    size={'sm'}
                                                    radius={'full'}
                                                >
                                                    Follow
                                                </Button>
                                            }
                                            </div>
                                        </div>
                                    )
                                    }
                                </div>
                            }
                        </>
                    }

                    {this.state.activeTab === 'Users' &&
                        <>
                            {(this.state.search.length > 0 && this.state.nonProfits.length === 0) ?
                                <div
                                    className='flex flex-col items-center justify-center flex-1 text-slate-400 pv-40'>
                                    <p>
                                        No results for this search. Try something else.
                                    </p>
                                </div>
                                :
                                <div className='p-5 flex flex-col flex-1 overflow-y-scroll'>
                                    {this.state.users.map((user) =>
                                        <div
                                            className={`${CARD_STYLE} flex flex-row items-center`} key={user.username}
                                            onClick={() => this.navigateToUser(user.username)}>
                                            <ProfilePicture
                                                initial={user.first_name[0] + user.last_name[0]}
                                                userID={user.username}
                                                size={60}
                                            />
                                            <div className='flex flex-col flex-1 ml-12' style={{textAlign: "left"}}>
                                                <p className='text-lg text-slate-800 font-semibold'>
                                                    {user.first_name} {user.last_name}
                                                </p>
                                                <div className='row-ac'>
                                                    <SvgPin className={'text-blue-500 w-3 h-3'}/>
                                                    <p className='text-md text-blue-500 ml-4'>
                                                        {user.city}, {user.state}
                                                    </p>
                                                </div>
                                            </div>
                                            {this.props.followees.includes(user.username) ?
                                                <Button
                                                    onClick={() => {this.props.removeFollowee(user.username)}}
                                                    variant={'default'}
                                                    radius={'full'}
                                                    size={'sm'}
                                                >
                                                    Unfollow
                                                </Button>
                                                :
                                                <Button
                                                    onClick={() => {this.props.addFollowee(user.username)}}
                                                    variant={'default'}
                                                    radius={'full'}
                                                    size={'sm'}
                                                >
                                                    Follow
                                                </Button>
                                            }
                                        </div>
                                    )
                                    }
                                </div>
                            }
                        </>
                    }
                </>
                }
            </div>
        )
    }

}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserListPage));
