import React, {PureComponent} from "react";
import ModalComponent from "../ModalComponent";
import {Button} from "../atoms/Button";
import GroupInviteWidget from "../groups/GroupInviteWidget";


class InviteToGroupModal extends PureComponent {
    render() {
        return (
            <ModalComponent toggle={this.props.close}
                            isOpen={this.props.isOpen}
                            noPadding
                            size={'lg'}
                            header={'Invite friends to the group'}
                            headerToggle
                            FooterComponent={
                                <div className='flex flex-row items-center gap-3'>
                                    <Button variant={'secondary'} onClick={this.props.close}>
                                        Close
                                    </Button>
                                </div>
                            }
            >
               <div className='flex flex-col p-5 gap-3'>
                   <GroupInviteWidget group={this.props.group}/>
               </div>
            </ModalComponent>
        );
    }
}

export default InviteToGroupModal;
