import React, {PureComponent} from "react";
import SvgPin from "./icons/SvgPin";
import SvgCalendar from "./icons/SvgCalendar";
import SvgGlobe from "./icons/SvgGlobe";
import {NPO_DUMMY_SLIDES} from "../utils/dummy";


class NPOCard
    extends PureComponent {
    render() {
        return (
            <div className='bg-white-pure br-12 box profile-card' style={{maxWidth: 450}}>
                <img src={this.props.npo.cover}
                     style={{
                         width: '100%',
                         height: this.props.mobile ? 100 : 140,
                         objectFit: "cover",
                         borderRadius: '12px 12px 0 0',
                         marginBottom:this.props.mobile ? -80 : -100
                     }}/>
                <div className='p-4'>
                    <img src={this.props.npo.img}
                         style={{
                             width: this.props.mobile ? 80 : 100,
                             height: this.props.mobile ? 80 : 100,
                             objectFit: "cover",
                             borderRadius: 12,
                             border: '4px solid #ffffff'
                         }}/>
                    <div className='title1 mb-4'>
                        {this.props.npo.name}
                    </div>
                    <div className='body2-bold black-50'>
                        Philanthropy
                    </div>
                    <div className={this.props.mobile ? 'body2 mt-8' : 'body2 mt-12'}>
                          {this.props.npo.bio}
                    </div>
                    <div className={`row-ac primary body2-bold ${this.props.mobile ? 'mt-8' : 'mt-16'}`}>
                        <div className='row-ac mr-20'>
                            <SvgGlobe width={18} height={18} className={'mr-4'}/>
                            {this.props.npo.location}
                        </div>
                        {/*<div className='row-ac'>*/}
                        {/*    <SvgCalendar width={20} height={20} className={'mr-4'}/>*/}
                        {/*    Joined Jan 23*/}
                        {/*</div>*/}
                    </div>
                    <div className={this.props.mobile ? 'row-ac mt-8' : 'row-ac mt-20'}>
                        <div className='row-ac pv-4 ph-12 br-12' style={{border: '2px solid #00000020'}}>
                            <div className={this.props.mobile ? 'body3-bold primary mr-8' : 'body2-bold primary mr-8'}>
                                {this.props.npo.interested} interested
                            </div>
                            <img src={require('../img/profilecover.jpg')}
                                 style={{
                                     width: this.props.mobile ? 20 : 28,
                                     height: this.props.mobile ? 20 : 28,
                                     objectFit: "cover",
                                     borderRadius: 28,
                                     border: '2px solid #ffffff'
                                 }}/>
                            <img src={require('../img/pp.png')}
                                 style={{
                                     width: this.props.mobile ? 20 : 28,
                                     height: this.props.mobile ? 20 : 28,
                                     objectFit: "cover",
                                     borderRadius: 28,
                                     border: '2px solid #ffffff',
                                     marginLeft: -12
                                 }}/>
                            <img src={require('../img/profilepic.png')}
                                 style={{
                                     width: this.props.mobile ? 20 : 28,
                                     height: this.props.mobile ? 20 : 28,
                                     objectFit: "cover",
                                     borderRadius: 28,
                                     border: '2px solid #ffffff',
                                     marginLeft: -12
                                 }}/>
                        </div>
                        <div className={`bg-primary br-32 pv-12 ph-16 ${this.props.mobile ? 'body3-bold' : 'body2-bold'} white-100 ml-12`}>
                            Follow
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NPOCard

