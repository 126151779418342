import React, {Component} from "react";
import {withRouter} from "react-router-dom";


class Hashtag extends Component {

    onClick = (e) => {
        this.props.history.push({pathname: `/hashtag/${this.props.hashtag.slice(1,)}`, state: {previous: "Back"}})
        e.stopPropagation()
    };

    render() {

        return (
                <div onClick={this.onClick}
                     className='inline-flex bg-transparent hover:bg-blue-50 rounded-md text-blue-500'
                >
                    <p className='font-semibold'>
                        {this.props.hashtag}
                    </p>
                </div>
        );
    }
}


export default withRouter(Hashtag);
