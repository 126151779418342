import React, {Component} from "react";
import AuthModal from "../components/AuthModal";
import {withRouter} from "react-router-dom";
import {formatStatistics} from "../utils/helpers";
import {getCampaign} from "../api/campaigns";
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {connect} from "react-redux";
import LoadingSection from "../components/LoadingSection";
import MiddleViewHeader from "../components/layout/MiddleViewHeader";


function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        mobile: state.userReducer.mobile,
    }
}

class CampaignPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mobileMenu: false,
            mobile: false,
            challenges: [],
            authModalOpen: false,
            loading: false,
            campaign: {},
            showFullDescription: false,
        }
    }

    componentDidMount = async () => {
        this.setState({loading: true})
        const res = await getCampaign("lXnfb8VkgzKcGWPba8gT");
        const campaign = res.campaign;
        this.setState({campaign, challenges: campaign.challenges, loading: false});
    }

    closeAuthModal = () => {
        this.setState({authModalOpen: false})
    }

    navigateToChallenge = (id) => {
        this.props.history.push({pathname: `/challenge/${id}`, state: {previous: "Campaign"}})
    }

    toggleDescription = () => {
        this.setState((prevState) => ({
            showFullDescription: !prevState.showFullDescription,
        }));
    };

    render() {
        const campaign = this.state.campaign || {};
        const challenges = campaign.challenges || [];
        if (this.state.loading) {
            return (
                <LoadingSection/>
            )
        }
        const truncatedDescription = campaign.description
            ? campaign.description.substring(0, 150) + "..."
            : "";

        return (
            <div className='flex flex-col flex-1 overflow-hidden items-center justify-center'>
                <div className='flex flex-col flex-1 overflow-y-scroll'
                     style={{minHeight: '100vh', paddingTop: !this.props.user ? 160 : 10}}>
                    <div className='absolute cover-parent hero-wrapper' style={{zIndex: -1, opacity: .3}}/>
                    <div
                        className=' col-plain br-12 p-4 mb-12 ml-12'
                        style={{
                            boxShadow: '0 4px 12px #00000030',
                            width: this.props.mobile ? '90vw' : '48vw',
                            border: '2px solid #054A9120',
                            backgroundColor: 'white',
                            boxSizing: 'border-box'
                        }}>
                        <img src={campaign.cover_image} style={{backgroundColor: "white"}}/>
                        <div className='row-jb mt-3'>
                            <div className='col-plain flex-1 mr-2'>
                                <p className='text-2xl font-bold'>
                                    {campaign.campaign_name}
                                </p>
                                <p className='body2 black-80 mt-8'>
                                    {this.state.showFullDescription
                                        ? campaign.description
                                        : truncatedDescription}
                                </p>
                                {campaign.description && campaign.description.length > 150 && (
                                    <button
                                        onClick={this.toggleDescription}
                                        className="mt-4 text-blue-500 hover:text-blue-800 underline mr-a"
                                    >
                                        {this.state.showFullDescription ? "Read less" : "Read more"}
                                    </button>
                                )}
                            </div>
                            {!this.props.mobile ?
                                <div style={{width: 100}} className='relative'>
                                    <CircularProgressbar
                                        value={100 * (campaign.donations_progress / campaign.donations_goal)}
                                        text={"$" + formatStatistics(campaign.donations_progress)}
                                        styles={buildStyles({
                                            strokeLinecap: 'butt',
                                            pathTransitionDuration: 0.5,
                                            pathColor: `#054A91`,
                                            trailColor: '#d6d6d6',
                                            textColor: `#054A91`,
                                        })}
                                    />
                                    <p className='text-xs mt-3 font-bold w-full mx-auto text-center'>
                                        Total Amount<br/>Raised
                                    </p>
                                </div>
                                : null}
                        </div>
                        <div className='row-ac-jc wrap pt-24' style={{gap: 20}}>
                            {/* <div className='mb-12 p-16 br-12'
                                style={{backgroundColor: '#0088ff30', flex: 1, color: '#0088ff'}}>
                                <h2 className=' title2 m-0'>We reached our goal!</h2>
                                <div className='col-plain body2 mt-8'>
                                    <p>
                                        Thanks for your support! With the help of the Starfish community, $1,007,584 was
                                        donated to participating
                                        causes. Your impact is greatly appreciated. Stay tuned for more ways to get involved
                                        and grow your impact.
                                    </p>
                                </div>
                            </div> */}
                            {this.props.mobile ?
                                <div style={{width: 140}} className='col-ac-jc'>
                                    <CircularProgressbar
                                        value={100 * (campaign.donations_progress / campaign.donations_goal)}
                                        text={"$" + formatStatistics(campaign.donations_progress)}
                                        styles={buildStyles({
                                            strokeLinecap: 'butt',
                                            pathTransitionDuration: 0.5,
                                            pathColor: `#054A91`,
                                            trailColor: '#d6d6d6',
                                            textColor: `#054A91`,
                                        })}
                                    />
                                    <p className='mt-12 body3-bold text-center'>
                                        Total Amount<br/>Raised
                                    </p>
                                </div>
                                : null
                            }
                        </div>
                    </div>
                    <div className='row-ac-jc mb-24 items-center justify-center'>
                        <p className='title1 mt-16'>
                            Participating Causes:
                        </p>

                    </div>
                    <div className='ml-12' style={{
                        width: this.props.mobile ? '90vw' : '48vw',
                        display: 'grid',
                        gridTemplateColumns: this.props.mobile ? '1fr' : '1fr 1fr',
                        columnGap: '8px',
                        boxSizing: 'border-box'
                    }}>
                        {challenges.map(item => {
                                const progressPercentage = 100 * (item.donations_progress / item.donations_goal);
                                return (
                                    <button className='feed-card col-plain br-12 p-5 mb-3'
                                            key={item.challenge_id}
                                            onClick={() => this.navigateToChallenge(item.challenge_id)}
                                            style={{
                                                gap: 8,
                                                boxShadow: '0 4px 12px #00000030',
                                                border: '2px solid #054A91FF',
                                                boxSizing: 'border-box'
                                            }}>
                                        <div className={'flex flex-row items-center gap-2'}>
                                            <img src={item.non_profit.logo_url}
                                                 style={{
                                                     width: 64,
                                                     height: 64,
                                                     border: '1px solid #00000020',
                                                     objectFit: "contain"
                                                 }}
                                                 onError={(e) => {
                                                     e.target.src =
                                                         "https://handshake-production-cdn.joinhandshake.com/static_assets/default_institution_1.png";
                                                 }}
                                                 className='br-12 bg-white'/>
                                            <div style={{
                                                display: 'flex',
                                                flex: 1,
                                                alignItems: "flex-start",
                                                textAlign: 'left'
                                            }}
                                                 className='col-plain'>
                                                <p className='text-sm font-medium line-clamp-2'>
                                                    {item.non_profit.name}
                                                </p>
                                                <p className='text-xs black-50'>
                                                    {item.non_profit.category}
                                                </p>
                                            </div>
                                        </div>
                                        {/*<div style={{width: 80}} className='col-ac'>*/}
                                        {/*    <CircularProgressbar*/}
                                        {/*        value={100 * (item.donations_progress / item.donations_goal)}*/}
                                        {/*        // value={64}*/}
                                        {/*        strokeWidth={12}*/}
                                        {/*        text={"$" + formatStatistics(item.donations_progress)}*/}
                                        {/*        styles={buildStyles({*/}
                                        {/*            strokeLinecap: 'butt',*/}
                                        {/*            textSize: '16px',*/}
                                        {/*            pathTransitionDuration: 0.5,*/}
                                        {/*            pathColor: `#0088ff`,*/}
                                        {/*            textColor: `#0088ff`,*/}
                                        {/*            trailColor: '#d6d6d6',*/}
                                        {/*        })}*/}
                                        {/*    />*/}
                                        {/*</div>*/}
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '8px',
                                                backgroundColor: '#d6d6d640',
                                                borderRadius: '4px',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: `${progressPercentage + 1}%`,
                                                    height: '100%',
                                                    backgroundColor: '#054A91',
                                                    transition: 'width 0.5s ease',
                                                }}
                                            />
                                        </div>
                                    </button>

                                )
                            }
                        )}
                    </div>
                    <AuthModal
                        isOpen={this.state.authModalOpen}
                        closeModal={this.closeAuthModal}
                        mobile={this.props.mobile}
                        createCheckout={() => null}
                    />
                </div>
            </div>
        )
    }

}


export default withRouter(connect(mapStateToProps)(CampaignPage));
