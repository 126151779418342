import React from 'react';
import {Button} from "../../components/atoms/Button";
import OnboardingStepHeader from "./OnboardingStepHeader";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import OnboardingButtons from "./OnboardingButtons";
import Calendar from "react-calendar";

function OnboardingDob({goNextStep, goBackStep, dob, setDob}) {
    const handleSubmit = () => {
        goNextStep();
    };

    const today = new Date()

    const calculateAge = (dob) => {
        const today = new Date();
        const birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };

    const isUnderage = dob ? calculateAge(dob) < 13 : true;

    return (
        <div className='flex flex-col gap-8 md:w-[460px]'>
            <OnboardingStepHeader
                title={'What’s your date of birth?'}
                description={'We don’t display this'}
            />
            <div className='flex flex-col  items-center'>
                <Calendar
                    onChange={(date) => setDob(date)}
                    value={dob}
                    maxDate={new Date()}
                    defaultView={'decade'}
                    className="border-[1px] rounded-lg py-1 px-2 outline-0 border-slate-200 focus:border-blue-500 text-slate-900"
                />
            </div>
            <OnboardingButtons
                goNextStep={handleSubmit}
                goBackStep={goBackStep}
                nextDisabled={isUnderage}
            />
        </div>
    );
}

export default OnboardingDob;
