import React, { Component } from 'react';
import Spinner from '../Spinner';
import ModalComponent from '../ModalComponent';
import { Button } from '../atoms/Button';
import { deletePost } from '../../api/posts';
import { connect } from 'react-redux';
import {toast} from 'react-toastify';

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class DeletePostModal extends Component {

    handleDelete = async () => {
        await deletePost(this.props.authUser, this.props.postId);
        this.props.closeModal();
        this.props.history.goBack();
        toast.success("Post successfully deleted");
    }

    render() {
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                size={'lg'}
                toggle={this.props.closeModal}
                header={"Delete Post"}
                headerToggle
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button
                            onClick={this.props.closeModal}
                            variant='secondary'
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={this.handleDelete}
                            variant='destructive'
                        >
                            Delete
                        </Button>
                    </div>
                }
            >
                <p className='w-full text-center'>
                    Are you sure you want to delete this post? This action cannot be undone!
                </p>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps)(DeletePostModal);
