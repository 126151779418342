import moment from "moment";

export function wait(timeout) {
    return new Promise((resolve) => {
        setTimeout(resolve, timeout);
    });
}

export const formatMessageTime = (timestamp) => {
    const messageDate = moment.utc(timestamp * 1000);
    const currentDate = moment.utc();
    const diffDays = currentDate.diff(messageDate, 'days');
    const isSameDay = currentDate.isSame(messageDate, 'day');

    if (diffDays < 1 && isSameDay) {
        // Same day
        return messageDate.local().format('LT'); // Converts to local time, e.g., 1:23 PM
    } else if (!isSameDay && diffDays === 0) {
        // Yesterday, considering it's within 24 hours but not the same day.
        return 'Yesterday';
    } else if (diffDays < 4) {
        // Within the last three days
        return messageDate.format('dddd'); // Day of the week, e.g., Wednesday
    } else {
        // Older than three days
        return messageDate.format('M/D/YY'); // Date format, e.g., 4/5/24
    }
};
