import React, {PureComponent} from "react";
import BGGraphic7 from "../../img/graphics/BGGraphic7";
import {motion} from "framer-motion";
import BGGraphic9 from "../../img/graphics/BGGraphic9";
import SvgCommunication from "../../components/icons/SvgCommunication";
import SvgCrowd from "../../components/icons/SvgCrowd";


class NpoSection2 extends PureComponent {
    render() {
        return (
            <section className={this.props.mobile ? 'col-ac relative' : 'row-ac-jb relative'}
                     style={{
                         height: !this.props.mobile && '90vh',
                         backgroundColor: "rgb(245,248,255)",
                         padding: this.props.mobile ? '80px 24px' : '60px 10vw'
                     }}>
                {/*<BGGraphic9 className='absolute' style={{*/}
                {/*    bottom: this.props.mobile ? -50 : -100,*/}
                {/*    right: this.props.mobile ? -12 : 60,*/}
                {/*    width: this.props.mobile ? '25vw' : '14vw',*/}
                {/*    transform: 'rotate(80deg)',*/}
                {/*    opacity: 1*/}
                {/*}}/>*/}
                <motion.div style={{flex: 1, maxWidth: !this.props.mobile && '30vw'}}
                            initial={{opacity: 0, transform: 'scale(1) translateY(12px)'}}
                            whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            transition={{default: {ease: "linear"}, delay: .2}}
                            className={this.props.mobile ? 'mb-40 col-plain' : 'col-plain'}>
                    {/*<div className='ph-24 pv-12 br-64 body1-bold mb-12 white-pure'*/}
                    {/*     style={{backgroundColor: "#6A93C6A0", marginRight: "auto"}}>*/}
                    {/*    <SvgCrowd width={36} height={36} fill={'#FFFFFF'}/>*/}
                    {/*</div>*/}
                    <div className='body3-bold black-50 mb-8' style={{textTransform: 'uppercase'}}>
                        CHALLENGES
                    </div>
                    <h1 className='heading2 black-100 mb-24 mt-0'>
                        Engage and invigorate<br/> your supporters
                    </h1>
                    <p className='mt-0 body1 black-70'>
                        Raise awareness and funds for your nonprofit in a impactful way using Starfish Challenges.
                        Challenges build excitement, foster connections and are a high-impact method to increase your
                        user base and – ultimately – your funding. This feature enables all your followers on Starfish
                        to become ambassadors for your organization as they share your story with their “circle of
                        influence.
                    </p>
                </motion.div>
                <div style={{flex: 1, maxWidth: this.props.mobile ? '100%' : '40vw'}} className='col-plain relative'>
                    <motion.div
                        initial={{opacity: 0, transform: 'scale(1) translateY(12px)'}}
                        whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                        transition={{default: {ease: "linear"}}}
                    >
                        <img src={require('../../img/npo/npo3.png')} className='floating'
                             style={{
                                 width: this.props.mobile ? '100%' : '30vw',
                                 objectFit: 'contain',
                                 marginLeft: !this.props.mobile && 80
                             }}/>
                    </motion.div>
                </div>
            </section>
        )
    }
}

export default NpoSection2
