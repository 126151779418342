import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import GroupPicture from "../components/atoms/GroupPicture";
import GroupNonProfitListItem from "../components/groups/GroupNonProfitListItem";
import ChallengeCard from "../components/ChallengeCard";
import {requestGroup} from "../api/groups";
import {getBatchNonProfits} from "../api/nonProfits";
import { getGroup } from '../api/activity';
import SvgCheck from '../components/icons/SvgCheck';
import {getChallenge} from "../api/challenges";
import GroupPrivacyIndicator from "../components/atoms/GroupPrivacyIndicator";
import {Button} from "../components/atoms/Button";
import { wait } from '@testing-library/user-event/dist/utils';
import {addRemoveGroupMember} from "../api/messaging";
import ConfirmationModal from "../components/modals/ConfirmationModal";
import lottie from "../img/lottie/Check.json";
import SvgUserCards from "../components/icons/categories/SvgUserCards";
import ProfilePicture from "../components/atoms/ProfilePicture";
import MiddleViewHeader from "../components/layout/MiddleViewHeader";
import SvgWave from "../components/icons/SvgWave";
import SvgShare2 from "../components/icons/SvgShare2";
import { shareGroup } from '../utils/share';

const DUMMY_GROUP = {
    "admin": {
        "johncervenka": true
    },
    "creator": "johncervenka",
    "description": "Highlighting the best dogs in the world!",
    "image_id": "a41672bb-2166-40db-8fb1-669d9bb38d77",
    "is_group": true,
    "members": [
        "cory",
        "jack",
        "jackstra",
        "jason10",
        "johncervenka",
        "johnstra",
        "mileskeeter1211",
        "nanewalt",
        "niraaj",
        "spencershah",
        "stevemott"
    ],
    "messages": {
        "-NwMNaBz0iACnHLYdQev": true,
        "-NwUqhHO__lFLuZKtzfI": true,
        "-NwUrf3etnLLtnIDwqLw": true
    },
    "name": "Best Dogs In The World",
    "non_profits": {
        "95-1643344": true
    },
    "recentMessage": {
        "preview": "The Chocolate Lab is another one of my all time favorites… loyal, playful and loves to bark incessantly whenever it gets to go on a car ride….:)",
        "timestamp": 1714226639,
        "username": "johncervenka"
    },
    "timestamp": 1713930638,
    "memberNames": {
        "cory": "Cory O’Shea",
        "jack": "Jack Phifer",
        "jackstra": "Jack Strachan",
        "jason10": "Jason Polan",
        "johncervenka": "John Cervenka",
        "johnstra": "Jack Strachan",
        "mileskeeter1211": "Drew Schneller",
        "nanewalt": "Nick Anewalt",
        "niraaj": "Niraaj B",
        "spencershah": "Spencer Shahidzadeh",
        "stevemott": "Steve Mott"
    },
    "id": "-NwDDZQILLHKyDEKDakG"
}

const GroupPreviewPage = ({groupId, isSheet, match, history}) => {
    const user = useSelector(state => state.userReducer.user);
    const [nonProfitsList, setNonProfitsList] = useState([]);
    const [group, setGroup] = useState(DUMMY_GROUP);
    const [copiedLink, setCopiedLink] = useState(false);
    const [challenge, setChallenge] = useState({});
    const isAdmin = "admin" in group && Object.keys(group.admin).includes(user?.username);
    const [showModal, setShowModal] = useState(false);

    const getGroupInfo = async () => {
        const routeGroupId = match?.params?.groupId;
        if (!!routeGroupId) {
            groupId = routeGroupId
        }

        const res = await getGroup(groupId);
        setGroup(res.group);
        getNps(res.group);
        getChallengeInfo(res.group);
    }


    useEffect(() => {
        getGroupInfo();
    }, [groupId])

    const getNps = async (group) => {
        if ("non_profits" in group && Object.keys(group.non_profits).length) {
            const res = await getBatchNonProfits(Object.keys(group?.non_profits))
            setNonProfitsList(res.non_profits);
        } else {
            setNonProfitsList([])
        }

    }

    const getChallengeInfo = async (group) => {
        if (!!group.challenge_id) {
            const res = await getChallenge(group.challenge_id);
            setChallenge(res.challenge);
        } else {
            setChallenge({});
        }
    }

    const toggleInvited = () => {
        joinGroup();
    }

    const joinGroup = async () => {
        if (!user) {
            history.push({pathname: `/authentication`})
        } else {
            await addRemoveGroupMember(user, group.id, user.username, `${user.first_name} ${user.last_name}`);
        }
    }

    const requestToJoin = async () => {
        setShowModal(true);
        await requestGroup(user, group.id, user.username, `${user.first_name} ${user.last_name}`);
    }

    const handleCloseModal = () => {
        setShowModal(false);
        window.location.reload();
    };

    const copyLinkToClipboard = async (e) => {
        e.stopPropagation()
        setCopiedLink(true)

        const link = await shareGroup(group.id, group.name, group.image_id);
        navigator.clipboard.writeText(link)
        wait(3000).then(() => {
            setCopiedLink(false)
        })
    }


    const creatorName = group?.memberNames[group?.creator];
    const joined = !!user?.username ? group.members.includes(user.username) : false;
    const requested = !!user?.username ? user.username in (group.requests || {}): false;
    return (
        <div className='bg-white flex flex-col flex-1 overflow-hidden'>
            {!isSheet &&
                <MiddleViewHeader
                    title={'Group Preview'}
                    previous
                    // back
                />
            }
            <div className='flex flex-col gap-5 pt-20 px-5 pb-20 flex-1 overflow-y-scroll'>
                {!isSheet &&
                    <div className='bg-blue-100 rounded-xl py-2 px-5 flex flex-row items-center gap-3 mt-36'>
                        <SvgWave className={'text-blue-500 w-10 h-10'}/>
                        <p className='flex-1 text-blue-500 font-semibold text-lg' style={{lineHeight: 1.2}}>
                            You are previewing a {group?.private ? "Private" : "Public"} Group on Starfish, the first social media built for good! Find
                            causes, participate in challenges, and meet other like minded doers of good!
                        </p>
                    </div>
                }
                <>
                    <div className='flex flex-col items-center gap-2 mt-5'>
                        <GroupPicture imageId={group?.image_id} size={'3xl'}/>
                        <p className='mt-2 -mb-1 text-center text-4xl font-semibold'>
                            {group?.name}
                        </p>
                        <p className='text-slate-700 text-center text-lg mb-3'>
                            {group?.description}
                        </p>
                        <div className='flex flex-row items-center gap-5'>
                            <div className='flex flex-row items-center gap-0'>
                                <ProfilePicture userID={group?.creator} size={20}/>
                                <p className='text-slate-700 text-base font-semibold text-center'>
                                    Created by {creatorName || 'GROUP CREATOR'}
                                </p>
                            </div>
                            <div className='flex flex-row items-center gap-2'>
                                <SvgUserCards className={'w-5 h-5'}/>
                                <p className='text-slate-700 text-base font-semibold text-center'>
                                    {group?.members?.length} members
                                </p>
                            </div>
                            <GroupPrivacyIndicator group={group} append={'Group'} size={'lg'}/>
                        </div>
                        <div className='flex flex-row items-center gap-3 mt-12'>
                            {!group.private ?
                                <Button size={'lg'} variant={joined ? 'secondary' : 'default'} disabled={joined}
                                        onClick={toggleInvited}>
                                    {joined ? 'Joined' : 'Join this group'}
                                </Button>
                                :
                                <Button size={'lg'} variant={joined || requested ? 'secondary' : 'default'}
                                        disabled={joined || requested}
                                        onClick={requestToJoin}>
                                    {joined ? 'Joined' : (requested ? 'Requested' : 'Request to join')}
                                </Button>
                            }
                            <Button variant={'secondary'} onClick={copyLinkToClipboard}>
                                {copiedLink ? <SvgCheck className={'w-5 h-5 scale-[1.2]'}/> :
                                    <SvgShare2 className={'w-6 h-6 mr-1'}/>}
                                {copiedLink ? "Link copied!" : "Share this group"}
                            </Button>
                        </div>
                    </div>
                    <div className='border border-slate-200 bg-white  p-3 rounded-xl w-full max-w-[600px] mx-auto mt-3'>
                        <div className='flex flex-row items-center justify-between'>
                            <p className='text-lg font-semibold mb-2'>
                                Group Challenge
                            </p>
                        </div>
                        <div className='mt-2'>
                            {Object.keys(challenge).length > 0 &&
                                <ChallengeCard
                                    key={challenge.challenge_id}
                                    challenge={challenge}
                                    border
                                    inPost
                                />
                            }
                            <div className='hidden only:flex'>
                                <p className='text-sm text-slate-500'>This group does not currently have a linked
                                    challenge</p>
                            </div>
                        </div>
                    </div>
                    <div className='border border-slate-200 bg-white  p-3 rounded-xl w-full max-w-[600px] mx-auto'>
                        <div className='flex flex-row items-center justify-between'>
                            <p className='text-lg font-semibold mb-2'>
                                Supported Nonprofits
                            </p>
                        </div>
                        <div className='mt-2'>
                            {nonProfitsList.map(nonProfit =>
                                <GroupNonProfitListItem
                                    nonProfit={nonProfit}
                                    key={nonProfit.id}
                                    group={group}
                                    removable
                                    isAdmin={isAdmin}
                                />
                            )}
                            <div className='hidden only:flex'>
                                <p className='text-sm text-slate-500'>
                                    No supported causes added yet
                                </p>
                            </div>
                        </div>
                    </div>
                </>
                <ConfirmationModal
                    isOpen={showModal}
                    lottie={lottie}
                    title={`You've requested to join ${group.name}!`}
                    description={"The owner of the group needs to accept you in order for you to join. You'll be notified if you are!"}
                    hideConfirm
                    cancelText={'Got it!'}
                    close={handleCloseModal}
                />
            </div>
        </div>
    );
};

export default GroupPreviewPage;
