import React, {Component} from "react";
import {CARD_STYLE_NO_HOVER} from "../../utils/dummy";
import ZeroStateArea from "../atoms/ZeroStateArea";
import NPOPicture from "../atoms/NPOPicture";
import {Button} from "../atoms/Button";
import SvgArrowForward from "../icons/ArrowForward";
import SvgCalendar from "../icons/SvgCalendar";
import SvgPin from "../icons/SvgPin";
import SvgSchool from "../icons/categories/SvgSchool";
import SvgStudy from "../icons/SvgStudy";
import ProfileWorkItem from "./ProfileWorkItem";
import ProfileSectionHeader from "./ProfileSectionHeader";
import EditPurposeModal from "../modals/EditPurposeModal";

class ProfileAboutPurpose extends Component {
    state = {
        editPurposeModalOpen: false,

    }

    openEditPurposeModal = () => {
        this.setState({editPurposeModalOpen: true})
    }

    closeEditPurposeModal = () => {
        this.setState({editPurposeModalOpen: false})
    }

    render() {
        return (
            <div className={`${CARD_STYLE_NO_HOVER}`}>
                <ProfileSectionHeader
                    label={this.props.sameUser ? 'My Purpose' : `${this.props.user.first_name}'s Purpose`}
                    onClickEdit={this.props.sameUser ? () => this.openEditPurposeModal() : null}
                    sameUser={this.props.sameUser}
                />
                <p className='text-slate-700 w-full mt-2 text-lg'>
                    {!!this.props.user.bio ? this.props.user.bio : "No purpose listed yet"}
                </p>
                <EditPurposeModal
                    isOpen={this.state.editPurposeModalOpen}
                    close={this.closeEditPurposeModal}
                />
            </div>
        )
    }
}

export default ProfileAboutPurpose