import icon from "../img/brand/logo.png";
import SvgApple from "./icons/SvgApple";
import SvgGooglePlay from "./icons/SvgGooglePlay";
import React from "react";
import {useSelector} from "react-redux";
import moment from "moment";


function MobilePlaceholder() {
    const user = useSelector(state => state.userReducer.user);
    const wasUserCreatedWithin24Hours = moment(user.created_at).isAfter(moment().subtract(24, 'hours'));
    const branded = true

    return (
        <div className='w-full h-[100vh] bg-white flex flex-col items-center justify-center gap-9'>
            <div className='row-ac'>
                <img src={icon} alt={'Icon'}
                     className='w-[60px]'/>
            </div>
            <div className='flex flex-col text-blue-500 gap-3 items-center text-center px-4 md:px-[20vw]'>
                {(wasUserCreatedWithin24Hours && branded) ?
                    <>
                        <img src={require('../img/brand/Starfish-we-donate (1).jpg')}
                             className='h-60 border border-slate-200 rounded-xl mx-auto aspect-square object-contain bg-red-400 mb-2'/>
                        <p className='text-2xl font-semibold'>
                            Thanks for signing up! Check out your donation in the app!
                        </p>
                        <p className='text-base text-black/50'>
                            Your account has been created! Visit us on Desktop or download the app below to view your
                            impact.
                        </p>
                    </>
                    :
                    <p className='title2'>
                        For the best experience on mobile, download the Starfish app.
                    </p>
                }
            </div>
            <div className="flex flex-row row-ac gap-3">
                <a style={{textDecoration: 'none'}}
                   href='https://apps.apple.com/us/app/starfish-social/id1642351040' target={'_blank'}
                   className='primary-button bg-white row-ac body2-bold mt-12'>
                    <SvgApple width={24} height={24}/>
                    <div className='ml-8' style={{marginTop: 2}}>
                        iOS
                    </div>
                </a>
                <a style={{textDecoration: 'none'}}
                   href='https://play.google.com/store/apps/details?id=com.starfishsocialmedia.app&hl=en_US&gl=US'
                   target={'_blank'}
                   className='primary-button bg-white row-ac body2-bold mt-12'>
                    <SvgGooglePlay className={'mr-8'} width={24} height={24}/>
                    Android
                </a>
            </div>
        </div>
    )
}

export default MobilePlaceholder
