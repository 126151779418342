import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class Spinner extends PureComponent {
    render() {
        return (
            <div className={this.props.dark ? 'spinner-light' : 'spinner'} style={{width: this.props.size, height: this.props.size}}/>
        );
    }
}

Spinner.propTypes = {
    size: PropTypes.number
};

export default Spinner;
