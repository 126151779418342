import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            width="48px"
            height="48px"
            {...props}
        >
            <path fill={'currentColor'} d="M42.373 8.86a2.48 2.48 0 00-.447.017L8.443 14.779a2.002 2.002 0 00-1.623 2.319l3.256 18.465c.872 4.945 5.59 8.872 10.38 11.68 2.714 4.564 5.854 8.143 6.524 8.886a1.998 1.998 0 001.758.639c2.072-.287 20.348-3.083 23.823-12.631l6.412-17.62a2.006 2.006 0 00-1.196-2.564L45.85 19.611l-1.608-9.113c-.168-.89-.866-1.584-1.869-1.639zm-1.723 4.302l.858 4.87-9.291-3.384 8.433-1.486zm-18.437 3.25l-5.36 14.727c-.973 2.674-.693 5.688.211 8.677-1.595-1.537-2.74-3.204-3.048-4.949l-2.909-16.496 11.106-1.959zm4.127.356l28.19 10.26-5.73 15.74c-2.107 5.793-13.968 8.927-19.554 9.875-3.672-4.317-10.743-14.343-8.635-20.135l5.729-15.74zm3.553 9.076a4.002 4.002 0 00-3.557 2.629l7.518 2.736a4 4 0 00-3.961-5.365zm13.156 4.789a4.002 4.002 0 00-3.557 2.629l7.518 2.734a4 4 0 00-3.961-5.363zM28.64 36.762a5.999 5.999 0 003.586 7.69 6.003 6.003 0 007.691-3.587l-11.277-4.103z" />
        </svg>
    )
}

export default SvgComponent
