import React, { Component } from "react";
import { connect } from "react-redux";
import { CARD_STYLE_NO_HOVER } from "../../utils/dummy";
import ZeroStateArea from "../atoms/ZeroStateArea";
import ProfileEducationItem from "./ProfileEducationItem";
import { deleteEducation } from "../../api/users";
import AddEducationModal from "../modals/AddEducationModal";
import ProfileSectionHeader from "./ProfileSectionHeader";
import { Button } from "../atoms/Button";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class ProfileAboutEducation extends Component {
    state = {
        addEducationModalOpen: false,
        isEditingExistingItems: false,
        showAllEducation: false,
        currentEducationItem: {},
    }

    openAddEducationModal = (currentEducationItem = {}) => {
        this.setState({ addEducationModalOpen: true, currentEducationItem })
    }

    closeAddEducationModal = () => {
        this.setState({ addEducationModalOpen: false })
    }

    toggleEditExistingMode = () => {
        this.setState({ isEditingExistingItems: !this.state.isEditingExistingItems })
    }

    toggleShowAllEducation = () => {
        this.setState((prevState) => ({ showAllEducation: !prevState.showAllEducation }));
    }

    deleteEducation = async (domain) => {
        await deleteEducation(this.props.authUser, domain);
        window.location.reload();
    }

    render() {
        const { education, user, sameUser } = this.props;
        const { showAllEducation } = this.state;
        const prefix = sameUser ? "My" : `${user.first_name}'s`;

        // Sort education items
        education.sort((a, b) => {
            if (!b.start_date && !a.start_date) return 0;
            if (!b.start_date) return 1;
            if (!a.start_date) return -1;
            return b.start_date.localeCompare(a.start_date);
        });

        // Determine which items to show
        const displayedEducation = showAllEducation ? education : education.slice(0, 3);

        return (
            <div className={`${CARD_STYLE_NO_HOVER}`}>
                <ProfileSectionHeader
                    label={`${prefix} Education`}
                    sameUser={sameUser}
                    editing={this.state.isEditingExistingItems}
                    setEditingMode={this.toggleEditExistingMode}
                    onClickAdd={this.openAddEducationModal}
                />

                {displayedEducation.map((item, index) => (
                    <ProfileEducationItem
                        key={index}
                        item={item}
                        index={index}
                        onClickEdit={this.openAddEducationModal}
                        onClickDelete={() => this.deleteEducation(item.domain)}
                        editing={this.state.isEditingExistingItems}
                    />
                ))}

                {education.length > 3 && (
                    <div className="w-full flex flex-col items-center mt-2">
                        <Button
                            variant="secondary"
                            size="sm"
                            onClick={this.toggleShowAllEducation}
                        >
                            {showAllEducation ? 'See Less Education' : 'Show More Education'}
                        </Button>
                    </div>
                )}

                {education.length === 0 && (
                    <ZeroStateArea
                        title={'Nothing here...'}
                        sub={`${user.first_name} does not have any education listed yet.`}
                    />
                )}

                <AddEducationModal
                    authUser={this.props.authUser}
                    isOpen={this.state.addEducationModalOpen}
                    close={this.closeAddEducationModal}
                    item={this.state.currentEducationItem}
                />
            </div>
        );
    }
}

export default connect(mapStateToProps)(ProfileAboutEducation);
