import * as React from "react"

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118.42 111.9" {...props}>
    <g data-name="Layer 2">
      <path
        d="M9.09 76.23c-1.18 8.67-1.51 18.59 4.42 24.93 7.89 8.43 21.49 5.45 32.37 1.84L91 88.09c6-2 12-4 17.17-7.58s9.44-9.05 10.18-15.39c1.1-9.51-5.71-18-12.53-24.57a196.69 196.69 0 0 0-34.11-26.41C63.35 9 49.18-1.4 38.69.16c-11.61 1.72-15.34 20.23-18.57 29.6A243.62 243.62 0 0 0 9.09 76.23Z"
        style={{
          fill: "#054A91",
        }}
      />
      <path
        d="M19.85 111.9c-5.52 0-10.76-1.42-14.63-5.55C-.93 99.77-.43 89.39.7 81.09h.41H.7a244.24 244.24 0 0 1 11.05-46.55c.48-1.4 1-3 1.51-4.73 3.06-10 7.26-23.65 17.39-25.16 9.16-1.36 20.88 6.14 29.44 11.61 1.39.88 2.68 1.71 3.86 2.43a197.39 197.39 0 0 1 34.14 26.47c6 5.81 13.83 14.72 12.65 24.92-.69 5.87-4.46 11.58-10.36 15.68C95.12 89.42 89 91.44 83.1 93.4L38 108.32c-5.35 1.78-11.93 3.58-18.15 3.58ZM32.93 5.33a15.22 15.22 0 0 0-2.15.15c-9.61 1.43-13.72 14.81-16.73 24.57-.52 1.72-1 3.35-1.51 4.76a244.28 244.28 0 0 0-11 46.39c-1.1 8.12-1.6 18.25 4.31 24.58 7.8 8.33 21.17 5.31 31.94 1.74l45.05-14.91c5.85-1.94 11.9-3.94 17.06-7.53 5.71-4 9.35-9.47 10-15.1 1.14-9.83-6.54-18.53-12.41-24.22a195.27 195.27 0 0 0-34-26.35c-1.16-.73-2.49-1.56-3.85-2.41-7.77-5-18.2-11.67-26.71-11.67Z"
        style={{
          fill: "#efa143",
        }}
      />
    </g>
  </svg>
)

export default SvgComponent
