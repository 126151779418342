import React, {useState, useEffect} from 'react';
import { uploadDoc } from '../../api/firebase';
import OnboardingStepHeader from "./OnboardingStepHeader";
import OnboardingButtons from "./OnboardingButtons";
import Dropzone from "react-dropzone";
import SvgAddCircle from "../../components/icons/SvgAddCircle";

function OnboardingPictures({goNextStep, goBackStep, bio, firstName, lastName, user, setCoverPic, setProfilePic, profilePic, coverPic}) {
    const [profileImageUrl, setProfileImageUrl] = useState('')
    const [coverUrl, setCoverUrl] = useState('')

    useEffect(() => {
        setProfileImageUrl(profilePic)
        setCoverUrl(coverPic)
    }, [profilePic, coverPic]);

    const handleSubmit = () => {
        goNextStep();
    };

    const onDropCover = async (acceptedFiles) => {
        const url = await uploadDoc("users/cover_photo/", user.username + ".jpeg", acceptedFiles[0]);
        setCoverPic(url)
        setCoverUrl(url)
    }

    const onDropProfileImage = async (acceptedFiles) => {
        const url = await uploadDoc("users/profile_picture/", user.username + ".jpeg", acceptedFiles[0]);
        setProfilePic(url);
        setProfileImageUrl(url)
    }

    return (
        <div className='flex flex-col gap-8 md:w-[460px]'>
            <OnboardingStepHeader
                title={'Bring your profile to life with some pictures'}
                description={'Images help you stand out'}
            />
            <div className='flex flex-col gap-2'>
                <Dropzone onDrop={(files) => onDropCover(files)}>
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} className='cursor-pointer'>
                            <input {...getInputProps()} />
                            <div style={{}} className=''>
                                {!!coverUrl ? (
                                    <div
                                        className='h-24 w-full bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square overflow-hidden flex flex-col items-center justify-center border border-gray-200 rounded-lg'
                                    >
                                        <img
                                            className='h-24 w-full object-cover bg-white aspect-square cursor-pointer  rounded-lg'
                                            src={coverUrl}
                                            alt="profile-pic"
                                        />
                                        {/* <div className='absolute flex flex-col items-center justify-center'>
                                                        <SvgEdit className={'text-blue-500'}/>
                                                        <p className='text-xs mt-2'>
                                                            Edit image
                                                        </p>
                                                    </div> */}
                                    </div>
                                ) : (
                                    <div
                                        className='h-24 w-full bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square flex flex-col items-center justify-center border border-gray-200 rounded-lg'
                                    >
                                        <SvgAddCircle className={'text-blue-500'}/>
                                        <p className='text-xs mt-2'>
                                            Add cover
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </Dropzone>
                <Dropzone onDrop={(files) => onDropProfileImage(files)}>
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} className='cursor-pointer mt-[-48px] ml-4'>
                            <input {...getInputProps()} />
                            <div style={{}} className=''>
                                {!!profileImageUrl ? (
                                    <div
                                        className='w-20 h-20 bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square overflow-hidden flex flex-col items-center justify-center border border-gray-200 rounded-full'
                                    >
                                        <img
                                            className='w-20 h-20 object-cover bg-white aspect-square cursor-pointer  rounded-full'
                                            src={profileImageUrl}
                                            alt="profile-pic"
                                        />
                                        {/* <div className='absolute flex flex-col items-center justify-center'>
                                                        <SvgEdit className={'text-blue-500'}/>
                                                        <p className='text-xs mt-2'>
                                                            Edit image
                                                        </p>
                                                    </div> */}
                                    </div>
                                ) : (
                                    <div
                                        className='w-20 h-20 bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square flex flex-col items-center justify-center border border-gray-200 rounded-full'
                                    >
                                        <SvgAddCircle className={'text-blue-500'}/>
                                        <p className='text-xs'>
                                            Add image
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </Dropzone>
                <div className='flex flex-col gap-1'>
                    <p className={'text-lg text-slate-800 font-semibold'}>
                        {firstName} {lastName}
                    </p>
                    <p>
                        {bio}
                    </p>
                </div>
            </div>
            <OnboardingButtons
                goNextStep={handleSubmit}
                goBackStep={goBackStep}
                nextDisabled={!bio}
            />
        </div>
    );
}

export default OnboardingPictures;
