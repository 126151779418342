import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            width="48px"
            height="48px"
            {...props}
        >
            <path fill={'currentColor'} d="M23.5 17c-2.31 0-4.197 1.756-4.45 4H6.5A4.5 4.5 0 002 25.5v17A4.5 4.5 0 006.5 47h12.55c.253 2.244 2.14 4 4.45 4h17c2.31 0 4.197-1.756 4.45-4H57.5a4.5 4.5 0 004.5-4.5v-17a4.5 4.5 0 00-4.5-4.5H44.95c-.253-2.244-2.14-4-4.45-4h-17zm0 4h17a.5.5 0 01.5.5v22.354C39.384 40.982 35.959 39 31.984 39c-3.95 0-7.356 1.959-8.984 4.8V21.5a.5.5 0 01.5-.5zm-17 4H19v15.19A9.235 9.235 0 0013 38c-2.83 0-5.347 1.242-7 3.178V25.5a.5.5 0 01.5-.5zM45 25h12.5a.5.5 0 01.5.5v15.666C56.347 39.236 53.827 38 51 38a9.244 9.244 0 00-6 2.18V25zm-13 1c-2.761 0-5 2.462-5 5.5s2.239 5.5 5 5.5 5-2.462 5-5.5-2.239-5.5-5-5.5zm-19 1.83c-2.209 0-4 1.951-4 4.36 0 2.407 1.791 4.36 4 4.36s4-1.953 4-4.36c0-2.409-1.791-4.36-4-4.36zm38 0c-2.209 0-4 1.951-4 4.36 0 2.407 1.791 4.36 4 4.36s4-1.953 4-4.36c0-2.409-1.791-4.36-4-4.36z" />
        </svg>
    )
}

export default SvgComponent
