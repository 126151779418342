import axios from './axios';

export const createActivity = async (user, params) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {params}
        const res = await axios.post("/activity", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const getActivity = async (user) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.get("/activity", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getGroup = async (group_id) => {
    try {
        const config = {
            headers: {mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {group_id}
        };
        const res = await axios.get("/activity/group", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

