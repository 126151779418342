import React, {Component} from "react";
import MissionSection from "./MissionSection";
import TimelineSection from "./TimelineSection";
import {connect} from "react-redux";
import StarfishLabPage from "./StarfishLabPage";
import LabHeaderSection from "./StarfishLab/LabHeaderSection";
import LabAdditionalDetails from "./StarfishLab/LabAdditionalDetails";

function mapStateToProps(state) {
    return {
        mobile: state.userReducer.mobile,
    }
}

class About extends Component {

    render() {
        return (
            <div className='col-plain'>
                <div className='col-ac pt-100'
                     style={{minHeight: '100vh', padding: this.props.mobile ? '80px 24px' : '0 10vw 10vh 10vw'}}>
                    <div className='col-plain mt-100'>
                        <LabHeaderSection mobile={this.props.mobile}/>
                        <LabAdditionalDetails mobile={this.props.mobile}/>
                    </div>
                </div>
            </div>
        )
    }

}


export default connect(mapStateToProps)(About);