import React, {Component} from "react";
import SvgArrowBack from "../icons/ArrowBack";
import {withRouter} from "react-router-dom";


class MiddleViewHeader extends Component {
    render() {
        const previous = this.props.previous || ''
        return (
            <div className={`px-5 min-h-[84px] py-4 justify-center border-b bg-white border-slate-200 gap-3 flex flex-col ${this.props.className}`}>
                <div className='flex flex-row items-center justify-between gap-5'>
                    <div className='flex flex-row items-center gap-5'>
                        {this.props.back &&
                            <div
                                onClick={this.props.history.goBack}
                                className='row-ac text-sm text-slate-400 hover:text-slate-800 pointer'>
                                <SvgArrowBack className={'w-4 h-4 mr-4'}/>
                                {previous ? previous : "Back"}
                            </div>
                        }
                        {this.props.title &&
                            <p className='text-2xl font-semibold text-slate-800'>
                                {this.props.title}
                            </p>
                        }
                    </div>
                    {this.props.RightComponent}
                </div>
                {this.props.children}
            </div>
        )
    }
}

export default withRouter(MiddleViewHeader)
