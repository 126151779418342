import React, {PureComponent} from 'react';
import LinkPreview from "./LinkPreview";
import TwitterXMA from "./TwitterXMA";
// import TwitterXMA from "./TwitterXMA";
// import YouTubeXMA from "./YouTubeXMA";
// import TikTokXMA from "./TikTokXMA";
// import InstagramXMA from "./InstagramXMA";

class LinkPreviewWrapper extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const linkPreview = this.props.link_preview;
        if (!linkPreview.type || linkPreview.type === "default") {
            return (
                <LinkPreview
                    url={linkPreview.url}
                    description={linkPreview.description}
                    title={linkPreview.title}
                    image={linkPreview.image_url}
                />)
        }
        // else if (linkPreview.type === "twitter") {
        //     console.log('twitter')
        //     return (<TwitterXMA tweet={linkPreview}/>)
        // }
            // else if (linkPreview.type === "youtube") {
            //     return (<YouTubeXMA youtube={linkPreview}/>)
            // } else if (linkPreview.type === "tiktok") {
            //     return (<TikTokXMA tiktok={linkPreview}/>)
            // } else if (linkPreview.type === "instagram") {
            //     return (<InstagramXMA instagram={linkPreview}/>)
        // }
        else {
            return (
                <LinkPreview
                    url={linkPreview.url}
                    description={linkPreview.description}
                    title={linkPreview.title}
                    image={linkPreview.image_url}
                />)
        }
    }
}

export default LinkPreviewWrapper;
