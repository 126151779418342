import React, {useEffect, useState} from 'react';
import TextInput from "../../components/inputs/TextInput";
import OnboardingStepHeader from "../Onboarding/OnboardingStepHeader";
import OnboardingButtons from "../Onboarding/OnboardingButtons";
import {v4 as uuid} from 'uuid';
import Dropzone from "react-dropzone";
import SvgAddCircle from "../../components/icons/SvgAddCircle";
import {uploadDoc} from "../../api/firebase";

function CreateGroupName({goNextStep, goBackStep, profilePic, setProfilePic, closeSheet, setImageId}) {
    const [profileImageUrl, setProfileImageUrl] = useState('')

    useEffect(() => {
        setProfileImageUrl(profilePic)
    }, [profilePic]);

    const onDropProfileImage = async (acceptedFiles) => {
        const imageId = uuid();
        const url = await uploadDoc("groups/", imageId, acceptedFiles[0]);
        setProfilePic(url);
        setProfileImageUrl(url);
        setImageId(imageId);
    }

    const handleSubmit = () => {
        goNextStep();
    };

    return (
        <div className='flex flex-col gap-8 w-[460px]'>
            <OnboardingStepHeader
                title={'Add a Group image!'}
                description={"This is your opportunity to give your Group some personality and make it more recognizable."}
            />
            <div className='flex flex-col gap-5 items-center my-12'>
                <Dropzone onDrop={(files) => onDropProfileImage(files)}>
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} className='cursor-pointer mt-[-48px] ml-4'>
                            <input {...getInputProps()} />
                            <div style={{}} className=''>
                                {!!profileImageUrl ? (
                                    <div
                                        className='w-32 h-32 bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square overflow-hidden flex flex-col items-center justify-center border border-gray-200 rounded-full'
                                    >
                                        <img
                                            className='w-32 h-32 object-cover bg-white aspect-square cursor-pointer  rounded-full'
                                            src={profileImageUrl}
                                            alt="profile-pic"
                                        />
                                        {/* <div className='absolute flex flex-col items-center justify-center'>
                                                        <SvgEdit className={'text-blue-500'}/>
                                                        <p className='text-xs mt-2'>
                                                            Edit image
                                                        </p>
                                                    </div> */}
                                    </div>
                                ) : (
                                    <div
                                        className='w-32 h-32 bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square flex flex-col items-center justify-center border border-gray-200 rounded-full'
                                    >
                                        <SvgAddCircle className={'text-blue-500'}/>
                                        <p className='text-xs'>
                                            Add image
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </Dropzone>
            </div>
            <OnboardingButtons
                finish
                goNextStep={handleSubmit}
                goBackStep={goBackStep}
                nextDisabled={!profileImageUrl}
            />
        </div>
    );
}

export default CreateGroupName;
