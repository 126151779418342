import React, {PureComponent} from "react";
import Portal from "../pages/PortalComponent";
import {wait} from "../utils/timestamp";
import SvgClose from "./icons/SvgClose";
import PropTypes from 'prop-types'
import '../styles/ModalComponent.css'
import {motion} from "framer-motion";
import {Button} from "./atoms/Button";
import ConfettiComponent from "./atoms/ConfettiComponent";

class ModalComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            modalOpacity: 0,
        }
    }

    componentDidMount() {
        if (this.props.isOpen) {
            this.init();
            this.animateOpen()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.init();
            this.animateOpen()
            document.body.style.overflow = 'hidden';
            return
        }

        if (this.state.isOpen && !prevState.isOpen) {
            this.setFocus();
        }

        if (prevProps.isOpen && !this.props.isOpen) {
            this.animateClosed()
            document.body.style.overflow = 'auto';
        }

        if (prevState.isOpen && !this.state.isOpen && this.props.isOpen) {
            this.props.toggle();
        }
    }

    componentWillUnmount() {
        if (this._element) {
            this.destroy();
        }
    }

    animateOpen = () => {
        this.setState({isOpen: true})
        wait(50).then(() => {
                this.setState({modalOpacity: 1})
            }
        )
    }

    animateClosed = () => {
        this.setState({modalOpacity: 0})
        wait(300).then(() => {
                this.setState({isOpen: false})
            }
        )
    }

    init = () => {
        try {
            this._triggeringElement = document.activeElement;
        } catch (err) {
            this._triggeringElement = null;
        }

        if (!this._element) {
            this._element = document.createElement('div');
            this._element.setAttribute('tabindex', '-1');

            this._element.style.position = 'relative';
            this._element.style.zIndex = this.props.zIndex || 1050;
            if (this.props.node) {
                this.props.node.appendChild(this._element)
            } else {
                const els = document.querySelectorAll('body')
                this._mountContainer = els[0]
                this._mountContainer.appendChild(this._element)
            }
        }
    }

    destroy = () => {
        if (this._element) {
            if (this.props.node) {
                this.props.node.removeChild(this._element)
            } else {
                this._mountContainer.removeChild(this._element);
            }
            this._element = null;
        }
        this.manageFocusAfterClose();
    }

    setFocus = () => {
        if (this._dialog && this._dialog.parentNode && typeof this._dialog.parentNode.focus === 'function') {
            this._dialog.parentNode.focus();
        }
    }

    manageFocusAfterClose = () => {
        if (this._triggeringElement) {
            if (this._triggeringElement.focus) {
                this._triggeringElement.focus()
            }
            this._triggeringElement = null;
        }
    }

    handleEscape = e => {
        if (!this.props.preventEscape && this.props.isOpen && e.keyCode === 27 && this.props.toggle) {
            e.preventDefault();
            e.stopPropagation();
            this.animateClosed()
        }
    }

    handleBackdropMouseDown = e => {
        this._mouseDownElement = e.target;
    }

    handleBackdropClick = e => {
        if (e.target === this._mouseDownElement) {
            e.stopPropagation();
            const backdrop = this._dialog ? this._dialog.parentNode : null;
            if (!this.props.isOpen) return;

            if (backdrop && e.target === backdrop && this.props.toggle) {
                this.animateClosed()
            }
        }
    }

    render() {
        if (!!this._element && this.state.isOpen) {
            const isModalHidden = !!this._element && !this.state.isOpen
            this._element.style.display = isModalHidden ? 'none' : 'block';

            const modalAttributes = {
                onClick: this.handleBackdropClick,
                onMouseDown: this.handleBackdropMouseDown,
                className: "modal-new-open",
                onKeyUp: this.handleEscape,
                role: 'dialog',
                tabIndex: '-1',
            }

            return (
                <Portal node={this._element}>
                    <div>
                        <div {...modalAttributes}
                             style={{opacity: this.state.modalOpacity, left: this.props.withNav ? 72 : 0}}>
                            {this.props.confetti &&
                                <ConfettiComponent/>
                            }
                            <div ref={c => this._dialog = c}>
                                <motion.div
                                    initial={{transform: 'translateY(30px)'}}
                                    animate={{transform: 'translateY(0px)'}}
                                    className={`${!this.props.hideBody && 'modal-content'} modal-content-${this.props.size || 'md'} br-12`}
                                    role="document"
                                    style={{
                                        transition: '100ms ease',
                                        padding: this.props.noPadding && 0,
                                        backgroundColor: this.props.backgroundColor,
                                        width: this.props.width,
                                        minWidth: this.props.width,
                                        maxWidth: this.props.width,
                                    }}
                                >
                                    {(this.props.header || this.props.headerToggle) &&
                                        <div className={'mb-0'}>
                                            <div
                                                style={{padding: this.props.noPadding && `${24}px ${24}px 0`}}
                                                className="mb-16 text-xl font-semibold text-slate-800 flex flex-row justify-between">
                                                <div className='flex-1 mr-3'>
                                                    {this.props.header}
                                                </div>
                                                {this.props.headerToggle && this.props.toggle &&
                                                    <Button variant={'secondary'} size={'icon'} className="pointer"
                                                            onClick={this.animateClosed}>
                                                        <SvgClose width={24} height={24}/>
                                                    </Button>
                                                }
                                            </div>
                                            {this.props.description &&
                                                <p className='text-gray-500 mt-0'>
                                                    {this.props.description}
                                                </p>
                                            }
                                        </div>
                                    }
                                    {this.props.children}
                                    {this.props.FooterComponent &&
                                        <div
                                            style={{padding: this.props.noPadding && `0 ${24}px ${24}px`}}
                                            className={this.props.footerAlignment === 'right' ? "row-ac-je pt-16" : "row-ac-jc pt-16"}>
                                            {this.props.FooterComponent}
                                        </div>
                                    }
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </Portal>
            )
        }
        return null;
    }
}

ModalComponent.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'full']),
    withNav: PropTypes.bool,
    header: PropTypes.string,
    headerToggle: PropTypes.bool,
    noPadding: PropTypes.bool,
    FooterComponent: PropTypes.object,
    footerAlignment: PropTypes.oneOf(['left', 'center', 'right']),
    zIndex: PropTypes.number,
    width: PropTypes.number,
    preventEscape: PropTypes.bool,
}

export default ModalComponent;
