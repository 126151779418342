import StreakModal from "../modals/StreakModal";
import React, {useState} from "react";


function StreakIndicator(props) {
    const [streakModalOpen, setStreakModalOpen] = useState(false)

    const toggleStreakModal = () => {
        setStreakModalOpen(!streakModalOpen)
    }

    if (props.streak > 0) {
        return (
            <>
                <div className='h-min' onClick={toggleStreakModal}>
                    {props.children}
                </div>
                <StreakModal
                    name={props.name}
                    sameUser={props.sameUser}
                    streak={props.streak}
                    longestStreak={props.longestStreak}
                    isOpen={streakModalOpen}
                    close={toggleStreakModal}
                />
            </>
        )
    } else return null
}

export default StreakIndicator
