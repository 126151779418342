import {getAuth, GoogleAuthProvider} from 'firebase/auth'
import {initializeApp} from 'firebase/app'
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from 'firebase/database';
import {getStorage, ref, uploadBytes, getDownloadURL} from 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyCT8DeU1Kr6R5KbCVJBDRxPMg9fV30pVwU",
  authDomain: "starfishapp.com",
  databaseURL: "https://starfish-seethegood-default-rtdb.firebaseio.com",
  projectId: "starfish-seethegood",
  storageBucket: "starfish-seethegood.appspot.com",
  messagingSenderId: "738898464392",
  appId: "1:738898464392:web:49e38d0977a3782ed0d93d",
  measurementId: "G-KC4KE9VR9Z"
};



const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseAnalytics = getAnalytics(firebaseApp);
export const firebaseStorage = getStorage(firebaseApp);
export const firebaseDatabase = getDatabase(firebaseApp);
export const googleProvider = new GoogleAuthProvider();

export const uploadDoc = async (folder, doc_id, doc) => {
  const docRef = ref(firebaseStorage, `${folder}/${doc_id}`);
  const snapshot = await uploadBytes(docRef, doc);
  console.log("Uploaded file:", snapshot);

  const docURL = await getDownloadURL(snapshot.ref);
  console.log("docurl", docURL)
  return docURL
};