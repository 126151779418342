import * as React from "react"

function SvgComponent(props) {
  return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          height={24}
          viewBox="0 -960 960 960"
          width={24}
          {...props}
      >
        <path fill={'currentColor'} d="M438-452l-56-56q-12-12-28-12t-28 12q-12 12-12 28.5t12 28.5l84 85q12 12 28 12t28-12l170-170q12-12 12-28.5T636-593q-12-12-28.5-12T579-593L438-452zm42 368q-7 0-13-1t-12-3q-135-45-215-166.5T160-516v-189q0-25 14.5-45t37.5-29l240-90q14-5 28-5t28 5l240 90q23 9 37.5 29t14.5 45v189q0 140-80 261.5T505-88q-6 2-12 3t-13 1zm0-80q104-33 172-132t68-220v-189l-240-90-240 90v189q0 121 68 220t172 132zm0-316z" />
      </svg>
  )
}

export default SvgComponent
