import React from "react";
import {NPO_CATEGORY_COLOR_DICT, NPO_CATEGORY_ICON_DICT, reverseCategoryMap} from "../../utils/dummy";

class NPOPicture extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageError: false
        };
    }

    handleImageError = () => {
        this.setState({imageError: true});
    };

    render() {
        const {imageError} = this.state;
        const fallbackInitials = this.props.initial;
        const category = reverseCategoryMap[this.props.np?.code]
        const SvgIcon = NPO_CATEGORY_ICON_DICT[category];
        const svgColor = NPO_CATEGORY_COLOR_DICT[category]
        return (
            <div style={{display: 'inline-block', position: 'relative'}} className='mr-8 '>
                <img
                    src={(!!this.props.np?.logo_url || !!this.props.logoUrl) && !this.state.imageError ? (this.props.np?.logo_url || this.props.logoUrl) : "https://handshake-production-cdn.joinhandshake.com/static_assets/default_institution_1.png"}
                    // onError={this.handleImageError}
                    onError={(e) => {
                        e.target.src =
                            "https://handshake-production-cdn.joinhandshake.com/static_assets/default_institution_1.png";
                        this.setState({imageError: true});
                    }}
                    style={{width: this.props.size || 40, height: this.props.size || 40}}
                    className={`object-contain rounded-lg ${this.props.className}`}
                    alt='Npo profile'
                />
                {(SvgIcon && !this.props.hideCategory) &&
                    <div style={{
                        bottom: -(this.props.size || 40) / 10,
                        right: -(this.props.size || 40) / 10,
                        padding: 2,
                        backgroundColor: svgColor,
                        borderColor: "white",
                        borderWidth: 1,
                    }}
                         className='rounded-full absolute'
                    >
                        <SvgIcon className={'text-white'} width={(this.props.size || 40) / 2.5}
                                 height={(this.props.size || 40) / 2.5}/>
                    </div>
                }
            </div>
        );
    }
}

export default NPOPicture
