import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 -960 960 960"
            width={48}
            {...props}
        >
            <path fill='currentColor'
                  d="M730-450v-60h150v60H730zm50 290l-121-90 36-48 121 90-36 48zm-82-503l-36-48 118-89 36 48-118 89zM210-200v-160h-70q-24.75 0-42.375-17.625T80-420v-120q0-24.75 17.625-42.375T140-600h180l200-120v480L320-360h-50v160h-60zm90-280zm260 134v-268q27 24 43.5 58.5T620-480q0 41-16.5 75.5T560-346zM140-540v120h196l124 74v-268l-124 74H140z"/>
        </svg>
    )
}

export default SvgComponent
