import * as React from "react";

const SvgArrowBack = (props) => (
    <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        {...props}
    >
        <path
            d="M15.66 9.17H6.35l4.067-4.066a.84.84 0 0 0 0-1.183.83.83 0 0 0-1.175 0L3.75 9.412a.83.83 0 0 0 0 1.175l5.492 5.492a.83.83 0 1 0 1.175-1.175L6.35 10.838h9.308a.836.836 0 0 0 .834-.834.836.836 0 0 0-.834-.833Z"
            fill={props.fill || "currentColor"}
        />
    </svg>
);

export default SvgArrowBack;
