import React, {PureComponent, useState} from "react";
import ModalComponent from "../ModalComponent";
import {Button} from "../atoms/Button";
import ProfilePicture from "../atoms/ProfilePicture";
import NPOPicture from "../atoms/NPOPicture";


function InviteLandingModal({isOpen, close, navigateToNPO, referralInfo}) {
    // let referralInfo = {
    //     np_name: "Camp Kesem National",
    //     referral_name: "Jack Phifer",
    //     referral_username: "jphifer311",
    //     np_logo_url: "https://storage.googleapis.com/starfish-seethegood.appspot.com/non_profits/profile_picture/51-0454157%281%29?Expires=33134745600&GoogleAccessId=firebase-adminsdk-pqols%40starfish-seethegood.iam.gserviceaccount.com&Signature=Tth8nXKhZYQagJZfbeeVko6g4GX1j8a16%2Boju2rM34wGS%2F8nYMl%2BswX9g7brazHso%2FquxY0igZqPOkkojP3KGw2irDiPbE0LNH7T8DlaLN0AN5LqvBxVPqcEgM2sBIuVVTm1pBE%2B9ZMsAuj%2BhwG54fLxsSB%2Fm5W4E1Fw36lWhoH9MnaWuiJoa5w2sFNKKUaWcyzt87ef%2F4Rqdt%2FHcDKnjA3Lo9Bbyz8SAqiWJlr7ASFf2XUOOm53xsOemOJvErx0j%2BtsupjH%2FF1Aqe67alXNbjD43EgEhtBq0RjjmJy%2FrSTmcHG6a5Rh3UYp2Aer7UCCXFyC5l2%2BTfQ%2BZrl8U9u0VQ%3D%3D"
    // }
    const np_name = referralInfo?.np_name || "";

    return (
        <ModalComponent toggle={close}
                        isOpen={isOpen}
                        noPadding
                        size={'md'}
                        headerToggle
                        confetti
        >
            <div className="p-5 pt-0">
                <h1 className="text-center text-3xl font-bold mb-3 -mt-[52px]">Welcome to Starfish!</h1>
                <div className="flex items-center justify-center mb-2">
                    <div className='flex items-center justify-center'>
                        <span className="text-sm text-slate-500 mr-2">Invited by:</span>
                        <ProfilePicture size={20} userID={referralInfo.referral_username}/>
                        <span className="text-sm font-semibold text-blue-500 ml-0">{referralInfo.referral_name}</span>
                    </div>
                </div>
                <p className="text-center text-base text-slate-700 mb-5 mx-5">
                    Thanks for accepting your invite from {referralInfo.referral_name},
                    we've donated on your behalf:</p>
                <h1 className="text-center text-6xl font-bold text-blue-500 mb-5">
                    $1
                </h1>
                <p className="text-center text-base font-medium mb-3 w-full">To Nonprofit:</p>
                <div className="flex items-center border border-slate-300 rounded-lg p-3 mb-5">
                    <NPOPicture
                        size={54} logoUrl={referralInfo?.np_logo_url}/>
                    <span className="text-base font-semibold ml-2">{referralInfo.np_name}</span>
                </div>
                <p className="text-center text-base text-slate-700 mb-5">Create your own share links and earn money for
                    your favorite causes too!</p>
                <div className="flex flex-col gap-3">
                    <Button onClick={navigateToNPO}>
                        Check out {np_name.length > 20 ? np_name.slice(0, 20) + '...' : np_name}
                    </Button>
                </div>
            </div>
        </ModalComponent>
    );
}

export default (InviteLandingModal);
