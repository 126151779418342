import React, {Component} from "react";

const sizeMap = {
    xs: 6,
    sm: 12,
    md: 24,
    lg: 32,
    xl: 44,
    '2xl': 64,
    '3xl': 128,
};

class GroupPicture extends Component {

    render() {
        const pixelSize = sizeMap[this.props.size] || sizeMap.md;
        return (
            <img
                src={`https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/groups%2F${this.props.imageId}?alt=media`}
                className={`rounded-full object-cover ${this.props.className}`}
                style={{
                    width:pixelSize,
                    height:pixelSize
                }}
            />
        )
    }
}

export default GroupPicture
