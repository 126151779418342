import React, {PureComponent} from "react";
import BGGraphic7 from "../../img/graphics/BGGraphic7";
import {motion} from "framer-motion";
import BGGraphic9 from "../../img/graphics/BGGraphic9";
import SvgCommunication from "../../components/icons/SvgCommunication";
import SvgCrowd from "../../components/icons/SvgCrowd";


class NpoSection3 extends PureComponent {
    render() {
        return (
            <section className={this.props.mobile ? 'col-ac' : 'row-ac-jb relative'}
                     style={{
                         height: !this.props.mobile && '90vh',
                         flexDirection: !this.props.mobile && 'row-reverse',
                         padding: this.props.mobile ? '80px 24px' : '80px 10vw'
                     }}>
                <motion.div style={{flex: 1, maxWidth: !this.props.mobile && '30vw'}}
                            initial={{opacity: 0, transform: 'scale(1) translateY(12px)'}}
                            whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            transition={{default: {ease: "linear"}, delay: .2}}
                            className={this.props.mobile ? 'mb-40 col-plain' : 'col-plain'}>
                    {/*<div className='ph-24 pv-12 br-64 body1-bold mb-12 white-pure'*/}
                    {/*     style={{backgroundColor: "#6A93C6A0", marginRight: "auto"}}>*/}
                    {/*    <SvgCrowd width={36} height={36} fill={'#FFFFFF'}/>*/}
                    {/*</div>*/}
                    <div className='body3-bold black-50 mb-8' style={{textTransform: 'uppercase'}}>
                        DONATIONS
                    </div>
                    <h1 className='heading2 black-100 mb-24 mt-0'>
                        Easier donations, for you<br/> and your supporters
                    </h1>
                    <p className='mt-0 body1 black-70'>
                        Collect donations directly from the Starfish platform with our proven safe and secure mobile and
                        web experiences. With the lowest fees in the industry, Starfish allows for you to raise funds
                        and awareness all in the same place forging a deeper and more meaningful connection with your
                        supporters.
                    </p>
                </motion.div>
                <div style={{flex: 1, maxWidth: this.props.mobile ? '100%' : '40vw'}} className='col-plain relative'>
                    <motion.div
                        initial={{opacity: 0, transform: 'scale(1) translateY(12px)'}}
                        whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                        transition={{default: {ease: "linear"}}}
                    >
                        <img src={require('../../img/npo/npo4.png')} className='floating'
                             style={{
                                 width: this.props.mobile ? '100%' : '30vw',
                                 objectFit: 'contain',
                                 marginLeft: !this.props.mobile && 80
                             }}/>
                    </motion.div>
                </div>
            </section>
        )
    }
}

export default NpoSection3
