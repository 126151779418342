import SvgChevronDown from "../icons/SvgChevronDown";
import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import {CARD_STYLE} from "../../utils/dummy";
import ProfilePicture from "../atoms/ProfilePicture";
import SvgPin from "../icons/SvgPin";
import {Button} from "../atoms/Button";
import { addRemoveGroupMember } from "../../api/messaging";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

function GroupInviteUserListItem({user, group, authUser, resetState}) {
    const [invited, setInvited] = useState(false)

    const toggleInvited = async () => {
        setInvited(!invited);
        await addRemoveGroupMember(authUser, group.id, user.username, `${user.first_name} ${user.last_name}`, true);
        resetState();
    }

    useEffect(() => {
        if ("members" in group && Object.keys(group.members).includes(user.username)) {
            setInvited(true);
        }
    }, [])

    return (
        <div
            className={`${CARD_STYLE} p-2 flex flex-row items-center`}
            key={user.username}
        >
            <ProfilePicture
                initial={user.first_name[0] + user.last_name[0]}
                userID={user.username}
                size={44}
            />
            <div className='flex flex-col flex-1 ml-12' style={{textAlign: "left"}}>
                <p className='text-slate-800 font-semibold'>
                    {user.first_name} {user.last_name}
                </p>
                {!!user.tagline ?
                    <p className='text-slate-500 font-semibold -mt-1.5'>
                        {user.tagline}
                    </p>
                    : null}
                {!!user.city ? 
                    <div className='row-ac'>
                        <SvgPin className={'text-blue-500 w-3 h-3'}/>
                        <p className='text-sm text-blue-500 ml-4'>
                            {user.city}, {user.state}
                        </p>
                    </div>
                : null}
            </div>
            <Button size={'sm'} variant={invited ? 'secondary' : 'default'} disabled={invited} onClick={toggleInvited}>
                {invited ? 'In Group' : 'Invite'}
            </Button>
        </div>
    )
}

export default connect(mapStateToProps)(GroupInviteUserListItem);
