import SvgChevronDown from "../icons/SvgChevronDown";
import React, {useState} from "react";
import GroupMembersListItem from "./GroupMembersListItem";
import {wait} from "../../utils/timestamp";
import SvgCheck from "../icons/SvgCheck";
import SvgShare from "../icons/SvgShare";
import SvgLink from "../icons/SvgLink";
import SvgLink2 from "../icons/SvgLink2";
import SvgSearch from "../icons/SvgSearch";
import {searchNonProfits, searchUsers} from "../../utils/typesense";
import {CARD_STYLE} from "../../utils/dummy";
import ProfilePicture from "../atoms/ProfilePicture";
import SvgPin from "../icons/SvgPin";
import {Button} from "../atoms/Button";
import GroupInviteUserListItem from "./GroupInviteUserListItem";

function GroupInviteWidget({group}) {
    const [copiedLink, setCopiedLink] = useState(false)
    const [search, setSearch] = useState('')
    const [usersList, setUsersList] = useState([])

    const link = `https://starfishapp.com/invite/${group?.id}`

    const searchChanged = (e) => {
        setSearch(e.target.value)
        searchUsers(e.target.value).then(userResults => {
            setUsersList(userResults)
        });
    }

    const copyLinkToClipboard = (e) => {
        e.stopPropagation()
        setCopiedLink(true)
        navigator.clipboard.writeText(link)
            .then(() => {
                console.log('Link copied to clipboard:', link);
                // You can show a success message or perform other actions here
            })
            .catch((error) => {
                console.error('Failed to copy link to clipboard:', error);
                // You can show an error message or handle the error here
            });
        wait(3000).then(() => {
            setCopiedLink(false)
        })
    }

    const resetState = () => {
        setSearch("");
        setUsersList([]);
    }

    return (
        <>
            <div className='border border-slate-200  p-3 rounded-xl'>
             <div className='flex flex-col'>
                 <p className='text-lg font-semibold'>
                    Your invite link
                 </p>
                 <p className='text-slate-500 mb-3'>
                    Use this link to invite new users to the group!!
                 </p>
             </div>
                <div className='mr-auto w-min'>
                    <div onClick={copyLinkToClipboard}
                         className={`flex flex-row items-center gap-3 p-2 rounded-xl  cursor-pointer ${copiedLink ? 'text-blue-500 border border-blue-200' : 'hover:bg-slate-100 border border-slate-200 border-dashed'}`}>
                        {copiedLink ? <SvgCheck className={'w-5 h-5 scale-[1.2]'}/> :
                            <SvgLink2 className={'w-5 h-5 scale-[1.2]'}/>}
                        <p className='whitespace-nowrap'>
                            {copiedLink ? 'Link copied!' : link}
                        </p>
                    </div>
                </div>
            </div>
            <div className='border border-slate-200  p-3 rounded-xl'>
                <p className='text-lg font-semibold mb-2'>
                    Invite Starfish users
                </p>
                <div className='row-ac relative mb-3'>
                    <input
                        onChange={searchChanged}
                        value={search}
                        type="text"
                        className={'w-100p search-input rounded-lg'}
                        placeholder="Search users..."
                    />
                    <SvgSearch className={'absolute black-50'}
                               style={{top: 10, left: 24, width: 24, height: 24}}/>
                </div>
                {search.length > 0 && usersList.length === 0 &&
                    <p className='text-slate-500 text-sm text-center mx-auto w-full my-5'>
                        No results match this search...
                    </p>
                }
               <div className='max-h-[300px] overflow-y-scroll'>
                   {usersList.map(user =>
                       <GroupInviteUserListItem
                           user={user}
                           key={user.username}
                           group={group}
                           resetState={resetState}
                       />
                   )}
               </div>
            </div>
        </>
    )
}

export default GroupInviteWidget
