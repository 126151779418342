import React, {useState} from 'react';
import TextInput from "../../components/inputs/TextInput";
import OnboardingStepHeader from "../Onboarding/OnboardingStepHeader";
import OnboardingButtons from "../Onboarding/OnboardingButtons";

function CreateChallengeName({
                                 goNextStep,
                                 goBackStep,
                                 name,
                                 setName,
                                 description,
                                 setDescription,
                                 npName,
                                 selectedGoalType
                             }) {
    const [errorMessage, setErrorMessage] = useState('');

    const handleNameChange = (event) => {
        if (errorMessage) setErrorMessage('');
        setName(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        if (errorMessage) setErrorMessage('');
        setDescription(event.target.value);
    };

    const validateForm = () => {
        if (!name) {
            setErrorMessage('Please enter your a name.');
            return false;
        }
        if (!description) {
            setErrorMessage('Please enter a description.');
            return false;
        }
        return true;
    };

    const handleSubmit = () => {
        if (validateForm()) {
            goNextStep();
        }
    };

    const PLACEHOLDERS = {
        awareness: {
            name: `e.g. Awareness Challenge for ${npName}`,
            description: `e.g. Tell new followers why they should support ${npName}, more about your involvement, etc...`
        },
        donation: {
            name: `e.g. Donation Challenge for ${npName}`,
            description: `e.g. Raising money for ${npName} so that they can help the community, launch a new initiative, ...`
        },
        pledge: {
            name: `e.g. Pledge Challenge for ${npName}`,
            description: `Describe your challenge, why people should join and pledge support, or why you care about ${npName}...`
        },
        default: {
            name: `e.g. ${npName} Challenge`,
            description: 'e.g. Tell us what your challenge is about.'
        }
    };

    const placeholders = PLACEHOLDERS[selectedGoalType] || PLACEHOLDERS.default;

    return (
        <div className='flex flex-col gap-8 w-[460px]'>
            <OnboardingStepHeader
                title={'Give your challenge a name!'}
                description={"Pick a catchy name for your challenge and add a description to tell people more about it."}
            />
            <div className='flex flex-col gap-5'>
                <TextInput
                    label={'Challenge Name'}
                    value={name}
                    placeholder={placeholders.name}
                    onChange={handleNameChange}
                />
                <TextInput
                    label={'Description'}
                    value={description}
                    textarea
                    placeholder={placeholders.description}
                    onChange={handleDescriptionChange}
                    className={'min-h-[140px]'}
                />
                <p className={`text-xs h-4 ${errorMessage ? 'text-red-400' : 'text-transparent'}`}>
                    {errorMessage}
                </p>
            </div>
            <OnboardingButtons
                goNextStep={handleSubmit}
                goBackStep={goBackStep}
                nextDisabled={!name || !description}
            />
        </div>
    );
}

export default CreateChallengeName;
