import * as React from "react"

const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={100}
        height={100}
        {...props}
    >
        <path
            fill={props.fill || 'currentColor'}
            d="M11 2v1H6c-1.103 0-2 .897-2 2v15c0 1.103.897 2 2 2h7.758l-2-2H6V5h5v1h2V5h5v12.758l2-2V5c0-1.103-.897-2-2-2h-5V2h-2zm1 7a2 2 0 0 0-2 2 2 2 0 0 0 2 2 2 2 0 0 0 2-2 2 2 0 0 0-2-2zm0 5c-2.185 0-4 .909-4 2.217V17h8v-.783C16 14.909 14.185 14 12 14zm10.293 2.293L18 20.586l-2.293-2.293-1.414 1.414L18 23.414l5.707-5.707-1.414-1.414z"/>
    </svg>
)

export default SvgComponent
