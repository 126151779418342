import React, {Component} from "react";
import MiddleViewHeader from "../components/layout/MiddleViewHeader";
import SvgLike from "../components/icons/SvgLike";
import NotificationComponent from "../components/NotificationComponent";
import LoadingSection from "../components/LoadingSection";
import {getNotifications} from "../api/notifications";
import {connect} from "react-redux";
import SvgGroupAdd from "../components/icons/SvgGroupAdd";
import SvgCheck from "../components/icons/SvgCheck";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}


class NotificationsPage extends Component {
    state = {
        liked: false,
        loading: true,
        notifications: [],
    }


    componentDidMount = async () => {
        const notifications = await getNotifications(this.props.authUser);
        this.setState({notifications, loading: false});
    }

    switchIcon = (type) => {
        switch (type) {
            case'liked_post':
                return <SvgLike className={'text-blue-500 w-6 h-6'}/>
            default:
                return <SvgLike className={'text-blue-500 w-6 h-6'}/>
        }
    }

    switchCopy = (type) => {
        switch (type) {
            case'liked_post':
                return 'liked your post'
            case 'followed':
                return 'followed you'
            case 'tagged_post':
                return 'tagged you in a post'
            case 'liked_comment':
                return 'liked your comment'
            case 'commented':
                return 'commented on your post'
            case 'challenge_referral':
                return 'invited you to a challenge'
            default:
                return ''
        }
    }

    render() {
        return (
            <div className='flex flex-col flex-1 overflow-hidden'>
                <MiddleViewHeader
                    title={'My Notifications'}
                />
                {this.state.loading ?
                    <LoadingSection/>
                    :
                    <div className='flex flex-col flex-1 p-5 overflow-y-scroll'>
                        {this.state.notifications.length === 0 &&
                            <div className='flex flex-col items-center gap-3 mt-40'>
                                <SvgCheck className={'text-slate-300 scale-[2]'}/>
                                <p className='text-slate-500 text-sm text-center'>
                                   You don't have any notifications yet.
                                </p>
                            </div>
                        }
                        {this.state.notifications.map(notification =>
                            <NotificationComponent
                                key={notification.created_at + notification.content.name}
                                notification={notification}
                            />
                        )}
                    </div>
                }
            </div>
        )
    }

}


export default connect(mapStateToProps)(NotificationsPage);
