import axios from "./axios";


export const createDonationCheckout = async (user, amount, ein, recurring=false, challenge_id=null, memo=null, post_id=null) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const body = {
            amount,
            ein,
            challenge_id,
            recurring,
            memo,
            post_id
        };
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post("/stripe/payment", body, config);
        if (res.data.checkout.error) {
            window.location = "https://www.starfishapp.com" 
            return;
        }
        window.location = res.data.checkout.url
        return res.data;
    } catch (err) {
        console.log(err);
    }
}
