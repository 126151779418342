import React, {Component} from "react";
import ModalComponent from "../ModalComponent";
import {Button} from "../atoms/Button";
import SvgClose from "../icons/SvgClose";
import {connect} from "react-redux";
import SvgArrowForward from "../icons/ArrowForward";
import SvgArrowBack from "../icons/ArrowBack";

function mapDispatchToProps(dispatch) {
    return {
        setImageViewerModalClosed: () => {
            dispatch({
                type: "SET_IMAGE_VIEWER_MODAL_CLOSED",
            })
        },
        setImageViewerModalIndex: (index) => {
            dispatch({
                type: "SET_IMAGE_VIEWER_MODAL_INDEX",
                index
            })
        },
    }
}

function mapStateToProps(state) {
    return {
        imageViewerModalOpen: state.userReducer.imageViewerModalOpen,
        imageViewerImages: state.userReducer.imageViewerImages,
        imageViewerIndex: state.userReducer.imageViewerIndex,
    }
}


class ImageViewerModal extends Component {
    constructor(props) {
        super(props);
    }

    closeModal = () => {
        this.props.setImageViewerModalClosed();
    }

    previousImage = (e) => {
        e.stopPropagation()
        const newIndex = this.props.imageViewerIndex - 1 > 0 ? this.props.imageViewerIndex - 1 : 0;
        this.props.setImageViewerModalIndex(newIndex)
    }

    nextImage = (e) => {
        e.stopPropagation()
        const newIndex = this.props.imageViewerIndex + 1 < this.props.imageViewerImages.length ? this.props.imageViewerIndex + 1 : this.props.imageViewerImages.length - 1
        this.props.setImageViewerModalIndex(newIndex)

    }

    render() {
        const { imageViewerModalOpen, imageViewerImages, imageViewerIndex } = this.props;
        const canNavigateBackward = imageViewerIndex > 0;
        const canNavigateForward = imageViewerIndex < imageViewerImages.length - 1;

        return (
            <ModalComponent
                isOpen={imageViewerModalOpen}
                size={'full'}
                toggle={this.closeModal}
                hideBody
            >
                <div className='p-5 flex flex-col flex-1 relative' onClick={this.closeModal}>
                    <div className='fixed top-5 right-5 z-10'>
                        <Button onClick={this.closeModal} size={'icon'} variant={'secondary'} radius={'full'}>
                            <SvgClose />
                        </Button>
                    </div>
                    <div className='flex flex-row justify-center flex-1 items-center relative'>
                        {canNavigateBackward && (
                            <Button className='absolute left-0' onClick={this.previousImage}>
                                <SvgArrowBack />
                            </Button>
                        )}
                        <img src={imageViewerImages[imageViewerIndex]}
                             className='max-h-[90vh] w-auto object-contain'
                             alt="Image Viewer"
                             onClick={(e) => e.stopPropagation()}
                        />
                        {canNavigateForward && (
                            <Button className='absolute right-0' onClick={this.nextImage}>
                                <SvgArrowForward />
                            </Button>
                        )}
                    </div>
                </div>
            </ModalComponent>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageViewerModal);
