import React, { Component } from "react";
import { connect } from "react-redux";
import { CARD_STYLE_NO_HOVER } from "../../utils/dummy";
import ZeroStateArea from "../atoms/ZeroStateArea";
import ProfileWorkItem from "./ProfileWorkItem";
import ProfileSectionHeader from "./ProfileSectionHeader";
import AddWorkModal from "../modals/AddWorkModal";
import { deleteConnection } from "../../api/users";
import SvgSupporter from "../icons/SvgSupporter";
import SvgVolunteer from "../icons/SvgVolunteer";
import SvgAward from "../icons/SvgAward";
import { Button } from "../atoms/Button";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class ProfileAboutWork extends Component {
    state = {
        addWorkModalOpen: false,
        isEditingExistingItems: false,
        showAllSupporter: false,
        showAllVolunteer: false,
        showAllEmployee: false,
        currentWorkItem: {},
    }

    openAddWorkModal = (currentWorkItem = {}) => {
        this.setState({ addWorkModalOpen: true, currentWorkItem });
    }

    closeAddWorkModal = () => {
        this.setState({ addWorkModalOpen: false });
    }

    toggleEditExistingMode = () => {
        this.setState({ isEditingExistingItems: !this.state.isEditingExistingItems });
    }

    toggleShowMore = (role) => {
        this.setState((prevState) => ({
            [role]: !prevState[role],
        }));
    }

    deleteConnection = async (ein) => {
        await deleteConnection(this.props.authUser, ein);
        window.location.reload();
    }

    selectColor = (role) => {
        switch (role) {
            case 'Supporter': return '#3b81f6';
            case 'Volunteer': return "#ea66c3";
            case 'Employee': return "#F17300";
            default: return '#000';
        }
    }

    renderIcon = (role) => {
        switch (role) {
            case 'Supporter': return <SvgSupporter className="w-5 h-5" />;
            case 'Volunteer': return <SvgVolunteer className="w-5 h-5" />;
            case 'Employee': return <SvgAward className="w-5 h-5" />;
            default: return null;
        }
    }

    renderRoleSection = (role, items, showAllStateKey) => {
        const showAll = this.state[showAllStateKey];
        const displayedItems = showAll ? items : items.slice(0, 3);

        return (
            <React.Fragment>
                <div
                    style={{ color: this.selectColor(role), backgroundColor: `${this.selectColor(role)}20` }}
                    className="flex flex-row items-center gap-3 rounded-xl font-medium py-2 px-3 mt-3"
                >
                    {this.renderIcon(role)}
                    <h3 className="text-lg">{role} Roles</h3>
                </div>
                {displayedItems.map(item => (
                    <ProfileWorkItem
                        key={item.ein}
                        item={item}
                        onClickEdit={this.openAddWorkModal}
                        onClickDelete={this.deleteConnection}
                        editing={this.state.isEditingExistingItems}
                    />
                ))}
                {items.length > 3 && (
                    <div className="w-full flex flex-col items-center mt-2">
                        <Button
                            variant="secondary"
                            size="sm"
                            onClick={() => this.toggleShowMore(showAllStateKey)}
                        >
                            {showAll ? `See Less ${role} Roles` : `Show More ${role} Roles`}
                        </Button>
                    </div>
                )}
            </React.Fragment>
        );
    }

    render() {
        const { work, user, sameUser } = this.props;
        const prefix = sameUser ? "My" : `${user.first_name}'s`;

        // Group work items by role
        const supporterItems = work.filter(item => item.role === 'Supporter');
        const volunteerItems = work.filter(item => item.role === 'Volunteer');
        const employeeItems = work.filter(item => item.role === 'Employee');

        return (
            <div className={`${CARD_STYLE_NO_HOVER}`}>
                <ProfileSectionHeader
                    label={`${prefix} Involvement`}
                    sameUser={sameUser}
                    editing={this.state.isEditingExistingItems}
                    setEditingMode={this.toggleEditExistingMode}
                    onClickAdd={this.openAddWorkModal}
                />
                {work.length === 0 ? (
                    <ZeroStateArea
                        title="Nothing here..."
                        sub={`${user.first_name} does not have any involvement listed yet.`}
                    />
                ) : (
                    <>
                        {supporterItems.length > 0 && this.renderRoleSection('Supporter', supporterItems, 'showAllSupporter')}
                        {volunteerItems.length > 0 && this.renderRoleSection('Volunteer', volunteerItems, 'showAllVolunteer')}
                        {employeeItems.length > 0 && this.renderRoleSection('Employee', employeeItems, 'showAllEmployee')}
                    </>
                )}
                <AddWorkModal
                    authUser={this.props.authUser}
                    item={this.state.currentWorkItem}
                    isOpen={this.state.addWorkModalOpen}
                    close={this.closeAddWorkModal}
                />
            </div>
        );
    }
}

export default connect(mapStateToProps)(ProfileAboutWork);
