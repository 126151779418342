import axios from './axios';

export const postComment = async (user, post_id, content, image_urls, users, nonProfits, hashtags, plaintext, videos) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            post_id, 
            content,
            image_urls,
            block_style: true,
            users,
            non_profits: nonProfits,
            hashtags,
            plaintext,
            videos,
        }
        const res = await axios.post("/comments", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const deleteComment = async (user, comment_id, post_id) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {post_id, comment_id}
        };
        const res = await axios.delete("/comments", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const likeUnlikeComment = async (user, comment_id) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {comment_id}
        const res = await axios.post("/comments/like", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}
