import React, {useState} from "react";
import moment from "moment";
import {Button} from "../atoms/Button";
import ProfilePicture from "../atoms/ProfilePicture";
import {useHistory} from "react-router-dom";
import { acceptRejectGroupRequest } from "../../api/groups";


function GroupManageApprovalsWidget({group, user}) {
    const history = useHistory();
    const approveUser = async (userId, name) => {
        await acceptRejectGroupRequest(user, group.id, userId, name, true);
    };

    const denyUser = async (userId, name) => {
        await acceptRejectGroupRequest(user, group.id, userId, name, false);
    };

    const navigateToUser = (username) => {
        history.push({pathname: `/profile/${username}`, state: {previous: "Back"}})
    }

    const pendingApprovals = group.requests || {};
    return (
        <div className='border border-slate-200 p-3 rounded-xl'>
            <div className='flex flex-row items-center gap-3 mb-2'>
                <p className='text-lg font-semibold'>
                    Members Pending Approval
                </p>
                {pendingApprovals.length > 0 &&
                    <div
                        className='bg-red-400 flex flex-col items-center justify-center text-white rounded-full text-sm font-semibold px-3'>
                        {pendingApprovals.length}
                    </div>
                }
            </div>
            {Object.keys(pendingApprovals).map(userId => {
                const user = pendingApprovals[userId]
                return (
                    <div key={userId}
                         className='flex flex-row items-center justify-between p-2 border-b border-slate-200 last:border-none'>
                        <div className='flex flex-col'>
                            <div onClick={() => navigateToUser(userId)} className='flex flex-row items-center gap-0 cursor-pointer'>
                                <ProfilePicture
                                    initial={user.name[0]}
                                    userID={userId}
                                />
                                <div className='flex flex-col'>
                                    <p className='font-medium text-slate-700'>{user.name}</p>
                                    {/* <p className='text-sm text-slate-500'>{user.tagline}</p> */}
                                </div>
                            </div>
                            <p className='text-xs text-slate-400 mt-2'>Requested {moment(user.created_at*1000).fromNow()}</p>
                        </div>
                        <div className='flex flex-row gap-2'>
                            <Button size='sm' variant='default' onClick={() => approveUser(userId, user.name)}>
                                Approve
                            </Button>
                            <Button size='sm' variant='destructive' onClick={() => denyUser(userId, user.name)}>
                                Deny
                            </Button>
                        </div>
                    </div>
                )
            })}
            {Object.keys(pendingApprovals).length === 0 &&
                <p className='text-sm text-slate-500'>No pending approvals.</p>
            }
            
        </div>
    )
}

export default GroupManageApprovalsWidget;
