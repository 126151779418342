import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import ContactUs from "../pages/ContactUs";
import CampaignBanner from "./CampaignBanner";
import SvgMenu from "./icons/SvgMenu";
import {logout} from "../api/authentication";
import SvgLogout from "./icons/SvgLogout";
import {connect} from "react-redux";
import SvgSearch from "./icons/SvgSearch";
import SvgArrowForward from "./icons/ArrowForward";


function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        mobile: state.userReducer.mobile,
    }
}

class Navbar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contactUsOpen: false,
            linkText: "For Nonprofits", // Initial button text
            linkTo: "/non-profits", // Initial link route
        }
    }

    componentDidMount() {
        // Set initial state based on the current route
        this.updateStateFromRoute(this.props.location.pathname);
    }

    componentDidUpdate(prevProps) {
        // Check for route changes and update state accordingly
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.updateStateFromRoute(this.props.location.pathname);
        }
    }

    toggleContactUs = () => {
        this.setState({contactUsOpen: !this.state.contactUsOpen})
    }

    navigateTo = (route) => {
        this.props.history.push(route)
    }

    handleLinkClick = () => {
        if (this.state.linkTo === "/non-profits") {
            this.setState({linkTo: "/", linkText: "For Individuals"});
        } else {
            this.setState({linkTo: "/non-profits", linkText: "For Nonprofits"});
        }
    };

    // Helper function to set state based on the route
    updateStateFromRoute = (pathname) => {
        if (pathname === "/non-profits") {
            this.setState({linkTo: "/", linkText: "For Individuals"});
        } else {
            this.setState({linkTo: "/non-profits", linkText: "For Nonprofits"});
        }
    };


    render() {
        const currentRoute = this.props.location.pathname
        const nonProfitsView = currentRoute === '/non-profits'
        if (this.props.mobile) {
            return (
                <nav className='row-ac-jb ph-16 bg-white'
                     style={{position: 'fixed', paddingTop: 80, paddingBottom: 20, height: 100}}>
                    <div className='absolute' style={{top: 0, left: 0}}>
                        <CampaignBanner navigateTo={this.navigateTo}/>
                    </div>
                    <Link to={'/'}>
                        <div className='row-ac'>
                            <img src={require('../img/brand/logo.png')} alt={'Icon'}
                                 style={{width: 32, marginBottom: 2}}/>
                            <div className='relative'>
                                <img src={require('../img/brand/LOGO-PRIMARY.png')} alt={'Starfish'} className='mb-4'
                                     width={68}/>
                                {nonProfitsView &&
                                    <p className='absolute black-50' style={{
                                        whiteSpace: 'nowrap',
                                        bottom: -8,
                                        right: -0,
                                        fontSize: 10,
                                        fontWeight: '600'
                                    }}>
                                        for Nonprofits
                                    </p>
                                }
                            </div>
                        </div>
                    </Link>
                    <div className='row-ac'>
                        {nonProfitsView ?
                            <Link to={'/'} className={'nav-link body3'}>
                                For Users
                            </Link>
                            :
                            <Link to={'/non-profits'} className={'nav-link body3'}>
                                For Nonprofits
                            </Link>
                        }
                        {/* {!nonProfitsView &&
                            <div className='row-ac primary pointer bg-black-4-h ml-12'
                                 style={{border: '1px solid #00000020', padding: '4px 12px', borderRadius: 100}}
                                 onClick={() => this.navigateTo("/search")}>
                                <div
                                    className={`nav-icon  mt-4`}>
                                    <SvgSearch width={14} height={14} className={''}/>
                                </div>
                                <p className='body3 ml-8'>
                                    Search
                                </p>
                            </div>
                        } */}
                        {nonProfitsView &&
                            <a style={{textDecoration: 'none'}}
                               href='https://nonprofit.starfishapp.com/' target={'_blank'}
                               className='secondary-button button-sm row-ac body3-bold ml-12'
                                // style={{backgroundColor: '#EFA042', textDecoration:"none"}}
                            >
                                <div className='mr-8'>
                                    Admin Login
                                </div>
                                <SvgArrowForward width={18} height={18} style={{transform: 'rotate(-45deg)'}}/>
                            </a>
                        }
                        {!!this.props.user ?
                            <div className={`nav-icon ml-16`}
                                 onClick={() => logout()} style={{marginTop: 4}}>
                                <SvgLogout width={26} height={26} className={''}/>
                            </div>
                            :
                            null
                        }
                        <div onClick={this.props.toggleNavMenu}
                             className='pointer mt-4 ml-12'
                             style={{
                                 transform: `scale(${this.props.navOpen ? '.5' : '1'}`,
                                 transition: "transform 300ms ease-in-out"
                             }}>
                            <SvgMenu width={30} height={30} style={{color: '#054A91'}}/>
                        </div>
                    </div>
                    <ContactUs
                        mobile
                        closeContactUs={this.toggleContactUs}
                        isOpen={this.state.contactUsOpen}
                    />
                </nav>
            )
        }
        return (
            <nav style={{backdropFilter: 'blur(4px)', paddingTop: 60}} className='bg-white flex-row flex-wrap h-32'>
                <div className='absolute' style={{top: 0, left: 0}}>
                    <CampaignBanner navigateTo={this.navigateTo}/>
                </div>
                <Link to={nonProfitsView ? '/non-profits' : '/'}>
                    <div className='row-ac'>
                        <img src={require('../img/brand/logo.png')} alt={'Icon'} style={{width: 64}}/>
                        <div className={'relative'}>
                            <img src={require('../img/brand/LOGO-PRIMARY.png')} alt={'Starfish'} className='mb-4'
                                 width={120}/>
                            {nonProfitsView &&
                                <p className='absolute black-50' style={{
                                    whiteSpace: 'nowrap',
                                    bottom: -16,
                                    right: -0,
                                    fontSize: 14,
                                    fontWeight: '600'
                                }}>
                                    for Nonprofits
                                </p>
                            }
                        </div>
                    </div>
                </Link>
                <div className='row-ac ml-auto'>
                    <div className='row-ac gap-5 '>
                        {nonProfitsView ?
                            <Link to={'/'} className={'nav-link text-sm font-bold'}>
                                For Users
                            </Link>
                            :
                            <Link to={'/non-profits'} className={'nav-link text-sm font-bold'}>
                                For Nonprofits
                            </Link>
                        }
                        <Link to={'/about'} className={'nav-link text-sm font-bold'}>
                            About
                        </Link>
                        <Link to={'/starfishlab'} className={'nav-link text-sm font-bold'}>
                            Starfish Lab
                        </Link>
                        <Link to={'/authentication'} className={'nav-link text-sm font-bold'}>
                            Login
                        </Link>
                        <a style={{textDecoration: 'none'}}
                            href='https://www.starfishapp.com/authentication?activeTab=signup'
                            className='primary-button button-sm bg-white row-ac text-sm font-bold'>
                            <div style={{marginTop: 2}}>
                                Signup
                            </div>
                        </a>
                        {nonProfitsView &&
                            <a style={{textDecoration: 'none'}}
                               href='https://nonprofit.starfishapp.com/' target={'_blank'}
                               className='secondary-button button-sm row-ac text-sm font-bold'
                                // style={{backgroundColor: '#EFA042', textDecoration:"none"}}
                            >
                                <div className=''>
                                    Admin Login
                                </div>
                            </a>
                        }
                    </div>
                    {/* {!nonProfitsView &&
                        <div className='row-ac primary pointer bg-black-4-h'
                             style={{border: '1px solid #00000020', padding: '2px 12px', borderRadius: 100}}
                             onClick={() => this.navigateTo("/search")}>
                            <div
                                className={`nav-icon  mt-4`}>
                                <SvgSearch width={20} height={20} className={''}/>
                            </div>
                            <p className='body2 ml-8'>
                                Find nonprofits
                            </p>
                        </div>
                    } */}
                    {!!this.props.user ?
                        <div className={`nav-icon ml-24`}>
                             onClick={() => logout()}
                            <SvgLogout width={30} height={30} className={''} style={{marginRight: -4}}/>
                        </div>
                        :
                        null
                    }
                    <div onClick={this.props.toggleNavMenu}
                         className='pointer  nav-icon ml-20 mb-4'
                         style={{
                             marginTop: 6,
                             transform: `scale(${this.props.navOpen ? '.5' : '1'}`,
                             transition: "transform 300ms ease-in-out"
                         }}>
                        <SvgMenu width={36} height={36}/>
                    </div>
                </div>
                <ContactUs
                    closeContactUs={this.toggleContactUs}
                    isOpen={this.state.contactUsOpen}
                />
            </nav>
        )
    }

}

export default withRouter(connect(mapStateToProps)(Navbar));
