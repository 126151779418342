import React, {Component} from "react";
import {getDonations, getDonationSummary} from "../../api/donations";
import {CARD_STYLE_NO_HOVER} from "../../utils/dummy";
import {connect} from "react-redux";
import moment from "moment";
import ChallengeCard from "../ChallengeCard";
import SvgSubdirectoryRight from "../icons/SvgSubdirectoryRight";
import SvgVerifiedDonor from "../icons/SvgVerifiedDonor";
import NPOPicture from "../atoms/NPOPicture";
import CollapseComponent from "../CollapseComponent";
import SvgChevronDown from "../icons/SvgChevronDown";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class ProfileDonationsListItem extends Component {
    state = {
        challengeCollapsed: false
    }

    toggleChallengeCollapse = () => {
        this.setState({challengeCollapsed: !this.state.challengeCollapsed})
    }

    render() {
        const item = this.props.item;
        const nonProfit = this.props.nonProfit;
        const challenge = this.props.challenge || {};
        return (
            <div className={`${CARD_STYLE_NO_HOVER} `}>
                <div key={item}
                     className={`flex flex-row items-center justify-between`}>
                    <div className='flex flex-col'>
                        <div className='flex flex-row items-center gap-1'>
                            <p className='text-slate-500 font-medium'>
                                Donation to
                            </p>
                        </div>
                        <p className='text-2xl text-slate-800 font-medium'>
                            {nonProfit.name}
                        </p>
                        <div className='flex flex-row items-center gap-5'>
                            <div className='flex flex-row items-center gap-1 text-green-500'>
                                <SvgVerifiedDonor className={'w-4 h-4'}/>
                                <p className='font-medium'>
                                    Verified
                                </p>
                            </div>
                            <p className='text-md text-slate-500 font-medium'>
                                EIN: {item.non_profit_id} | {moment(item.created_at).format('LLLL')}
                            </p>
                        </div>
                    </div>
                    <NPOPicture size={60} className={'border-slate-100 border bg-slate-50'}   np={nonProfit} logoUrl={nonProfit.logo_url}/>
                </div>
                {!!item.challenge_id &&
                    <div className='mt-3 flex flex-col gap-2 ml-5'>
                        <div onClick={this.toggleChallengeCollapse} className='flex flex-row items-center justify-between gap-2 group'>
                            <div
                                 className='flex flex-row items-center gap-2 text-blue-500 group-hover:bg-blue-500/10 rounded-full px-2'>
                                <SvgSubdirectoryRight className={'w-5 h-5'}/>
                                <p className='font-medium'>
                                    As a part of the {challenge.challenge_name}
                                </p>
                            </div>
                            <div className='flex flex-row items-center gap-2 text-blue-500'>
                                <SvgChevronDown  className={'w-5 h-5'}/>
                            </div>
                        </div>
                        <CollapseComponent isOpen={this.state.challengeCollapsed}>
                            <ChallengeCard hasBorder className={'border-0 shadow-none p-0'} challenge={challenge}/>
                        </CollapseComponent>
                    </div>
                }
            </div>

        )
    }

}


export default connect(mapStateToProps)(ProfileDonationsListItem);
