import React, {Component} from "react";
import MiddleViewHeader from "../components/layout/MiddleViewHeader";
import ChatPage from "./ChatPage";
import {connect} from "react-redux";
import {Button} from "../components/atoms/Button";
import SvgUserPlus from "../components/icons/SvgUserPlus";
import {attachUserChatsListener} from "../api/messaging";
import LoadingSection from "../components/LoadingSection";
import GroupListItem from "../components/groups/GroupListItem";
import GroupDetailsSheet from "./Sheets/GroupDetailsSheet";
import InviteToGroupModal from "../components/modals/InviteToGroupModal";
import SvgGroupAdd from "../components/icons/SvgGroupAdd";
import SvgSearch from "../components/icons/SvgSearch";
import SearchGroupsSheet from "./Sheets/SearchGroupsSheet";
import SearchNonProfitsSheet from "./Sheets/SearchNonProfitsSheet";
import PostComponent from "../components/posts/PostComponent";
import { getChallengeActions } from "../api/challenges";
import SvgEditNote from "../components/icons/SvgEditNote";
import GroupPicture from "../components/atoms/GroupPicture";
import SvgChevronDown from "../components/icons/SvgChevronDown";
import ChallengeDonationBar from "../components/ChallengeDonationBar";
import ChallengeAwarenessBar from "../components/ChallengeAwarenessBar";
import {getGroupPosts} from "../api/feeds";
import GroupChatInitialContent from "../components/groups/GroupChatInitialContent";


function mapDispatchToProps(dispatch) {
    return {
        toggleCreateGroupModal: () => {
            dispatch({
                type: "TOGGLE_CREATE_GROUP_MODAL",
            })
        },
        toggleCreatePostModal: (groupName, groupId) => {
            dispatch({
                type: "TOGGLE_CREATE_POST_MODAL",
                groupName,
                groupId
            })
        },
        updatePosts: (posts) => {
            dispatch({
                type: "UPDATE_POSTS",
                posts,
            })
        },
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        posts: state.postReducer.posts,
    }
}

class GroupsPage extends Component {
    state = {
        chats: [],
        posts: [],
        loading: true,
        currentGroup: {},
        challengeParticipants: [],
        groupDetailsSheetOpen: false,
        inviteToGroupModalOpen: false,
        groupSearchModalOpen: false,
        nonProfitSearchModalOpen: false,
    }

    componentDidMount() {
        this.chatsListener = attachUserChatsListener(this.props.authUser, async chats => {
            const filteredChats = chats.filter(chat => chat?.is_group);
            this.setState(prevState => {
                    const currentGroup = !!prevState.currentGroup && Object.values(prevState.currentGroup).length ? prevState.currentGroup : filteredChats[0];
                    this.getGroupsPosts(currentGroup?.id);
                    return {
                        chats: filteredChats,
                        currentGroup,
                        loading: false
                    }
                }
            );
        })
    }

    selectGroup = (group) => {
        this.setState({currentGroup: group, groupDetailsSheetOpen: false});
        this.getGroupsPosts(group.id);
    }

    toggleGroupDetailsSheet = () => {
        this.setState({groupDetailsSheetOpen: !this.state.groupDetailsSheetOpen})
    }

    toggleInviteToGroupModal = () => {
        this.setState({inviteToGroupModalOpen: !this.state.inviteToGroupModalOpen})
    }

    toggleGroupSearchModal = () => {
        this.setState({groupSearchModalOpen: !this.state.groupSearchModalOpen})
    }

    toggleNonprofitSearchModal = () => {
        this.setState({nonProfitSearchModalOpen: !this.state.nonProfitSearchModalOpen})
    }

    getGroupsPosts = async (groupId) => {
        const res = await getGroupPosts(this.props.authUser, groupId);
        this.setState({posts: res.posts});

        if (!!this.state.currentGroup?.challenge_id) {
            const actions = await getChallengeActions(this.state.currentGroup?.challenge_id)
            this.setState({challengeParticipants: actions.actions.map(action => action.user_id)})
        }
        
    }


    render() {
        const chat = this.state.currentGroup || {};
        const self = this.props.authUser.uid;
        if (this.state.loading) {
            return (
                <LoadingSection/>
            )
        }
        const numPendingApprovals = "requests" in chat ? Object.keys(chat.requests).length : 0;
        const isAdmin = "admin" in chat && Object.keys(chat.admin).includes(self);
        return (
            <div className='flex flex-col flex-1 overflow-hidden relative'>
                <MiddleViewHeader
                    title={'Groups'}
                    RightComponent={
                        <div className='flex flex-row items-center gap-3'>
                            <Button
                                size={'lg'}
                                variant={'secondary'}
                                icon={SvgUserPlus}
                                onClick={this.props.toggleCreateGroupModal}
                            >
                                Create Group
                            </Button>
                            <Button
                                size={'lg'}
                                icon={SvgUserPlus}
                                onClick={this.toggleGroupSearchModal}
                            >
                                Find Groups <SvgSearch/>
                            </Button>
                        </div>
                    }
                />
                <div className='flex flex-row flex-1 overflow-hidden bg-white'>
                    <div className='w-[300px] overflow-y-scroll flex flex-col pt-0 border-r border-slate-200'>
                        {this.state.chats.map(group =>
                            <GroupListItem
                                username={this.props.authUser.uid}
                                group={group}
                                currentGroup={this.state.currentGroup}
                                selectGroup={this.selectGroup}
                            />
                        )}
                        <div className='p-5 flex flex-col items-center gap-3'>
                            {this.state.chats.length === 0 &&
                                <div className='flex flex-col items-center gap-3 mt-12'>
                                    <SvgGroupAdd className={'text-slate-300'}/>
                                    <p className='text-slate-500 text-sm text-center'>
                                        You aren't in any groups yet.
                                    </p>
                                </div>
                            }
                            <Button size={'sm'} onClick={this.toggleGroupSearchModal}>
                                Search for {this.state.chats.length > 0 && 'more'} groups
                            </Button>
                        </div>
                    </div>
                    {this.state.chats.length > 0 &&
                        <div className='flex flex-col flex-1 relative overflow-hidden'>
                            <div className='relative flex flex-col flex-1 overflow-hidden'>
                                <div>
                                    <div
                                        onClick={this.toggleGroupDetailsSheet}
                                        className='flex absolute top-2 left-2 right-2  p-3 border border-slate-200 rounded-lg flex-col
                            bg-white hover:bg-slate-50 cursor-pointer group z-[1]
                            '>
                                        <div className='flex flex-row items-center justify-between'>
                                            <div className='flex flex-row items-center gap-3'>
                                                <GroupPicture imageId={chat.image_id}/>
                                                <p className='font-semibold'>
                                                    {chat.name}
                                                </p>
                                            </div>
                                            <div className="flex flex-row gap-2">
                                                {isAdmin && numPendingApprovals !== 0 &&
                                                    <div
                                                        className='bg-red-400 flex flex-col items-center justify-center text-white rounded-full text-sm font-semibold px-3'>
                                                        {numPendingApprovals}
                                                    </div>
                                                }
                                                <SvgChevronDown className={'-rotate-90 group-hover:text-blue-500'}/>
                                            </div>
                                        </div>
                                        {(!!chat.challenge_id && (this.state.challenge?.donations_goal || this.state.challenge?.awareness_goal)) &&
                                            <div className='mt-2 flex flex-col gap-2'>
                                                {this.state.challenge?.donations_goal > 0 ?
                                                    <div>
                                                        <ChallengeDonationBar challenge={this.state.challenge}/>
                                                    </div>
                                                    : null}
                                                {this.state.challenge?.awareness_goal > 0 ?
                                                    <div>
                                                        <ChallengeAwarenessBar challenge={this.state.challenge}/>
                                                    </div>
                                                    : null}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='flex flex-col flex-1 overflow-y-scroll relative pt-14'>
                                    {this.state.posts.map(post =>
                                        <PostComponent
                                            post={post}
                                            challengeParticipant={this.state.challengeParticipants.includes(post.creator)}
                                            isInsideGroup
                                        />
                                    )}
                                    <GroupChatInitialContent
                                        hasNoMessages={this.state.posts.length === 0}
                                        group={chat}
                                        toggleInviteToGroupModal={this.toggleInviteToGroupModal}
                                        toggleNonprofitSearchModal={this.toggleNonprofitSearchModal}
                                    />
                                </div>
                                <div className='absolute bottom-5 left-1/2 -translate-x-1/2'>
                                    <Button
                                        size={'lg'}
                                        className={' sticky'}
                                        icon={SvgUserPlus}
                                        onClick={() => this.props.toggleCreatePostModal(chat.name, chat.id)}
                                    >
                                        <SvgEditNote/>
                                        Post in this Group
                                    </Button>
                                </div>
                            </div>
                            <GroupDetailsSheet
                                isOpen={this.state.groupDetailsSheetOpen}
                                currentGroup={this.state.currentGroup}
                                toggleGroupDetailsSheet={this.toggleGroupDetailsSheet}
                            />
                            <InviteToGroupModal
                                isOpen={this.state.inviteToGroupModalOpen}
                                close={this.toggleInviteToGroupModal}
                                group={this.state.currentGroup}
                            />
                            <SearchNonProfitsSheet
                                isOpen={this.state.nonProfitSearchModalOpen}
                                close={this.toggleNonprofitSearchModal}
                                group={this.state.currentGroup}
                            />
                        </div>
                    }
                        </div>
                        <SearchGroupsSheet
                        isOpen={this.state.groupSearchModalOpen}
                     close={this.toggleGroupSearchModal}
                />
            </div>
        )
    }

}


export default connect(mapStateToProps, mapDispatchToProps)(GroupsPage);
