import { useEffect, useState } from "react";
import SvgChevronDown from "../../components/icons/SvgChevronDown";
import React from "react";
import ChallengeCard from "../../components/ChallengeCard";
import { getAllChallenges } from "../../api/challenges";
import { set } from "firebase/database";

function SearchChallengesSheet({goBack, updateChallenge}) {
    const [challenges, setChallenges] = useState([])

    useEffect(() => {
        getChallenges();
    }, [])

    const getChallenges = async () => {
        const res = await getAllChallenges();
        setChallenges(res.challenges);
    }

    return (
        <>
            <div onClick={goBack}
                 className='flex flex-row items-center gap-2 cursor-pointer text-slate-500 hover:text-slate-700'>
                <SvgChevronDown className={'rotate-90'}/>
                <p>
                    Back
                </p>
            </div>
            {challenges.map(challenge =>
                <div onClick={() => {updateChallenge(challenge.challenge_id); goBack()}}>
                    <ChallengeCard
                        key={challenge.challenge_id}
                        challenge={challenge}
                        border
                        inPost
                        noRedirect
                    />
                </div>
            )}
        </>
    )
}

export default SearchChallengesSheet
