import * as React from "react"

const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={100}
        height={100}
        {...props}
    >
        <path
            fill={props.fill || 'currentColor'}
            d="M6 1v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2v2H8V1H6zM5 5h14v2H5V5zm0 4h14v10H5V9zm8 4v4h4v-4h-4z"/>
    </svg>
)

export default SvgComponent
