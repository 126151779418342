import React, {PureComponent} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import LoginCard from "../components/LoginCard";
import SignUpCard from "../components/SignUpCard";
import icon from "../img/brand/logo.png";
import logo from "../img/brand/LOGO-PRIMARY.png";

class AuthenticationPage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'Login', // 'Login' or 'Signup',
            path: "",
            ein: "",
        };
    }

    toggleTab = () => {
        this.setState(prevState => ({
            activeTab: prevState.activeTab === 'Login' ? 'Signup' : 'Login'
        }));
    };

    componentDidMount = () => {
        const queryParameters = new URLSearchParams(window.location.search);
        const activeTab = queryParameters.get("activeTab");
        const path = queryParameters.get("path")
        const ein = queryParameters.get("ein")

        if (activeTab === "signup") {
            this.setState({activeTab: 'Signup'})
        }
        if (!!path) {
            this.setState({path});
            if (path.split("/")[0] === "referral") {
                const inviteId = window.atob(path.split("/")[1]);
                const ein = inviteId.split("-");
                this.setState({ein: `${ein[1]}-${ein[2]}`})
            }
        }
        if (!!ein) {
            this.setState({ein})
        }

    }

    render() {
        const {activeTab} = this.state;
        const commonProps = {history: this.props.history, mobile: this.props.mobile, toggleTab: this.toggleTab, path: this.state.path};

        return (
            <div
                className="full-page flex flex-col relative items-center justify-center relative min-h-[100vh] bg-brand relative">
                <div className='burst z-[0]'/>
                <div className='burst2 z-[0]'/>
                <div className='mb-5 absolute top-0 left-0 right-0 items-center flex flex-col pt-5'>
                    <div className='row-ac'>
                        <img src={icon} alt={'Icon'}
                             className='w-[60px]'/>
                        <div className='relative'>
                            <img src={logo} alt={'Starfish'}
                                 className='w-[120px] mb-1'/>
                        </div>
                    </div>
                </div>
                <div className='relative z-10 pt-[100px]'>
                    <AnimatePresence mode={'wait'}>
                        {activeTab === 'Login' ? (
                            <motion.div
                                key="login"
                                initial={{opacity: 0, y: 20}}
                                animate={{opacity: 1, y: 0}}
                                exit={{opacity: 0, y: 50}}
                                transition={{duration: 0.3, ease: "easeInOut"}}
                            >
                                <LoginCard {...commonProps} />
                            </motion.div>
                        ) : (
                            <motion.div
                                key="signup"
                                initial={{opacity: 0, y: 20}}
                                animate={{opacity: 1, y: 0}}
                                exit={{opacity: 0, y: 50}}
                                transition={{duration: 0.3, ease: "easeInOut"}}
                            >
                                <SignUpCard {...commonProps} ein={this.state.ein}/>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </div>
        );
    }
}

export default AuthenticationPage;
