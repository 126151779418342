import React, {useState} from 'react';
import TextInput from "../../components/inputs/TextInput";
import OnboardingStepHeader from "./OnboardingStepHeader";
import OnboardingButtons from "./OnboardingButtons";
import {DUMMY_CITIES} from "../../utils/dummy";
import CollapseComponent from "../../components/CollapseComponent";
import SearchDropdown from "../../components/atoms/SearchDropdown";

function OnboardingCity({goNextStep, goBackStep, city, state, setCity, setState}) {
    const handleSubmit = () => {
        goNextStep();
    };


    return (
        <div className='flex flex-col gap-8 md:w-[460px]'>
            <OnboardingStepHeader
                title={"What's your city?"}
                description={'This helps you connect with causes and people in your community'}
            />
            <SearchDropdown
                city={city}
                state={state}
                setCity={setCity}
                setState={setState}
            />
            <OnboardingButtons
                goNextStep={handleSubmit}
                goBackStep={goBackStep}
                nextDisabled={!city}
            />
        </div>
    );
}

export default OnboardingCity;
