import React, {PureComponent} from "react";
import SvgPin from "./icons/SvgPin";
import SvgCalendar from "./icons/SvgCalendar";
import SvgVolunteer from "./icons/SvgVolunteer";
import SvgStarfish from "./icons/SvgStarfish";
import SvgSupporter from "./icons/SvgSupporter";
import SvgID from "./icons/SvgID";


class ConnectionCard extends PureComponent {

    renderTag = () => {
        switch (this.props.connection.type) {
            case 'Supporter':
                return (
                    <div className='body2-bold mb-4 ph-3 br-4 row-ac'
                         style={{
                             backgroundColor: '#3e7cb150',
                             color: '#3e7cb1',
                             alignSelf: "flex-start",
                             padding: '1.5px 8px'
                         }}>
                        <SvgSupporter width={16} height={16} className={'mr-4'}/> {this.props.connection.type}
                    </div>
                )
            case 'Volunteer':
                return (
                    <div className='body2-bold mb-4 dullPurple ph-3 br-4 row-ac'
                         style={{backgroundColor: '#cc93e250', alignSelf: "flex-start", padding: '1.5px 8px'}}>
                        <SvgVolunteer width={16} height={16} className={'mr-4'}/> {this.props.connection.type}
                    </div>
                )
            case 'Employee':
                return (
                    <div className='body2-bold mb-4 ph-3 br-4 row-ac'
                         style={{backgroundColor: '#F1730050', color:'#F17300', alignSelf: "flex-start", padding: '1.5px 8px'}}>
                        <SvgID width={16} height={16} className={'mr-4'}/> {this.props.connection.type}
                    </div>
                )
        }
    }

    render() {
        return (
            <div className='bg-white-pure br-12 box profile-card' style={{maxWidth: 500}}>
                <div className='p-4'>
                    <div className='row-plain' style={{alignItems: "stretch"}}>
                        <img src={this.props.connection.img}
                             style={{
                                 width: this.props.mobile ? 60 : 88,
                                 height: this.props.mobile ? 60 : 88,
                                 objectFit: "contain",
                                 borderRadius: 12,
                                 border: '4px solid #ffffff'
                             }}/>
                        <div className='col-jb mv-4 ml-12'>
                            <div>
                                <div className={this.props.mobile ? 'body1-bold' : 'title2 mb-4'}>
                                    {this.props.connection.name}
                                </div>
                                <div className='body2-bold black-50'>
                                    {this.props.connection.category}
                                </div>
                            </div>
                            {this.renderTag()}
                        </div>
                    </div>
                    <div className='mt-12 body2 black-100'>
                        {this.props.connection.desc}
                    </div>
                    <div className='row-ac body2-bold mt-16'
                         style={{color: this.props.connection.type === 'Volunteer' ? '#cc93e2' : this.props.connection.type === 'Supporter' ? '#3e7cb1' : '#F17300'}}>
                        <div className='row-ac mr-20'>
                            <SvgCalendar width={18} height={18} className={'mr-4'}/>
                            {this.props.connection.type} since {this.props.connection.since}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ConnectionCard
