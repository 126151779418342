import React, {useState, useEffect} from 'react';
import TextInput from "../../components/inputs/TextInput";
import NonProfitSearchDropdownResult from "./NonProfitSearchDropdownResult";
import {filteredNonProfits, searchNonProfits} from "../../utils/typesense";
import {getDefaultNonProfits, foll} from "../../api/nonProfits";
import {connect} from "react-redux";
import Spinner from "../Spinner";
import LoadingSection from "../LoadingSection";


function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
        mobile: state.userReducer.mobile,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addInterest: (interest) => {
            dispatch({
                type: "ADD_INTEREST",
                interest,
            })
        },
    }
}


function NonProfitSearchDropdown(props) {
    const [search, setSearch] = useState('');
    const [nonProfits, setNonProfits] = useState([]);
    const [loading, setLoading] = useState(true)

    const handleSearchChange = (event) => {
        const search = event.target.value;

        if (!search) getNonProfits();
        else {
            searchNonProfits(search).then((nonProfitResults) => {
                setNonProfits(nonProfitResults);
            })
        }
        setSearch(search);
    };

    useEffect(() => {
        getNonProfits();
    }, []);

    const getNonProfits = async () => {
        const nonProfitResults = await filteredNonProfits(props.categories, props.city, props.state)
        if (nonProfitResults.length < 4) {
            const nonProfits = await getDefaultNonProfits(props.authUser);
            setNonProfits(nonProfits.non_profits);
            setLoading(false)
        } else {
            setNonProfits(nonProfitResults);
            setLoading(false)
        }
    }

    return (
        <div className='flex flex-col relative'>
            <TextInput
                label={'Find nonprofits'}
                value={search}
                placeholder={'Search causes...'}
                onChange={handleSearchChange}
            />
            <div className='pt-3 h-[300px] overflow-y-scroll'>
                {!!loading &&
                <div className='mt-12'>
                    <Spinner dark/>
                </div>
                }
                {(nonProfits).map((res) =>
                    <NonProfitSearchDropdownResult
                        item={res}
                        key={res.ein}
                        selectNp={props.onSelect}
                    />
                )}
            </div>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(NonProfitSearchDropdown);
