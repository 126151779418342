import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            width="48px"
            height="48px"
            {...props}
        >
            <path fill={'currentColor'} d="M45 11c-2.991 0-5.91 1.242-8.26 2.646-3.901 1.947-7.295 1.38-10.584.827C24.712 14.23 23.35 14 22 14c-6.458 0-14 3.405-14 13 0 10.379 2.007 22.797 2.092 23.32.002.013.007.023.01.035.01.057.026.111.04.166.02.074.041.148.069.217.015.039.033.075.05.112a1.975 1.975 0 00.2.336 1.992 1.992 0 00.424.421c.034.026.069.051.105.075a2 2 0 00.217.117c.04.019.078.038.12.055.082.032.166.058.253.08.033.008.064.02.098.027.125.025.254.039.384.039h6.133a2 2 0 002-2v-8.844c1.566.46 3.128.627 4.551.627 1.323 0 2.467-.147 3.436-.316.486 4.053 1.853 8.845 1.923 9.09A1.998 1.998 0 0032.027 52H38a2 2 0 002-2v-7a2 2 0 00-4 0v5h-2.441C32.989 45.81 32 41.625 32 39a2 2 0 00-2-2c-.226 0-.44.046-.643.115l-.002-.008c-.059.02-5.78 1.921-10.128-.695l-.004.006a1.977 1.977 0 00-1.028-.291 2 2 0 00-2 2V48H13.79C13.272 44.5 12 35.12 12 27c0-8.351 7.654-9 10-9 1.014 0 2.22.204 3.492.418 1.664.28 3.512.57 5.51.568 0 .034.004.068.006.102.001.031-.003.062 0 .094.044.475 1.13 11.72 7.472 19.12a2.002 2.002 0 002.55.413c.466-.28 3.858-2.389 5.796-5.61 1.665 1.044 4.283 2.326 7.903 3.127C54.056 40.295 52.432 44 50 44a2.002 2.002 0 01-.5-3.938 2 2 0 10-.996-3.873A5.997 5.997 0 0044 42c0 3.309 2.691 6 6 6 4.386 0 7.571-4.142 8.691-11.166C59.74 36.931 60.828 37 62 37a2 2 0 000-4c-9.923 0-14.552-4.38-14.588-4.414a1.995 1.995 0 00-1.947-.514 2.002 2.002 0 00-1.406 1.444c-.462 1.85-2.238 3.62-3.653 4.75-3.829-5.424-4.992-12.33-5.283-14.49a24.916 24.916 0 013.635-2.665c.354-.183.712-.38 1.072-.597.035-.021.063-.05.096-.073C41.51 15.623 43.274 15 45 15c3.383 0 7.909 7.254 9.545 13.496a2.001 2.001 0 002.44 1.428 2 2 0 001.427-2.442C56.62 20.638 51.272 11 45 11z" />
        </svg>
    )
}

export default SvgComponent
