import axios from "./axios";

export const getCampaign = async (campaign_id) => {
    try {
        const config = {
            params: {campaign_id}
        };
        const res = await axios.get("/campaigns", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}