import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="30px"
            height="30px"
            {...props}
        >
            <path fill={'currentColor'} d="M27.968 12.171c-.139-.792-.809-1.458-1.666-1.657-1.022-.238-5.331-.501-6.134-.536-.264-.012-.903-.104-1.159-.73-1.48-3.628-2.633-5.683-2.82-6.011-.392-.825-1.175-1.291-1.96-1.229-.766.074-1.383.647-1.573 1.462a.853.853 0 00-.023.149c-.003.038-.309 3.843-1.183 5.906-.17.402-.17.402-.968.471l-.154.014c-2.304.208-4.273.334-5.45.41-.458.029-.809.052-1.031.069a1.991 1.991 0 00-1.782 1.487 1.835 1.835 0 00.8 2.011c.074.049 1.879 1.227 5.615 3.429.443.262.665.572.401 1.312-.404 1.136-2.49 6.661-2.49 6.661a1.914 1.914 0 00.68 2.275c.676.47 1.535.443 2.176-.06.497-.291 3.169-1.908 5.443-4.232.295-.302.445-.348.446-.354.016.007.097.044.262.213 2.323 2.406 4.9 4.08 5.344 4.361.339.271.739.409 1.143.409.363 0 .729-.111 1.051-.337a1.913 1.913 0 00.713-2.181c-.145-.712-1.226-6.04-1.421-6.855l-.074-.295c-.181-.693-.176-.73.131-.986.706-.591 3.748-2.628 4.854-3.358.644-.42.955-1.099.829-1.818zM14 19a1 1 0 110-2 1 1 0 010 2zm2-5a1 1 0 110-2 1 1 0 010 2zm2.5 2a.5.5 0 110-1 .5.5 0 010 1z" />
        </svg>
    )
}

export default SvgComponent
