import axios from "./axios";

export const getChallenge = async (challenge_id) => {
    // if (!firebaseAuth().currentUser) {
    //     return;
    // }
    try {
        // const token = await firebaseAuth().currentUser.getIdToken();
        const config = {
            // headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {challenge_id}
        };
        const res = await axios.get("/challenges/public", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const getChallengeActions = async (challenge_id) => {
    // if (!firebaseAuth().currentUser) {
    //     return;
    // }
    try {
        // const token = await firebaseAuth().currentUser.getIdToken();
        const config = {
            // headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {challenge_id}
        };
        const res = await axios.get("/challenges/activity/public", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getAllChallenges = async () => {
    try {
        const config = {
            headers: {mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.get("/challenges/feed/public", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getNonProfitChallenges = async (user, queried_user_id, ein) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {queried_user_id, ein}
        };
        const res = await axios.get("/challenges/nonprofit", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getUserChallenges = async (user, queried_user_id) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {queried_user_id}
        };
        const res = await axios.get("/challenges/user", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const createChallenge = async (user, params) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post("/challenges", params, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const createChallengeAction = async (user, params) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            params
        }
        const res = await axios.put("/challenges", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getPrivateChallenge = async (user, challenge_id) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {challenge_id}
        };
        const res = await axios.get("/challenges", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}