import React, {Component} from "react";
import PostLikedCount from "../PostLikedCount";
import SvgMessage from "../icons/SvgMessage";
import SvgShare from "../icons/SvgShare";
import {likeUnlikePost} from "../../api/posts";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import SvgRepost from "../icons/SvgRepost";
import {wait} from "../../utils/timestamp";
import SvgCheck from "../icons/SvgCheck";
import RepostModal from "../modals/RepostModal";

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
        posts: state.postReducer.posts,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        likePost: (postId, userId) => {
            dispatch({
                type: "LIKE_POST",
                postId,
                userId,
            })
        },
    }
}

class PostInteractionsRow extends Component {
    state = {
        copiedLink: false,
        repostModalOpen: false
    }

    navigateToPost = (e) => {
        e.stopPropagation()
        if (!this.props.inFullPage) {
            this.props.history.push('/post/' + this.props.post.post_ID);
        }
    }

    copyLinkToClipboard = (e) => {
        e.stopPropagation()
        this.setState({copiedLink: true})
        const link = `https://share.starfishapp.com/post/${this.props.post.post_ID}`
        navigator.clipboard.writeText(link)
            .then(() => {
                console.log('Link copied to clipboard:', link);
                // You can show a success message or perform other actions here
            })
            .catch((error) => {
                console.error('Failed to copy link to clipboard:', error);
                // You can show an error message or handle the error here
            });
        wait(3000).then(() => {
            this.setState({copiedLink: false})
        })
    }

    likePost = async (e) => {
        e.stopPropagation();
        if (this.props.static) this.props.history.push({pathname: `/authentication`, state: {previous: "Back"}});
        this.props.likePost(this.props.post.post_ID, this.props.user.username);
        await likeUnlikePost(this.props.authUser, this.props.post.post_ID);
    }

    openRepostModal = (e) => {
        e.stopPropagation()
        if (this.props.static) this.props.history.push({pathname: `/authentication`, state: {previous: "Back"}});
        this.setState({repostModalOpen: true})
    }

    closeRepostModal = () => {
        this.setState({repostModalOpen: false})
    }


    render() {
        const user = this.props.user || {};
        const post = this.props.post || {};
        const np = post.non_profit || {};
        const likes = post?.likes || {};
        return (
            <div className='flex flex-row items-center gap-5 font-semibold text-slate-500'>
                <PostLikedCount
                    active={this.props.static ? false : (!!user.username ? user.username in post.likes : false)}
                    toggle={this.likePost}
                    numLikes={post.num_likes || 0}
                />
                <div onClick={this.navigateToPost}
                     className='flex flex-row items-center gap-1 cursor-pointer'>
                    <SvgMessage className={'w-5 h-5'}/>
                    {post.comments &&
                        <p className=''>
                            {post.comments.length ? post.comments.length : post.comments}
                        </p>
                    }
                </div>
                <div onClick={this.openRepostModal} className='flex flex-row items-center gap-1 cursor-pointer'>
                    <SvgRepost className={'w-5 h-5 scale-[1.2]'}/>
                    <p className=''>
                        Repost
                    </p>
                </div>
                <div onClick={this.copyLinkToClipboard}
                     className={`flex flex-row items-center gap-1  cursor-pointer ${this.state.copiedLink && 'text-blue-500'}`}>
                    {this.state.copiedLink ?
                        <SvgCheck className={'w-5 h-5 scale-[1.2]'}/>
                        :
                        <SvgShare className={'w-5 h-5 scale-[1.2]'}/>
                    }
                    <p>
                        {this.state.copiedLink ?
                            'Link copied!' :
                            'Share'
                        }
                    </p>
                </div>
                <RepostModal
                    isOpen={this.state.repostModalOpen}
                    post={post}
                    closeModal={this.closeRepostModal}
                />
            </div>
        )
    }

}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostInteractionsRow));
