import React, {PureComponent} from "react";
import {motion} from "framer-motion";
import SvgSearch from "../../components/icons/SvgSearch";
import SvgMegaphone from "../../components/icons/SvgMegaphone";
import {withRouter} from "react-router-dom";


class ChallengeCTASection extends PureComponent {
    state = {}


    render() {
        return (
            <div className={`col-ac`}
                 style={{
                     padding: this.props.mobile ? '80px 24px' : '8vw 10vw 0vw 10vw'
                 }}>
                <motion.div style={{maxWidth: !this.props.mobile && '50vw', border: '2px solid #054A91'}}
                            initial={{opacity: 0, transform: 'scale(1) translateY(12px)'}}
                            whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            transition={{default: {ease: "linear"}, delay: .2}}
                            className={this.props.mobile ? 'mb-40 col-ac text-center p-5 br-24' : 'col-ac text-center  p-12 br-24'}>

                    <div className='row-ac primary mb-20'
                         style={{border: '1px solid #00000010', padding: '4px 20px', borderRadius: 100}}>
                        <div
                            className={`nav-icon  mt-4`}>
                            <SvgMegaphone width={20} height={20} className={''}/>
                        </div>
                        <p className='body2 ml-8'>
                            New Feature
                        </p>
                    </div>
                    <h1 className={`${this.props.mobile ? 'heading4' : 'heading3'}  mb-12 mt-0 text-center primary`}>
                        Introducing Starfish Challenges!
                    </h1>
                    <p className='mt-0 body1 black-70'>
                        A new way to get involved. Find all kinds of exciting causes to support!
                    </p>
                    <button onClick={() => this.props.history.push('/challenges')}  className='primary-button body1-bold mt-32'>
                        Find challenges
                    </button>
                </motion.div>
            </div>
        )
    }
}

export default withRouter(ChallengeCTASection)
