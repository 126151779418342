import React, {useState} from 'react';
import TextInput from "../../components/inputs/TextInput";
import OnboardingStepHeader from "./OnboardingStepHeader";
import OnboardingButtons from "./OnboardingButtons";
import {NPO_CATEGORIES_DICT} from "../../utils/dummy";

function OnboardingCategories({goNextStep, goBackStep, categories, onChangeCategory}) {
    const handleSubmit = () => {
        goNextStep();
    };

    return (
        <div className='flex flex-col gap-8 md:w-[460px]'>
            <OnboardingStepHeader
                title={'Pick your favorite causes'}
                description={'Select the categories you care about most'}
            />
            <div className='grid grid-cols-2 gap-2 md:gap-5'>
                {NPO_CATEGORIES_DICT.map((category, index) =>
                    <div
                        key={category.name}
                        onClick={() => onChangeCategory(category.name)}
                        className={`border p-2 md:p-3 pr-5 flex flex-row gap-3 items-center justify-center rounded-xl
                        ${categories.includes(category.name) ? 
                        'text-white bg-blue-500'
                            :
                            'border-slate-200 hover:bg-slate-100 hover:text-blue-500 cursor-pointer text-slate-800'
                        }
                    
                    `}>
                        <category.icon className={'w-6 h-6 md:w-10 md:h-10'}/>
                        <p className='text-sm font-medium flex flex-1'>
                            {category.name}
                        </p>
                    </div>
                )}
            </div>
            <OnboardingButtons
                goNextStep={handleSubmit}
                goBackStep={goBackStep}
                nextDisabled={!categories.length}
            />
        </div>
    );
}

export default OnboardingCategories;
