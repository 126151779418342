import React, {Component} from "react";
import moment from "moment";
import ProfilePicture from "./atoms/ProfilePicture";
import {CARD_STYLE_NO_HOVER} from "../utils/dummy";

class DonationCard extends Component {

    render() {
        const action = this.props.action || {};
        const createdAt = moment(action.created_at);
        return (
            <div className={`${CARD_STYLE_NO_HOVER}`}>
                <div className='row-ac'>
                    <ProfilePicture
                        initial={action.creator_name[0]}
                        userID={action.user_id}
                    />
                    <div className='flex flex-col'>
                        <div className='col-plain'>
                            <p className='body2 black-100'>
                                <span style={{fontWeight: 'bold'}}>{action.creator_name}</span> donated to the
                                Challenge.
                            </p>
                        </div>
                        <p className='body3 black-50'>
                            {createdAt.fromNow()}
                        </p>
                    </div>
                </div>
                {!!action.memo &&
                    <p className='body2 mt-4'>
                        {action.memo}
                    </p>
                }
            </div>
        )
    }

}


export default DonationCard;
