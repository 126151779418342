import React, {Component} from "react";
import Avatar from "boring-avatars";

class ProfilePicture extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageError: false
        };
    }

    handleImageError = () => {
        this.setState({imageError: true});
    };

    render() {
        const {imageError} = this.state;
        const {userID} = this.props;
        const fallbackInitials = this.props.initial;
        const primary = '#2196f3';
        const primary2 = '#2196f3'
        const secondary = '#2196f3';
        const tertiary = '#349aea';
        const quaternary = '#0c6cb9'
        return (
            <div style={{display: 'inline-block', position: 'relative'}} className='mr-8'>
                {!imageError ? (
                    <img
                        src={`https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/users%2Fprofile_picture%2F${userID}.jpeg?alt=media`}
                        onError={this.handleImageError}
                        style={{width: this.props.size || 40, height: this.props.size || 40, borderRadius: '50%'}}
                        alt='User Profile'
                        className='aspect-square'
                    />
                ) : (
                    <Avatar
                        size={this.props.size || 40}
                        name={userID}
                        variant="beam"
                        colors={[primary, secondary, tertiary, quaternary]}
                    />
                    // <div
                    //     style={{
                    //         width: this.props.size || 40,
                    //         height: this.props.size || 40,
                    //         borderRadius: '50%',
                    //         backgroundColor: '#ccc',
                    //         display: 'flex',
                    //         justifyContent: 'center',
                    //         alignItems: 'center',
                    //         fontSize: '16px',
                    //         fontWeight: 'bold',
                    //         color: 'white'
                    //     }}>
                    //     {fallbackInitials}
                    // </div>
                )}
            </div>
        );
    }
}

export default ProfilePicture
