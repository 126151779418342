import React, {useState} from 'react';
import TextInput from "../../components/inputs/TextInput";
import OnboardingStepHeader from "../Onboarding/OnboardingStepHeader";
import OnboardingButtons from "../Onboarding/OnboardingButtons";
import AccessibleToggle from "../../components/atoms/AccessibleToggle";

function CreateGroupName({goNextStep, goBackStep, name, setName, description, setDescription, isPrivate, setIsPrivate, closeSheet}) {
    const [errorMessage, setErrorMessage] = useState('');

    const handleNameChange = (event) => {
        if (errorMessage) setErrorMessage('');
        setName(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        if (errorMessage) setErrorMessage('');
        setDescription(event.target.value);
    };

    const validateForm = () => {
        if (!name) {
            setErrorMessage('Please enter your first name.');
            return false;
        }
        if (!description) {
            setErrorMessage('Please enter your last name.');
            return false;
        }
        return true;
    };

    const handleSubmit = () => {
        if (validateForm()) {
            goNextStep();
        }
    };

    return (
        <div className='flex flex-col gap-8 w-[460px]'>
            <OnboardingStepHeader
                title={'Give your Group a name!'}
                description={"Pick a catchy name for your group. A good name and description helps others find your group!"}
            />
            <div className='flex flex-col gap-5'>
                <TextInput
                    label={'Name'}
                    value={name}
                    placeholder={'e.g. Neighborhood Pals'}
                    onChange={handleNameChange}
                />
                <TextInput
                    label={'Description'}
                    value={description}
                    textarea
                    placeholder={'e.g. Bringing together the people of the neighborhood to do good!'}
                    onChange={handleDescriptionChange}
                    className={'min-h-[140px]'}
                />
                <AccessibleToggle
                    label="Make this group private?"
                    subLabel={"Users will request to join, you control who gets accepted."}
                    value={isPrivate}
                    onToggle={setIsPrivate}
                />
                <p className={`text-xs h-4 ${errorMessage ? 'text-red-400' : 'text-transparent'}`}>
                    {errorMessage}
                </p>
            </div>
            <OnboardingButtons
                goNextStep={handleSubmit}
                goBackStep={closeSheet}
                nextDisabled={!name || !description}
            />
        </div>
    );
}

export default CreateGroupName;
