import SvgChevronDown from "../icons/SvgChevronDown";
import React from "react";
import {withRouter} from "react-router-dom";
import ProfilePicture from "../atoms/ProfilePicture";
import SvgCheck from "../icons/SvgCheck";
import { Button } from "../atoms/Button";

function GroupMembersListItem({username, name, removeMember, user, isAdmin, history, isChallengeParticipant}) {
    const navigateToUser = (username) => {
        history.push({pathname: `/profile/${username}`, state: {previous: "Back"}})
    }
    return (
        <div className='flex flex-row items-center gap-3 justify-between cursor-pointer' onClick={() => navigateToUser(username)}>
            <div className="flex flex-row items-center gap-3">
                {isChallengeParticipant ? 
                    <SvgCheck className={'primary'}/>
                    :
                    <div className="ml-24"></div>
                }
                <ProfilePicture
                    userID={username}
                />
                <p>
                    {name}
                </p>
            </div>
            {user.username !== username && isAdmin &&
                <Button size={'sm'} variant={'destructive'} onClick={(e) => {e.stopPropagation(); removeMember(username, name)}}>
                    Remove
                </Button>
            }
        </div>
    )
}

export default withRouter(GroupMembersListItem)
