import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
            width="50px"
            height="50px"
            {...props}
        >
            <path fill={'currentColor'} d="M28.875 11A.998.998 0 0028 12v7.063c-11.512.351-17.719 6.445-20.781 12.5C4.113 37.699 4.004 43.682 4 43.874V44c-.016.55.418 1.016.969 1.031A1.002 1.002 0 006 44.063V44c.008-.094.23-3.121 3.094-6.344 2.812-3.16 8.3-6.347 18.906-6.562V38c0 .383.219.734.566.902a.994.994 0 001.059-.12l16-13a.998.998 0 000-1.563l-16-13c-.21-.172-.48-.25-.75-.219zM30 14.094L43.406 25 30 35.906V30c0-.55-.45-1-1-1-11.754 0-18.09 3.613-21.406 7.344-.125.14-.196.265-.313.406A27.351 27.351 0 019 32.437C11.918 26.672 17.488 21 29 21c.55 0 1-.45 1-1z" />
        </svg>
    )
}

export default SvgComponent
